/* eslint-disable */
let marketId = 28
export default {
  // 获取USDT交易对
  param_ALL: {
    'fid': 'marketdetail-req',
    'symbol': ['USDT', 'GOOD'],
    'marketId': marketId
  },
  param_USDT: {
    "fid":"marketdetail-req",
    "symbol":["btc"],
    "marketId":marketId
  },
  param_GU: {
    'fid': 'marketdetail-req',
    'symbol': ['usdt'],
    'marketId': marketId
  },
  param_GOOD: {
    'fid': 'marketdetail-req',
    'symbol': ['eth'],
    'marketId': marketId
  },
  param_tradeTop: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'symbol',
    'direction': 'asc',
    'marketId': marketId
  },
  param_tradeBottom: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'symbol',
    'direction': 'desc',
    'marketId': marketId
  },
  param_priceTop: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'close',
    'direction': 'asc',
    'marketId': marketId
  },
  param_priceBottom: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'close',
    'direction': 'desc',
    'marketId': marketId
  },
  param_UpDownTop: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'quotechange',
    'direction': 'asc',
    'marketId': marketId
  },
  param_UpDownBottom: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'quotechange',
    'direction': 'desc',
    'marketId': marketId
  },
  param_amountTop: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'amount',
    'direction': 'asc',
    'marketId': marketId
  },
  param_amountBottom: {
    'fid': 'marketdetail-req',
    'symbol': '',
    'orderBy': 'amount',
    'direction': 'desc',
    'marketId': marketId
  },
  //  top
  param_top: {
    'fid': 'subscribe-req',
    'subscribeType':'top',
    'symbol': '100001',
    // 'depth': 0,
    'topNum': 8
  },
  //  实时成交
  param_realtime: {
    'fid': 'pricedetail-req',
    'marketId': 111,
    'contractId': '',
    'bCtrl': 1,
    'positionIndex': -500,
    'count': 500
  },
  //  订阅
  param_subscribe: {
    'fid': 'subscribe-req',
    "subscribeType":"market",
    'contractIds': ['100001', '100002', '100003']
  },

  // 定义socket请求挂牌参数
  param_contract: {
    'fid': 'contract-req',
    'marketId': ''
  },
  // 定义市场最新报价参数
  param_price: {
    'fid': 'lpmkt-req',
    'marketId': ''
  },
  //  param_subscribe: {
  //      "fid": "subscribe-req",
  //      "contractIds": []
  //  }

}
