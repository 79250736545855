 export default {
     //获取USDT交易对
     param_ALL: {
         "fid": "marketdetail-req",
         "symbol": ["USDT", "GOOD"]
     },
     param_USDT: {
         "fid": "marketdetail-req",
         "symbol": ["btc"]
     },
     param_GU: {
         "fid": "marketdetail-req",
         "symbol": ["usdt"]
     },
     param_GOOD: {
         "fid": "marketdetail-req",
         "symbol": ["eth"]
     },
     param_tradeTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "symbol",
         "direction": "asc"
     },
     param_tradeBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "symbol",
         "direction": "desc"
     },
     param_priceTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "close",
         "direction": "asc"
     },
     param_priceBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "close",
         "direction": "desc"
     },
     param_UpDownTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "quotechange",
         "direction": "asc"
     },
     param_UpDownBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "quotechange",
         "direction": "desc"
     },
     param_amountTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "amount",
         "direction": "asc"
     },
     param_amountBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "amount",
         "direction": "desc"
     },
     //  top
     param_top: {
         "fid": "top-req",
         "symbol": "  ",
         "depth": 0,
         "topNum": 10
     },
     //  实时成交
     param_realtime: {
         "fid": "pricedetail-req",
         "marketId": 111,
         "contractId": "",
         "bCtrl": 1,
         "positionIndex": -500,
         "count": 500
     },
     //  订阅
     param_subscribe: {
         "fid": "subscribe-req",
         "contractIds": ['100001', '100002', '100003']
     },
    
     //定义socket请求挂牌参数
     param_contract: {
         "fid": "contract-req",
         "marketId": ""
     },
     //定义市场最新报价参数
     param_price: {
         "fid": "lpmkt-req",
         "marketId": ""
     },
    //  param_subscribe: {
    //      "fid": "subscribe-req",
    //      "contractIds": []
    //  }
    
 }