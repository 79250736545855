import Vue from "vue";
import locale from 'element-ui/lib/locale';
import VueI18n from "vue-i18n";
import messages from "./langs";
Vue.use(VueI18n);
//浏览器默认语言
let sLanguage = navigator.language.slice(-2).toLowerCase();
sLanguage == 'cn'|| sLanguage == 'en'|| sLanguage == 'tw' ? sLanguage : sLanguage = 'cn';
//从localStorage拿到用户的语言选择，如果没有，那npm就默认浏览器语言。
const i18n = new VueI18n({
  locale: localStorage.lang || sLanguage,
  // navigator.language
  messages,
})
locale.i18n((key, value) => i18n.t(key, value)) //为了实现element-ui插件的多语言切换
export default i18n;
