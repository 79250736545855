// state
const state = {
  // 新人竞拍选中信息(详情页)
  newPeCommodity: {},
  // 全民竞拍选中信息(详情页)
  allPeAction: {},
  // 会员竞拍选中信息(详情页)
  memberBid: {},
  // 领拍选中信息(详情页)
  takenDeatil: {},
  // 积分商城选中信息
  integralDetail: {},
  // 用户报名的商品列表
  selectFirmApplyCommodity: [],
  // 商城选中的商品(商品列表信息)
  selectMallGood: {},
  // 商城选中的商品(商品详情信息)
  selectMallGoodDetail: {},
  // 商城选中商品规格信息 仅在下单使用
  mallSkuData: {},
  // plus选中的商品(商品列表信息)
  selectPlusGood: {},
  // plus选中的商品
  selectPlusGoodDetail: {},
  // 积分商城选中的商品(详情)
  selectInterGood: {},
  // 特卖专区选中商品(列表)
  selectSpecialGood: {},
  // 特卖专区选中商品详情
  selectSpecialGoodDetail: {},
  // 特卖专区规格信息 仅在下单使用
  specialSkuData: {},
  // 我的->我的竞拍,付款选中竞拍信息
  selectAuctionItemFirm: {},
  // 我的拍品
  mytakenDeatil: {},
  // 支付方式
  ishopDictPayTypeList: {},

  // 竞价选中商品列表信息
  auctionEnrolment:{}
}

// getters
const getters = {
  newPeCommodityGetter: state => state.newPeCommodity,
  allPeActionGetter: state => state.allPeAction,
  memberBidGetter: state => state.memberBid,
  takenDeatilGetter: state => state.takenDeatil,
  selectFirmApplyCommodityGetter: state => state.selectFirmApplyCommodity,
  selectMallGoodGetter: state => state.selectMallGood,
  selectMallGoodDetailGetter: state => state.selectMallGoodDetail,
  mallSkuDataGetter: state => state.mallSkuData,
  integralDetailGetter: state => state.integralDetail,
  selectPlusGoodGetter: state => state.selectPlusGood,
  selectPlusGoodDetailGetter: state => state.selectPlusGoodDetail,
  selectInterGoodGetter: state => state.selectInterGood,
  selectSpecialGoodGetter: state => state.selectSpecialGood,
  selectSpecialGoodDetailGetter: state => state.selectSpecialGoodDetail,
  specialSkuDataGetter: state => state.specialSkuData,
  selectAuctionItemFirmGetter: state => state.selectAuctionItemFirm,
  mytakenDeatilGetter: state => state.mytakenDeatil,
  ishopDictPayTypeListGetter: state => state.ishopDictPayTypeList,
  auctionEnrolmentGetter: state => state.auctionEnrolment,
}

// actions
const actions = {

}

// mutations
const mutations = {
  setNewPeCommodity(state, data) {
    state.newPeCommodity = data
  },
  setAllPeAction(state, data) {
    state.allPeAction = data
  },
  setMemberBid(state, data) {
    state.memberBid = data
  },
  setTakenDeatil(state, data) {
    state.takenDeatil = data
  },
  setIntegralDetail(state, data) {
    state.integralDetail = data
  },
  setSelectFirmApplyCommodity(state, data) {
    state.selectFirmApplyCommodity = data
  },
  setSelectMallGood(state, data) {
    state.selectMallGood = data
  },
  setSelectMallGoodDetail(state, data) {
    state.selectMallGoodDetail = data
  },
  setMallSkuData(state, data) {
    state.mallSkuData = data
  },
  setSelectPlusGood(state, data) {
    state.selectPlusGood = data
  },
  setSelectPlusGoodDetail(state, data) {
    state.selectPlusGoodDetail = data
  },
  setSelectInterGood(state, data) {
    state.selectInterGood = data
  },
  setSelectSpecialGood(state, data) {
    state.selectSpecialGood = data
  },
  setSelectSpecialGoodDetail(state, data) {
    state.selectSpecialGoodDetail = data
  },
  setSpecialSkuData(state, data) {
    state.specialSkuData = data
  },
  setSelectAuctionItemFirm(state, data) {
    state.selectAuctionItemFirm = data
  },
  setMytakenDeatil(state, data) {
    state.mytakenDeatil = data
  },
  setIshopDictPayTypeList(state, data) {
    state.ishopDictPayTypeList = data
  },
  setAuctionEnrolment(state, data) {
    state.auctionEnrolment = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
