 export default {
     //获取GC交易对
     param_ALL: {
         "fid": "marketdetail-req",
         "symbol": ["GC", "GOOD"]
     },
     param_GC: {
         "fid": "marketdetail-req",
         "symbol": ["btc"]
     },
     param_GU: {
         "fid": "marketdetail-req",
         "symbol": ["usdt"]
     },
     param_GOOD: {
         "fid": "marketdetail-req",
         "symbol": ["eth"]
     },
     param_tradeTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "symbol",
         "direction": "asc"
     },
     param_tradeBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "symbol",
         "direction": "desc"
     },
     param_priceTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "close",
         "direction": "asc"
     },
     param_priceBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "close",
         "direction": "desc"
     },
     param_UpDownTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "quotechange",
         "direction": "asc"
     },
     param_UpDownBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "quotechange",
         "direction": "desc"
     },
     param_amountTop: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "amount",
         "direction": "asc"
     },
     param_amountBottom: {
         "fid": "marketdetail-req",
         "symbol": "",
         "orderBy": "amount",
         "direction": "desc"
     },
     //  top
     param_top: {
         "fid": "top-req",
         "symbol": "  ",
         "depth": 0,
         "topNum": 7
     },
     //  实时成交
     param_realtime: {
         "fid": "pricedetail-req",
         "marketId": 111,
         "contractId":"",
         "bCtrl": 1,
         "positionIndex": -500,
         "count": 500,
         "bsFlag":-1
     },
     //  订阅
     param_subscribe: {
         "fid": "subscribe-req",
        //  "contractIds": ['100001','100002','100003'],
         "contractIds": ['1']
     },
    //  取消订阅
    param_unsubscribe: {
        "fid": "unsubscribe-req",
        "contractIds": ['1'],
    },
     //定义socket请求挂牌参数
     param_contract: {
         "fid": "contract-req",
         "marketId": ""
     },
     //定义市场最新报价参数
     param_price: {
         "fid": "lpmkt-req",
         "marketId": ""
     },
    //  param_subscribe: {
    //      "fid": "subscribe-req",
    //      "contractIds": []
    //  }

     // 获取挂牌当日分钟线
     param_timeline:{
        "fid": "timeline-req",
        "marketId": "",
        "contractId": '121000269',
        "dateIndex": 0
    }, 
    //   请求挂牌K线
    param_kline:{
        "fid": "kline-req",
        "marketId": "",
        "contractId": '121000269',
        "period":"",
        "mulnum": 1,
        "positionIndex": -500,
        "count": 500
    },
 }