/* eslint-disable */
import store from '@/store/index'
const hqWsZyq = (function() {
  const self = {}
  const callbackPool = [] // onMessage分类处理函数
  const delayPool = [] // 延迟处理请求数据存储池
  let registerPool = [] // 已注册方法存储池
  var ws = null
  const connected = false

  function newWebSocket() {
    let wsURL = '';
    let wsTmp = null
     try{
       wsURL = window.g.HQ_URL_PATH?window.g.HQ_URL_PATH:sessionStorage.getItem('HQ_URL_PATH')
     } catch (e) {
       wsURL = sessionStorage.getItem('HQ_URL_PATH')
     }

     wsTmp = new WebSocket(wsURL)
    wsTmp.onopen = function(evnt) {
      // // console.log("open Socket Success");
      onOpen(evnt)
    }
    wsTmp.onmessage = function(evnt) {
      onMessage(evnt)
    }
    wsTmp.onclose = function(evnt) {
      onclose(evnt)
    }
    wsTmp.onerror = function(evnt) {
      onError(evnt)
    }
    return wsTmp
  }
  ws = newWebSocket()
  // Socket连接成功后触发OnOpen函数，调用请求可能会快于握手，所以需要在onOpen中判定下dedaypool 中是否有未发送的请求数据
  function onOpen() {
    // // console.log('b'+ws.readyState);
    // ws.readyState = true;
    updateStatus('onOpen : ' + (connected ? 'TTrue' : 'FFalse'))
    // 重新发送请求
    setTimeout(() => {
      const market = store.state.hqData.marketDetails[0].symbolId
      self.send(hqProtocolZyq.param_USDT)

      if(!hqProtocolZyq.param_realtime.contractId){
        hqProtocolZyq.param_realtime.contractId = market
      }
      if(!hqProtocolZyq.param_top.symbol){
        hqProtocolZyq.param_top.symbol = market
      }
      self.send(hqProtocolZyq.param_top)
      self.send(hqProtocolZyq.param_realtime)
      self.send(hqProtocolZyq.param_subscribe)
    }, 1000)
    // 缓存池中存在请求
    while (delayPool.length > 0) {
      const popData = delayPool.shift()
      self.send(popData)
    }
  }
  // Socket断开连接后触发Onclose函数
  function onclose() {
    // ws.readyState = false;
    updateStatus('onClosed : ' + (connected ? 'TTrue' : 'FFalse'))
    // $timeout(function () {
    setTimeout(() => {
      // // console.log('Reconnecting to server...')
      ws = newWebSocket()
    }, 3000)
  }
  // Socket获取到从服务端返回的值，触发onMessage函数。
  function onMessage(evnt) {
    // 执行回调函数 ------------前任用这种写法有bug，万一某个函数里面有异步就g了
    for (let j = 0; j < registerPool.length; j++) {
      registerPool[j](JSON.parse(evnt.data))
    }
  }
  // 注册OnMessage 方法的回调函数
  self.register = function(fn) {
    // 遍历并判断是否已经注册过该函数。
    for (let i = 0; i < registerPool.length; i++) {
      // 如果已经注册该函数，则用函数替代掉原来注册的函数以销毁 全局变量
      if (registerPool[i].toString() == fn.toString()) {
        registerPool[i] = fn
        return
      }
    }
    // 如果没有注册过该函数，则将该函数注入注册池中
    registerPool.push(fn)
  }
  // 销毁OnMessage方法的回调函数
  self.unregister = function(fn) {
    const position = registerPool.indexOf(fn)
    if (position >= 0) {
      registerPool.splice(position, 1)
    }
  }
  self.clear = function() {
    registerPool = null
  }
  // Socket报错执行函数，并执行断线重连
  function onError(evnt) {
    // ws.readyState = false;
    // // console.log('ERROR: ', evnt);
    // $timeout(function () {
    // setTimeout(() => {
    //   //// console.log('Reconnecting to server...')
    //   ws = newWebSocket()
    // }, 3000)
  }
  // 打印当前状态
  function updateStatus(status) {
    // // console.log(status);
  }
  // 实际发送websocket请求
  function doSend(webSocketVo) {
    return ws.send(JSON.stringify(webSocketVo))
  }
  // 暴露给外面的发送函数
  self.send = function(webSocketVo) {
    if (ws.readyState == 1) {
      return doSend(webSocketVo)
    } else if (ws.readyState == 0) {
      // webSocketVo.isReg = false;//不需要注册
      delayPool.push(webSocketVo)
      // // console.log("sending is delay.");
      return 'sending is delay.'
    } else {
      // // console.log("sending is doing.");
      // //// console.log("webSocketVo:",webSocketVo);
      return 'ws close'
    }
  }
  self.reStart = function(){
    ws = newWebSocket()
  }
  return self
})()

//     //socket接收消息分发函数
//     let DispathSocketEvent = function(eventData)
//     {
//         switch(eventData.fid) {
//             case "market-resp": //市场信息
//             //// console.log("市场id："+this.$state)
//                 let marketid=eventData.markets[0].marketId;
//                 //// console.log(hqProtocolZyq);
//                 for(let param in hqProtocolZyq) {
//                     if(hqProtocolZyq[param].hasOwnProperty('marketId') === true){
//                         hqProtocolZyq[param].marketId=marketid;
//                     }
//                 }
//                 //// console.log(hqProtocolZyq);
//                 hqWs.send(hqProtocolZyq.param_price);
//                 hqWs.send(hqProtocolZyq.param_contract);
//                 break;
//             case "contract-resp"://根据市场获取的挂牌信息
//             let data_contract = eventData.contracts;
//                 break;
//             case "lpmkt-resp"://市场最新报价信息
//             let data_lpmkt=eventData.respList;
//                 break;
//             case "timeline-resp"://分钟线
//                 break;
//             case "kline-resp"://挂牌K线
//                 break;
//             case "subscribe-resp"://订阅成功
//                 break;
//             case "lastprice-push"://订阅成功后的最新报价的推送。只有当服务器的值发生改变后，才会产生-次推送
//                 break;
//             case "unsubscribe-resp"://取消订阅成功
//                 break;
//             default:
//                 break;
//             }
//     }
//     //// console.log(hqWs);
//    hqWs.register(DispathSocketEvent);

export { hqWsZyq }
