import cnLocale from "element-ui/lib/locale/lang/zh-CN";

const cn = {
  message: {
    Trading_in: "交易中",
    successfuldeal: "交易成功",
    removed: "已撤销",
    Purchaseprice: "申购价",
    placementprice: "配售价",
    Placetheorderoftime: "下单时间",
    Purchasequantity: "购买数量",
    Paytheamount: "支付金额",
    Transactiontype: "交易类型",
    Ticketnumber: "中签数量",
    amounttobepaidbythewinningbid: "中签支付金额",
    totalfee: "总手续费",
    Handlingfee: "中签手续费",
    Clinchadealthestate: "成交状态",
    about: "关于",
    contact: "联系方式",
    purchase1: "申购",
    placement1: "配售",
    purchase: "申购配售情况",
    goumaijiage: "购买价格",
    goumaishuliang: "购买数量",
    ieoBuy: "申购",
    ieoBuy1: "配售",
    ieoTotalIssue: "总发行数量",
    ieoStartTime: "开始时间",
    ieoEndTime: "结束时间",
    ieoPrice: "价格",
    ieoAmount: "数量",
    ieoRemainingAmount: "剩余数量",
    ieoMin: "最小值",
    ieoMax: "最大值",
    ieoRealNaame: "实名用户才可下单",
    ieoPAmount: "请输入购买数量",
    ieoPNum: "购买数量过高",
    Minimum_p_purchase_quantity: "用户最大购买数量",
    ieo_title_p1: " 平台通证 (",
    ieo_title_p2: ") 预售发行",
    ieo_title_p3: ") 增发",
    rightsissue: "增发",
    rightsIssue1: "增发价",

    Issue: "发行",
    Left_time: "剩余时间",
    Days: "天",
    Hours: "时",
    Minutes: "分",
    Seconds: "秒",
    Issue_completed: "发行完成",
    Issue_price: "发行价",
    Issue_price_: "发行价格",
    Available_amount1: "可用资金",
    Trade_volume: "交易额",
    Buy: "买入",
    请输入购买数量: "请输入购买数量",
    交易成功: "交易成功",
    不存在的IEO项目: "不存在的IEO项目",
    购买量过多: "购买量过多",
    购买量过少: "购买量过少",
    项目不可以交易: "项目不可以交易",
    项目已过期: "项目已过期",
    项目未开始: "项目未开始",
    用户余额不足: "用户余额不足",
    IEO交易成功: "IEO交易成功",
    Operations_are_too_frequent: "操作过于频繁",
    get_project_info_fail: "获取项目信息失败",
    //--
    Pre_sale_Project_information: "预售项目基本信息",
    Project_Name: "项目名称",
    Project_Company_Name: "项目公司名称",
    Project_Website: "项目官网",
    Project_white_paper: "项目白皮书",
    Telegram: "项目电报",
    Project_Introduction: "项目介绍",
    Pre_sale_Project_rules: "预售项目规则",
    Pre_sale_open_time: "预售起始时间",
    Pre_sale_end_time: "预售结束时间",
    Minimum_purchase_quantity: "单笔最小购买数量",
    Locked_in_coinlim: "购买锁仓解锁规则",
    quantity_empty_warn: "请输入购买数量",
    number_only_warn: "买入数量必须为数字",
    立即到账: "立即到账",
    分期解锁: "分期解锁",
    Tradevolume: "交易额",
    Confirm: "确认",
    OK: "确定",
    CANCEL: "取消",
    //导航
    language: "语言",
    Tech_lab: "创新区",
    Log_in: "登录",
    Sign_up: "注册",
    Nowope: "现已开放",
    immediatelyinto: "立即进入",
   
    //底部导航
    //登录模块
    Immediately_Log_in: "立即登录",
    Account_number: "账号",
    Please_input_mobile_number: "请输入手机号",
    Password: "密码",
    More_than_6_characters: "6个字符以上",
    Identifying_Code: "图形验证码",
    Please_input_the_identification_code: "请输入图形验证码",
    Log_in: "登录",
    Forgot_Password: "忘记密码",
    Sign_up_now: "立即注册",
    Welcome: "欢迎注册",
    //注册模块
    Mobile_number: "手机号码",
    Mobile_number2: "电话",
    Please_input_mobile_number: "请输入手机号",
    Password: "登录密码",
    six_20_alphanumerical_characters: "6-20位大小写字母或数字",
    eight_20_alphanumerical_characters: "8-20位大小写字母或数字",
    For_security_reasons_Please_create_a_password_unique_to_this_account:
      "请避免和其他网址使用相同的密码",
    Confirm_Password: "确认密码",
    Please_retype_the_password: "请再次输入密码",
    Invitation_code_optional: "邀请码",
    Please_input_invitation_code: "请填写邀请码",
    Identification_code: "图形验证码",
    Please_input_the_identification_code: "请输入图形验证码",
    SMS_verification_code: "短信验证码",
    Please_input_the_SMS_verification_code: "请输入短信或邮箱验证码",
    Get_the_verification_code: "获取验证码",
    I_agree_to_the: "我已阅读并同意",
    Terms_and_Conditions: "《用户协议》",
    //重置密码
    Reset_password: "重置登录密码",
    password_reset_message: "请联系客服进行密码重置",
    Please_input_the_registration_mobile_number: "请输入注册时的手机号",
    Please_input_the_identification_code: "请输入图形验证码",
    Submit: "提交",

    //(左1)
    Estimated_value: "净资产折合",
    Market: "自选",
    Price: "价格",
    Entrustment_price: "委托价格",
    Entrustment_Amount: "委托数量",
    Entrustment_Amount2: "委托总额",
    Change: "涨跌幅",
    //(左2)
    Announcement: "公告",
    //(中1)
    Increase_Amount: "涨幅",
    High: "高",
    Low: "低",
    twenty_four_H_Vol: "量",
    //(中2)
    Market_Order: "市价交易",
    Limit_Order: "限价交易",
    Available: "可用",
    unfrozenFunds: "解冻",
    Buy_Price: "买入价",
    Buy_Amount: "买入量",
    Sell_Price: "卖出价",
    Sell_Amount: "卖出量",
    //(中3)
    Open_orders: "当前委托",
    Buy: "买入",
    Sell: "卖出",
    Buy_oneword: "买",
    Sell_oneword: "卖",
    All: "全部",
    Time: "时间",
    Pairs: "交易",
    Direction: "方向",
    Amount: "数量",
    // Total:'委托总额',
    Executed: "已成交",
    Unexecuted: "未成交",
    Action: "操作",
    //(中4)
    Depth_map: "深度图",
    //(中5)
    Introduction: "简介",
    Issue_time: "发行时间",
    Total_Amount: "发行总量",
    thousand: "万",
    Token_price: "众筹价格",
    Official_website: "官网",
    //(右1)
    Latest_price: "最新价",
    Accumulation: "累计",
    //(右2)
    Last_price: "实时成交",

    //左侧菜单
    //右侧属性
    Buy_a: "购买",
    Sell_b: "出售",
    Payment_method: "支付方式",
    Merchant: "商家",
    order_quantity1: "成单数",
    Trade_Rate: "完成率",
    Limits: "限额",
    Unit_price: "单价",
    //订单管理
    //左侧菜单
    My_orders: "订单管理",
    //右侧状态
    Unpaid: "未付款",
    Paid: "已付款",
    Completed: "已完成",
    Cancelled: "已取消",
    Pending: "申诉中",
    //属性
    order_quantity2: "订单量",
    category: "类别",
    amount: "数量",
    // Price: '单价',
    // Amount: 总价,
    Counterparty: "交易对象",
    Details: "查看详情",
    Cancel: "取消订单",
    //商家申请
    //左侧菜单
    Merchant_application: "商家申请",
    //属性
    Apply_to_become_a_merchant: "成为商家",
    Merchant_certification: "商家认证",
    // Submit: '提交申请',
    Approved: "审核通过",
    //左侧菜单
    Post_Ad: "发布广告",
    //属性
    Minimum_single_turnover: "单笔最小成交额",
    Maximum_single_turnover: "单笔最大成交额",
    Note: "备注",
    Within_characters: "28个字符内",
    Trade_password: "资金密码",
    Transaction_Method: "交易方式",
    UnionPay: "银联支付",
    Alipay: "支付宝",
    WeChat: "微信",
    //左侧菜单
    My_Ad: "我的广告",
    //属性
    Ad_code: "广告编码",
    Ad_type: "广告类型",
    Order_limits: "订单限额",
    Action_Status: "操作/状态",
    Withdraw: "撤单",
    Withdrawn: "已撤单",
    //左侧菜单
    Orders2: "商家订单",
    //属性
    Set_contact_information: "设置联系方式",
    Set_payment_method: "设置支付方式",
    Please_input_your_mobile_number: "请输入您的电话号码",
    Email: "邮箱",
    Please_input_your_email_address: "请输入您的邮箱",
    QQ: "QQ",
    Please_input_your_QQ_username: "请输入您的QQ",
    Please_input_your_WeChat: "请输入您的微信",
    Others: "其他",
    Please_input_any_other_contact_information: "请输入您的其他联系方式",
    Save_contact_information: "保存联系方式",
    //设置昵称
    Nickname: "设置昵称",
    Please_input_nickname: "请输入您的昵称",
    Cannot_be_changed_after_submission: "设置后不可更改",
    Next: "下一步",
    //设置联系方式
    WeChat_Pay: "微信支付",
    Name: "姓名",
    Please_input_your_name: "请输入您的姓名",
    Account_address: "开户地址",
    Please_input_your_account_address: "请输入您的开户地址",
    Bank_card_number: "银行卡号",
    Please_input_your_bank_card_number: "请输入您的银行卡号",
    Remarks: "备注信息",
    Feel_free_to_input_any_remarks: "请输入您的备注信息",
    Trade_password: "资金密码",
    Please_input_your_trade_password: "请输入您的资金密码",
    Save_payment_settings: "保存支付设置",
    Order_details: "订单详情",
    Order_cancelled: "订单已取消",
    Any_problem_Open_a_dispute: "有问题？去申诉",
    Contact_information: "联系方式",
    Bank_account: "开户行",
    Remarks_Information: "备注信息",
    Remarks_Required: "（请务必填写）",
    For_your_financial_safety_please_use_your_own_bank_account_for_any_transactions:
      "为了您的资金安全，请使用您本人的银行卡账号进行转账。",
    ThirdParty_payments_are_not_allowed: "使用任何非本人账户支付，视为无效。",
    Confirm_payment: "确认付款",
    //资产
    //左菜单
    Account: "交易账户",
    Estimated_balance: "资产折合",
    //交易详情
    In_orders: "冻结",
    Total: "总价",
    Transaction: "交易",
    Copy: "复制",
    QR_code: "二维码",
    Reminder_title: "温馨提示：",
    Reminder_a: "请勿向上述地址充值任何非",
    Reminder_b: "资产，否则资产将不可找回。",
    Reminder_c: "您充值至上述地址后，需要整个网络节点的成功确认。",
    Reminder_d: "最小充值金额：",
    Reminder_e: "，小于最小金额的充值将不会上账。",
    Reminder_f:
      "您的充值地址不会经常改变，可以重复充值；如有更改，我们会尽量通过网站公告或邮件通知您。",
    Reminder_g: "请务必确认电脑及浏览器安全，防止信息被篡改或泄露。",
    //左菜单
    Financial_log: "财务记录",
    Type: "类型",
    State: "状态",
    Confirming: "确认中",
    //其他详情
    Other_records: "其他记录",
    Actual_Amount: "实际到账数量",
    Get_verification_code: "获取验证码",
    Note_b: "请务必确认电脑及浏览器安全，防止信息被篡改或泄露。",
    //左菜单
    Address_management: "地址管理",
    //地址管理详情
    // Remarks: '备注',

    //我的
    //左菜单
    My_Information: "我的信息",
    //我的信息内容
    RealName_Verification: "初级认证",
    Unverified: "未认证",
    Verified: "已认证",
    Verification: "认证",
    Verify: "认证",
    Log_in_password: "登录密码",
    Security_Level: "密码强度",
    Wake: "弱",
    Medium: "中",
    Strong: "强",
    Fund_password: "资金密码",
    Set: "已设置",
    Not_Set: "未设置",
    //左菜单
    Individual_ID_verification: "初级认证",
    //实名认证内容
    Nationality: "所属地区",
    Nationality_two: "地区",
    ID_Number: "身份证号",
    //左菜单
    Login_password: "登录密码",
    //登录密码内容
    Old_login_password: "当前登录密码",
    New_login_password: "新登录密码",
    Confirm_login_password: "确认登录密码",
    //左菜单
    //资金密码内容
    Old_fund_password: "当前资金密码",
    New_fund_password: "新资金密码",
    Confirm_fund_password: "确认资金密码",
    //左菜单
    My_Invitation_code: "我的邀请码",
    //我的邀请码内容
    Invite_friends_to_register: "推荐好友点击链接注册",
    Kindly_advise_your_friends_to_fill_in_the_invitation_code_field_when_registering:
      "好友注册时填写推荐码",
    A_person_has_been_invited: "已邀请1人",

    //订单+公告
    //分类
    ieo_title_p1: " 平台通证 (",
    ieo_title_p2: ") 预售发行",
    Order_history: "历史委托",
    Transaction_history: "历史成交",
    //订单详情
    Transaction_Type: "交易类型",
    Pair: "交易对",
    //公告详情
    Announcement_list: "公告列表",
    Details2: "公告详情",

    // 客户缺少的页面部分的翻译（用有道翻译翻译的）
    Assets: "资产",
    Order: "订单",
    Exit: "退出",
    More_announcements: "更多",
    Real_time_price: "实时价格",
    Download_the_APP: "APP下载",
    Do_you_forget_asset_password: "忘记资金密码？",
    Address_Lists: "地址列表",
    Delete: "删除",
    Account2: "平台账户",
    The_charge_which_less_than_minimum_number: "小于最小金额的充值将不会上账；",
    Wallet_scanning_is_recommended: "推荐使用钱包扫描",
    New_password: "新的密码",
    Please_set_new_password: "请设置新的密码",
    Enter_the_registered_mobile_number: "请填写注册时的手机号",
    Please_enter_the_password: "请输入密码",
    Please_keep_my_information: "请记住我",
    Cancel2: "取消",
    Confirm: "确定",
    Tips_Reminders: "提示",
    Unexecuted_Volume: "未成交数量",
    Most_Recent_Trade: "当前成交",
    Most_History_Trade: "历史成交",
    Or: "或",
    Open_orders2: "开始交易",
    Trading_View: "分时图",
    Transaction_fees: "交易手续费",
    Buying_order: "买单",
    Selling_order: "卖单",
    Withdrawing_fees: "提现手续费",
    Withdrawing_rate: "提现费率",
    Single_limit: "单笔限额",
    Transaction_Volume: "交易额",
    Market_Index: "盘口",
    Alipay_number: "支付宝账号",
    Alipay_payment_code: "支付宝二维码",
    WeChat_number: "微信账号",
    WeChat_payment_code: "微信支付二维码",
    Payment_successful: "确认已付款",
    Confirm_Completion: "确认完成",
    Business_Information: "商家资料",
    Senior_Verification: "高级认证",
    Registration_Time: "注册时间",
    Type_of_complaint: "申诉类型",
    Reason_for_complaint: "申诉原因",
    Waiting_for_a_receipt: "等待接单",
    Complaint_Passed: "通过申诉",
    Complaint_rejected: "驳回申诉",
    Complaint: "申诉",
    Orders_was_rejected: "拒绝接单",
    Business_Account: "商家账号",
    Advertisement_ID: "广告ID",
    Minimum_Limit: "最小限量",
    Maximum_Limit: "最大限量",
    Remaining_Amount: "剩余数量",
    Limit: "限量",
    UnionPay_Icon: "银联图标",
    Place_an_order: "下单",
    Submit_Senior_Verification_Documents: "去高级认证",
    Senior_Verification_was_Completed: "您已通过高级认证",
    Failed_to_Senior_Verified: "您的高级认证审核失败",
    Senior_Verification_Documents_are_under_review: "高级认证审核中",
    Reasons_for_the_Failure: "失败原因",
    Modify: "去修改",
    Please_select_your_option: "请选择",
    Senior_Real_name_Verification: "高级实名认证",
    Certificates_Name: "证件姓名",
    Certificates_Number: "证件号码",
    Valid_start_date_of_certificates: "证件有效起始日期",
    Valid_end_date_of_certificates: "证件有效结束日期",
    Choose_the_date: "选择日期",
    Tips_Please_choose_JPG_photo_first_to_ensure_picture_quality:
      "提示：为确保图片上传质量，请优选JPG格式",
    Front_photo_of_your_ID_card: "请上传身份证正面",
    Back_photo_of_your_ID_card: "请上传身份证反面",
    Photo_of_you_holding_your_ID_card: "请上传手持身份证",
    Invitation_Record: "邀请记录",
    Invited_number: "被邀请账号",
    Invitation_Time: "邀请时间",
    // 表单验证部分缺少的翻译
    Choose_your_location: "请选择地区",
    Enter_your_real_name: "请输入真实姓名",
    Enter_your_ID_number: "请输入身份证号",
    Failed: "失败",
    Processing: "在途",
    Under_review: "审核中",
    Rejected: "驳回",
    Please_enter_numerals: "必须输入数字",
    Please_complete_Verification_and_Senior_Verification_first:
      "请先进行身份认证和高级身份认证",
    You_did_copy_just_now: "您刚复制了",
    Failed_to_copy: "复制失败",
    Entered_passwords_differ: "两次输入密码不一致",
    Please_enter_11_mobile_number: "请输入11位手机号",
    Please_enter_11_valid_mobile_number: "请输入11位有效手机号码",
    Please_enter_new_password: "请输入新的密码",
    Please_enter_8_20_characters_numbers_or_punctuation:
      "请输入8-20位字母，数字或符号",
    Please_enter_characters_numbers_or_punctuation:
      "请输入数字、字母或特殊符号",
    Please_confirm_the_password: "请输入确认密码",
    Wrong_SMS_verification_code: "短信验证码或邮箱验证码错误",
    Please_read_the_Terms_Conditions: "请阅读用户协议",
    Authorization_completed: "已委托",
    Conclude_a_transaction_partially: "部分成交",
    Cancel_the_order_after_partial_transaction: "部分成交后撤单",
    Please_enter_the_selling_amount: "请输入卖出数量",
    Please_complete_the_verification_first_before_transaction:
      "请您先进行实名认证再进行交易",
    Please_enter_the_selling_price: "请输入卖出价",
    Please_enter_the_buying_amount: "请输入买入数量",
    Please_enter_the_buying_price: "请输入买入价",
    Payment_successful_Paid: "付款成功",
    Failed_to_cancel: "取消失败",
    The_order_was_completed: "订单已完成",
    Please_confirm_your_complaint: "您确定申诉吗",
    Tips: "提示",
    Complaint_successful: "申诉成功",
    Complaint_cancellation_was_completed: "已取消申诉",
    Seller: "卖家",
    The_buyer_has_received_the_order_waiting_for_buyer_payment:
      "买家已接单，等待买家付款",
    Wait_for_counterpart_payment: "等待对方付款",
    Buyer_has_completed_payment_please_make_sure_whether_the_transaction_was_finished:
      "买家已付款，请确定是否完成订单",
    Counterpart_has_completed_the_payment: "对方已付款",
    You_have_confirmed_the_payment_and_the_order_was_completed:
      "您已确认收款，订单已完成",
    Your_complaint_has_been_passed: "您的申诉已通过",
    Application_passed: "申请通过",
    Your_complaint_has_been_rejected: "您的申诉已驳回",
    Application_rejected: "申请驳回",
    Wait_for_buyer_receiving_order: "等待买家接单",
    Wait_for_counterpart_receiving_order: "等待对方接单",
    Counterpart_has_refused_the_order: "对方已拒绝接单",
    Buyer_has_refused_the_order: "买家拒绝接单",
    Option_3_I_completed: "选项3：我已付款，但未备注",
    Confirm_to_close: "确认关闭",
    Enter_your_bank_of_deposit: "请输入开户银行",
    Save_UnionPay_payment_settings: "保存银联支付设置",
    Update_UnionPay_payment_settings: "更新银联支付设置",
    Please_confirm_your_cancel_of_the_order: "您确定取消订单吗",
    You_have_canceled_the_order: "您已取消订单",
    The_enter_number_canot_lager_than_the_available_funds:
      "输入数量不能大于当前可用资金",
    The_enter_number_canot_lager_than_the_current_amount:
      "输入数量不能大于当前量",
    The_enter_number_canot_less_than_minimum_limit: "输入数量不能小于最小限额",
    Please_enter_the_data: "请输入数据",
    Please_enter_the_valid_start_date: "请输入有效起始日期",
    Please_enter_the_valid_end_date: "请输入有效结束日期",
    Please_upload_the_photo_of_ID_card: "请上传身份证照片",
    The_photo_format_of_head_portrait_should_be_JPG_or_PNG:
      "上传图片只能是 JPG或 PNG格式",
    The_picture_memory_size_of_head_portrait_should_be_less_than_3M:
      "上传图片大小不能超过3MB",
    Forgot_the_login_password: "忘记登录密码",
    Please_enter_current_login_password: "请输入当前的登录密码",
    Please_enter_new_login_password: "请输入新密码",
    Check_up: "查看",
    Go_to_verified: "去认证",
    Invitation_successful: "邀请成功",
    Invitation_failed: "邀请失败",

    // 补充
    The_highest: "最高",
    The_minimum: "最低",
    Your_identity_information_has_been_authenticated: "您的身份信息已通过认证",
    Network_instability: "网络不稳定",
    The_session_is_invalid_Please_login_again: "会话失效，请重新登录！",
    // 0912补充
    Logo_image: "logo图片",
    Clinch_a_deal_the_price: "成交价格",
    Clinch_a_deal_the_quantity: "成交数量",
    Clinch_a_deal_amount: "成交总额",
    time_sharing: "分时",
    Set_up_the: "设置",
    indicators: "指标",
    Place_the_order_failed: "下单失败",
    Assets_will_be_frozen_after_the_complaint_and_the_ombudsman_will_step_in:
      "提起申诉后资产将会冻结，申诉专员将会介入本次交易。",
    Maliciously_appealing_terms_disrupt_the_normal_operation_order_of_the_platform:
      "恶意申诉属于扰乱平台正常运营秩序的行为，情节严重将冻结账户。",
    Photo_attachments: "图片附件",
    Remark_information_copy_successfully: "备注信息，复制成功",
    Failed_to_obtain_payment_method: "获取支付方式失败",
    Please_select_appeal_type: "请选择申诉类型",
    Please_enter_cause_of_complaint: "请输入申诉原因",
    The_appeal_failed: "申诉失败",
    Payment_failure: "付款失败",
    If_payment_is_not_confirmed_within_15_minutes:
      "如15分钟内没有确认付款，订单将自动取消。",
    Order_unpaid: "订单未付款",
    Order_paid: "订单已付款",
    Identify_successful: "确认成功",
    Confirm_the_failure: "确认失败",
    The_appeal_type: "申诉类型",
    Please_enter_your_alipay_account: "请输入您的支付宝账号",
    Qr_code_upload: "二维码上传",
    Picture_of_qr_code_uploaded: "二维码图片上传",
    WeChat_payment_account: "微信支付账号",
    Please_enter_your_WeChat_payment_account: "请输入您的微信支付账号",
    Save_alipay_payment_Settings: "保存支付宝支付设置",
    Update_alipay_payment_Settings: "更新支付宝支付设置",
    Save_the_WeChat_payment_Settings: "保存微信支付设置",
    Update_WeChat_payment_Settings: "更新微信支付设置",
    Please_upload_the_picture_of_qr_code: "请上传二维码图片",
    Upload_pictures_can_only_be_JPG_or_PNG_format:
      "上传图片只能是 JPG或 PNG格式!",
    Operation_canceled: "操作已取消",
    Alipay_icon: "支付宝图标",
    Micro_letter_icon: "微信图标",
    Authentication_passed: "身份认证通过",
    Portrait_photo_of_my_id_card: "本人身份证人像面照片",
    My_id_card_picture_of_national_emblem: "本人身份证国徽面照片",
    Holding_personal_id_card_photo: "手持本人身份证人像面照片",
    His_AD: "他的广告",
    Ad_number: "广告编号",
    Create_Time: "创建时间",
    Revocation_failure: "撤销失败",
    Order_is_invalid: "订单已失效",
    The_order_has_lapsed_and_payment_information_cannot_be_viewed:
      "订单已失效，无法查看支付信息",
    The_complaint_order_has_been_processed: "申诉订单已处理",
    The_order_has_been_appealed: "订单已申诉完成",
    Please_ensure_that_your_contact_information_is_unblocked_to_facilitate_customer_service:
      "被申诉中，请确保您的联系方式畅通，以方便客服联系您",
    Order_Pending: "订单申诉中",
    Order_appeals: "订单申诉",



    // 挂牌融合后新增
    // 挂牌交易页面
    Current_Interests: "当前权益",
    Risk_Rate: "风险率",
    change_lows: "涨跌",
    Opening_Price: "开盘",
    Closing_Price: "昨收",
    time_line: "分时线",
    Min: "分钟",
    Hour: "小时",
    Day: "日线",
    Most_available_buy: "可买",
    Maximum_permissible_price_difference: "允许成交价和报价的最大价差",
    Advance_charge: "预付款",
    Most_available: "可卖",
    History_buying_orders: "买入分笔",
    History_selling_orders: "卖出分笔",
    More: "更多",
    Piece: "个",
    K_line_data: "K线数据",
    needs: "需",
    Please_log_in_and_trade: "请登录后进行交易",
    Please_enter_purchase_price_positive_number_of_pure_numbers:
      "请输入买入价格!(纯数字的正数)",
    Please_enter_the_number_of_purchases_positive_numbers_of_pure_numbers:
      "请输入买入数量!(纯数字的正数)",
    The_maximum_quantity_can_be_ordered: "最大可下单数量为",
    The_minimum_quantity_can_be_ordered: "最小可下单数量为",
    Please_do_not_enter_numbers_less_than_zero: "请不要输入小于0的数字",
    Please_enter_the_selling_price_the_positive_number_of_pure_numbers:
      "请输入卖出价格!(纯数字的正数)",
    Please_enter_the_number_of_sales_the_positive_number_of_pure_numbers:
      "请输入卖出数量!(纯数字的正数)",
    Pens_and_detail: "分笔明细",
    No_data: "暂无数据",
    Please_enter_a_selling_price_of_not_less_than_zero: "请输入不小于0的卖出价",
    Sell_volume_should_not_be_less_than_zero: "卖出量不可小于0",
    Please_enter_a_bid_price_of_not_less_than_zero: "请输入不小于0的买入价",
    Purchase_volume_should_not_be_less_than_zero: "买入量不可小于0",
    Amount_of_order_cannot_be_zero: "下单数量不能等于0",

    // 挂牌资产状况页面
    Condition_of_listed_assets: "挂牌资产状况",
    Main_block: "主版块",
    Listed: "挂牌交易",
    Market: "市场",
    Total_Amount_two: "总额",
    Transfer: "划转",
    Identity_Verification: "实名认证",
    From: "从",
    main_board: "主版块",
    Main: "主",
    balance: "余额",
    transfer_to: "转至",
    Choose_an_account: "请选择账户",
    Available_amount: "可划转数量",
    Please_consent_to_Risk_Disclosure_before_trading:
      "请同意风险揭示书后再进行交易",

    // 挂牌交易资产账户页面
    The_current_balance: "当前余额",
    Asset_account: "资产账户",
    Prepayment: "占用预付款",
    Available_prepayment: "可用预付款",
    Real_time_price_difference: "实时价差",
    Frozen_advance_payment: "冻结预付款",
    Freezing_fee: "冻结手续费",

    // 挂牌资金流水页面
    Capital_flow: "资金流水",
    Complete: "完成",
    Transfer_to_a_record: "转入记录",
    Turn_out_record: "转出记录",
    All_records: "全部记录",

    // 挂牌交易账户页面
    mid_long_term_transactions: "中远期交易",
    microdisk: "微盘",
    Remarks_required_short: "备注（必填）",
    Balance_is: "余额为",
    Please_select_an_account: "请选择账户",
    Transferable_quantity: "可划转数量",
    Please_input_quantity: "请输入数量",
    Successful_transfer: "划转成功",
    Successful_transfer2: "请求成功",
    // 挂牌交易账户资产财务记录页面
    Transaction_record: "交易记录",
    Transfer_credentials: "划转凭据",
    Registered_account: "挂牌账户",

    // 挂牌账户页面
    Mutual_transfer_of_funds: "资金互转",
    Turn_to_success: "转入成功",
    Transfer_failed_Please_try_again_later: "转入失败，请稍后重试",
    Turn_out_to_be_successful: "转出成功",
    Turn_out_failure_Please_try_again_later: "转出失败，请稍后重试",
    wallet: "钱包",
    Listed_assets_status: "挂牌资产状况",
    Option_transaction: "期权交易",

    // 挂牌快捷平仓
    Quick_and_flat: "快捷平仓",
    contract: "合约",
    Current_price: "当前价",
    Success: "成功",

    // 挂牌成交查询
    Listing_name: "挂牌名称",
    open_a_granary_to_provide_relief: "开仓",
    Close_a_position: "平仓",
    Flat_price_difference: "平仓价差",
    Transaction_type: "成交类型",
    Market_transaction: "市价成交",
    Limited_transaction: "限价成交",
    Stop_loss_deal: "止损成交",
    Close_business: "止盈成交",
    Strong_business: "强平成交",
    Hedging: "对冲单",
    Receipt: "交收单",

    // 挂牌平仓明细
    Flat_single_number: "平仓单号",
    Listing_code: "挂牌代码",
    Flat_number: "平仓数量",
    Exit_price: "平仓价",
    Flat_type: "平仓类型",
    Jiancang_single_number: "建仓单号",

    // 挂牌客户持货
    Buying_average_holding_price: "买入平均持仓价",
    Buying_position: "买入持仓金额",
    Buying_quantity: "买入数量",
    Buy_floating_profit_and_loss: "买入浮动盈亏",
    Average_selling_price: "卖出平均持货价",
    Selling_positions: "卖出持仓金额",
    Selling_quantity: "卖出数量",
    Sell_floating_profit_and_loss: "卖出浮动盈亏",
    Customer_net_position: "客户净头寸",
    Floating_profit_and_loss_aggregate: "浮动盈亏汇总",

    // 挂牌委托查询
    Price_stop_price: "止盈价",
    Stop_price: "止损价",
    Entrustment_mode: "委托方式",
    Are_you_sure_you_want_to_cancel_the_order: "您确定要撤单吗",

    // 挂牌交收部分
    Delivery: "交收",
    If_the_price_difference_is_filled_out_please_fill_in_the_price_difference_greater_than_zero:
      "价差填写错误，请填写大于0的价差",
    Delivery_time: "交收时间",
    Money_of_payment: "交收金额",
    Amount_of_delivery: "交收数量",
    Settlement_fee: "交收手续费",

    // 挂牌持仓明细
    market_price: "市价",
    Fixed_price: "限价",
    Stop_loss: "止损",
    target_profit: "止盈",
    Coercion: "强平",
    Withdrawal_failed_Please_try_again_later: "撤单失败,请稍后重试",
    Equal_stock_price: "持仓均价",
    Holding_number: "持仓数量",
    Floating_P_L: "浮动盈亏",
    Advance_payment_in_position: "持仓预付款",
    Building_time: "建仓时间",
    Building_price: "建仓价格",
    Quantity_of_warehouse: "建仓数量",
    Holding_price: "持仓价",
    Prepayment_coefficient: "预付款系数",
    Advance_payment_rate: "预付款费率",
    Stop_get_and_stop_loss: "止盈止损",
    Listing: "挂牌",
    Stop_or_stop_price_is_not_set_correctly: "止盈或止损价设置不正确",
    Please_fill_in_the_price_of_stop_or_stop_loss: "请填写止盈或止损价格",

    // 挂牌委托查询、成交查询、持仓明细、持仓汇总、客户持货标题
    Entrustment_inquiry: "委托查询",
    Entrustment_detail: "委托明细",
    Transaction_inquiries: "成交查询",
    Holding_details: "持仓明细",
    Holding_summary: "持仓汇总",
    Customer_holding: "客户持货",
    Intersection_query: "交收查询",

    // 挂牌资料
    Listing_information: "挂牌资料",
    Listing_state: "挂牌状态",
    Minimum_variable_price: "最小变动价格",
    Prepayment_algorithm: "预付款算法",
    Transaction_fee_algorithm: "交易手续费算法",
    Warehouse_handling_fee_coefficient: "建仓手续费系数",
    Closing_fee_coefficient: "平仓手续费系数",
    Single_minimum_quantity: "单笔最小可委托数量",
    The_maximum_quantity_of_a_single_pen_can_be_commissioned:
      "单笔最大可委托数量",
    Maximum_position: "最大持仓量",
    Market_position_authorization: "市价建仓权限",
    Market_clearing_authority: "市价平仓权限",
    Surplus_stop_loss_authority: "止盈止损权限",
    Yes: "有",
    No: "无",
    Stop_loss_order_price_difference: "止损下单价格差",
    Profit_margin: "止盈下单价格差",
    Price_fixing_price_difference: "限价建仓价格差",
    Order_price_difference_default_value: "下单价格差默认值",
    Overnight_fee: "过夜费",
    Unlisted: "未上市",
    Already_listed: "已上市",
    Already_out_of_the_market: "已退市",
    Proportion: "比例",
    Fixed_value: "固定值",
    Trading_Unit: "交易单位",
    Trading_Unit_Name: "交易单位名称",
    Volume_hand: "手",

    // 个人中心增加
    Go_to_verify_again: "去重新认证",
    Examples: "示例",
    Please_enter_ID_number: "请输入证件号码",
    Fail_to_pass_senior_identity_verification_please_try_again_later:
      "高级认证失败，请稍后重试",
    Requirements_ID_photo_is_clear_and_its_size_should_be_no_more_than_3M:
      "照片要求：证件照清晰；照片大小不超过3M。",

    // otc交易补充
    You_can_add_friends_through_WeChat: "可通过微信添加好友沟通",
    Please_confirm_that_you_have_paid_seller: "您确定已成功付款给卖方了吗？",
    Important_Note: "重要提示！",
    You_need_to_pay_seller_through_cyberbank_Alipay_or_other_method_and:
      "您需要手动通过网银或支付宝等方式付款给卖方后方可点击确认付款，否则此笔订单无效！",
    Appealing_order_number: "申诉单号",

    // v1.1.0补充 及 中远期
    Profit_and_loss_of_closing_position: "平仓盈亏",
    Net_profit_and_loss_of_holdings: "持有净盈亏",
    Gross_profit_and_loss_of_hedging: "对冲总盈亏",
    Initial_rights_and_interests: "期初权益",
    Delegation_history: "委托历史",
    Transaction_history: "成交历史",
    Contract_name: "合约名称",
    Contract_status: "合约状态",
    Minimum_unit_of_changeable_amount: "最小数量变动单位",
    Demurrage_algorithm: "延期费算法",
    Demurrage_coefficient: "延期费系数",
    Order_pan: "买卖盘",
    Buying_Order_pan: "买盘",
    Selling_order_pan: "卖盘",
    History_records: "历史记录",
    Asset_status: "资产状况",
    Mid_long_term_asset_status: "中远期资产状况",
    Available_amount_buy: "可购买数量",
    Available_amount_sell: "可卖出数量",
    Verification_code_error: "验证码错误",
    Exhibition_or_not: "是否展示",
    Backhand_commission: "反手委托",
    Proprietary_members_are_not_allowed_to_trade: "自营会员不可进行交易",
    Delegate_list: "委托列表",
    lease_confirm_that_the_payment_information_is_accurate:
      "请确认填写的支付方式信息准确",
    //融合挂牌
    Opening_price_name: "开仓价",
    Please: "请",
    Query: "查询"
  },
  ...cnLocale
};
export default cn;
