import twLocale from "element-ui/lib/locale/lang/zh-TW";


const tw = {
    message: {
        Trading_in:'交易中',
        successfuldeal:'交易成功',
        removed:'已撤銷',
        Nowope:'現已開放',
        immediatelyinto:'立即進入',
        contact:'聯繫方式',
        purchase1:'申購',
        placement1:'配售',
        Purchaseprice:'申購價',
        placementprice:'配售價',
        Placetheorderoftime:'下單時間',
        Purchasequantity:'購買數量',
        Paytheamount:'支付金額',
        Transactiontype:'交易類型',
        Ticketnumber:'中籤數量',
        amounttobepaidbythewinningbid:'中籤支付金額',
        totalfee:'總手續費',
        Handlingfee:'中籤手續費',
        Clinchadealthestate:'成交狀態',
        purchase:'申購配售情況',
        goumaijiage:"購買價格",
        goumaishuliang:"購買數量",
        ieoBuy: '申購',
        ieoBuy1: '配售',
        ieoTotalIssue: '總發行數量',
        ieoStartTime: '開始時間',
        ieoEndTime: '結束時間',
        ieoPrice: '價格',
        ieoAmount: '數量',
        ieoRemainingAmount: '剩餘數量',
        ieoMin: '最小值',
        ieoMax: '最大值',
        ieoRealNaame:'實名用戶才可下單',
        ieoPAmount: '請輸入購買數量',
        ieoPNum: '購買數量過高',
        Minimum_p_purchase_quantity: '用戶最大購買數量',
        ieo_title_p1:' 平台通证 (',
        ieo_title_p2:') 預售發行',
        ieo_title_p3:') 增發',
        rightsissue:'增發',
        rightsIssue1:'增發價',
        Issue:'發行',
        Left_time:'剩餘時間',
        Days:'天',
        Hours: '時',
        Minutes: '分',
        Seconds: '秒',
        Issue_completed:'發行完成',
        Issue_price: '發行價',
        Issue_price_: '發行價格',
        Available_amount1: '可用資金',
        Trade_volume: '交易額',
        Buy:'買入',
        请输入购买数量: '請輸入購買數量',
        交易成功: '交易成功',
        不存在的IEO项目: '不存在的IEO數量',
        购买量过多: '購買量過多',
        购买量过少: '購買量過少',
        项目不可以交易: '項目不可以交易',
        项目已过期: '項目已過期',
        项目未开始: '項目未開始',
        用户余额不足: '用戶余額不足',
        IEO交易成功: 'IEO交易成功',
        Operations_are_too_frequent: '操作過於頻繁',
        get_project_info_fail: '獲取項目信息失敗',
        //--
        Pre_sale_Project_information: '預售項目基本信息',
        Project_Name:'項目名稱',
        Project_Company_Name:'項目公司名稱',
        Project_Website: '項目官網',
        Contract_address: '合約地址',
        Project_white_paper: '項目白皮書',
        Telegram: '項目電報',
        Project_Introduction:'項目介紹',
        Pre_sale_Project_rules:'預售項目規劃',
        Pre_sale_open_time:'預售起始時間',
        Pre_sale_end_time:'預售結束時間',
        Minimum_purchase_quantity:'單筆最小購買數量',
        Locked_in_coinlim:'購買鎖倉解鎖規則',
        quantity_empty_warn:"請輸入購買數量",
        number_only_warn:"買入數量必須位數字",
        立即到账:"立即到賬",
        分期解锁:"分期解鎖",
        Tradevolume:"交易額",
        Confirm:"確認",
        OK:"確定",
        CANCEL:"取消",
        language: '語言',
        //导航
        Tech_lab: '創新區',
        Log_in: '登錄',
        Sign_up: '註冊',
        //底部导航
        Help: '幫助中心',
        API_Documentation: 'API文檔',
        Virtual_asset_launching: '數字資產介紹',        
        About: '關於',
        Privacy_Policy: '隱私條款',
        Fees: '手續費',
        //登录模块
        Immediately_Log_in: '立即登錄',
        Account_number: '賬號',
        Please_input_mobile_number: '請輸入手機號',
        Password: '密碼',
        More_than_6_characters: '6個字符以上',
        Identifying_Code: '圖形驗證碼',
        Please_input_the_identification_code: '請輸入圖形驗證碼',
        Log_in: '登錄',
        Forgot_Password: '忘記密碼',
        Sign_up_now: '立即注冊',
        Welcome: '歡迎注冊',
        //注册模块
        Mobile_number: '手機號碼',
        Mobile_number2: '電話',
        Please_input_mobile_number: '請輸入手機號',
        Password: '登錄密碼',
        six_20_alphanumerical_characters: '6-20位大小寫字母或數字',
        eight_20_alphanumerical_characters: '8-20位大小寫字母或數字',
        For_security_reasons_Please_create_a_password_unique_to_this_account: '請避免和其他網址使用相同的密碼',
        Confirm_Password: '確認密碼',
        Please_retype_the_password: '請再次輸入密碼',
        Invitation_code_optional: '邀請碼',
        Please_input_invitation_code: '請填寫邀請碼',
        Identification_code: '圖形驗證碼',
        Please_input_the_identification_code: '請輸入圖形驗證碼',
        SMS_verification_code: '短信驗證碼',
        Please_input_the_SMS_verification_code: '請輸入短信或郵箱驗證碼',
        Get_the_verification_code: '獲取驗證碼',
        I_agree_to_the: '我已閱讀並同意',
        Terms_and_Conditions: '《用戶協議》',
        //重置密码
        Reset_password: '重置登錄密碼',
        password_reset_message:'請聯系客服進行密碼重置',
        Please_input_the_registration_mobile_number: '請輸入注冊時的手機號',
        Please_input_the_identification_code: '請輸入圖形驗證碼',
        Submit: '提交',

        //(左1)
        Estimated_value: '净資產折合',
        Market: '自選',
        Price: '價格',
        Entrustment_price: '委託價格',
        Entrustment_Amount: '委託數量',
        Entrustment_Amount2: '委託總額',
        Change: '漲跌幅',
        //(左2)
        Announcement: '公告',
        //(中1)
        Increase_Amount: '漲幅',
        High: '高',
        Low: '低',
        twenty_four_H_Vol: '量',
        //(中2)
        Market_Order: '市場交易',
        Limit_Order: '限價交易',
        Available: '可用',
        Buy_Price: '買入價',
        Buy_Amount: '買入量',
        Sell_Price: '賣出價',
        Sell_Amount: '賣出量',
        Delegate_list:'委託列表',
        //(中3)
        Open_orders: '當前委托',
        Buy: '買入',
        Sell: '賣出',
        Buy_oneword: '買',
        Sell_oneword: '賣',
        All: '全部',
        Time: '時間',
        Pairs: '交易',
        Direction: '方向',
        Amount: '數量',
        // Total:'委托總額',
        Executed: '已成交',
        Unexecuted: '未成交',
        Action: '操作',
        //(中4)
        Depth_map: '深度圖',
        //(中5)
        Introduction: '簡介',
        Issue_time: '發行時間',
        Total_Amount: '發行總量',
        thousand: '萬',
        Circulation: '流通總量',
        Token_price: '衆籌價格',
        Whitepaper: '白皮書',
        Official_website: '官網',
        Block_explorer: '區塊鏈查詢',
        //(右1)
        Latest_price: '最新價',
        Accumulation: '纍計',
        //(右2)
        Last_price: '實時成交',

        //左侧菜单
        //右侧属性
        Buy_a: '購買',
        Sell_b: '出售',
        Payment_method: '支付方式',
        Merchant: '商家',
        order_quantity1: '成單數',
        Trade_Rate: '完成率',
        Limits: '限額',
        Unit_price: '單價',
        //订单管理
        //左侧菜单
        My_orders: '訂單管理',
        //右侧状态
        Unpaid: '未付款',
        Paid: '已付款',
        Completed: '已完成',
        Cancelled: '已取消',
        Pending: '申訴中',
        //属性
        order_quantity2: '订单量',
        category: '類別',
        amount: '數量',
        // Price: '单价',
        // Amount: 总价,
        Counterparty: '交易對象',
        Details: '查看詳情',
        Cancel: '取消訂單',
        //商家申请
        //左侧菜单
        Merchant_application: '商家申請',
        //属性
        Apply_to_become_a_merchant: '成為商家',
        Merchant_certification: '商家認證',
        // Submit: '提交申请',
        Approved: '審核通過',
        //左侧菜单
        Post_Ad: '發佈廣告',
        //属性
        Minimum_single_turnover: '單筆最小成交額',
        Maximum_single_turnover: '單筆最大成交額',
        Note: '備註',
        Within_characters: '28個字符內',
        Trade_password: '資金密碼',
        Transaction_Method: '交易方式',
        UnionPay: '銀聯支付',
        Alipay: '支付寶',
        WeChat: '微信',
        //左侧菜单
        My_Ad: '我的廣告',
        //属性
        Ad_code: '廣告編碼',
        Ad_type: '廣告類型',
        Order_limits: '訂單限額',
        Action_Status: '操作/狀態',
        Withdraw: '撤單',
        Withdrawn: '已撤單',
        //左侧菜单
        Orders2: '商家訂單',
        //属性
        Set_contact_information: '設置聯繫方式',
        Set_payment_method: '設置支付方式',
        Please_input_your_mobile_number: '請輸入您的電話號碼',
        Email: '郵箱',
        Please_input_your_email_address: '請輸入您的郵箱',
        QQ: 'QQ',
        Please_input_your_QQ_username: '請輸入您的QQ',
        Please_input_your_WeChat: '請輸入您的微信',
        WhatsApp: 'WhatsApp',
        Please_input_your_WhatsApp_number: '請輸入您的WhatsApp',
        Others: '其他',
        Please_input_any_other_contact_information: '請輸入您的其他聯繫方式',
        Save_contact_information: '保存聯繫方式',
        //设置昵称
        Nickname: '設置暱稱',
        Please_input_nickname: '請輸入您的暱稱',
        Cannot_be_changed_after_submission: '設置后不可更改',
        Next: '下一步',
        //设置联系方式
        // Mobile_number: '电话号码',
        //设置支付方式
        CNY_Settings: 'CNY設置',
        USD_Settings: 'USD設置',
        EUR_settings: 'EUR設置',
        WeChat_Pay: '微信支付',
        Name: '姓名',
        Please_input_your_name: '請輸入您的姓名',
        Account_address: '開戶地址',
        Please_input_your_account_address: '請輸入您的開戶地址',
        Bank_card_number: '銀行卡號',
        Please_input_your_bank_card_number: '請輸入您的銀行卡號',
        Remarks: '備註信息',
        Feel_free_to_input_any_remarks: '請輸入您的備註信息',
        Trade_password: '資金密碼',
        Please_input_your_trade_password: '請輸入您的資金密碼',
        Save_payment_settings: '保存支付設置',
        Order_details: '訂單詳情',
        Sell_GC: '賣出GC',
        Order_number: '訂單號',
        Buyer: '買家',
        Volume: '成交量',
        Total_Pending: '總申訴',
        Total_Completed: '總勝訴',
        Transaction_Amount: '交易金額',
        Customer_payment_method: '對方選擇的付款方式',
        UnionPay_payment: '銀聯支付',
        Waiting_for_buyer_payment: '等待買家付款',ded_contact_for_customers_to_contact_you: '您的申訴正在審核中，請確保您的聯繫方式暢通，以便客服聯繫你',
        Cancel_pending_order: '取消申訴',
        //订单已取消
        Order_cancelled: '訂單已取消',
        Any_problem_Open_a_dispute: '有問題？去申訴',
        Contact_information: '聯繫方式',
        Bank_account: '開戶行',
        Remarks_Information: '備註信息',
        Remarks_Required: '（請務必填寫）',
        For_your_financial_safety_please_use_your_own_bank_account_for_any_transactions: '為了您的資金安全，請使用您本人的銀行卡賬號進行轉賬。',
        ThirdParty_payments_are_not_allowed: '使用任何非本人賬戶支付，視為無效。',
        Confirm_payment: '確認付款',

        //资产
        //左菜单
        Account: '交易賬戶',
        Estimated_balance: '資產折合',
        //交易详情
        In_orders: '凍結',
        Total: '總價',
        BTC_estimate: 'BTC估值',
        Transaction: '交易',
        Copy: '複製',
        QR_code: '二維碼',
        Reminder_title: '溫馨提示：',
        Reminder_a: '請勿向上述地址充值任何非',
        Reminder_b: '資產，否則資產將不可找回。',
        Reminder_c: '您充值至上述地址后，需要整個網絡節點的成功確認。',
        Reminder_d: '最小充值金額：',
        Reminder_e: '，小於最小金額的充值將不會上賬。',
        Reminder_f: '您的充值地址不會經常改變，可以重複充值；如有更改，我們會盡量通過網站公告或郵件通知您。',
        Reminder_g: '請務必確認電腦及瀏覽器安全，防止信息被篡改或泄露。',
        //左菜单
        Financial_log: '財務記錄',
        unfrozenFunds:'解凍',
        Type: '類型',
        State: '狀態',
        Confirming: '確認中',
        //其他详情
        Other_records: '其他記錄',
        Rewards: '系統贈送',
        //左菜单
        Address_management: '地址管理',
        //地址管理详情
        // Remarks: '备注',

        //我的
        //左菜单
        My_Information: '我的信息',
        //我的信息内容
        RealName_Verification: '實名認證',
        Unverified: '未認證',
        Verified: '已認證',
        Verification: '認證',
        Verify: '認證',
        Log_in_password: '登錄密碼',
        Security_Level: '密碼強度',
        Wake: '弱',
        Medium: '中',
        Strong: '強',
        Fund_password: '資金密碼',
        Set: '已設置',
        Not_Set: '未設置',
        //左菜单
        Individual_ID_verification: '實名認證',
        //实名认证内容
        Nationality: '所屬地區',
        Nationality_two: '地區',
        ID_Number: '身份證號',
        //左菜单
        Login_password: '登錄密碼',
        //登录密码内容
        Old_login_password: '當前登錄密碼',
        New_login_password: '新登錄密碼',
        Confirm_login_password: '確認登錄密碼',
        //左菜单
        //资金密码内容
        Old_fund_password: '當前資金密嗎',
        New_fund_password: '新資金密碼',
        Confirm_fund_password: '確認資金密碼',
        //左菜单
        My_Invitation_code: '我的邀請碼',
        //我的邀请码内容
        Invite_friends_to_register: '推薦好友點擊鏈接註冊',
        Kindly_advise_your_friends_to_fill_in_the_invitation_code_field_when_registering: '好友註冊時填寫推薦碼',
        A_person_has_been_invited: '已邀請1人',
        Recevied_A_GOOD_reward: '已獲得1 GOOD 佣金',

        //订单+公告
        //分类
        Order_history: '歷史委託',
        Transaction_history: '歷史成交',
        //订单详情
        Transaction_Type: '交易類型',
        Pair: '交易對',
        //公告详情
        Announcement_list: '公告列表',
        //白皮书
        Write_book:'白皮書',
        Details2: '公告詳情',
        More_announcements: '更多',
        Real_time_price: '實時價格',
        Download_the_APP: 'APP下載',
        Do_you_forget_asset_password: '忘記資金密碼？',
        Address_Lists: '地址列表',
        Delete: '刪除',
        Account2: '平臺帳戶',
        The_charge_which_less_than_minimum_number: '小於最小金額的充值將不會上賬',
        Please_scan_the_QR_code_by_GOOD_wallet: '請使用GOOD錢包掃描',
        Wallet_scanning_is_recommended: '推薦使用錢包掃描',
        New_password: '新的密碼',
        Please_set_new_password: '請設置新的密碼',
        Enter_the_registered_mobile_number: '請填寫註冊時的手機號',
        Please_enter_the_password: '請輸入密碼',
        Please_keep_my_information: '請記住我',
        Cancel2: '取消',
        Confirm: '確定',
        Tips_Reminders: '提示',
        Unexecuted_Volume: '未成交數量',
        Most_Recent_Trade: '當前成交',
        Most_History_Trade:'歷史成交',
        Or: '或',
        Open_orders2: '開始交易',
        Trading_View: '分時圖',
        Transaction_fees: '交易手續費',
        Buying_order: '買單',
        Selling_order: '賣單',
        Withdrawing_fees: '提現手續費',
        Withdrawing_rate: '提現費率',
        Single_limit: '單筆限額',
        Transaction_Volume: '交易額',
        Market_Index: '盤口',
        Alipay_number: '支付寶帳號',
        Alipay_payment_code: '支付寶二維碼',
        WeChat_number: '微信帳號',
        WeChat_payment_code: '微信支付二維碼',
        Payment_successful: '確認已付款',
        Confirm_Completion: '確認完成',
        Business_Information: '商家資料',
        Senior_Verification: '高級認證',
        Registration_Time: '註冊時間',
        Type_of_complaint: '申訴類型',
        Reason_for_complaint: '申訴原因',
        Waiting_for_a_receipt: '等待接單',
        Complaint_Passed: '通過申訴',
        Complaint_rejected: '駁回申訴',
        Complaint: '申訴',
        Orders_was_rejected: '拒絕接單',
        Business_Account: '商家帳號',
        Advertisement_ID: '廣告ID',
        Minimum_Limit: '最小限量',
        Maximum_Limit: '最大限量',
        Remaining_Amount: '剩餘數量',
        Limit: '限量',
        UnionPay_Icon: '銀聯圖標',
        // Orders_number: '成單數',
        Place_an_order: '下單',
        Submit_Senior_Verification_Documents: '去高級認證',
        Senior_Verification_was_Completed: '已通過高級認證',
        Failed_to_Senior_Verified: '高級認證審核失敗',
        Senior_Verification_Documents_are_under_review: '高級認證審核中',
        Reasons_for_the_Failure: '失敗原因',
        Modify: '去修改',
        Please_select_your_option: '請選擇',
        Senior_Real_name_Verification: '高級實名認證',
        Photo_Requirements: '照片要求：證件照清晰；手持證件照要求左手持身份證件，右手持帶good、認證人簽名、認證當天日期的證明檔',
        Certificates_Location: '證件所屬地區',
        Certificates_Name: '證件姓名',
        Certificates_Number: '證件號碼',
        Valid_start_date_of_certificates: '證件有效起始日期',
        Valid_end_date_of_certificates: '證件有效結束日期',
        Choose_the_date: '選擇日期',
        Tips_Please_choose_JPG_photo_first_to_ensure_picture_quality: '提示：為確保圖片上傳品質，請優選JPG格式',
        Front_photo_of_your_ID_card: '請上傳身份證正面',
        Back_photo_of_your_ID_card: '請上傳身份證反面',
        Photo_of_you_holding_your_ID_card: '請上傳手持身份證',
        Invitation_Record: '邀請記錄',
        Invited_number: '被邀請帳號',
        Invitation_Time: '邀請時間',
        // 表单验证部分缺少的翻译
        Choose_your_location: '請選擇地區',
        Enter_your_real_name: '請輸入真實姓名',
        Enter_your_ID_number: '請輸入身份證號',
        Failed: '失敗',
        Processing: '在途',
        Under_review: '審核中',
        Rejected: '駁回',
        Please_enter_numerals: '必須輸入數字',
        Please_complete_Verification_and_Senior_Verification_first: '請先進行身份認證和高級身份認證',
        You_did_copy_just_now: '您剛複製了',
        Failed_to_copy: '複製失敗',
        Entered_passwords_differ: '兩次輸入密碼不一致',
        Please_enter_11_mobile_number: '請輸入11位手機號',
        Please_enter_11_valid_mobile_number: '請輸入11位有效手機號碼',
        Please_enter_new_password: '請輸入新的密碼',
        Please_enter_8_20_characters_numbers_or_punctuation: '請輸入8-20位字母，數字或符號',
        Please_enter_characters_numbers_or_punctuation: '請輸入數字、字母或特殊符號',
        Please_confirm_the_password: '請輸入確認密碼',
        Wrong_SMS_verification_code: '短信驗證碼或郵箱驗證碼錯誤',
        Please_read_the_Terms_Conditions: '請閱讀用戶協議',
        Authorization_completed: '已委託',
        Conclude_a_transaction_partially: '部分成交',
        Cancel_the_order_after_partial_transaction: '部分成交後撤單',
        Please_enter_the_selling_amount: '請輸入賣出數量',
        Please_complete_the_verification_first_before_transaction: '請您先進行實名認證再進行交易',
        Please_enter_the_selling_price: '請輸入賣出價',
        Please_enter_the_buying_amount: '請輸入買入數量',
        Please_enter_the_buying_price: '請輸入買入價',
        Payment_successful_Paid: '付款成功',
        Failed_to_cancel: '取消失敗',
        The_order_was_completed: '訂單已完成',
        Please_confirm_your_complaint: '您確定申訴嗎',
        Tips: '提示',
        Complaint_successful: '申訴成功',
        Complaint_cancellation_was_completed: '已取消申訴',
        Seller: '賣家',
        The_buyer_has_received_the_order_waiting_for_buyer_payment: '買家已接單，等待買家付款',
        Wait_for_counterpart_payment: '等待對方付款',
        Buyer_has_completed_payment_please_make_sure_whether_the_transaction_was_finished: '買家已付款，請確定是否完成訂單',
        Counterpart_has_completed_the_payment: '對方已付款',
        You_have_confirmed_the_payment_and_the_order_was_completed: '您已確認收款，訂單已完成',
        Your_complaint_has_been_passed: '您的申訴已通過',
        Application_passed: '申請通過',
        Your_complaint_has_been_rejected: '您的申訴已駁回',
        Application_rejected: '申請駁回',
        Wait_for_buyer_receiving_order: '等待買家接單',
        Wait_for_counterpart_receiving_order: '等待對方接單',
        Counterpart_has_refused_the_order: '對方已拒絕接單',
        Buyer_has_refused_the_order: '買家拒絕接單',
        Option_3_I_completed: '選項3：我已付款，但未備註',
        Confirm_to_close: '確認關閉',
        Enter_your_bank_of_deposit: '請輸入開戶銀行',
        Save_UnionPay_payment_settings: '保存銀聯支付設置',
        Update_UnionPay_payment_settings: '更新銀聯支付設置',
        Please_confirm_your_cancel_of_the_order: '您確定取消訂單嗎',
        You_have_canceled_the_order: '您已取消訂單',
        The_enter_number_canot_lager_than_the_available_funds: '輸入數量不能大於當前可用資金',
        The_enter_number_canot_lager_than_the_current_amount: '輸入數量不能大於當前量',
        The_enter_number_canot_less_than_minimum_limit: '輸入數量不能小於最小限額',
        Please_enter_the_data: '請輸入數據',
        Please_enter_the_valid_start_date: '請輸入有效起始日期',
        Please_enter_the_valid_end_date: '請輸入有效結束日期',
        Please_upload_the_photo_of_ID_card: '請上傳身份證照片',
        The_photo_format_of_head_portrait_should_be_JPG_or_PNG: '上傳頭像圖片只能是 JPG或 PNG格式',
        The_picture_memory_size_of_head_portrait_should_be_less_than_3M: '上傳頭像圖片大小不能超過3MB',
        Forgot_the_login_password: '忘記登錄密碼',
        Please_enter_current_login_password: '請輸入當前的登錄密碼',
        Please_enter_new_login_password: '請輸入新密碼',
        Check_up: '查看',
        Go_to_verified: '去認證',
        Invitation_successful: '邀請成功',
        Invitation_failed: '邀請失敗',

        // 补充
        The_highest: '最高',
        The_minimum: '最低',
        Your_identity_information_has_been_authenticated: '您的身份信息已通過認證',
        Network_instability: '網路不穩定',
        The_session_is_invalid_Please_login_again: '會話失效，請重新登錄！',
        // 0912补充
        Logo_image: 'logo圖片',
        Good_wallet_download: 'NFTE錢包下載',
        GDUEX_download: 'GDUEX下載',
        An_important_statement_of_Good_chain_uniqueness: 'NFTE鏈唯一性的重要聲明',
        Clinch_a_deal_the_price: '成交價格',
        Clinch_a_deal_the_quantity: '成交數量',
        Clinch_a_deal_amount: '成交總額',
        time_sharing: '分時',
        Set_up_the: '設置',
        indicators: '指標',
        Place_the_order_failed: '下單失敗',
        Assets_will_be_frozen_after_the_complaint_and_the_ombudsman_will_step_in: '提起申訴後資產將會凍結，申訴專員將會介入本次交易。',
        Maliciously_appealing_terms_disrupt_the_normal_operation_order_of_the_platform: '惡意申訴屬於擾亂平臺正常運營秩序的行為，情節嚴重將凍結帳戶。',
        Photo_attachments: '圖片附件',
        Remark_information_copy_successfully: '備註信息，複製成功',
        Failed_to_obtain_payment_method: '獲取支付方式失敗',
        Please_select_appeal_type: '請選擇申訴類型',
        Please_enter_cause_of_complaint: '請輸入申訴原因',
        The_appeal_failed: '申訴失敗',
        Payment_failure: '付款失敗',
        If_payment_is_not_confirmed_within_15_minutes: '如15分鐘內沒有確認付款，訂單將自動取消。',
        Order_unpaid: '訂單未付款',
        Order_paid: '訂單已付款',
        Identify_successful: '確認成功',
        Confirm_the_failure: '確認失敗',
        The_appeal_type: '申訴類型',
        Please_enter_your_alipay_account: '請輸入您的支付寶帳號',
        Qr_code_upload: '二維碼上傳',
        Picture_of_qr_code_uploaded: '二維碼圖片上傳',
        WeChat_payment_account: '微信支付帳號',
        Please_enter_your_WeChat_payment_account: '請輸入您的微信支付帳號',
        Save_alipay_payment_Settings: '保存支付寶支付設置',
        Update_alipay_payment_Settings: '更新支付寶支付設置',
        Save_the_WeChat_payment_Settings: '保存微信支付設置',
        Update_WeChat_payment_Settings: '更新微信支付設置',
        Please_upload_the_picture_of_qr_code: '請上傳二維碼圖片',
        Upload_pictures_can_only_be_JPG_or_PNG_format: '上傳圖片只能是 JPG或 PNG格式!',
        Operation_canceled: '操作已取消',
        Alipay_icon: '支付寶圖標',
        Micro_letter_icon: '微信圖標',
        Authentication_passed: '身份認證通過',
        Portrait_photo_of_my_id_card: '本人身份證人像面照片',
        My_id_card_picture_of_national_emblem: '本人身份證國徽面照片',
        Holding_personal_id_card_photo: '手持本人身份證人像面照片',
        His_AD: '他的廣告',
        Ad_number: '廣告編號',
        Create_Time: '創建時間',
        Revocation_failure: '撤銷失敗',
        Order_is_invalid: '訂單已失效',
        The_order_has_lapsed_and_payment_information_cannot_be_viewed: '訂單已失效，無法查看支付信息',
        The_complaint_order_has_been_processed: '申訴訂單已處理',
        The_order_has_been_appealed: '訂單已申訴完成',
        Please_ensure_that_your_contact_information_is_unblocked_to_facilitate_customer_service: '被申訴中，請確保您的聯繫方式暢通，以方便客服聯繫您',
        Order_Pending: '訂單申訴中',
        Order_appeals: '訂單申訴',

        // 0918补充
        Activity_reward: '活動獎勵',
        Participation_in_GOOD_community_activities_Award: '參與GOOD社區活動獎勵',
        Thank_you_for_your_support_and_concern: '感謝用戶對NFTE的支持與關注，為回饋廣大NFTE用戶，即日起將正式啟動持有GOOD激勵計畫！全網NFTE用戶基於自願原則，均可參與鎖倉獎勵活動，將獲得30%的GOOD獎勵。',
        Participation_time: '參與活動時間',
        From_now_on_to_GOOD_main_network_formally_launched: '即日起至GOOD主網正式上線',
        Conditions_of_participation: '參與條件',
        Good_advanced_certified_users: 'NFTE高級認證用戶，10000枚GOOD起，鎖定10個月',
        Incentive_conditions: '獎勵條件',
        Users_can_apply_for_the_lock_up_bonus_activities: '用戶可在客戶端申請鎖倉獎勵活動，申請成功後將得到鎖倉數量的30%的GOOD',
        Activity_reward_scheme: '活動獎勵方案',
        Reward_start_time: '獎勵開始時間',
        Since_the_date_of_GOOD_formally_launched: '自GOOD正式上線之日起開始計算',
        Automatic_release: '自動釋放',
        Accelerated_release: '加速釋放',
        User_account_for_the_month_GOOD_settlement: '用戶帳戶當月用於GOOD結算產生的手續費總額的30%,釋放等額市值的GOOD，手續費按月結算。',
        For_more_information_please_login_to_the_official_website_or_APP: '瞭解更多資訊，請登錄官方網站或APP',
        Official_website: '官方網站',
        The_ultimate_right_of_interpretation_of_this_activity_belongs_to_Good_Foundation: '本活動最終解釋權歸Good Foundation所有',
        I_agree_that_the_content_has_been_clearly_understood: '我同意，已清晰瞭解內容，並自願參加此活動',
        Application_for_participation: '申請參與',
        Participation_volume: '參與量',
        Submission_Apply: '提交申請',
        Insufficient_number_of_participants: '不足最小參與數量',
        Please_enter_your_account_number_when_registering: '請輸入註冊時的帳號',
        Please_enter_a_mailbox_account_or_cell_phone_number: '請輸入郵箱帳號或者手機號',
        Mobile_registration: '手機註冊',
        Mailbox_registration: '郵箱註冊',
        Please_enter_a_mailbox_account: '請輸入郵箱帳號',
        Mailbox_verification_code: '郵箱驗證碼',
        Please_enter_SMS_verification_code: '請輸入短信驗證碼',
        Please_enter_the_mailbox_verification_code: '請輸入郵箱驗證碼',
        Please_enter_a_mailbox_account: '請輸入郵箱帳號',
        Please_input_the_correct_mailbox_account: '請輸入正確格式的郵箱帳號',
        Revoke_a_complaint: '撤銷申訴',
        Upload_please_wait_a_moment: '上傳中,請稍等...',
        Have_in_hand: '進行中',
        Expired: '已失效',
        Completion_of_the_complaint: '申訴完成',
        Order_time: '下單時間',
        Transaction_user_status: '交易用戶狀態',
        Acceptances_status: '承兌商狀態',
        Transaction_user_complaint_status: '交易用戶申訴狀態',
        Acceptances_state_of_complaint: "承兌商申訴狀態",
        Time_of_payment: '付款時間',
        Completion_time: '完成時間',
        Cancellation_of_time: '取消時間',
        Expired_time: '失效時間',
        Appeals_time: '申訴時間',
        Receivables: '已收款',
        Unreceivable: '未收款',
        forced_cancellation: '強制取消',
        Not_appealing: '未申訴',
        Automatic_cancellation_after: '後自動取消',
        Can_not_exceed_the_maximum_order_quantity: '不能大於最大下單數量',
        The_advertisement_is_out_of_date_Please_re_select_the_order: '廣告已過期，請重新選擇下單',
        You_have_placed_the_order_waiting_for_the_buyer_to_pay: '您已下單，等待買家付款',
        If_the_other_party_fails_to_pay_within_15_minutes_the_order_is_invalid: '若對方15分鐘內未付款，則訂單失效',



        // 挂牌融合后新增
        // 挂牌交易页面
        Current_Interests: '當前權益',
        Risk_Rate: '風險率',
        change_lows: '漲跌',
        Opening_Price: '開盤',
        Closing_Price: '昨收',
        time_line: '分時線',
        Min: '分鐘',
        Hour: '小時',
        Day: '日線',
        Most_available_buy: '可買',
        Maximum_permissible_price_difference: '允許成交價和報價的最大價差',
        Advance_charge: '預付款',
        Most_available: '可賣',
        History_buying_orders: '買入分筆',
        History_selling_orders: '賣出分筆',
        More: '更多',
        Piece: '個',
        K_line_data: 'K線數據',
        needs: '需',
        Please_log_in_and_trade: '請登錄後進行交易',
        Please_enter_purchase_price_positive_number_of_pure_numbers: '請輸入買入價格!(純數字的正數)',
        Please_enter_the_number_of_purchases_positive_numbers_of_pure_numbers: '請輸入買入數量!(純數字的正數)',
        The_maximum_quantity_can_be_ordered: '最大可下單數量為',
        The_minimum_quantity_can_be_ordered: '最小可下單數量為',
        Please_do_not_enter_numbers_less_than_zero: '請不要輸入小於0的數字',
        Please_enter_the_selling_price_the_positive_number_of_pure_numbers: '請輸入賣出價格!(純數字的正數)',
        Please_enter_the_number_of_sales_the_positive_number_of_pure_numbers: '請輸入賣出數量!(純數字的正數)',
        Pens_and_detail: '分筆明細',
        No_data: '暫無數據',
        Please_enter_a_selling_price_of_not_less_than_zero: '請輸入不小於0的賣出價',
        Sell_volume_should_not_be_less_than_zero: '賣出量不可小於0',
        Please_enter_a_bid_price_of_not_less_than_zero: '請輸入不小於0的買入價',
        Purchase_volume_should_not_be_less_than_zero: '買入量不可小於0',
        Amount_of_order_cannot_be_zero: '下單數量不能等於0',

        // 挂牌资产状况页面
        Condition_of_listed_assets: '掛牌資產狀況',
        Main_block: '主版塊',
        Listed: '掛牌交易',
        Market: '市場',
        Total_Amount_two: '總額',
        Transfer: '劃轉',
        Identity_Verification: '實名認證',
        From: '從',
        main_board: '主版塊',
        Main: '主',
        balance: '餘額',
        transfer_to: '轉至',
        Choose_an_account: '請選擇帳戶',
        Available_amount: '可劃轉數量',
        Please_consent_to_Risk_Disclosure_before_trading: '請同意風險揭示書後再進行交易',

        // 挂牌交易资产账户页面
        The_current_balance: '當前餘額',
        Asset_account: '資產帳戶',
        Prepayment: '佔用預付款',
        Available_prepayment: '可用預付款',
        Real_time_price_difference: '實時價差',
        Frozen_advance_payment: '凍結預付款',
        Freezing_fee: '凍結手續費',

        // 挂牌资金流水页面
        Capital_flow: '資金流水',
        Complete: '完成',
        Transfer_to_a_record: '轉入記錄',
        Turn_out_record: '轉出記錄',
        All_records: '全部記錄',

        // 挂牌交易账户页面
        mid_long_term_transactions: '中遠期交易',
        microdisk: '微盤',
        Remarks_required_short: '備註（必填）',
        Balance_is: '餘額為',
        Please_select_an_account: '請選擇帳戶',
        Transferable_quantity: '可劃轉數量',
        Please_input_quantity: '請輸入數量',
        Successful_transfer: '劃轉成功',
        Successful_transfer2: '請求成功',
        // 挂牌交易账户资产财务记录页面
        Transaction_record: '交易記錄',
        Transfer_credentials: '劃轉憑據',
        Registered_account: '掛牌帳戶',

        // 挂牌账户页面
        Mutual_transfer_of_funds: '資金互轉',
        Turn_to_success: '轉入成功',
        Transfer_failed_Please_try_again_later: '轉入失敗，請稍後重試',
        Turn_out_to_be_successful: '轉出成功',
        Turn_out_failure_Please_try_again_later: '轉出失敗，請稍後重試',
        wallet: '錢包',
        Listed_assets_status: '掛牌資產狀況',
        Option_transaction: '期權交易',

        // 挂牌快捷平仓
        Quick_and_flat: '快捷平倉',
        contract: '合約',
        Current_price: '當前價',
        Success: '成功',

        // 挂牌成交查询
        Listing_name: '掛牌名稱',
        open_a_granary_to_provide_relief: '開倉',
        Close_a_position: '平倉',
        Flat_price_difference: '平倉價差',
        Transaction_type: '成交類型',
        Market_transaction: '市價成交',
        Limited_transaction: '限價成交',
        Stop_loss_deal: '止損成交',
        Close_business: '止盈成交',
        Strong_business: '強平成交',
        Hedging: '對沖單',
        Receipt: '交收單',

        // 挂牌平仓明细
        Flat_single_number: '平倉單號',
        Listing_code: '掛牌代碼',
        Flat_number: '平倉數量',
        Exit_price: '平倉價',
        Flat_type: '平倉類型',
        Jiancang_single_number: '建倉單號',

        // 挂牌客户持货
        Buying_average_holding_price: '買入平均持倉價',
        Buying_position: '買入持倉金額',
        Buying_quantity: '買入數量',
        Buy_floating_profit_and_loss: '買入浮動盈虧',
        Average_selling_price: '賣出平均持貨價',
        Selling_positions: '賣出持倉金額',
        Selling_quantity: '賣出數量',
        Sell_floating_profit_and_loss: '賣出浮動盈虧',
        Customer_net_position: '客戶淨頭寸',
        Floating_profit_and_loss_aggregate: '浮動盈虧匯總',

        // 挂牌委托查询
        Price_stop_price: '止盈價',
        Stop_price: '止損價',
        Entrustment_mode: '委託方式',
        Are_you_sure_you_want_to_cancel_the_order: '您確定要撤單嗎',

        // 挂牌交收部分
        Delivery: '交收',
        If_the_price_difference_is_filled_out_please_fill_in_the_price_difference_greater_than_zero: '價差填寫錯誤，請填寫大於0的價差',
        Delivery_time: '交收時間',
        Money_of_payment: '交收金額',
        Amount_of_delivery: '交收數量',
        Settlement_fee: '交收手續費',

        // 挂牌持仓明细
        market_price: '市價',
        Fixed_price: '限價',
        Stop_loss: '止損',
        target_profit: '止盈',
        Coercion: '強平',
        Withdrawal_failed_Please_try_again_later: '撤單失敗,請稍後重試',
        Equal_stock_price: '持倉均價',
        Holding_number: '持倉數量',
        Floating_P_L: '浮動盈虧',
        Advance_payment_in_position: '持倉預付款',
        Building_time: '建倉時間',
        Building_price: '建倉價格',
        Quantity_of_warehouse: '建倉數量',
        Holding_price: '持倉價',
        Prepayment_coefficient: '預付款係數',
        Advance_payment_rate: '預付款費率',
        Stop_get_and_stop_loss: '止盈止損',
        Listing: '掛牌',
        Stop_or_stop_price_is_not_set_correctly: '止盈或止損價設置不正確',
        Please_fill_in_the_price_of_stop_or_stop_loss: '請填寫止盈或止損價格',

        // 挂牌委托查询、成交查询、持仓明细、持仓汇总、客户持货标题
        Entrustment_inquiry: '委託查詢',
        Entrustment_detail: '委託明細',
        Transaction_inquiries: '成交查詢',
        Holding_details: '持倉明細',
        Holding_summary: '持倉匯總',
        Customer_holding: '客戶持貨',
        Intersection_query: '交收查詢',

        // 挂牌资料
        Listing_information: '掛牌資料',
        Listing_state: '掛牌狀態',
        Minimum_variable_price: '最小變動價格',
        Prepayment_algorithm: '預付款算法',
        Transaction_fee_algorithm: '交易手續費算法',
        Warehouse_handling_fee_coefficient: '建倉手續費係數',
        Closing_fee_coefficient: '平倉手續費係數',
        Single_minimum_quantity: '單筆最小可委託數量',
        The_maximum_quantity_of_a_single_pen_can_be_commissioned: '單筆最大可委託數量',
        Maximum_position: '最大持倉量',
        Market_position_authorization: '市價建倉權限',
        Market_clearing_authority: '市價平倉權限',
        Surplus_stop_loss_authority: '止盈止損權限',
        Yes: '有',
        No:'无',
        Stop_loss_order_price_difference: '止損下單價格差',
        Profit_margin: '止盈下單價格差',
        Price_fixing_price_difference: '限價建倉價格差',
        Order_price_difference_default_value: '下單價格差默認值',
        Overnight_fee: '過夜費',
        Unlisted: '未上市',
        Already_listed: '已上市',
        Already_out_of_the_market: '已退市',
        Proportion: '比例',
        Fixed_value: '固定值',
        Trading_Unit: '交易單位',
        Trading_Unit_Name: '交易單位名稱',
        Volume_hand: '手',

        // 个人中心增加
        Go_to_verify_again: '去重新認證',
        Examples: '示例',
        Please_enter_ID_number: '請輸入證件號碼',
        Fail_to_pass_senior_identity_verification_please_try_again_later: '高級認證失敗，請稍後重試',
        Users_who_would_like_to_do_contract_transactions_on_this_site_need_to_complete_their_identity_verification: '在本站進行合約交易的用戶需要進行實名認證，請提供真實有效的本人身份證照片，虛假認證產生的一切後果由用戶負責！',
        Requirements_ID_photo_is_clear_and_its_size_should_be_no_more_than_3M: '照片要求：證件照清晰；照片大小不超過3M。',

       


         Please: '請',
         Query: '查询',
    },
    ...twLocale
}
export default tw;
