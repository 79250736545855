// initial state

// import { null } from "mathjs";

// shape: [{ id, quantity }]
const state = {
  set nickNameStatus(val) {
    sessionStorage.setItem("nickNameStatus", val);
  },
  get nickNameStatus() {
    sessionStorage.getItem("nickNameStatus");
  },
  set highCerStatus(val) {
    sessionStorage.setItem("highCerStatus", val);
  },
  get highCerStatus() {
    sessionStorage.getItem("highCerStatus");
  },
  commodityStrategy: [],
  isIndex: false,
  sessionStr: "",
  sessionUserId: "",
  userInfo: {},
  myInviteCode: "",
  myUrl: "/login",
  setReg: "",
  regUrl: "/register",
  currencyDetail: {},
  commodityInformationData: {},
  bottomLinkNavKeyData: "",
  totalAssetsData: "",
  riskNoteCookie: "",
  currencyDetailMid: {},
  commodityInformationDataMid: {},
  riskNoteCookieMid: "",
  iframeState: [], // iframe状态

  set sessionInfo(val) {
    if (val === "") {
      sessionStorage.clear();
      return;
    }
    let { isLogin, userId, sessionStr, tel, firmId, firmCode, userCode } = val;
    sessionStorage.setItem("isLogin", isLogin);
    sessionStorage.setItem("userId", userId);
    sessionStorage.setItem("sessionStr", sessionStr);
    sessionStorage.setItem("tel", tel);
    sessionStorage.setItem("firmId", firmId);
    sessionStorage.setItem("firmCode", firmCode);
    sessionStorage.setItem("userCode", userCode);
  },
  get sessionInfo() {
    let sessionInfo = {};
    if (!sessionStorage.getItem("isLogin")) {
      sessionStorage.setItem("isLogin", false);
    }
    sessionInfo.isLogin = sessionStorage.getItem("isLogin");
    sessionInfo.userId = sessionStorage.getItem("userId");
    sessionInfo.sessionStr = sessionStorage.getItem("sessionStr");
    sessionInfo.tel = sessionStorage.getItem("tel");
    sessionInfo.firmId = sessionStorage.getItem("firmId");
    sessionInfo.firmCode = sessionStorage.getItem("firmCode");
    sessionInfo.userType = sessionStorage.getItem("userType");
    sessionInfo.userCode = sessionStorage.getItem("userCode");
    return sessionInfo;
  },
  set userType(val) {
    sessionStorage.setItem("userType", val);
  },
  get userType() {
    return sessionStorage.getItem("userType");
  },
  set userCerStatus(val) {
    sessionStorage.setItem("userCerStatus", val);
  },
  get userCerStatus() {
    return sessionStorage.getItem("userCerStatus");
  },
  logoUrl: null,
  roleRight: [],
  gxLeaveForFlag: false,
  heartbeatFlag: false, // 心跳轮询
  approveFlag: {},
  newtradeId: null, // 交易表主键
  h_accountTime: null, //资金更新时间 *
  h_deliveryOrderTime: null, //交收订单更新时间
  h_holdTime: null, //持仓汇总更新时间
  h_orderTime: null, //委托更新时间
  h_outfundTime: null, //出金阈值更新时间 *
  h_sumClosePl: null, //浮动盈亏 *
  h_warehouseTime: null, //交收仓单更新时间
  h_avilibleMargin: null, //可用资金
  userAsset: {
    accountId: null,
    avilibleMargin: 0,
    balance: 0,
    firmId: 0,
    canBeMargin: 0,
    closePl: 0,
    createTime: 0,
    deliveryClosePl: 0,
    deliveryFee: 0,
    floatPl: 0,
    frozenFunds: 0,
    inGold: 0,
    lastBalance: 0,
    occupyMargin: 0,
    outGold: 0,
    procedureFee: 0,
    risk: 0,
    swapsFee: 0,
    symbolId: null,
    transferClosePl: 0,
    updateTime: 0,
    oweMoney: 0
  },
  marketStatus: {
    content: null,
    status: "",
    systemTime: 0,
    tradedayid: 0
  }
};

// getters
const getters = {
  marketStatusGetter: state => state.marketStatus,
  userAssetGetter: state => state.userAsset,

  h_accountTime: state => state.h_accountTime,

  h_sumClosePl: state => state.h_sumClosePl,

  h_avilibleMargin: state => state.h_avilibleMargin,

  h_warehouseTime: state => state.h_warehouseTime,

  h_outfundTime: state => state.h_outfundTime,

  h_orderTime: state => state.h_orderTime,

  h_holdTime: state => state.h_holdTime,

  h_deliveryOrderTime: state => state.h_deliveryOrderTime,

  newtradeIdGetter: state => state.newtradeId,

  approveFlagGetter: state => state.approveFlag,
  heartbeatFlagGetter: state => state.heartbeatFlag,
  gxLeaveForFlagGetter: state => state.gxLeaveForFlag,
  iframeStateGetter: state => state.iframeState,
  commodityStrategyGetter: state => state.commodityStrategy,
  highCerStatusGetter: state => state.highCerStatus,
  nickNameStatusGetter: state => state.nickNameStatus,
  isIndexGetter: state => state.isIndex,
  sessionInfoGetter: function(state) {
    return state.sessionInfo;
  },
  userTypeGetter: state => state.userType,
  userInfoGetter: state => state.userInfo,
  myInviteCodeGetter: state => state.myInviteCode,
  userCerStatusGetter: state => state.userCerStatus,
  currencyDetailGetter: state => state.currencyDetail,
  commodityInformationDataGetter: state => state.commodityInformationData,
  bottomLinkNavKeyGetter: state => state.bottomLinkNavKeyData,
  totalAssetsGetter: state => state.totalAssetsData,
  riskNoteCookieGetter: state => state.riskNoteCookie,
  currencyDetailMidGetter: state => state.currencyDetailMid,
  riskNoteCookieMidGetter: state => state.riskNoteCookieMid,
  setriskNoteCookieMid(state, value) {
    state.riskNoteCookieMid = value;
  },
  commodityInformationDataMidGetter: state => state.commodityInformationDataMid,
  logoUrlGetter: state => state.logoUrl,
  roleRightGetter: state => state.roleRight
};

// actions
const actions = {};

// mutations
const mutations = {
  setMarketStatus(state, data) {
    state.marketStatus = data;
  },
  setUserAsset(state, data) {
    state.userAsset = data;
  },
  setH_accountTime(state, data) {
    state.h_accountTime = data;
  },
  setH_sumClosePl(state, data) {
    state.h_sumClosePl = data;
  },
  setH_avilibleMargin(state, data) {
    state.h_avilibleMargin = data;
  },
  setH_warehouseTime(state, data) {
    state.h_warehouseTime = data;
  },
  setH_outfundTime(state, data) {
    state.h_outfundTime = data;
  },
  setH_orderTime(state, data) {
    state.h_orderTime = data;
  },
  setH_holdTime(state, data) {
    state.h_holdTime = data;
  },
  setH_deliveryOrderTime(state, data) {
    state.h_deliveryOrderTime = data;
  },
  setNewtradeId(state, data) {
    state.newtradeId = data;
  },
  setApproveFlag(state, data) {
    state.approveFlag = data;
  },
  setHeartbeatFlag(state, data) {
    state.heartbeatFlag = data;
  },
  setGxLeaveForFlag(state, data) {
    state.gxLeaveForFlag = data;
  },
  setIframeState(state, data) {
    state.iframeState.push(data);
  },
  setCommodityStrategy(state, data) {
    state.commodityStrategy = data;
  },
  setHighCerStatus(state, value) {
    state.highCerStatus = value;
  },
  setNickNameStatus(state, value) {
    state.nickNameStatus = value;
  },
  changeIsIndex(state, value) {
    state.isIndex = value;
  },
  setUserInfo(state, value) {
    state.userInfo = value;
  },
  setMyViteCode(state, value) {
    state.myInviteCode = value;
  },
  setSessionInfo: function(state, val) {
    state.sessionInfo = val;
  },
  setUserType(state, value) {
    state.userType = value;
  },
  setUserCerStatus: function(state, val) {
    state.userCerStatus = val;
  },
  setCurrencyDetail(state, value) {
    state.currencyDetail = value;
  },
  setCommodityInformationData(state, value) {
    state.commodityInformationData = value;
  },
  setBottomLinkNavKey(state, value) {
    state.bottomLinkNavKeyData = value;
  },
  setTotalAssets(state, value) {
    state.totalAssetsData = value;
  },
  setRiskNoteCookie(state, value) {
    state.riskNoteCookie = value;
  },
  setCurrencyDetailMid(state, value) {
    state.currencyDetailMid = value;
  },
  setcommodityInformationDataMid(state, value) {
    state.commodityInformationDataMid = value;
  },
  setriskNoteCookieMid(state, value) {
    state.riskNoteCookieMid = value;
  },
  setLogoUrl(state, value) {
    state.logoUrl = value;
  },
  setRoleRight(state, value) {
    state.roleRight = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
