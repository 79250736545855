const oVueFilter = {
  //截取小数点后n位
  toFixedFn(val, num) {
    if (num) {
      let realVal = Number(val).toFixed(num);
      return realVal;
    } else {
      return val;
    }
  },
  //精确小数点三位,不四舍五入
  exactPointThree(val) {
    let newVal;
    val = Number(val);
    if (val < 0) {
      val = val.toString().substring(1);
      newVal = Number(val.toString().match(/^\d+(?:\.\d{0,3})?/)) * -1;
    } else {
      newVal = Number(val.toString().match(/^\d+(?:\.\d{0,3})?/));
    }
    //整数自动补0
    if (newVal.toString().indexOf(".") == -1) {
      newVal += ".000";
    }
    let len = newVal.toString().indexOf(".") + 1;
    //2位数自动补0
    if (
      newVal
        .toString()
        .substring(len)
        .split("").length == 2
    ) {
      newVal += "0";
    }
    //1位数自动补0
    if (
      newVal
        .toString()
        .substring(len)
        .split("").length == 1
    ) {
      newVal += "00";
    }
    return newVal;
    //   return Number(num.toString().match(/^\d+(?:\.\d{0,3})?/))
  },
  //精确小数点两位,不四舍五入
  exactPointTwo(val) {
    let newVal;
    val = Number(val);
    if (val < 0) {
      val = val.toString().substring(1);
      newVal = Number(val.toString().match(/^\d+(?:\.\d{0,2})?/)) * -1;
    } else {
      newVal = Number(val.toString().match(/^\d+(?:\.\d{0,2})?/));
    }
    //整数自动补0
    if (newVal.toString().indexOf(".") == -1) {
      newVal += ".00";
    }
    let len = newVal.toString().indexOf(".") + 1;
    //1位数自动补0
    if (
      newVal
        .toString()
        .substring(len)
        .split("").length == 1
    ) {
      newVal += "0";
    }
    return newVal;
  },
  exactPointNon(val) {
    return val;
  },
  //日期格式转化（转化到时分秒）
  DateFormate(timer) {
    if (timer && !isNaN(timer)) {
      var date = new Date(timer);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();
      [year, month, day, hour, min, sec] = [
        year,
        month,
        day,
        hour,
        min,
        sec
      ].map(item => (item < 10 ? "0" + item : item));
      return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
    } else {
      return "--";
    }
  },
  // 日期格式化（年月日）
  dataFormateYear(timer) {
    if (timer) {
      let timers = timer.toString();
      let year = timers.slice(0, 4);
      let month = timers.slice(4, 6);
      let day = timers.slice(6, 8);
      return `${year}-${month}-${day}`;
    }
    return "";
  },
  //日期格式转化（转化到时分秒）
  DateFormateDH(timer) {
    if (timer && !isNaN(timer)) {
      var date = new Date(timer);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();
      [year, month, day, hour, min, sec] = [
        year,
        month,
        day,
        hour,
        min,
        sec
      ].map(item => (item < 10 ? "0" + item : item));
      return `${month}/${day} ${hour}:${min}`;
    } else {
      return "--";
    }
  },
  // 日期格式化（转化到天）
  DateFormateDay(timer) {
    if (timer && !isNaN(timer)) {
      var date = new Date(timer);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();
      [year, month, day, hour, min, sec] = [
        year,
        month,
        day,
        hour,
        min,
        sec
      ].map(item => (item < 10 ? "0" + item : item));
      return `${year}-${month}-${day}`;
    } else {
      return "--";
    }
  },
  // 日期格式化（对于后台传的int类型的日期，加中横线）
  DateFormateInt(timer) {
    if (timer && !isNaN(timer)) {
      var year = timer.toString().slice(0, 4);
      var month = timer.toString().slice(4, 6);
      var day = timer.toString().slice(6, 8);
      return `${year}-${month}-${day}`;
    } else {
      return "--";
    }
  },
  DateFormateNoYearAndSeconds(timer) {
    if (timer && !isNaN(timer)) {
      var date = new Date(timer);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();
      [year, month, day, hour, min, sec] = [
        year,
        month,
        day,
        hour,
        min,
        sec
      ].map(item => (item < 10 ? "0" + item : item));
      return `${month}/${day} ${hour}:${min}`;
    } else {
      return "--";
    }
  },
  // 交易方式
  filterBuyWay(id) {
    let statusName = null;
    switch (id) {
      case "A":
        statusName = "整批";
        break;
      case "P":
        statusName = "分销";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  buyFormate(id) {
    let statusName = null;
    switch (id) {
      case 1:
        statusName = "普通竞拍";
        break;
      case 2:
        statusName = "跌价式竞拍";
        break;
      case 3:
        statusName = "大宗竞拍";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 企业认证状态
  enterpriseStatusName(id) {
    let statusName = null;
    switch (id) {
      case 1:
        statusName = "会员待审核";
        break;
      case 2:
        statusName = "审核通过";
        break;
      case 3:
        statusName = "会员驳回";
        break;
      case 4:
        statusName = "风控待审核";
        break;
      case 5:
        statusName = "风控驳回";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 入库订单状态
  warehouseStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "已申请";
        break;
      case 1:
        statusName = "质检中";
        break;
      case 2:
        statusName = "质检通过";
        break;
      case 3:
        statusName = "质检不通过";
        break;
      case 4:
        statusName = "不通过时同意质检结果并生成仓单";
        break;
      case 5:
        statusName = "已提货";
        break;
      case 6:
        statusName = "已撤销";
        break;
      case 7:
        statusName = "审核不通过";
        break;
      case 8:
        statusName = "审核通过";
        break;
      case 9:
        statusName = "已入库";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 申请仓单状态
  apllayStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "待入库";
        break;
      case "Y":
        statusName = "已入库";
        break;
      case "T":
        statusName = "质检通过";
        break;
      case "F":
        statusName = "质检不通过";
        break;
      case "D":
        statusName = "已提货";
        break;
      case "U":
        statusName = "已撤销";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 仓储状态
  arrivalStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "F":
        statusName = "待入库";
        break;
      case "T":
        statusName = "已入库";
        break;
      case "B":
        statusName = "部分回购";
        break;
      case "A":
        statusName = "全部回购";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 持仓状态
  holdStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "F":
        statusName = "待入库";
        break;
      case "T":
        statusName = "已入库";
        break;
      case "B":
        statusName = "部分补入";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 仓储状态，持仓状态
  basisPickStatusFormate(id) {
    let statusName = null;
    switch (
      id // W：待确认（20分钟时效）T：已确认F：已失效U：已取消
    ) {
      case "W":
        statusName = "待确认";
        break;
      case "T":
        statusName = "已完成";
        break;
      case "F":
        statusName = "已失效";
        break;
      case "U":
        statusName = "已取消";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 存单质检报告审核状态
  depositRStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 2:
        statusName = "待审核";
        break;
      case 4:
        statusName = "审核不通过";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 回购状态 W：已提交MU：会员撤销FU:用户撤销T：已完成FR:用户拒绝MR：会员拒绝
  repoStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "已申请";
        break;
      case "T":
        statusName = "已完成";
        break;
      case "MU":
        statusName = "会员撤销";
        break;
      case "FU":
        statusName = "用户撤销";
        break;
      case "FR":
        statusName = "用户拒绝";
        break;
      case "MR":
        statusName = "会员拒绝";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 入库类型
  warehouseTypeFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "申请入库";
        break;
      case 1:
        statusName = "补入库";
        break;
      case 2:
        statusName = "增发";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 是否可议价
  isholdFormate(id) {
    let statusName = null;
    switch (id) {
      case "F":
        statusName = "不可议价";
        break;
      case "T":
        statusName = "可议价";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 是否接受议价
  isspFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "不可议价";
        break;
      case 1:
        statusName = "可议价";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 订单审核状态
  auditStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "Y":
        statusName = "已提交未支付";
        break;
      case "P":
        statusName = "已支付待审核";
        break;
      case "T":
        statusName = "已通过";
        break;
      case "F":
        statusName = "未通过";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  inspectionStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "未质检";
        break;
      case "T":
        statusName = "已送检";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 检验单状态 0：待设置质检费用1：待上传质检报告2：未审核3：审核通过4：审核不通过
  inspectionStatusFormates(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "待设置质检费用";
        break;
      case 1:
        statusName = "待上传质检报告";
        break;
      case 2:
        statusName = "未审核";
        break;
      case 3:
        statusName = "审核通过";
        break;
      case 4:
        statusName = "审核不通过";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 供求状态 W:待审核U：已撤销T：审核通过F：审核不通过D：强制下架
  cbSupplyNoteStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "待审核";
        break;
      case "U":
        statusName = "已撤销";
        break;
      case "T":
        statusName = "审核通过";
        break;
      case "F":
        statusName = "审核未通过";
        break;
      case "D":
        statusName = "强制下架";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 质押状态
  pledgeStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 1:
        statusName = "质押申请中";
        break;
      case 2:
        statusName = "质押已报价";
        break;
      case 11:
        statusName = "质押物已赎回";
        break;
      case 12:
        statusName = "质押物部分卖出";
        break;
      case 13:
        statusName = "质押物全部卖出";
        break;
      case 14:
        statusName = "质押物回收";
        break;
      case 15:
        statusName = "质押物部分还款";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 还款状态
  paybackStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "未还款";
        break;
      case 1:
        statusName = "已部分还款";
        break;
      case 2:
        statusName = "已完成还款";
        break;
      case 9:
        statusName = "--";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 融资状态
  financeStatusFormate(row) {
    let statusName = null;
    // let currentTime = (new Date()).getTime()
    switch (row) {
      case 0:
        statusName = "申请中";
        break;
      case 1:
        statusName = "融资中";
        break;
      case 2:
        statusName = "已驳回";
        break;
      case 3:
        statusName = "已完成";
        break;
      case 4:
        statusName = "已逾期";
        break;
      case 9:
        statusName = "--";
        break;
      default:
        statusName = "--";
        break;
    }
    // if(row.financePaybackTime<currentTime){
    //   statusName = '已到期'
    // }
    return statusName;
  },
  // 仓储状态
  goodsStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "F":
        statusName = "待入库";
        break;
      case "T":
        statusName = "已入库";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 现货入库申请状态汇总
  wareApplayStatus(row) {
    let statusName = null;
    if (row.auditStatus == "W" && row.applyHoldStatus != "U") {
      statusName = "待审核";
    } else if (
      row.auditStatus == "T" &&
      row.applyHoldStatus == "W" &&
      row.inspectionStatus == "W"
    ) {
      statusName = "审核通过";
    } else if (row.auditStatus == "F") {
      statusName = "审核不通过";
    } else if (
      row.auditStatus == "T" &&
      row.applyHoldStatus == "T" &&
      row.inspectionStatus == "T"
    ) {
      statusName = "质检通过";
    } else if (
      row.auditStatus == "T" &&
      row.applyHoldStatus == "F" &&
      row.inspectionStatus == "T"
    ) {
      statusName = "质检不通过";
    } else if (
      row.applyHoldStatus == "D" &&
      (row.auditStatus == "F" || row.auditStatus == "T")
    ) {
      statusName = "已提货";
    } else if (row.auditStatus == "W" && row.applyHoldStatus == "U") {
      statusName = "已撤销";
    } else if (
      row.auditStatus == "T" &&
      row.applyHoldStatus == "Y" &&
      row.inspectionStatus == "W"
    ) {
      statusName = "已入库";
    } else if (
      row.auditStatus == "T" &&
      row.applyHoldStatus == "Y" &&
      row.inspectionStatus == "T"
    ) {
      statusName = "质检中";
    } else {
      statusName = "--";
    }
    return statusName;
  },
  // 开票状态
  ballotStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "未开票";
        break;
      case "O":
        statusName = "待确认";
        break;
      case "T":
        statusName = "已确认";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 卖方违约状态
  sellersDefaultStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "N":
        statusName = "未违约";
        break;
      case "W":
        statusName = "已延期";
        break;
      case "F":
        statusName = "已违约";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 买方违约状态
  buyersDefaultStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "N":
        statusName = "未违约";
        break;
      case "W":
        statusName = "已延期";
        break;
      case "F":
        statusName = "已违约";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 订单状态
  orderStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "O":
        // statusName = '已挂单';
        statusName = "摘牌";
        break;
      case "Z":
        statusName = "已摘单";
        break;
      case "T":
        statusName = "已完成";
        break;
      case "U":
        statusName = "已撤单";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 最新订单状态
  ordersStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "ZZ":
        // statusName = '已挂单';
        statusName = "待确认";
        break;
      case "Z":
        statusName = "已摘单";
        break;
      case "T":
        statusName = "已完成";
        break;
      // case 'U':
      //   statusName = '已撤单';
      //   break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  //挂单类型状态
  hangStatusFormate(id) {
    let statusName = null;
    switch (id) {
      //已挂单B：部分摘单A：全部摘单U：已撤销
      case "O":
        statusName = "已挂单";
        break;
      case "B":
        statusName = "部分摘单";
        break;
      case "A":
        statusName = "全部摘单";
        break;
      case "U":
        statusName = "已撤单";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 是否撤单
  isCancelOrder(id) {
    let statusName = null;
    switch (id) {
      case "U":
        statusName = "已撤销";
        break;
      default:
        statusName = "未撤销";
        break;
    }
    return statusName;
  },
  // 摘单状态
  pickOrderStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "待确认";
        break;
      case "T":
        statusName = "已确认";
        break;
      case "F":
        statusName = "已失效";
        break;
      case "U":
        statusName = "已取消";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 付款状态
  payStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "待付款";
        break;
      case "F":
        statusName = "已付首笔款";
        break;
      case "L":
        statusName = "已付剩余款";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 议价状态  N：未议价W：已提交T：已同意F：已拒绝
  priceStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "N":
        statusName = "未议价";
        break;
      case "W":
        statusName = "已提交";
        break;
      case "T":
        statusName = "已同意";
        break;
      case "F":
        statusName = "已拒绝";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 质检状态
  testQualityStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "待入库";
        break;
      case "Y":
        statusName = "已入库";
        break;
      case "T":
        statusName = "质检通过";
        break;
      case "F":
        statusName = "质检不通过";
        break;
      case "D":
        statusName = "已提货";
        break;
      case "U":
        statusName = "已撤销";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // testQualityStatusFormate(id) {
  //   let statusName = null;
  //   switch (id) {
  //     case 0:
  //       statusName = '待设置质检费用';
  //       break;
  //     case 1:
  //       statusName = '待上传质检报告';
  //       break;
  //     case 2:
  //       statusName = '未审核';
  //       break;
  //     case 3:
  //       statusName = '审核通过';
  //       break;
  //     case 4:
  //       statusName = '审核不通过';
  //       break;
  //     default:
  //       statusName = '--';
  //       break;
  //   }
  //   return statusName;
  // },
  // 来源(产能/竞价)
  sourceIdFormate(id) {
    let statusName = null;
    switch (id) {
      case "W":
        statusName = "现货";
        break;
      case "J":
        statusName = "竞价";
        break;
      case "C":
        statusName = "预售";
        break;

      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 个人持有仓单状态
  firmHoldStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "持有";
        break;
      case 1:
        statusName = "冻结";
        break;
      case 2:
        statusName = "已售";
        break;
      case 3:
        statusName = "已提货";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 仓储状态
  firmGoodsStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "待售";
        break;
      case 1:
        statusName = "已提货";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 挂单状态 X：现货挂单 B：履约订金挂单Q：求购单
  guaStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "X":
        statusName = "现货挂单";
        break;
      case "B":
        statusName = "履约订金挂单";
        break;
      case "Q":
        statusName = "求购单";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 提货状态(物流)
  pickupGoodsStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "待指派";
        break;
      case 1:
        statusName = "待接货";
        break;
      case 2:
        statusName = "运送中";
        break;
      case 3:
        statusName = "已完成";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 提货状态(自提)
  pickupGoodsSelfStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "待自提";
        break;
      case 1:
        statusName = "已自提";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 订单类型
  typeFormate(type) {
    let statusName = null;
    switch (type) {
      case "X":
        statusName = "现货挂单";
        break;
      case "B":
        statusName = "履约订金挂单";
        break;
      case "Q":
        statusName = "求购单";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 竞价类型
  biddingTypeFormate(type) {
    let statusName = null;
    switch (type) {
      case "X":
        statusName = "竞买";
        break;
      case "B":
        statusName = "竞买";
        break;
      case "Q":
        statusName = "竞卖";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 是否流派
  biddingIsFlowFormate(type) {
    let statusName = null;
    switch (type) {
      case 1:
        statusName = "是";
        break;
      case 0:
        statusName = "否";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 持仓类型 持仓类型X：现货持仓Y：预售持仓B：预售补入库持仓 // 所有订单都可以挂竞价单
  holdTypeFilter(type) {
    let statusName = null;
    switch (type) {
      case "X":
        statusName = "现货持仓";
        break;
      case "Y":
        statusName = "预售持仓";
        break;
      case "B":
        statusName = "预售补入库持仓";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },

  // 商品属性转化
  firstAttributeTransformate(obj) {
    if (obj != null) {
      let newObj = JSON.parse(obj);
      let objValue = Object.values(newObj);
      if (Object.keys(newObj).length != 0) {
        let str = "";
        str = Object.keys(newObj)[0] + ": " + objValue[0];
        return str;
      }
    }

    return "";
  },
  // 商品属性转化键
  keyAttributeTransformate(obj) {
    if (obj != null) {
      let newObj = JSON.parse(obj);

      if (Object.keys(newObj).length != 0) {
        let str = "";
        str = " | " + Object.keys(newObj)[0] + ": ";
        return str;
      }
    }

    return "";
  },
  // 商品属性转化值
  valueAttributeTransformate(obj) {
    if (obj != null) {
      let newObj = JSON.parse(obj);
      let objValue = Object.values(newObj);
      if (Object.keys(newObj).length != 0) {
        let str = "";
        str = objValue[0];
        return str;
      }
    }

    return "";
  },
  // 转化所有的商品属性
  allAttributeTransformate(obj) {
    let newObj = JSON.parse(obj);
    let nameArray = [];
    let newObjKey = Object.keys(newObj);
    let newObjValue = Object.values(newObj);

    if (newObjKey.length != 0) {
      for (let i = 0; i < newObjKey.length; i++) {
        let obj = {};
        obj[newObjKey[i]] = newObjValue[i];
        nameArray.push(obj);
      }
      return nameArray;
    }
    return "";
  },
  // 企业认证状态
  enterStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case 1:
        statusName = "您的申请处于待审核状态";
        break;
      case 4:
        statusName = "您的申请处于待审核状态";
        break;
      case 2:
        statusName = "您已认证通过";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 契约所企业认证状态
  contractOfficeStatusFormate(id) {
    let statusName = null;
    switch (id) {
      case "-1":
        statusName = "无认证记录";
        break;
      case "1":
        statusName = "未提交认证申请";
        break;
      case "2":
        statusName = "您已认证通过";
        break;
      case "3":
        statusName = "认证不通过";
        break;
      case "4":
        statusName = "认证中";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  // 金额转大写
  digitUppercase(n) {
    var fraction = ["角", "分"];
    var digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    var unit = [
      ["元", "万", "亿"],
      ["", "拾", "佰", "仟"]
    ];
    var IsNum = Number(n);
    if (!isNaN(IsNum)) {
      var head = n < 0 ? "欠" : "";
      n = Math.abs(n);
      var s = "";
      for (var i = 0; i < fraction.length; i++) {
        s += (
          digit[Math.floor(((n * 100) / 10) * Math.pow(10, i)) % 10] +
          fraction[i]
        ).replace(/零./, "");
      }
      s = s || "整";
      n = Math.floor(n);
      for (var i = 0; i < unit[0].length && n > 0; i++) {
        var p = "";
        for (var j = 0; j < unit[1].length && n > 0; j++) {
          p = digit[n % 10] + unit[1][j] + p;
          n = Math.floor(n / 10);
        }
        s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
      }
      return (
        head +
        s
          .replace(/(零.)*零元/, "元")
          .replace(/(零.)+/g, "零")
          .replace(/^整$/, "零元整")
      );
    } else {
      return "";
    }
  },
  // 企业认证状态
  BankSignStatus(id) {
    let statusName = null;
    switch (id) {
      case 0:
        statusName = "请前往签约页面进行签约!";
        break;
      case 1:
        statusName = "";
        break;
      case 2:
        statusName = "解约成功";
        break;
      case 3:
        statusName = "您的签约正在申请中,请耐心等待结果!";
        break;
      case 4:
        statusName = "您的解约正在申请中,请耐心等待结果!";
        break;
      case 5:
        statusName = "绑卡出错";
        break;
      case 6:
        statusName =
          "打款验证出错,请前往入金记录页面获取第一次入金流水号,并在此页面点击打款验证输入认证金额及流水号";
        break;
      case 7:
        statusName = "解绑在途";
        break;
      case 8:
        statusName = "解绑出错";
        break;
      case 11:
        statusName = "绑卡在途";
        break;
      case 12:
        statusName = "打款验证在途";
        break;
      case 13:
        statusName =
          "机构绑卡成功,请去网银端入金,并前往银行签约界面进行打款验证";
        break;
      default:
        statusName = "--";
        break;
    }
    return statusName;
  },
  toThousandss(num) {
    if (typeof num === "number" && !isNaN(num)) {
      if (num.toString().indexOf("-") != -1) {
        num = Math.abs(num);
        var result = "";
        var index = num.toString().indexOf(".");
        if (index != -1) {
          const y = num.toString().indexOf(".") + 1;
          const count = num.toString().length - y;
          if (count == 2) {
            num = Number(num.toString() || 0);
          } else if (count > 2) {
            num = (num.toString() || 0).toString().substring(0, 2 + index + 1);
            num = Number(num);
          } else if (count < 2) {
            num = Number(num.toString() || 0).toFixed(2);
          }
        } else {
          num = Number(num.toString() || 0).toFixed(2);
        }
        // num = Number(num.toString() || 0).toFixed(2)
        var numAry = num.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        result = result + "." + lastNum;
        console.log(result);
        return "-" + result;
      } else {
        // eslint-disable-next-line no-redeclare
        var result = "";
        var index = num.toString().indexOf(".");
        if (index != -1) {
          const y = num.toString().indexOf(".") + 1;
          const count = num.toString().length - y;
          if (count == 2) {
            num = Number(num.toString() || 0);
          } else if (count > 2) {
            num = (num.toString() || 0).toString().substring(0, 2 + index + 1);
            num = Number(num);
          } else if (count < 2) {
            num = Number(num.toString() || 0).toFixed(2);
          }
        } else {
          num = Number(num.toString() || 0).toFixed(2);
        }
        var numAry = num.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        result = result + "." + lastNum;
        console.log(result);
        return result;
      }
    } else {
      console.log(num);
      return num;
    }
  },
  toThousands(num) {
    if (typeof num === "number" && !isNaN(num)) {
      var result = "";
      num = Number(num.toString() || 0).toFixed(2);
      var numAry = num.toString().split(".");
      var preNum = numAry[0];
      var lastNum = numAry[1];
      while (preNum.length > 3) {
        result = "," + preNum.slice(-3) + result;
        preNum = preNum.slice(0, preNum.length - 3);
      }
      if (preNum) {
        result = preNum + result;
      }
      result = result + "." + lastNum;
      return result;
    } else {
      return num;
    }
  },
  toThousands3(num) {
    if (typeof num === "number" && !isNaN(num)) {
      if (num.toString().indexOf("-") != -1) {
        num = Math.abs(num);
        var result = "";
        var index = num.toString().indexOf(".");
        if (index != -1) {
          const y = num.toString().indexOf(".") + 1;
          const count = num.toString().length - y;
          if (count == 2) {
            num = Number(num.toString() || 0);
          } else if (count > 2) {
            num = (num.toString() || 0).toString().substring(0, 2 + index + 1);
            num = Number(num);
          } else if (count < 2) {
            num = Number(num.toString() || 0).toFixed(2);
          }
        } else {
          num = Number(num.toString() || 0).toFixed(2);
        }
        // num = Number(num.toString() || 0).toFixed(2)
        var numAry = num.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        result = result + "." + lastNum;
        console.log(result);
        return "-" + result;
      } else {
        var result = "";
        var index = num.toString().indexOf(".");
        if (index != -1) {
          const y = num.toString().indexOf(".") + 1;
          const count = num.toString().length - y;
          if (count == 2) {
            num = Number(num.toString() || 0);
          } else if (count > 2) {
            num = (num.toString() || 0).toString().substring(0, 2 + index + 1);
            num = Number(num);
          } else if (count < 2) {
            num = Number(num.toString() || 0).toFixed(2);
          }
        } else {
          num = Number(num.toString() || 0).toFixed(2);
        }
        var numAry = num.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        result = result + "." + lastNum;
        console.log(result);
        return result;
      }
    } else {
      console.log(num);
      return num;
    }
  },
  // 负数千分位处理
  formatNum(strNum) {
    var outputmoney = function(number) {
      number = number.replace(/\,/g, ""); // 把所有的逗号，清除
      if (isNaN(number) || number === "") return "";
      number = Math.round(number * 100) / 100;
      if (number < 0) {
        return (
          "-" +
          outputdollars(Math.floor(Math.abs(number) - 0) + "") +
          outputcents(Math.abs(number) - 0)
        );
      } else {
        return (
          outputdollars(Math.floor(number - 0) + "") + outputcents(number - 0)
        );
      }
    };
    // 格式化金额
    var outputdollars = function(number) {
      if (number.length <= 3) {
        return number === "" ? "0" : number;
      } else {
        var mod = number.length % 3;
        var output = mod === 0 ? "" : number.substring(0, mod);
        for (let i = 0; i < Math.floor(number.length / 3); i++) {
          if (mod === 0 && i === 0) {
            output += number.substring(mod + 3 * i, mod + 3 * i + 3);
          } else {
            output += "," + number.substring(mod + 3 * i, mod + 3 * i + 3);
          }
        }
        return output;
      }
    };
    var outputcents = function(amount) {
      amount = Math.round((amount - Math.floor(amount)) * 100);
      return amount < 10 ? ".0" + amount : "." + amount;
    };
    return outputmoney(strNum + "");
  },
  priceFormat(e) {
    return Number(e).toFixed(2);
  },
  priceFormat1(e) {
    if (Number(e) >= 0) {
      if (typeof e === "number" && !isNaN(e)) {
        var result = "";
        e = Number(e.toString() || 0).toFixed(2);
        var numAry = e.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        result = result + "." + lastNum;
        return result;
      } else {
        return e;
      }
      // return Number(e).toFixed(2)
    } else {
      return "--";
    }
  },
  // 千分位(不保留小数)
  toThousands1(num) {
    if (typeof num === "number" && !isNaN(num)) {
      if (num.toString().indexOf("-") != -1) {
        num = Math.abs(num);
        var result = "";
        num = Number(num.toString() || 0);
        var numAry = num.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        if (lastNum) {
          result = result + "." + lastNum;
        }
        return "-" + result;
      } else {
        var result = "";
        num = Number(num.toString() || 0);
        var numAry = num.toString().split(".");
        var preNum = numAry[0];
        var lastNum = numAry[1];
        while (preNum.length > 3) {
          result = "," + preNum.slice(-3) + result;
          preNum = preNum.slice(0, preNum.length - 3);
        }
        if (preNum) {
          result = preNum + result;
        }
        if (lastNum) {
          result = result + "." + lastNum;
        }
        return result;
      }
    } else {
      return num;
    }
  }
};

export default oVueFilter;
