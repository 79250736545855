// initial state
// shape: [{ id, quantity }]
const state = {
  // 个人/企业开户标记
  OAORUOAA: 0, // 开户类型： 1-个人开户 2-企业开户 0-未开户
  // 个人开户
  UOAStatus: {
    agreementImg: '',
    agreementStatus: 0,
    auditStatus: 0,
    bankCard: '',
    bankCellphone: '',
    bankId: 0,
    bankStatus: 0,
    certificateAddr: '',
    certificateBackImg: '',
    certificateBirth: '',
    certificateFrontImg: '',
    certificateGender: '',
    certificateIssuingAuthority: '',
    certificateName: '',
    certificateNumber: '',
    certificatePortrait: '',
    certificateStatus: 0,
    certificateValidEnd: '',
    certificateValidStart: '',
    createTime: 0,
    employedImg: '',
    employedStatus: 0,
    informationAddress: '',
    informationCellphone: '',
    informationName: '',
    informationStatus: 0,
    note: '',
    signatureImg: '',
    signatureStatus: 0,
    status: '',
    updateTime: 0,
    userId: 0,
    videoImg: '',
    videoStatus: 0,
    videoSucceedImg: '',
    videoUrl: ''
  },
  UOAStatusIntSign: 0, // 标记状态,发生变化刷新  queryAuthnStatus  接口
  // 企业开户
  OAStatus: {
    agreementImg: '',
    agreementStatus: 0,
    auditStatus: 0,
    bankCard: '',
    bankCellphone: '',
    bankId: 0,
    bankStatus: 0,
    certificateAddr: '',
    certificateBackImg: '',
    certificateBirth: '',
    certificateFrontImg: '',
    certificateGender: '',
    certificateIssuingAuthority: '',
    certificateName: '',
    certificateNumber: '',
    certificatePortrait: '',
    certificateStatus: 0,
    certificateValidEnd: '',
    certificateValidStart: '',
    createTime: 0,
    employedImg: '',
    employedStatus: 0,
    informationAddress: '',
    informationCellphone: '',
    informationName: '',
    informationStatus: 0,
    note: '',
    signatureImg: '',
    signatureStatus: 0,
    status: '',
    updateTime: 0,
    userId: 0,
    videoImg: '',
    videoStatus: 0,
    videoSucceedImg: '',
    videoUrl: '',
    licenseAddress: ''
  },
  OAStatusIntSign: 0 // 标记状态,发生变化刷新  queryAuthnStatus  接口
}

// getters
const getters = {
  UOAStatusGetter: state => state.UOAStatus,
  UOAStatusIntSign: state => state.UOAStatusIntSign,
  OAStatusGetter: state => state.OAStatus,
  OAStatusIntSign: state => state.OAStatusIntSign
}

// actions
const actions = {}

// mutations
const mutations = {
  setUOAStatus(state, data) {
    state.UOAStatus = data
  },
  setUOAStatusWithKeyAndValue(state, [key, value]) {
    state.UOAStatus[key] = value
  },
  reloadUOA(state) {
    state.UOAStatusIntSign++
  },
  setOAStatus(state, data) {
    state.OAStatus = data
  },
  setOAStatusWithKeyAndValue(state, [key, value]) {
    state.OAStatus[key] = value
  },
  reloadOA(state) {
    state.OAStatusIntSign++
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
