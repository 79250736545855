// initial state
// shape: [{ id, quantity }]
const state = {  
  myTradeTotal:'0',
  myOrderTotal:'0',
  myHisOrderTotal:'0',
  myTradeRecord: [],
  myTradeRecord1: [],
  
  myOrderRecord: [],
  myHisOrderRecord: [],
  selAssetDetail:{},
  myAsset:[],
}

// getters
const getters = {
  selAssetDetailGetter: state => state.selAssetDetail,
  myTradeTotalGetter:state=>state.myTradeTotal,
  myOrderTotalGetter:state=>state.myOrderTotal,
  myHisOrderTotalGetter:state=>state.myHisOrderTotal,
  myTradeRecordGetter: (state) => {
  return state.myTradeRecord;   
  },
  myTradeRecordGetter1: (state) => {
    return state.myTradeRecord1;   
    },
  myOrderRecordGetter: (state) => {
     return state.myOrderRecord;    
  },
  myHisOrderRecordGetter: (state) => {
    return state.myHisOrderRecord;    
 },
 myAssetGetter: state => state.myAsset,
}

// actions
const actions = {

}
 
// mutations
const mutations = {
  setMyTradeTotal(state,data){
    state.myTradeTotal=data;
  },
  setMyOrderTotal(state,data){
    state.myOrderTotal=data;
  },
  setMyHisOrderTotal(state,data){
    state.myHisOrderTotal=data;
  },
  setMyTradeRecord(state, data) {
    state.myTradeRecord = data;
  },
  setMyTradeRecord1(state, data) {
    state.myTradeRecord1 = data;
  },
  setMyOrderRecord(state, data) {
    state.myOrderRecord = data;
  },
  setMyHisOrderRecord(state, data) {
    state.myHisOrderRecord = data;
  },
  setMyAsset(state,data){
    state.myAsset=data;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
