import crypto from 'crypto';
// 引入基本模板
require('echarts/lib/echarts')
// 引入组件
require('echarts/lib/chart/line')
require('echarts/lib/chart/candlestick')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
export default {
    calculateMA(dayCount, data) {
        let result = [];
        for (let i = 0, len = data.length; i < len; i++) {
            if (i < dayCount) {
                result.push('-');
                continue;
            }
            let sum = 0;
            for (let j = 0; j < dayCount; j++) {
                sum += Number(data[i - j][1]);
            }
            result.push((sum / dayCount).toFixed(2));
        }
        return result;
      },
    splitTimeLineData(rawData) {        
        let timelinevalues = [];      
        let timelineaveragevalues = [];     
        for(let item of rawData){
            timelinevalues.push(item.price.toFixed(2));            
            timelineaveragevalues.push(item.averagePrice.toFixed(2));
        }   
        
    },
    timeLineOption:{
        title: {
            show: false
        },
        tooltip: {
            trigger: 'axis',
            alwaysShowContent:false,
            position:['4.7%','7%'],
            axisPointer: {
                type: 'cross',
                label:{
                    precision:2,
                    margin:0,
                    textStyle:{
                        fontsize:5
                    },
                    padding:[5,0,5,0]
                }
            }
        },
        grid: {
            top: 10,
            right: 50,
            left: 40,
            bottom: 30
        },
        xAxis: {
            type: 'category',
            scale: true,
            boundaryGap: false,
            data: '',
            axisLine: {               
                lineStyle:{
                    color:'#8392A5'
                }
            },
        },
        yAxis: {
            scale: true,
            type:'value',
            minInterval :0.1,
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#eee'
                }
            },
            axisLine: {               
                lineStyle:{
                    color:'#8392A5'
                }
            },
        },
        dataZoom: [
            {
                type: 'inside',
                start: 60,
                end: 100
            }
        ],
        color: ['#5db1d3',  '#91c7ae', 'grey', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
        series: [{
            name: '分时数据',
            type: 'line',
            smooth: 'true',
        }, {
            name: '日均线',
            type: 'line',
            smooth: 'true'
        },{
            name:'MA5',
            type:'line',
            smooth: 'true',
        },{
            name:'MA10',
            type:'line',
            smooth: 'true',
        },{
            name:'MA15',
            type:'line',
            smooth: 'true',
        }]
    },
    kLineOption:{
        tooltip: {
            show:true,
            trigger: 'axis',
            alwaysShowContent:false,
            position:['4.7%','2.2%'],
            axisPointer: {
                type: 'cross',
                label:{
                    precision:2,
                    margin:0,
                    textStyle:{
                        fontsize:5
                    },
                    padding:[5,0,5,0]
                }
            },
        },
        grid: {
            top: 10,
            right: '5%',
            left: 40,
            bottom: 30,
            background:'#ccc'
        },
        xAxis: {
            type: 'category',
            data:'',
            scale: true,
            boundaryGap: true,
            axisLine: {
                onZero: false,
                lineStyle:{
                    color:'#8392A5'
                }
            },
            splitLine: {show: false},
            splitNumber: 20,
            min: 'dataMin',
            max: 'dataMax',
            
        },
        yAxis: {
            type:'value',
            minInterval :0.1,
            scale: true,
            splitLine: {show: false},
            splitArea: {
                show: false
            },
            axisLine: {               
                lineStyle:{
                    color:'#8392A5'
                }
            },
        },
        dataZoom: [
            {
                type: 'inside',
                filterMode:'filter',
                start:  80,
                end: 100
            }
        ],
        legend:{
            data:['K线数据','MA5','MA10','MA15'],
            inactiveColor: '#777',
            textStyle: {
                color: '#fff'
            },
            selected: {
                // 选中'系列1'
                'K线数据': true,
                // 不选中'系列2'
                'MA5': false,
                'MA10':false,
                'MA15':false
            },
            top:-2.4
        },
        color: ['grey', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
        series: [
            {
                name: 'K线数据',
                type: 'candlestick',
                itemStyle: {
                    normal: {
                        borderColor: '#e32c30',
                        color0: '#2eb54d',
                        borderColor0: '#2eb54d'
                    }
                }
            },
            {
                name:'MA5',
                type:'line',
                smooth: 'true',
            },{
                name:'MA10',
                type:'line',
                smooth: 'true',
            },{
                name:'MA15',
                type:'line',
                smooth: 'true',
            }
        ]
    },
    
};