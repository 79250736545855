import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import transData from './modules/transData'
import hqData from './modules/hqData'
import jp from './modules/jp'
import search from './modules/search'
import uoa from './modules/uoa'
Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    common,
    transData,
    hqData,
    jp,
    search,
    uoa
  },
  strict: debug
})
