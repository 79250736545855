import Vue from 'vue'
import store from "./store";
import protocolFwd from "./assets/js/trans/protocolFwd";
import http from "./assets/js/trans/http";
let math = require("mathjs");
let Decimal = require("decimal.js");
import {Message} from 'element-ui'

// 离开iframe
Vue.prototype.leaveIframe = () => {
  store.commit("setIframeState", 1);
};

// 获取用户权限
Vue.prototype.loadRight = () => {
  protocolFwd.param_findAllRightNew.param.firmId = sessionStorage.getItem(
    "firmId"
  );
  protocolFwd.param_findAllRightNew.param.type = 3;
  return new Promise(function(resolve) {
    http.postFront(protocolFwd.param_findAllRightNew).then(response => {
      const { code, message, value } = response.data;
      if (code == 0) {
        store.commit("setRoleRight", value);
        resolve("loadRightSuccess");
      } else {
        this.$message(message);
      }
    });
  });
};

// 判断用户是否有权限
Vue.prototype.isHasRight = rightcode => {
  const rightList = store.state.common.roleRight;
  let rightCodeList = [];
  for (let i = 0; i < rightList.length; i++) {
    rightCodeList.push(rightList[i].meta.rightCode);
  }
  if (rightCodeList.indexOf(rightcode) !== -1) {
    return true;
  } else {
    return false;
  }
};

//判断字段设置样式
Vue.prototype.setStrClass = (el, val, class1, class2) => el.indexOf(val) != -1 ? class1 : class2;

//时间戳转换(具体)
Vue.prototype.DateFormate = timer => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hour = date.getHours();
  let min = date.getMinutes();
  let sec = date.getSeconds();
  [year, month, day, hour, min, sec] = [
    year,
    month,
    day,
    hour,
    min,
    sec
  ].map(item => (item < 10 ? "0" + item : item));
  // return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
  return `${hour}:${min}:${sec}`;
};

//全局回调函数
Vue.prototype.each = (arr, fn) => {
  for (let i = 0; i < arr.length; i++) {
    fn(arr[i]);
  }
};

//判断并修改各种数据的值
Vue.prototype.modifyData = (arrIndex, arrProperty, judgeCondition, str, str2) => {
  if (arrIndex[arrProperty] == judgeCondition) {
    arrIndex[arrProperty] = str;
  } else {
    arrIndex[arrProperty] = str2 || arrIndex[arrProperty];
  }
};

//判断并转换时间戳
Vue.prototype.modifyTime = (arrIndex, arrProperty, newProperty) => {
  if (newProperty) {
    arrIndex[newProperty] = Vue.prototype.DateFormate(
      Number(arrIndex[arrProperty])
    );
    return;
  }
  arrIndex[arrProperty] = Vue.prototype.DateFormate(
    Number(arrIndex[arrProperty])
  );
};

//转换所有属性
Vue.prototype.attributeTrans = obj => {
  if (obj != null && JSON.stringify(obj) != "{}") {
    let newObj = JSON.parse(obj);
    let nameArray = [];
    let newObjKey = Object.keys(newObj);
    let newObjValue = Object.values(newObj);
    if (newObjKey.length != 0) {
      for (let i = 0; i < newObjKey.length; i++) {
        let obj = {};
        obj.label = newObjKey[i] + ":" + newObjValue[i];
        nameArray.push(obj);
      }
      return nameArray;
    }
  }
  return "";
};

//判断并添加数据
Vue.prototype.addObjData = (arrIndex, arrProperty, judgeCondition, str, str2, newProperty) => {
  if (arrIndex[arrProperty] == judgeCondition) {
    arrIndex[newProperty] = str;
  } else {
    arrIndex[newProperty] = str2 || arrIndex[arrProperty];
  }
};

//判断数值是否是NaN
Vue.prototype.isNaNfn = (num, obj, pro) => {
  if (isNaN(num) == true) {
    obj[pro] = "0.00";
  } else {
    return (obj[pro] = num);
  }
};
//小数点四舍五入
Vue.prototype.toFixedFn = (arrIndex, arrProperty, num, newProperty) => {
  if (newProperty) {
    arrIndex[newProperty] = Number(arrIndex[arrProperty]).toFixed(num);
    return;
  }
  arrIndex[arrProperty] = Number(arrIndex[arrProperty]).toFixed(num);
};
//拷贝对象
Vue.prototype.copy = obj => {
  var newobj = {};
  for (var attr in obj) {
    newobj[attr] = obj[attr];
  }
  return newobj;
};
//精确小数点3位,不四舍五入
Vue.prototype.exactPointThree = (num, arrIndex, arrProperty) => {
  let newVal;
  num = Number(num);
  if (num < 0) {
    num = num.toString().substring(1);
    newVal = Number(num.toString().match(/^\d+(?:\.\d{0,3})?/)) * -1;
  } else {
    newVal = Number(num.toString().match(/^\d+(?:\.\d{0,3})?/));
  }
  //整数自动补0
  if (newVal.toString().indexOf(".") == -1) {
    newVal += ".000";
  }
  let len = newVal.toString().indexOf(".") + 1;
  //2位数自动补0
  if (
    newVal
      .toString()
      .substring(len)
      .split("").length == 2
  ) {
    newVal += "0";
  }
  //1位数自动补0
  if (
    newVal
      .toString()
      .substring(len)
      .split("").length == 1
  ) {
    newVal += "00";
  }
  if (arrProperty) {
    arrIndex[arrProperty] = newVal;
  }
  return newVal;
  // return Number(num.toString().match(/^\d+(?:\.\d{0,3})?/))
};
//精确小数点2位,不四舍五入
Vue.prototype.exactPointTwo = (num, arrIndex, arrProperty) => {
  let newVal;
  num = Number(num);
  if (num < 0) {
    num = num.toString().substring(1);
    newVal = Number(num.toString().match(/^\d+(?:\.\d{0,2})?/)) * -1;
  } else {
    newVal = Number(num.toString().match(/^\d+(?:\.\d{0,2})?/));
  }
  //整数自动补0
  if (newVal.toString().indexOf(".") == -1) {
    newVal += ".00";
  }
  let len = newVal.toString().indexOf(".") + 1;
  //1位数自动补0
  if (
    newVal
      .toString()
      .substring(len)
      .split("").length == 1
  ) {
    newVal += "0";
  }
  if (arrProperty) {
    arrIndex[arrProperty] = newVal;
  }
  return newVal;
  // return Number(num.toString().match(/^\d+(?:\.\d{0,3})?/))
};
Vue.prototype.exactPointNon = (num, arrIndex, arrProperty) => {
  if (arrProperty) {
    arrIndex[arrProperty] = num;
  }
  return num;
};
//读取cookie
Vue.prototype.getCookie = name => {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
  else return null;
};
//数组对象深拷贝
Vue.prototype.deepClone = value => {
  let type = typeof value;
  let newone = undefined;
  if (type === "string" || type === "number" || type === "boolean") {
    newone = value;
  } else if (type === "object") {
    if (value === null) {
      newone = null;
    } else if (
      Object.prototype.toString.call(value).slice(8, -1) === "Object"
    ) {
      newone = {};
      for (let key of Object.keys(value)) {
        newone[key] = Vue.prototype.deepClone(value[key]);
      }
    } else if (Object.prototype.toString.call(value).slice(8, -1) === "Array") {
      newone = [];
      for (let i = 0; i < value.length; i++) {
        newone.push(Vue.prototype.deepClone(value[i]));
      }
    }
  }
  return newone;
};
//小数点后舍0,不四舍五入
Vue.prototype.automaticallyToZero = (num, arrIndex, arrProperty) => {
  let nIndex, aNumVal, bool;
  aNumVal = num.slice(num.indexOf(".") + 1).split("");
  //是否有小数点
  if (num.indexOf(".") == -1) {
    num;
  } else {
    //小数点后是否全为0;
    for (let i = 0; i < aNumVal.length; i++) {
      if (aNumVal[i] != 0) {
        bool = true;
        break;
      } else {
        bool = false;
      }
    }
    //全为0
    if (bool == false) {
      num = num.slice(0, num.indexOf("."));
    } else {
      //截取
      aNumVal = aNumVal.reverse();
      if (num.indexOf(".") != -1 && aNumVal.length > 0) {
        for (let i = 0; i < aNumVal.length; i++) {
          if (aNumVal[i] != "0") {
            nIndex = i; //最后一位不为0数字的下标
            break;
          } else {
            nIndex = 0;
          }
        }
        num = num.substring(0, num.slice().length - nIndex);
      }
    }
  }
  if (arrProperty) {
    arrIndex[arrProperty] = num;
  }
  return num;
};
//数组去重
Vue.prototype.arrayNonRepeatfy = arr => {
  let hashMap = new Map();
  let result = new Array();
  for (let i = 0; i < arr.length; i++) {
    if (hashMap.has(arr[i])) {
      hashMap.set(arr[i], true);
    } else {
      hashMap.set(arr[i], false);
      result.push(arr[i]);
    }
  }
  return result;
};

Vue.prototype.printFn = value => {
  const precision = 14;
  return Number(math.format(value, precision));
};

Vue.prototype.$EL_MESSAGE = msg => {
  let height = document.documentElement.clientHeight / 2 - 50;
  if (typeof msg === "string") {
    let msgData = {
      showClose: true,
      message: msg,
      offset: height
    };
    Message(msgData);
  } else {
    msg.offset = height;
    Message(msg);
  }
};
