import Vue from "vue";
import Router from "vue-router";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

// 路由懒加载
// 一期
// 主体
const Home = r =>
  require.ensure([], () => r(require("@/components/home/Home")));
const HomePage = r =>
  require.ensure([], () => r(require("@/components/HomePage/HomePage")));
const HomePage1 = r =>
  require.ensure([], () => r(require("@/components/HomePage/HomePage1")));
// 现货资源
const ResourceDocuments = r =>
  require.ensure([], () =>
    r(require("@/components/ResourceDocuments/ResourceDocuments"))
  );
// 现货资源 -> 商品详情
const ResourceDocumentsDetails = r =>
  require.ensure([], () =>
    r(require("@/components/ResourceDocuments/productionDetails"))
  );
// 现货求购
const purchaseDocument = r =>
  require.ensure([], () =>
    r(require("@/components/purchaseDocument/purchaseDocument"))
  );
// 现货求购 -> 商品详情
const purchaseDocumentDetails = r =>
  require.ensure([], () =>
    r(require("@/components/purchaseDocument/productionDetails"))
  );
// 供求需求
const supplyDemandInfo = r =>
  require.ensure([], () =>
    r(require("@/components/supplyDemandInfo/supplyDemandInfo"))
  );
// 供求需求 -> 商品详情
const supplyDemandDetails = r =>
  require.ensure([], () =>
    r(require("@/components/supplyDemandInfo/supplyDemandDetails"))
  );
// 产品预售
const productionSell = r =>
  require.ensure([], () =>
    r(require("@/components/productionSell/productionSell"))
  );
// 产品预售 -> 商品详情
const productionDetails = r =>
  require.ensure([], () =>
    r(require("@/components/productionSell/productionDetails"))
  );
// 产品预售 -> 我的购物车
const productionCart = r =>
  require.ensure([], () =>
    r(require("@/components/productionSell/productionCart"))
  );
// 买家中心
const matching = r =>
  require.ensure([], () => r(require("@/components/matching/matching")));
// 买家中心 -> 我的求购
const matchingQiugou = r =>
  require.ensure([], () => r(require("@/components/matching/tab/qiugou")));
// 买家中心 -> 我的订单
const matchingOrder = r =>
  require.ensure([], () => r(require("@/components/matching/tab/order")));
// 买家中心 -> 我的成交
const matchingDealOrder = r =>
  require.ensure([], () => r(require("@/components/matching/tab/dealOrder")));
// 买家中心 -> 我的持仓
const matchingHoldProduct = r =>
  require.ensure([], () => r(require("@/components/matching/tab/holdProduct")));
// 买家中心 -> 产品预售协议列表
const matchingBuyAgreeList = r =>
  require.ensure([], () =>
    r(require("@/components/matching/tab/buyAgree/buyAgreeList"))
  );
// 买家中心 -> 我要提货
const matchingPickGoods = r =>
  require.ensure([], () =>
    r(require("@/components/matching/tab/pickGoods/pickGoods"))
  );
// 买家中心 -> 提货查询
const matchingPickGoodsList = r =>
  require.ensure([], () =>
    r(require("@/components/matching/tab/pickGoods/pickGoodsList"))
  );

const helpCenter = r =>
  require.ensure([], () => r(require("@/components/helpCenter/helpCenter")));
// const helpDetail = r =>
//   require.ensure([], () => r(require("@/components/helpCenter/subpage/helpDetails")));
// 资讯
const news = r =>
  require.ensure([], () => r(require("@/components/news/news")));
// 资讯 -> 详情页
const newsDetails = r =>
  require.ensure([], () => r(require("@/components/news/subpage/newsDetails")));
// 公告
const notice = r =>
  require.ensure([], () => r(require("@/components/notice/notice")));
// 公告 -> 详情页
const noticeDetails = r =>
  require.ensure([], () => r(require("@/components/notice/noticeDetails")));
// 卖家中心 -> 发布资源
const sellerCenterBaojia = r =>
  require.ensure([], () => r(require("@/components/sellerCenter/tab/baojia")));
// 卖家中心 -> 我的报价
const sellerCenterOrder = r =>
  require.ensure([], () => r(require("@/components/sellerCenter/tab/order")));
// 卖家中心 -> 我的订单
const sellerCenterResource = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/resource"))
  );
// 卖家中心 -> 我的成交
const sellerCenterDealOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/dealOrder"))
  );
// 卖家中心 -> 新增商品
const sellerCenterAddProduct = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/addProduct"))
  );
// 卖家中心 -> 我的商品
const sellerCenterMyProduction = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/myProduction"))
  );
// 卖家中心 -> 发布产能资源单
const sellerCenterAddResourceOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/addResourceOrder"))
  );
// 卖家中心 -> 商品审核
const sellerCenterProductionAudit = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/productionAudit"))
  );
// 卖家中心 -> 店铺管理
const sellerCenterShopManage = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/shopManage"))
  );
// 卖家中心 -> 产品预售协议
const sellerCenterSellAgreeList = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/sellAgreeList"))
  );
// 卖家中心 -> 产品预售订单
const sellerCenterSellOrderList = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/tab/sellOrderList"))
  );

// 资产中心共用页面
const fundsManage = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/fundsManage"))
  );

// 资产中心
const capitalCenter = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/capitalCenter"))
  );
// 资产中心 -> 充值
const capitalCenterState = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/capitalCenterState"))
  );
// 资产中心 -> 充值
const capitalCenterDeposit = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/deposit"))
  );
// 资产中心 -> 提现
const capitalCenterWithdraw = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/withdraw"))
  );
// 资产中心 -> 资金信息
const capitalCenterInfo = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/MyFinancialMsg"))
  );
// 资产中心 -> 银行签约
const capitalCenterSign = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/signBank"))
  );
// 资产中心 -> 申报信息
const capitalCenterInfoReporing = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/infoReporing"))
  );
// 资产中心 -> 财务记录
const financialRecord = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/financialRecord"))
  );
// 资产中心 -> 划转
const transfer = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/transfer"))
  );
// 资产中心 -> 银行签约列表
const capitalCenterSignList = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/signBankList"))
  );
// 资产中心 -> 银行解约
const capitalCenterUnSign = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/unsignBank"))
  );
// 资产中心 -> 设置资金密码
const capitalCenterSetPwd = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/setMarginPwd"))
  );
// 资产中心 -> 修改资金密码
const capitalCenterModifyPwd = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/modifyMarginPwd"))
  );
// 资产中心 -> 忘记资金密码
const capitalCenterForgetPwd = r =>
  require.ensure([], () =>
    r(require("@/components/capitalCenter/subpage/forgetMarginPwd"))
  );
// 资产中心 -> 资金划转
const fundsTransfer = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/fundsTransfer"))
  );
// 店铺
const shopCenter = r =>
  require.ensure([], () => r(require("@/components/shopCenter/shopCenter")));
// 我的找稀土
const personalCenter = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/personalCenter"))
  );
// 我的找稀土 -> 个人中心 -> 账户信息
const personalAccountInfo = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/accountInfo"))
  );
// 我的找稀土 -> 个人中心 -> 账户安全
const personalAccountSafe = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/modifyPassword"))
  );
// 我的找稀土 -> 设置密码
// const personalSetPassword = r =>
//   require.ensure([], () =>
//     r(require("@/components/personalCenter/tab/setPassword"))
//   );
// 我的找稀土 -> 企业信息 -> 企业认证
const personalEnterprise = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/enterprise"))
  );
// 我的找稀土 -> 企业信息 -> 公司认证
const personalEnterpriseAuth = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/enterpriseAuth"))
  );
// 我的找稀土 -> 企业信息 -> 电子签章管理
const personalElectronicSignature = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/electronicSignature"))
  );
// 我的找稀土 -> 员工管理 -> 员工账号管理
const personalStaffAccountMgt = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/staffAccountMgt"))
  );
// 我的找稀土 -> 员工管理 -> 角色管理
const personalStaffRoleMgt = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/staffRoleMgt"))
  );
// 用户中心 -> 经营产品信息
const personalProductInfo = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/productInfo"))
  );
// 商城 -> 主页
const mall = r =>
  require.ensure([], () => r(require("@/components/mall/mall")));
// 商城 -> 商品详情页
const mallProductionDetails = r =>
  require.ensure([], () =>
    r(require("@/components/mall/mallProductionDetails"))
  );
// 商城 -> 提交订单页
const mallConfirmOrder = r =>
  require.ensure([], () => r(require("@/components/mall/confirmOrder")));
// 商城 -> 个人中心
const mallUserCenter = r =>
  require.ensure([], () =>
    r(require("@/components/mall/userCenter/mallUserCenter"))
  );
// 商城 -> 个人中心 -> 订单查询
const mallUserOrderList = r =>
  require.ensure([], () =>
    r(require("@/components/mall/userCenter/mallOrderList"))
  );
// 商城 -> 个人中心 -> 地址管理
const mallUserAddress = r =>
  require.ensure([], () =>
    r(require("@/components/mall/userCenter/mallAddress"))
  );
// 登录
const Login = r =>
  require.ensure([], () => r(require("@/components/reglogin/login/Login")));
// 忘记密码
const Forget = r =>
  require.ensure([], () =>
    r(require("@/components/reglogin/forgetPassword/forget"))
  );
// 注册
const Register = r =>
  require.ensure([], () =>
    r(require("@/components/reglogin/register/Register"))
  );
// 搜索
const search = r =>
  require.ensure([], () => r(require("@/components/search/search")));

// 三期
// 卖家中心
const sellerCenter = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/sellerCenter"))
  );
// 卖家中心 -> 资源管理 -> 预售持仓申请
const sellerResourceHoldSaleApply = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/holdSaleApply/holdSaleApply")
    )
  );
// 卖家中心 -> 资源管理 -> 现货入库申请
const sellerResourceWarehousingApply = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/warehousingApply/warehousingApply")
    )
  );
// 卖家中心 -> 资源管理 -> 现货资源
const sellerResourceSpotResource = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/spotResource/spotResource")
    )
  );
// 卖家中心 -> 资源管理 -> 竞价资源
const sellerResourceBiddingResource = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/biddingResource/biddingResource")
    )
  );
// 卖家中心 -> 资源管理 -> 预售资源
const sellerResourcePreSellResource = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/preSellResource/preSellResource")
    )
  );
// 卖家中心 -> 资源管理 -> 销售信息
const sellerResourceSellInfoResource = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/salesInformation/sellInfoResource")
    )
  );
// 卖家中心 -> 资源管理 -> 基差转让挂单
const basisTransferRegistration = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/basisTransferRegistration/basisTransferRegistration")
    )
  );
// 卖家中心 -> 资源管理 -> 基差挂单
const sellerResourceBasisList = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/resourceMgt/basisList/basisList"))
  );
// 卖家中心 -> 资源管理 -> 我的质检报告
const sellerResourceQualityInspectionReport = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/qualityInspectionReport/qualityInspectionReport")
    )
  );

// 卖家中心 -> 资源管理 -> 现货资源订单
const sellerResourceSpotResourceOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/spotResourceOrder/spotResourceOrder")
    )
  );
// 卖家中心 -> 资源管理 -> 竞价资源订单
const sellerResourceBiddingResourceOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/biddingResourceOrder/biddingResourceOrder")
    )
  );
// 卖家中心 -> 资源管理 -> 预售资源订单
const sellerResourcePreSellResourceOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/resourceMgt/preSellResourceOrder/preSellResourceOrder")
    )
  );
// 卖家中心 -> 销售管理 -> 基差订单
const sellerSalesBasisOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/basisOrder/basisOrder"))
  );
// 卖家中心 -> 销售管理 -> 可回购订单
const sellerSalesCanRepOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/canRepOrder/canRepOrder"))
  );
// 卖家中心 -> 销售管理 -> 基差回购订单
const sellerSalesBasisRepoOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/salesMgt/basisRepoOrder/basisRepoOrder")
    )
  );
// 卖家中心 -> 销售管理 -> 现货订单
const sellerSalesSpotOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/spotOrder/spotOrder"))
  );
// 卖家中心 -> 销售管理 -> 竞价订单
const sellerSalesBiddingOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/biddingOrder/biddingOrder"))
  );
// 卖家中心 -> 销售管理 -> 已报名竞价
const sellerSalesRegisteredBidding = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/salesMgt/registeredBidding/registeredBidding")
    )
  );
// 卖家中心 -> 销售管理 -> 中标竞价
const sellerSalesWinningBidding = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/salesMgt/winningBidding/winningBidding")
    )
  );
// 卖家中心 -> 销售管理 -> 我的质检报告
const sellerSalesQualityInspectionReport = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/salesMgt/qualityInspectionReport/qualityInspectionReport")
    )
  );
// 卖家中心 -> 资源管理 -> 存单质检报告
const sellerSaleDepositReport = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/depositReport/depositReport"))
  );
// 卖家中心 -> 销售管理 -> 我的合同
const sellerSalesContract = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/contract/contract"))
  );
// 卖家中心 -> 销售管理 -> 我的存单
const sellerSalesDepositOrder = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/depositOrder/depositOrder"))
  );
// 卖家中心 -> 销售管理 -> 我的持仓
const sellerSalesMyPosition = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/myPosition/myPosition"))
  );
// 卖家中心 -> 销售管理 -> 我的仓单
const sellerSalesWarehouseOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/salesMgt/warehouseOrder/warehouseOrder")
    )
  );
// 卖家中心 -> 销售管理 -> 我的质押单
const sellerSalesMyPledge = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/myPledge/myPledge"))
  );
// 卖家中心 -> 融资管理 -> (仓单)我的融资
const sellerSalesMyFinancing = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/financingManagement/myFinancing"))
  );
// 卖家中心 -> 融资管理 -> (订单)我的融资
const sellerSalesMyOrderFinancing = r =>
  require.ensure([], () =>
    r(
      require("@/components/sellerCenter/orderFinancingManagment/orderMyFinancing")
    )
  );
// 卖家中心 -> 物流管理 -> 自提单查询
const sellerPickSelfBill = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/logisticsMgt/selfBill/selfBill"))
  );
// 卖家中心 -> 物流管理 -> 物流提单查询
const sellerPickLogisticsBill = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/selfBill/selfBill"))
  );
// 买家中心
const buyerCenter = r =>
  require.ensure([], () => r(require("@/components/buyerCenter/buyerCenter")));
// 买家中心 -> 求购管理 -> 现货求购
const buyerCenterBuySpotBuy = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/wantToBuyMgt/spotBuy/spotBuy"))
  );
// 买家中心 -> 求购管理 -> 竞价求购
const buyerCenterBuyBiddingBuy = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/wantToBuyMgt/biddingBuy/biddingBuy"))
  );
// 买家中心 -> 求购管理 -> 采购信息
const buyerCenterBuyInfo = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/wantToBuyMgt/buyInfomation/buyInfo"))
  );
// 买家中心 -> 求购管理 -> 现货求购订单
const buyerCenterBuySpotBuyOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/buyerCenter/wantToBuyMgt/spotBuyOrder/spotBuyOrder")
    )
  );
// 买家中心 -> 求购管理 -> 竞价求购订单
const buyerCenterBuyBiddingBuyOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/buyerCenter/wantToBuyMgt/biddingBuyOrder/biddingBuyOrder")
    )
  );
// 买家中心 -> 采购管理 -> 现货订单
const buyerCenterPurchaseSpotOrder = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/purchaseMgt/spotOrder/spotOrder"))
  );
// 买家中心 -> 采购管理 -> 竞价订单
const buyerCenterPurchaseBiddingOrder = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/purchaseMgt/biddingOrder/biddingOrder"))
  );
// 买家中心 -> 采购管理 -> 已报名竞价
const buyerCenterPurchaseRegisteredBidding = r =>
  require.ensure([], () =>
    r(
      require("@/components/buyerCenter/purchaseMgt/registeredBidding/registeredBidding")
    )
  );
// 买家中心 -> 采购管理 -> 中标竞价
const buyerCenterPurchaseWinningBidding = r =>
  require.ensure([], () =>
    r(
      require("@/components/buyerCenter/purchaseMgt/winningBidding/winningBidding")
    )
  );
// 买家中心 -> 采购管理 -> 产品预售订单
const buyerCenterPurchasePreSellOrder = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/purchaseMgt/preSellOrder/preSellOrder"))
  );
// 买家中心 -> 采购管理 -> 我的合同
const buyerCenterPurchaseContract = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/purchaseMgt/contract/contract"))
  );
//买家中心 -> 采购管理 -> 基差订单
const buyerCenterPurchaseBasisOrder = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/purchaseMgt/basisOrder/basisOrder"))
  );
//买家中心 -> 采购管理 -> 基差回购订单
const buyerCenterPurchaseBasisBuybackOrder = r =>
  require.ensure([], () =>
    r(
      require("@/components/buyerCenter/purchaseMgt/basisBuybackOrder/basisBuybackOrder")
    )
  );

// 买家中心 -> 物流管理 -> 我的持仓
const buyerCenterMyPosition = r =>
  require.ensure([], () =>
    r(require("@/components/sellerCenter/salesMgt/myPosition/myPosition"))
  );
// 买家中心 -> 物流管理 -> 我要提货
const buyerCenterPickupGoods = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/logisticsMgt/pickupGoods/pickupGoods"))
  );
// 买家中心 -> 物流管理 -> 自提单查询
const buyerCenterPickSelfBill = r =>
  require.ensure([], () =>
    r(require("@/components/buyerCenter/logisticsMgt/selfBill/selfBill"))
  );
// 买家中心 -> 物流管理 -> 物流提单查询
const buyerCenterPickLogisticsBill = r =>
  require.ensure([], () =>
    r(
      require("@/components/buyerCenter/logisticsMgt/logisticsBill/logisticsBill")
    )
  );
// footerPage
const footerDetails = r =>
  require.ensure([], () => r(require("@/components/base/footer/footerPage")));

// footerDeails
const FooterDetail = r =>
  require.ensure([], () => r(require("@/components/base/footer/FooterDetail")));

// 金融结算
const financial = r =>
  require.ensure([], () =>
    r(require("@/components/financialSettlement/financialSettlement"))
  );
// 金融结算 -> 订单融资
const financialOrder = r =>
  require.ensure([], () =>
    r(require("@/components/financialSettlement/subPage/order"))
  );
// 金融结算 -> 应收账款融资
const financialAccountReceive = r =>
  require.ensure([], () =>
    r(require("@/components/financialSettlement/subPage/accountReceive"))
  );
// 金融结算 -> 仓单质押融资
const financialWarehouse = r =>
  require.ensure([], () =>
    r(require("@/components/financialSettlement/subPage/warehouse"))
  );
// 金融结算 -> 票据融资
const financialBill = r =>
  require.ensure([], () =>
    r(require("@/components/financialSettlement/subPage/bill"))
  );
//基差交易
const basisTrading = r =>
  require.ensure([], () =>
    r(require("@/components/BasisTrading/BasisTrading"))
  );
// 基差交易 -> 商品详情
const basisDetails = r =>
  require.ensure([], () =>
    r(require("@/components/BasisTrading/basisDetails"))
  );

// 首页 -> iframe
const iframeModule = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/iframeModule"))
  );
// 首页 -> 现货模块
const moduleXH = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/moduleXH"))
  );
// 首页 -> 竞价模块
const moduleBidding = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/moduleBidding"))
  );
// 首页 -> 预售交易模块
const moduleYSJY = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/moduleYSJY"))
  );
// 首页 -> iframe2
const oldIframeModule = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/oldIframeModule"))
  );
const iframeController = r =>
  require.ensure([], () =>
    r(
      require("@/components/HomePage/component/iframeController/iframeController")
    )
  );

// 首页 -> 资产管理
const conditionOfAssets = r =>
  require.ensure([], () =>
    // r(require("@/components/capitalCenter/MyAccount"))
    r(require("@/components/capitalCenter/capitalCenter1"))
  );

// 首页 -> 用户信息
const userMessage = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/userMessage"))
  );

// 首页 -> 公告
const notices = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/notice"))
  );

// 首页 -> 个人中心
const userCenter1 = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/userCenter1"))
  );

// 首页 -> 账户&安全
const userOrSafety = r =>
  require.ensure([], () =>
    r(require("@/components/personalCenter/tab/userOrSafety"))
  );

// 首页 -> 公告详情
const noticeDetailss = r =>
  require.ensure([], () =>
    r(require("@/components/HomePage/component/children/noticeDetails"))
  );

// 首页 -> 新手指引
const guide = r =>
  require.ensure([], () => r(require("@/components/guide/noviceGuide")));

// 选择认证方式
const certificationTypes = r =>
  require.ensure([], () =>
    r(require("@/components/certificationTypes/certificationTypes"))
  );
Vue.use(Router);
const routerPush = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return routerPush.call(this, location).catch(err => err);
};
export default new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      redirect: "/login",
      children: [
        {
          path: "/index",
          name: "HomePage",
          component: HomePage,
          meta: {
            isIndex: true,
            requireAuth: true
          },
          children: [
            {
              path: "/HomePage1",
              name: "HomePage1",
              component: HomePage1
            },
            {
              path: "/iframeModule",
              name: "iframeModule",
              component: iframeModule
            },
            {
              path: "/moduleXH",
              name: "moduleXH",
              component: moduleXH
            },
            {
              path: "/moduleBidding",
              name: "moduleBidding",
              component: moduleBidding
            },
            {
              path: "/moduleYSJY",
              name: "moduleYSJY",
              component: moduleYSJY
            },
            {
              path: "/oldIframeModule",
              name: "oldIframeModule",
              component: oldIframeModule
            },
            {
              path: "/iframeController",
              name: "iframeController",
              component: iframeController,
              meta: {
                isController: true
              }
            },
            {
              path: "/address",
              name: "mallUserAddress",
              component: mallUserAddress
            },
            {
              path: "/modifyPwd",
              name: "capitalCenterModifyPwd",
              component: capitalCenterModifyPwd,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/accountSafe",
              name: "personalAccountSafe",
              component: personalAccountSafe,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/noticeDetailss/:id",
              name: "noticeDetailss",
              component: noticeDetailss
            },
            {
              path: "/personalCenter/staffAccountMgt",
              name: "personalStaffAccountMgt",
              component: personalStaffAccountMgt,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/footerDetails/:id",
              name: "footerDetails",
              component: footerDetails
            },
            {
              path: "/userOrSafety",
              name: "userOrSafety",
              component: userOrSafety,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/guide",
              name: "guide",
              component: guide,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/personalEnterpriseAuth",
              name: "personalEnterpriseAuth",
              component: personalEnterpriseAuth,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/certificationTypes",
              name: "certificationTypes",
              component: certificationTypes,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/userCenter1",
              name: "userCenter1",
              component: userCenter1,
              meta: {
                requireAuth: true
              }
            },

            {
              path: "/notices",
              name: "notices",
              component: notices,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/FooterDetail",
              name: "FooterDetail",
              component: FooterDetail,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/myPosition",
              name: "buyerCenterMyPosition",
              component: buyerCenterMyPosition,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/logisticsBill",
              name: "sellerPickLogisticsBill",
              component: sellerPickLogisticsBill,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/warehousingApply",
              name: "sellerResourceWarehousingApply",
              component: sellerResourceWarehousingApply,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/fundsManage",
              name: "fundsManage",
              component: fundsManage,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/conditionOfAssets",
              name: "conditionOfAssets",
              component: conditionOfAssets,
              meta: {
                requireAuth: true
              },
              // redirect: "/conditionOfAssets/fundsTransfer",
              // redirect: "/conditionOfAssets/infoReporing",
              children: [
                {
                  path: "/conditionOfAssets/info",
                  name: "capitalCenterInfo",
                  component: capitalCenterInfo,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/StateFunding",
                  name: "capitalCenterState",
                  component: capitalCenterState,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/deposit",
                  name: "capitalCenterDeposit",
                  component: capitalCenterDeposit,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/infoReporing",
                  name: "capitalCenterInfoReporing",
                  component: capitalCenterInfoReporing,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/withdraw",
                  name: "capitalCenterWithdraw",
                  component: capitalCenterWithdraw,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/sign",
                  name: "capitalCenterSign",
                  component: capitalCenterSign,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/signList",
                  name: "capitalCenterSignList",
                  component: capitalCenterSignList,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/unsign",
                  name: "capitalCenterUnSign",
                  component: capitalCenterUnSign,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/setPwd",
                  name: "capitalCenterSetPwd",
                  component: capitalCenterSetPwd,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/modifyPwd",
                  name: "capitalCenterModifyPwd",
                  component: capitalCenterModifyPwd,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/forgetPwd",
                  name: "capitalCenterForgetPwd",
                  component: capitalCenterForgetPwd,
                  meta: {
                    requireAuth: true
                  }
                },
                {
                  path: "/conditionOfAssets/fundsTransfer",
                  name: "fundsTransfer",
                  component: fundsTransfer,
                  meta: {
                    requireAuth: true
                  }
                }
              ]
            },
            {
              path: "/StateFunding",
              name: "capitalCenterState",
              component: capitalCenterState,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/infoReporing",
              name: "capitalCenterInfoReporing",
              component: capitalCenterInfoReporing,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sign",
              name: "capitalCenterSign",
              component: capitalCenterSign,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/signList",
              name: "capitalCenterSignList",
              component: capitalCenterSignList,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/transfer",
              name: "transfer",
              component: transfer,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/financialRecord",
              name: "financialRecord",
              component: financialRecord,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/userMessage",
              name: "userMessage",
              component: userMessage,
              meta: {
                requireAuth: true
              }
            }
          ]
        },
        {
          path: "/Forget",
          name: "Forget",
          component: Forget
        },
        {
          path: "/login",
          name: "login",
          component: Login
        },
        {
          path: "/register/:id",
          name: "register",
          component: Register
        },
        {
          path: "/ResourceDocuments/:commodity?",
          name: "ResourceDocuments",
          component: ResourceDocuments
        },
        {
          path: "/ResourceDocuments/details/:id?/:pickResourcesId?",
          name: "ResourceDocumentsDetails",
          component: ResourceDocumentsDetails
        },
        {
          path: "/purchaseDocument",
          name: "purchaseDocument",
          component: purchaseDocument
        },
        {
          path: "/purchaseDocument/details/:id?/:pickResourcesId?",
          name: "purchaseDocumentDetails",
          component: purchaseDocumentDetails
        },
        {
          path: "/supplyDemandInfo",
          name: "supplyDemandInfo",
          component: supplyDemandInfo
        },
        {
          path: "/supplyDemandInfo/details/:id?",
          name: "supplyDemandDetails",
          component: supplyDemandDetails
        },
        {
          path: "/productionSell",
          name: "productionSell",
          component: productionSell
        },
        {
          path: "/productionSell/details/:id?/:pickResourcesId?",
          name: "productionDetails",
          component: productionDetails
        },
        {
          path: "/productionSell/cart",
          name: "productionCart",
          component: productionCart,
          meta: {
            requireAuth: true
          }
        },
        {
          path: "/matching",
          name: "matching",
          component: matching,
          redirect: "/matching/qiugou",
          children: [
            {
              path: "/matching/qiugou",
              name: "matchingQiugou",
              component: matchingQiugou,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/matching/order",
              name: "matchingOrder",
              component: matchingOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/matching/dealOrder",
              name: "matchingDealOrder",
              component: matchingDealOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/matching/holdProduct",
              name: "matchingHoldProduct",
              component: matchingHoldProduct,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/matching/buyAgreeList",
              name: "matchingBuyAgreeList",
              component: matchingBuyAgreeList,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/matching/pickGoods",
              name: "matchingPickGoods",
              component: matchingPickGoods,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/matching/pickGoodsList",
              name: "matchingPickGoodsList",
              component: matchingPickGoodsList,
              meta: {
                requireAuth: true
              }
            }
          ]
        },
        {
          path: "/buyerCenter",
          name: "buyerCenter",
          component: buyerCenter,
          redirect: "/buyerCenter/buy/spotBuy",
          children: [
            {
              path: "/buyerCenter/buy/spotBuy",
              name: "buyerCenterBuySpotBuy",
              component: buyerCenterBuySpotBuy,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/buy/biddingBuy",
              name: "buyerCenterBuyBiddingBuy",
              component: buyerCenterBuyBiddingBuy,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/buy/buyInfo",
              name: "buyerCenterBuyInfo",
              component: buyerCenterBuyInfo,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/buy/spotBuyOrder",
              name: "buyerCenterBuySpotBuyOrder",
              component: buyerCenterBuySpotBuyOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/buy/biddingBuyOrder",
              name: "buyerCenterBuyBiddingBuyOrder",
              component: buyerCenterBuyBiddingBuyOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/spotOrder",
              name: "buyerCenterPurchaseSpotOrder",
              component: buyerCenterPurchaseSpotOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/biddingOrder",
              name: "buyerCenterPurchaseBiddingOrder",
              component: buyerCenterPurchaseBiddingOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/registeredBidding",
              name: "buyerCenterPurchaseRegisteredBidding",
              component: buyerCenterPurchaseRegisteredBidding,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/winningBidding",
              name: "buyerCenterPurchaseWinningBidding",
              component: buyerCenterPurchaseWinningBidding,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/preSellOrder",
              name: "buyerCenterPurchasePreSellOrder",
              component: buyerCenterPurchasePreSellOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/contract",
              name: "buyerCenterPurchaseContract",
              component: buyerCenterPurchaseContract,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/basisOrder",
              name: " buyerCenterPurchaseBasisOrder",
              component: buyerCenterPurchaseBasisOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/purchase/basisBuybackOrder",
              name: "buyerCenterPurchaseBasisBuybackOrder",
              component: buyerCenterPurchaseBasisBuybackOrder,
              meta: {
                requireAuth: true
              }
            },
            // {
            //   path: "/buyerCenter/logistics/myPosition",
            //   name: "buyerCenterMyPosition",
            //   component: buyerCenterMyPosition,
            //   meta: {
            //     requireAuth: true
            //   }
            // },
            {
              path: "/buyerCenter/logistics/pickupGoods",
              name: "buyerCenterPickupGoods",
              component: buyerCenterPickupGoods,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/logistics/selfBill",
              name: "buyerCenterPickSelfBill",
              component: buyerCenterPickSelfBill,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/buyerCenter/logistics/logisticsBill",
              name: "buyerCenterPickLogisticsBill",
              component: buyerCenterPickLogisticsBill,
              meta: {
                requireAuth: true
              }
            }
          ]
        },
        {
          path: "/sellerCenter",
          name: "sellerCenter",
          component: sellerCenter,
          redirect: "/sellerCenter/resource/holdSaleApply",
          children: [
            {
              path: "/sellerCenter/baojia",
              name: "sellerCenterBaojia",
              component: sellerCenterBaojia,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/order",
              name: "sellerCenterOrder",
              component: sellerCenterOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource",
              name: "sellerCenterResource",
              component: sellerCenterResource,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/dealOrder",
              name: "sellerCenterDealOrder",
              component: sellerCenterDealOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/addProduct",
              name: "sellerCenterAddProduct",
              component: sellerCenterAddProduct,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/myProduction",
              name: "sellerCenterMyProduction",
              component: sellerCenterMyProduction,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/addResourceOrder",
              name: "sellerCenterAddResourceOrder",
              component: sellerCenterAddResourceOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/productionAudit",
              name: "sellerCenterProductionAudit",
              component: sellerCenterProductionAudit,
              meta: {
                requireAuth: true
              }
            },
            // {
            //   path: "/sellerCenter/shopManage",
            //   name: "sellerCenterShopManage",
            //   component: sellerCenterShopManage,
            //   meta: {
            //     requireAuth: true
            //   }
            // },
            {
              path: "/sellerCenter/sellAgreeList",
              name: "sellerCenterSellAgreeList",
              component: sellerCenterSellAgreeList,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sellOrderList",
              name: "sellerCenterSellOrderList",
              component: sellerCenterSellOrderList,
              meta: {
                requireAuth: true
              }
            },
            // {
            //   path: "/sellerCenter/resource/warehousingApply",
            //   name: "sellerResourceWarehousingApply",
            //   component: sellerResourceWarehousingApply,
            //   meta: {
            //     requireAuth: true
            //   }
            // },
            {
              path: "/sellerCenter/resource/holdSaleApply",
              name: "sellerResourceHoldSaleApply",
              component: sellerResourceHoldSaleApply,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/spotResource",
              name: "sellerResourceSpotResource",
              component: sellerResourceSpotResource,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/biddingResource",
              name: "sellerResourceBiddingResource",
              component: sellerResourceBiddingResource,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/preSellResource",
              name: "sellerResourcePreSellResource",
              component: sellerResourcePreSellResource,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/sellInfoResource",
              name: "sellerResourceSellInfoResource",
              component: sellerResourceSellInfoResource,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/basisTransferRegistration",
              name: "basisTransferRegistration",
              component: basisTransferRegistration,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/basisList",
              name: "sellerResourceBasisList",
              component: sellerResourceBasisList,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/qualityInspectionReport",
              name: "sellerResourceQualityInspectionReport",
              component: sellerResourceQualityInspectionReport,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/spotResourceOrder",
              name: "sellerResourceSpotResourceOrder",
              component: sellerResourceSpotResourceOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/biddingResourceOrder",
              name: "sellerResourceBiddingResourceOrder",
              component: sellerResourceBiddingResourceOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/resource/preSellResourceOrder",
              name: "sellerResourcePreSellResourceOrder",
              component: sellerResourcePreSellResourceOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/spotOrder",
              name: "sellerSalesSpotOrder",
              component: sellerSalesSpotOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/basisOrder",
              name: "sellerSalesBasisOrder",
              component: sellerSalesBasisOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/canRepOrder",
              name: "sellerSalesCanRepOrder",
              component: sellerSalesCanRepOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/basisRepoOrder",
              name: "sellerSalesBasisRepoOrder",
              component: sellerSalesBasisRepoOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/biddingOrder",
              name: "sellerSalesBiddingOrder",
              component: sellerSalesBiddingOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/registeredBidding",
              name: "sellerSalesRegisteredBidding",
              component: sellerSalesRegisteredBidding,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/winningBidding",
              name: "sellerSalesWinningBidding",
              component: sellerSalesWinningBidding,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/qualityInspectionReport",
              name: "sellerSalesQualityInspectionReport",
              component: sellerSalesQualityInspectionReport,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/depositReport",
              name: "sellerSaleDepositReport",
              component: sellerSaleDepositReport,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/contract",
              name: "sellerSalesContract",
              component: sellerSalesContract,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/depositOrder",
              name: "sellerSalesDepositOrder",
              component: sellerSalesDepositOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/myPosition",
              name: "sellerSalesMyPosition",
              component: sellerSalesMyPosition,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/warehouseOrder",
              name: "sellerSalesWarehouseOrder",
              component: sellerSalesWarehouseOrder,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/myFinancing",
              name: "sellerSalesMyFinancing",
              component: sellerSalesMyFinancing,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/myOrderFinancing",
              name: "sellerSalesMyOrderFinancing",
              component: sellerSalesMyOrderFinancing,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/sales/myPledge",
              name: "sellerSalesMyPledge",
              component: sellerSalesMyPledge,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/sellerCenter/logistics/selfBill",
              name: "sellerPickSelfBill",
              component: sellerPickSelfBill,
              meta: {
                requireAuth: true
              }
            }
            // {
            //   path: "/sellerCenter/logistics/logisticsBill",
            //   name: "sellerPickLogisticsBill",
            //   component: sellerPickLogisticsBill,
            //   meta: {
            //     requireAuth: true
            //   }
            // }
          ]
        },
        {
          path: "/personalCenter",
          name: "personalCenter",
          component: personalCenter,
          redirect: "/personalCenter/accountInfo",
          children: [
            {
              path: "/personalCenter/accountInfo",
              name: "personalAccountInfo",
              component: personalAccountInfo,
              meta: {
                requireAuth: true
              }
            },
            // {
            //   path: "/personalCenter/setPassword",
            //   name: "personalSetPassword",
            //   component: personalSetPassword,
            //   meta: {
            //     requireAuth: true
            //   }
            // },
            // {
            //   path: "/personalCenter/accountSafe",
            //   name: "personalAccountSafe",
            //   component: personalAccountSafe,
            //   meta: {
            //     requireAuth: true
            //   }
            // },
            {
              path: "/personalCenter/enterprise",
              name: "personalEnterprise",
              component: personalEnterprise,
              meta: {
                requireAuth: true
              }
            },
            // {
            //   path: "/personalCenter/enterpriseAuth",
            //   name: "personalEnterpriseAuth",
            //   component: personalEnterpriseAuth,
            //   meta: {
            //     requireAuth: true
            //   }
            // },
            {
              path: "/personalCenter/electronicSignature",
              name: "personalElectronicSignature",
              component: personalElectronicSignature,
              meta: {
                requireAuth: true
              }
            },

            {
              path: "/personalCenter/staffRoleMgt",
              name: "personalStaffRoleMgt",
              component: personalStaffRoleMgt,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/personalCenter/personalProductInfo",
              name: "personalProductInfo",
              component: personalProductInfo,
              meta: {
                requireAuth: true
              }
            },
            {
              path: "/personalCenter/shopManage",
              name: "sellerCenterShopManage",
              component: sellerCenterShopManage,
              meta: {
                requireAuth: true
              }
            }
          ]
        },
        {
          path: "/shopCenter",
          name: "shopCenter",
          component: shopCenter
        },
        {
          path: "/mall",
          name: "mall",
          component: mall
        },
        {
          path: "/mall/productionDetails/:hangResourcesId/:pickResourcesId?",
          name: "mallProductionDetails",
          component: mallProductionDetails
        },
        {
          path: "/mall/confirmOrder",
          name: "mallConfirmOrder",
          component: mallConfirmOrder
        },
        {
          path: "/mall/user",
          name: "mallUserCenter",
          component: mallUserCenter,
          redirect: "/mall/user/address",
          children: [
            {
              path: "/mall/user/orderList",
              name: "mallUserOrderList",
              component: mallUserOrderList
            }
            // {
            //   path: "/mall/user/address",
            //   name: "mallUserAddress",
            //   component: mallUserAddress
            // }
          ]
        },
        {
          path: "/helpCenter/:id?",
          name: "helpCenter",
          component: helpCenter,
          meta: {
            keepAlive: true
          }
        },
        // {
        //   path: "/helpDetail",
        //   name: "helpDetail",
        //   component: helpDetail,
        //   meta: {
        //     keepAlive: true
        //   }
        // },
        {
          path: "/news",
          name: "news",
          component: news,
          meta: {
            keepAlive: true
          }
        },
        {
          path: "/news/newsList/newsDetails/:id",
          name: "newsDetails",
          component: newsDetails
        },
        {
          path: "/search",
          name: "search",
          component: search
        },
        {
          path: "/notice",
          name: "notice",
          component: notice,
          meta: {
            keepAlive: true
          }
        },

        {
          path: "/financial",
          name: "financial",
          component: financial
        },
        {
          path: "/financial/order",
          name: "financialOrder",
          component: financialOrder
        },
        {
          path: "/financial/accountReceive",
          name: "financialAccountReceive",
          component: financialAccountReceive
        },
        {
          path: "/financial/warehouse",
          name: "financialWarehouse",
          component: financialWarehouse
        },
        {
          path: "/financial/bill",
          name: "financialBill",
          component: financialBill
        },
        {
          path: "/basisTrading",
          name: "basisTrading",
          component: basisTrading
        },
        {
          path: "/basisTrading/details/:id?/:pickResourcesId?",
          name: "basisDetails",
          component: basisDetails
        }
      ]
    },
    {
      path: "*",
      name: "error",
      component: () => import("../components/404")
    }
  ]
});
