function calculateMA(dayCount, data) {
  var result = [];
  for (var i = 0, len = data.length; i < len; i++) {
      if (i < dayCount) {
          result.push('-');
          continue;
      }
      var sum = 0;
      for (var j = 0; j < dayCount; j++) {
          sum += data[i - j][1];
      }
      result.push((sum / dayCount).toFixed(2));
  }
  return result;
}

let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/candlestick');
require('echarts/lib/chart/line');
require('echarts/lib/chart/bar');
// 引入提示框和标题组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/dataZoom');
require('echarts/lib/component/title');
require('echarts/lib/component/legend');

//国际化
import i18n from '../../../i18n/i18n'
let chartLanguage = i18n._vm.messages[i18n._vm.locale].message;

let colorList = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'];
let labelFont = 'bold 12px Sans-serif';
let kLineOption={
    animation: false,
                color: colorList,
                title: {
                    left: 'center',
                    text: '移动端 K线图'
                },
                legend: {
                    top: 30,
                    data: ['K线数据', 'MA5', 'MA10', 'MA20', 'MA30']
                },
                tooltip: {
                    show:true,
                    trigger: 'axis',
                    alwaysShowContent:false,
                    position:['4.7%','2.2%'],
                    axisPointer: {
                        type: 'cross',
                        label:{
                            precision:2, 
                            margin:0,
                            textStyle:{
                                fontsize:5
                            },
                            padding:[5,0,5,0]
                        }
                    }
                },
                axisPointer: {
                    link: [{
                        xAxisIndex: [0, 1]
                    }]
                },
                dataZoom: [ {
                    type: 'inside',
                    xAxisIndex: [0, 1],
                    start: 40,
                    end: 70,
                    top: 30,
                    height: 20
                }],
                xAxis: [{
                    type: 'category',
                    data: '',
                    boundaryGap : false,
                    axisLine: { lineStyle: { color: '#777' } },
                    axisLabel: {
                        formatter: function (value) {
                            return echarts.format.formatTime('MM-dd', value);
                        }
                    },
                    min: 'dataMin',
                    max: 'dataMax',
                    axisPointer: {
                        show: true
                    }
                }, {
                    type: 'category',
                    gridIndex: 1,
                    data: '',
                    scale: true,
                    boundaryGap : false,
                    splitLine: {show: false},
                    // axisLabel: {show: false},
                    axisTick: {show: false},
                    axisLine: { lineStyle: { color: '#777' } },
                    splitNumber: 20,
                    min: 'dataMin',
                    max: 'dataMax',
                    axisPointer: {                        
                        type: 'shadow',                         
                    }
                }],
                yAxis: [{

                    type:'value',
                    minInterval :0.1,
                    scale: true,
                    // splitArea: {
                    //     show: true
                    // }

                    // scale: true,
                    // splitNumber: 2,
                    // axisLine: { lineStyle: { color: '#777' } },
                    // splitLine: { show: true },
                    // axisTick: { show: false },
                    // axisLabel: {
                    //     inside: true,
                    //     formatter: '{value}\n'
                    // }
                }, {
                    type:'value',
                    gridIndex: 1,
                    minInterval :0.1,
                    scale: true,


                    
                    
                    splitNumber: 2,
                    axisLabel: {show: false},
                    axisLine: {show: false},
                    axisTick: {show: false},
                    splitLine: {show: false}
                }],
                grid: [{
                    left: 20,
                    right: 20,
                    top: 110,
                    height: 120
                }, {
                    left: 20,
                    right: 20,
                    height: 40,
                    top: 260
                }],
                graphic: [{
                    type: 'group',
                    left: 'center',
                    top: 70,
                    width: 300,
                    bounding: 'raw',
                    children: [{
                        id: 'MA5',
                        type: 'text',
                        style: {fill: colorList[1], font: labelFont},
                        left: 0
                    }, {
                        id: 'MA10',
                        type: 'text',
                        style: {fill: colorList[2], font: labelFont},
                        left: 'center'
                    }, {
                        id: 'MA20',
                        type: 'text',
                        style: {fill: colorList[3], font: labelFont},
                        right: 0
                    }]
                }],
                series: [{
                    name: 'Volume',
                    type: 'bar',
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    itemStyle: {
                        normal: {
                            color: '#7fbe9e'
                        },
                        emphasis: {
                            color: '#140'
                        }
                    },
                    data: ''
                    
                }, {
                    type: 'candlestick',
                    name: 'K线数据',
                    data: '',                    
                    itemStyle: {
                        normal: {
                            color: '#ef232a',
                            color0: '#14b143',
                            borderColor: '#ef232a',
                            borderColor0: '#14b143'
                        },
                        emphasis: {
                            color: 'black',
                            color0: '#444',
                            borderColor: 'black',
                            borderColor0: '#444'
                        }
                    }
                }, {
                    name: 'MA5',
                    type: 'line',
                    data: '',
                    smooth: true,
                    showSymbol: false,
                    lineStyle: {
                        normal: {
                            width: 1
                        }
                    }
                }, {
                    name: 'MA10',
                    type: 'line',
                    data: '',
                    smooth: true,
                    showSymbol: false,
                    lineStyle: {
                        normal: {
                            width: 1
                        }
                    }
                }, {
                    name: 'MA20',
                    type: 'line',
                    data: '',
                    smooth: true,
                    showSymbol: false,
                    lineStyle: {
                        normal: {
                            width: 1
                        }
                    }
                }]
            
};

let timeLineOption={    
        title: {
            show: false
        },
        legend:{
            data:['分时数据','日均线','MA5','MA10','MA15'],
            selected: {
                // 选中'系列1'
                '日K': true,
                // 不选中'系列2'
                '日均线':true,
                'MA5': false,
                'MA10':false,
                'MA15':false
            },
        },
        tooltip: {
            trigger: 'axis',
            alwaysShowContent:true,
            position:['4.7%','7%'],
            axisPointer: {
                type: 'cross',
                label:{
                    precision:2,
                    margin:0,
                    textStyle:{
                        fontsize:5
                    },
                    padding:[5,0,5,0]
                }
            }
        },
        grid: {
            top: 30,
            right: 30,
            left: 40,
            bottom: 30
        },
        xAxis: {
            type: 'category',
            scale: true,
            boundaryGap: false,
            data: ''
        },
        yAxis: {
            scale: true,
            type:'value',
            minInterval :0.1,
            splitLine: {
                show: true,
                lineStyle: {
                    color: '#eeeeee'
                }
            }
        },
        dataZoom: [
            {
                type: 'inside',
            }
        ],
        color: ['#5db1d3',  '#91c7ae', 'grey', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],
        series: [{
            name: 'timeline',
            type: 'line',
            data: '',
            lineStyle: {
                normal: {
                    width: 2
                }
            },
            markLine: {
                silent: true,
                symbol:['none','none'],
                data: [
                    {yAxis:'newprice'}
                ],
                lineStyle:{
                    normal: {
                        color: 'rgba(225,104,29,1)'
                    }
                }
            }
        },{
            name:'timelineaverage',
            type: 'line',
            data: 'timelineaveragevalues'
        },{
            name: 'MA5',
            type: 'line',
            data: '',
            smooth: true,
            showSymbol: false,
            lineStyle: {
                normal: {
                    width: 1,color:'grey',opacity: 0.8
                }
            }
        },{
            name: 'MA10',
            type: 'line',
            data: '',
            smooth: true,
            showSymbol: false,
            lineStyle: {
                normal: {
                    width: 1,opacity: 0.8
                }
            }
        },{
            name: 'MA15',
            type: 'line',
            data: '',
            smooth: true,
            showSymbol: false,
            lineStyle: {
                normal: {
                    width: 1,opacity: 0.8
                }
            }
        }]
    }


export {
    calculateMA,
    kLineOption,
    timeLineOption,
};