const xhlifecycles = {
  beforeLoad: (appWindow) => console.log(),
  beforeMount: (appWindow) => console.log(),
  afterMount: (appWindow) => console.log(),
  beforeUnmount: (appWindow) => console.log(),
  afterUnmount: (appWindow) => console.log(),
  activated: (appWindow) => console.log(),
  deactivated: (appWindow) => console.log(),
  loadError: (url, e) => console.log('现货模块->加载失败'),
};
const biddinglifecycles = {
  beforeLoad: (appWindow) => console.log(),
  beforeMount: (appWindow) => console.log(),
  afterMount: (appWindow) => console.log(),
  beforeUnmount: (appWindow) => console.log(),
  afterUnmount: (appWindow) => console.log(),
  activated: (appWindow) => console.log(),
  deactivated: (appWindow) => console.log(),
  loadError: (url, e) => console.log('竞价交易模块->加载失败'),
};
const ysjylifecycles = {
  beforeLoad: (appWindow) => console.log(),
  beforeMount: (appWindow) => console.log(),
  afterMount: (appWindow) => console.log(),
  beforeUnmount: (appWindow) => console.log(),
  afterUnmount: (appWindow) => console.log(),
  activated: (appWindow) => console.log(),
  deactivated: (appWindow) => console.log(),
  loadError: (url, e) => console.log('预售交易模块->加载失败'),
};

export default {
  xhlifecycles,
  biddinglifecycles,
  ysjylifecycles,
};
