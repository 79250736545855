import enLocale from "element-ui/lib/locale/lang/en";


const en = {
    message: {
        Trading_in:'trading in',
        successfuldeal:'successful deal',
        removed:'removed',
        Nowope:'now open',
        immediatelyinto:'into',
        contact:'contact',
        Paymentcurrency:'Payment currency',
        Purchaseprice:'Purchase price',
        placementprice:'placement price',
        Placetheorderoftime:'Place the order of time',
        Purchasequantity:'Purchase quantity',
        Paytheamount:'Pay the amount',
        Transactiontype:'Transaction type',
        Ticketnumber:'Ticket number',
        amounttobepaidbythewinningbid:'amount to be paid by the winning bid',
        totalfee:'total fee',
        Handlingfee:'Handling fee',
        Clinchadealthestate:'Clinch a deal the state',
        purchase:'Subscription and placement',
        goumaijiage:"purchase price",
        goumaishuliang:"Purchase quantity",
        ieoBuy: 'purchase',
        ieoBuy1: 'placement',
  ieoTotalIssue: 'Total Issue Quantity',
  ieoStartTime: 'Start time',
  ieoEndTime: 'End time',
  ieoPrice: 'Price',
  ieoAmount: 'Amount',
  ieoRemainingAmount: 'Remaining Amount',
  ieoMin: 'Min',
  ieoMax: 'Max',
  ieoRealNaame:'Real-name users can place orders',
  ieoPAmount: 'Please enter the quantity purchased',
  ieoPayWay: 'Please select the payment currency',
  ieoPNum: 'The purchase quantity is too high',
  Minimum_p_purchase_quantity: 'Maximum number of users purchased',
  ieo_title_p1:' Token (',
  ieo_title_p2:') Pre-Sale Issue',
  ieo_title_p3:') rights issue',
  rightsissue:'rights issue',
  rightsIssue1:'rightsIssue price',
  Asset_certification_currency:'Asset certification currency',
  Issue:'issue',
  Left_time:'Left time',
  Days:'Days',
  Hours: 'Hours',
  Minutes: 'Minutes',
  Seconds: 'Seconds',
  Issue_completed:'Issue completed',
  Issue_price: 'Issue price',
  Issue_price_: ' Issue price',
  Currency: 'Currency',
  Available_amount1: 'Available amount',
  Trade_volume: 'trade volume',
  Buy:'Buy',
  Operations_are_too_frequent: 'Operations are too frequent',
  get_project_info_fail: 'Failed to obtain project information',
  Tradevolume:"Trade volume",
  //--
  Pre_sale_Project_information: 'Pre-sale Project information',
  Project_Name:'Project Name',
  Project_Company_Name:'Project Company Name',
  Pre_sale_coin_Full_name: 'Pre-sale coin Full name',
  Pre_sale_coin_Type: 'Pre-sale coin Type',
  Pre_sale_coin_abbreviation: 'Pre-sale coin abbreviation',
  Project_Website: 'Project Website',
  Contract_address: 'Contract address',
  Project_white_paper: 'Project white paper',
  Telegram: 'Telegram',
  Project_Introduction:'Project Introduction',
  Pre_sale_Project_rules:'Pre-sale Project rules',
  Pre_sale_coin_quantity: 'Pre-sale coin quantity',
  Pre_sale_open_time:'Pre-sale open time',
  Pre_sale_end_time:'Pre-sale end time',
  Minimum_purchase_quantity:'Minimum purchase quantity',
  Locked_in_coinlim:'Locked in coinlim',
  Pre_sale_Exchange_ratio:'Pre-sale & Exchange ratio',
  quantity_empty_warn:"Please enter the purchase quantity",
  number_only_warn:"The number of purchases must be a number",
  Confirm:"Confirm",
  OK:"OK",
  CANCEL:"CANCEL",
        language: 'Language',
        //导航
        Crypto_trading: 'Exchange',
        Fiat_trading: 'Fiat',
        Tech_lab: 'Tech lab',
        Log_in: 'Log in',
        //登录模块
        Immediately_Log_in: 'Log in',
        Account_number: 'Account number',
        Please_input_mobile_number: 'Please input mobile number',
        Password: 'Password',
        More_than_6_characters: 'More than 6 characters',
        Identifying_Code: 'Identifying Code',
        Please_input_the_identification_code: 'Please input the identification code',
        Log_in: 'Log in',
        Forgot_Password: 'Forgot Password?',
        Sign_up_now: 'Sign up now',
        Welcome: 'Welcome',
        //注册模块
        Mobile_number: 'Mobile number',
        Mobile_number2: 'Mobile number',
        Please_input_mobile_number: 'Please input mobile number',
        The_email_address_or_mobile_number_you_provide_will_become_your_Good_ID_and_will_be_used_for_all_future_communications: 'The email address or mobile number you provide will become your Good ID and will be used for all future communications.',
        Password: 'Password',
        six_20_alphanumerical_characters: '6-20 alphanumerical characters',
        eight_20_alphanumerical_characters: '8-20 alphanumerical characters',
        For_security_reasons_Please_create_a_password_unique_to_this_account: 'For security reasons, please create a password unique to this account.',
        Confirm_Password: 'Confirm Password',
        Please_retype_the_password: 'Please retype the password',
        Invitation_code_optional: 'Invitation code',
        Please_input_invitation_code: 'Please input invitation code',
        Identification_code: 'Identification code',
        Please_input_the_identification_code: 'Please input the identification code',
        SMS_verification_code: 'SMS verification code',
        Please_input_the_SMS_verification_code: 'Please enter SMS or email verification code Get the verification code',
        Get_the_verification_code: 'Get the verification code',
        I_agree_to_the: 'I agree to the',
        Sign_up: 'Sign up',
        //重置密码
        Reset_password: 'Reset password',
        password_reset_message:'Please contact customer service for password reset',
        Please_input_the_registration_mobile_number: 'Please input the registration mobile number',
        Please_input_the_identification_code: 'Please input the identification code',
        Submit: 'Submit',

        //(左1)
        Estimated_value: 'Estimated value',
        Market: 'Market',
        Token: 'Token',
        Price: 'Price',
        Entrustment_price: 'Entrustment price',
        Entrustment_Amount: 'Entrustment Amount',
        Entrustment_Amount2: 'Entrustment Amount',
        Change: 'Change',
        //(左2)
        Announcement: 'Announcement',
        //(中1)
        Increase_Amount: 'Change',
        High: 'High',
        Low: 'Low',
        twenty_four_H_Vol: 'Vol',
        //(中2)
        Market_Order: 'Market',
        Limit_Order: 'Limit',
        Available: 'Available',
        Deposit: 'Deposit',
        unfrozenFunds:'Thaw',
        Buy_Price: 'Buy Price',
        Buy_Amount: 'Buy Amount',
        Sell_Price: 'Price',
        Sell_Amount: 'Amount',
        Delegate_list:'Delegate list',
        //(中3)
        Open_orders: 'Open orders',
        Buy: 'Buy',
        Sell: 'Sell',
        Buy_oneword: 'Buy',
        Sell_oneword: 'Sell',
        All: 'All',
        Time: 'Time',
        Pairs: 'Pairs',
        Direction: 'Direction',
        Amount: 'Amount',
        Withdrawal_Amount: 'Withdrawal Amount',
        // Total:'委托总额',
        Executed: 'Executed',
        Unexecuted: 'Unexecuted',
        Action: 'Action',
        //(中4)
        Depth_map: 'Depth map',
        //(中5)
        Information: 'Information',
        Introduction: 'Introduction',
        Issue_time: 'Issue time',
        Total_Amount: 'Total Amount',
        thousand: 'ten thousand',
        Circulation: 'Circulation',
        Token_price: 'Token price',
        Whitepaper: 'Whitepaper',
        Official_website: 'Official website',
        Block_explorer: 'Block explorer',
        //(右1)
        Latest_price: 'Latest price',
        Accumulation: 'Accumulation',
        //(右2)
        Last_price: 'Last price',
        //左侧菜单
        OTC: 'Fiat',
        //右侧属性
        Buy_a: 'Buy',
        Sell_b: 'Sell',
        Currency_unit: 'Currency unit',
        Payment_method: 'Payment method',
        Merchant: 'Merchant',
        order_quantity1: 'order quantity',
        Trade_Rate: 'Trade Rate',
        Limits: 'Limits',
        Unit_price: 'Unit price',
        //订单管理
        //左侧菜单
        My_orders: 'My orders',
        //右侧状态
        Unpaid: 'Unpaid',
        Paid: 'Paid',
        Completed: 'Completed',
        Cancelled: 'Cancelled',
        Pending: 'Pending',
        //属性
        order_quantity2: 'order quantity',
        category: 'Category',
        amount: 'amount',
        // Price: '单价',
        // Amount: 总价,
        Counterparty: 'Counterparty',
        Details: 'Details',
        Cancel: 'Cancel order',
        //商家申请
        //左侧菜单
        Merchant_application: 'Merchant application',
        //属性
        Apply_to_become_a_merchant: 'Apply to become a merchant',
        Merchant_certification: 'Merchant certification',
        // Submit: '提交申请',
        Approved: 'Approved',
        OTC_Merchant_Certification_Agreement: 'Fiat Merchant Certification Agreement',
        //认证商家过程
        Step_a_Application_Materials: 'Step 1: Application Materials',
        Step_a_Application_Materials_Details_a: '1. Please download the application template and fill in the required information.',
        Step_a_Application_Materials_Details_b: '2. Please record a video as per the following requirements: Hold the front side of your passport or ID card to the camera in a manner where all text is legible. Read the following statement: “I (full name, and identification document number), confirm that my assets were all acquired from legal and verifiable sources. I intend to trade digital assets such as Bitcoin out of my own willingness whilst fully understand the possible risks associated.”',
        Step_b_Send_application_email: 'Step 2: Send application email',
        Step_b_Send_application_email_Details: 'Please send the specifified documents and video to otc@gs. The email subject should be "Application for Fiat Merchant".',
        Step_c_Submit_application: 'Step 3: Submit application',
        Step_c_Submit_application_Details: 'After sending the email, please click "Confirm" to submit the application and agree to freeze 50000 GOOD as a security deposit.',
        Step_d_Application_review: 'Step 4: Application review',
        Step_d_Application_review_Details: 'We will review your application within 3 days and contact you. Please be sure to read and thoroughly understand the Fiat Service Agreement.',
        Consent_agreement: 'I have read the above agreement and hereby agree to freeze the required amount of GOOD as a security deposit.',
        Apply_for_Merchant_now: 'Apply for Merchant now',
        //左侧菜单
        Post_Ad: 'Post Ad',
        //属性
        Selling_Ad: 'Selling Ad',
        Buying_Ad: 'Buying Ad',
        Currency: 'Currency',
        Minimum_single_turnover: 'Minimum single turnover',
        Maximum_single_turnover: 'Maximum single turnover',
        Note: 'Note',
        Within_characters: 'Within 28 characters',
        Trade_password: 'Trade password',
        Transaction_Method: 'Transaction Method',
        UnionPay: 'UnionPay',
        Alipay: 'Alipay',
        WeChat: 'WeChat',
        //左侧菜单
        My_Ad: 'My Ad',
        //属性
        Ad_code: 'Ad code',
        Ad_type: 'Ad type',
        Order_limits: 'Order limits',
        Action_Status: 'Action/Status',
        Withdraw: 'Cancel the order',
        Withdrawn: 'Withdrawn',
        //左侧菜单
        Orders2: 'Orders',
        OTC_Settings: 'Fiat Settings',
        //属性
        Set_contact_information: 'Set contact information',
        Set_payment_method: 'Set payment method',
        Please_input_your_mobile_number: 'Please input your mobile number',
        Email: 'Email',
        Please_input_your_email_address: 'Please input your email address',
        QQ: 'QQ',
        Please_input_your_QQ_username: 'Please input your QQ username',
        Please_input_your_WeChat: 'Please input your WeChat',
        WhatsApp: 'WhatsApp',
        Please_input_your_WhatsApp_number: 'Please input your WhatsApp number',
        Others: 'Others',
        Please_input_any_other_contact_information: 'Please input any other contact information',
        Save_contact_information: 'Save contact information',
        //设置昵称
        The_following_fields_need_to_be_filled_in_first_before_proceeding_to_use_OTC: 'The following fields need to be filled in first before proceeding to use Fiat.',
        Nickname: 'Nickname',
        Please_input_nickname: 'Please input nickname',
        Cannot_be_changed_after_submission: 'Cannot be changed after submission',
        Next: 'Next',
        //设置联系方式
        // Mobile_number: '电话号码',
        //设置支付方式
        CNY_Settings: 'CNY Settings',
        USD_Settings: 'USD Settings',
        EUR_settings: 'EUR settings',
        WeChat_Pay: 'WeChat Pay',
        Name: 'Name',
        Please_input_your_name: 'please input your name',
        Account_address: 'Account address',
        Please_input_your_account_address: 'Please input your account address',
        Bank_card_number: 'Bank card number',
        Please_input_your_bank_card_number: 'Please input your bank card number',
        Remarks: 'Remarks',
        Feel_free_to_input_any_remarks: 'Feel free to input any remarks',
        Trade_password: 'Trade password',
        Please_input_your_trade_password: 'Please input your trade password',
        Save_payment_settings: 'Save payment settings',
        Details_filled_in_Click_to_start_trading: 'Details filled in. Click to start trading.',
        Order_details: 'Order details',
        Sell_GC: 'Sell GC',
        Order_number: 'Order number',
        Buyer: 'Buyer',
        Volume: 'Volume',
        Total_Pending: 'Total Pending',
        Total_Completed: 'Total Completed',
        Transaction_Amount: 'Transaction Amount',
        Customer_payment_method: 'Customer payment method',
        UnionPay_payment: 'UnionPay',
        Waiting_for_buyer_payment: 'Waiting for payment',
        //订单已取消
        Order_cancelled: 'Order cancelled',
        Any_problem_Open_a_dispute: 'Any problem? Go to appeal.',
        Contact_information: 'Contact information',
        Bank_account: 'Bank account',
        Remarks_Information: 'Remarks',
        Remarks_Required: ' (Required)',
        For_your_financial_safety_please_use_your_own_bank_account_for_any_transactions: 'For your financial safety, please use your own bank account for any transactions. ',
        ThirdParty_payments_are_not_allowed: 'Third-party payments are not allowed.',
        Confirm_payment: 'Confirm payment',

        //资产
        //左菜单
        Account: 'Account',
        Estimated_balance: 'Estimated balance',
        //交易详情
        In_orders: 'Frozen',
        Total: 'Total',
        BTC_estimate: 'BTC estimate',
        Transaction: 'Transaction',
        Copy: 'Copy',
        QR_code: 'QR code',
        Reminder_title: 'Reminder: ',
        Reminder_a: 'Do not deposit any non',
        Reminder_b: 'assets to the above address, otherwise the assets will not be recovered.',
        Reminder_c: 'After you recharge to the above address, you need the successful confirmation of the entire network node.',
        Reminder_d: 'Minimum deposit amount is ',
        Reminder_e: 'The charge which less than minimum number won’t make an entry in account book.',
        Reminder_f: 'Your deposit address will not change frequently, and it can be deposited repeatedly.If there is any changes, we will try to notify you through website announcement or E-mail.',
        Reminder_g: 'Make sure that your computers and browsers are secure to prevent information from being tampered with or leaked.',
        //左菜单
        Financial_log: 'Financial log',
        Deposit_log: 'Deposit log',
        Type: 'Type',
        State: 'State',
        Confirming: 'Confirm',
        Withdrawal_log: 'Withdrawal log',
        //其他详情
        Other_records: 'Other records',
        Rewards: 'Rewards',
        Address: 'Address',
        Actual_Amount: 'Actual Amount',
        Get_verification_code: 'Get verification code ',
        Note_a: 'the minimum withdrawal is',
        Note_b: 'Please ensure that the device and browser you’re accessing this page from are secure to prevent any information from being tampered with or leaked.',
        //左菜单
        Address_management: 'Address management',
        //地址管理详情
        // Remarks: '备注',

        //我的
        //左菜单
        My_Information: 'My Information',
        //我的信息内容
        RealName_Verification: 'Real-name Verification',
        Unverified: 'Unverified',
        Verified: 'Verified',
        Verification: 'Verification',
        Verify: 'Verify',
        Log_in_password: 'Log in password',
        Security_Level: 'Security Level',
        Wake: 'Wake',
        Medium: 'Medium',
        Strong: 'Strong',
        Fund_password: 'Fund password',
        Set: 'Set',
        Not_Set: 'Not Set',
        //左菜单
        Individual_ID_verification: 'Individual ID verification',
        //实名认证内容
        Nationality: 'Nationality',
        Nationality_two: 'Nationality',
        ID_Number: 'ID Number',
        //左菜单
        Login_password: 'Login password',
        //登录密码内容
        Old_login_password: 'Old login password',
        New_login_password: 'New login password',
        Confirm_login_password: 'Confirm login password',
        //左菜单
        //资金密码内容
        Old_fund_password: 'Old fund password',
        New_fund_password: 'New fund password',
        Confirm_fund_password: 'Confirm fund password',
        //左菜单
        My_Invitation_code: 'My Invitation code',
        //我的邀请码内容
        Invite_friends_to_register: 'Invite friends to register',
        Kindly_advise_your_friends_to_fill_in_the_invitation_code_field_when_registering: 'Kindly advise your friends to fill in the invitation code field when registering.',
        A_person_has_been_invited: '1 person has been invited',
        Recevied_A_GOOD_reward: 'Recevied 1 GOOD reward',

        //订单+公告
        //分类
        Order_history: 'Order history',
        Transaction_history: 'Transaction history',
        //订单详情
        Transaction_Type: 'Transaction Type',
        Pair: 'Pair',
        //公告详情
        Announcement_list: 'Announcement list',
        //白皮书
        Write_book:'Write Book',
        Details2: 'Details',

        // 客户缺少的页面部分的翻译（用有道翻译翻译的）
        Assets: 'Asset',
        Order: 'Order',
        Exit: 'Log out',
        More_problems: 'Other questions',
        Scan_to_download_the_APP: 'Download the APP by scanning the QR code',
        After_downloading_the_iOS_version_go_to_setup_general_device_management_click_trust_Good_wallet_to_finish: 'Settings after downloading iOS APP---General---Device Management---Trust Good wallet---Done',
        Strategic_global: 'Global Strategics',
        We_will_arrange_local_transaction_service_centers_in_several_countries_and_regions: 'Established local transaction service center in several countries and areas to give an access of quality cryptocurrency assets to users worldwide.',
        Top_security: 'Top Security',
        Cooperating_with_an_elite_team_with_years_of_experience_in_digital_asset_security_risk: 'Cooperated with experienced elite team of cryptocurrency assets management, Good Chain has super-large scale assets manage system to stably serve millions of users.',
        Graphene_distributed_architecture_combined_with_anti_ddos_attack_system: 'Graphene distributed architecture combined with anti-DDOS attract system, multiple security authentication mechanism to ensure top security of users’ assets.',
        Innovation_system: 'Creative System',
        The_model_of_international_co_reation_and_win_win_cooperation_builds_sustainable: 'International cooperation and all-win modes promote ecosystem into sustainable development, creative block-producer system create community economy and share community asset together.',
        The_OTC_premium_service_seamlessly_connects_digital_currency_recharge_and: 'Quality Fiat service mode guarantee the charging and withdrawing process smoothly, service fee as dividend rewarding mechanism make community powerful and  prosperous',
        Cutting_edge_technology: 'Cutting-edge technology',
        Independent_professional_graphene_digital_asset_ecosystem_value_system: 'Independent professional Graphene cryptocurrency asset ecosystem, public main-chain system supports digital assets’ establishment and issuance',
        High_performance_architecture_design_millisecond_transaction_document_processing: 'High-performance architecture and millisecond transaction processing speed, which makes the transaction system safe, responsible and convenient.',
        Good_chain_The_world_leading_digital_exchange: 'Good Chain——Global Advanced Crypto Assets  Exchange',
        More_announcements: 'More',
        Real_time_price: 'Live price',
        Download_the_APP: 'APP Download',
        Do_you_forget_asset_password: 'Do you forget asset password?',
        Address_Lists: 'Address Lists',
        Delete: 'Delete',
        Charge_Address: 'Charge Address',
        Account2: 'Account',
        The_charge_which_less_than_minimum_number: 'The charge which less than minimum number won’t make an entry in account book(In Reminders)',
        Please_scan_the_QR_code_by_GOOD_wallet: 'Please scan the QR code by GOOD wallet',
        Wallet_scanning_is_recommended: 'Wallet scanning is recommended',
        New_password: 'New password',
        Please_set_new_password: 'Please set new password',
        Enter_the_registered_mobile_number: 'Enter the registered mobile number',
        Please_enter_the_password: 'Please enter the password',
        Please_keep_my_information: 'Please keep my information',
        Cancel2: 'Cancel',
        Confirm: 'Confirm',
        Tips_Reminders: 'Tips/Reminders',
        Unexecuted_Volume: 'Unexecuted Volume',
        Most_Recent_Trade: 'Most Recent Trade',
        Most_History_Trade:'Transaction history',
        Or: 'Or',
        Open_orders2: 'Open orders',
        Please_enter_the_token_name: 'Please enter token name',
        Trading_View: 'Trading View',
        Transaction_fees: 'Transaction fees',
        Buying_order: 'Buying order',
        Selling_order: 'Selling order',
        Withdrawing_fees: 'Withdrawing fees',
        Withdrawing_rate: 'Withdrawing rate',
        Single_limit: 'Single limit',
        Transaction_Volume: 'Transaction Volume',
        Market_Index: 'Market Index',
        Alipay_number: 'Alipay number',
        Alipay_payment_code: 'Alipay payment code',
        WeChat_number: 'WeChat number',
        WeChat_payment_code: 'WeChat payment code',
        Payment_successful: 'Payment successful',
        Confirm_Completion: 'Confirm Completion',
        Business_Information: 'Business Information',
        Senior_Verification: 'Senior Verification',
        Registration_Time: 'Registration Time',
        Type_of_complaint: 'Type of complaint',
        Reason_for_complaint: 'Reason for complaint',
        Waiting_for_a_receipt: 'Waiting for a receipt',
        Complaint_Passed: 'Complaint Passed',
        Complaint_rejected: 'Complaint rejected',
        Complaint: 'Complaint',
        Orders_was_rejected: 'Orders was rejected',
        Business_Account: 'Business Account',
        Advertisement_ID: 'Advertisement ID',
        Minimum_Limit: 'Minimum Limit',
        Maximum_Limit: 'Maximum Limit',
        Remaining_Amount: 'Remaining Amount',
        Limit: 'Limit',
        UnionPay_Icon: 'UnionPay Icon',
        // Orders_number: 'Orders number',
        Place_an_order: 'Place an order',
        Submit_Senior_Verification_Documents: 'Submit Senior Verification Documents',
        Senior_Verification_was_Completed: 'Senior Verification was Completed',
        Failed_to_Senior_Verified: 'Failed to Senior Verified',
        Senior_Verification_Documents_are_under_review: 'Senior Verification Documents are under review',
        Reasons_for_the_Failure: 'Reasons for the Failure',
        Modify: 'Modify',
        Please_select_your_option: 'Please select your option',
        Senior_Real_name_Verification: 'Senior Real-name Verification',
        Users_should_complete_real_name_verification_before_doing: 'Users should complete real-name verification before doing Fiat transaction on this platform, please  provide your authentic and valid ID card photos. False authentication may lead to account freeze and all the consequences caused by false authentication should be charged by users themselves.',
        Photo_Requirements: "Photo Requirements: 1) The ID card photo should clearly enough; 2) Please upload a photo with you holding your ID card in your left hand and a blank page showing 'good', your autograph and current date in your right hand.",
        Certificates_Location: 'Certificates Location',
        Certificates_Name: 'Certificates Name',
        Certificates_Number: 'Certificates Number',
        Valid_start_date_of_certificates: 'Valid start date of certificates',
        Valid_end_date_of_certificates: 'Valid end date of certificates',
        Choose_the_date: 'Choose the date',
        Tips_Please_choose_JPG_photo_first_to_ensure_picture_quality: 'Tips: Please choose JPG photo first to  ensure picture quality',
        Front_photo_of_your_ID_card: 'Front photo of your ID card',
        Back_photo_of_your_ID_card: 'Back photo of your ID card',
        Photo_of_you_holding_your_ID_card: 'Photo of you holding your ID card',
        Invitation_Record: 'Invitation Record',
        Invited_number: 'Invited number',
        Invitation_Time: 'Invitation Time',
        // 表单验证部分缺少的翻译
        Choose_your_location: 'Choose your location',
        Enter_your_real_name: 'Enter your real name',
        Enter_your_ID_number: 'Enter your ID number',
        Withdraw_coin_token: 'Withdraw',
        Failed: 'Failed',
        Processing: 'Processing',
        Under_review: 'Under review',
        Rejected: 'Rejected',
        Canot_less_than_the_minimum_coin_withdrawing_number: 'Can’t less than the minimum coin-withdrawing number',
        Enter_the_coin_withdrawing_address: 'Enter the coin-withdrawing address',
        Enter_the_coin_withdrawing_number: 'Enter the coin-withdrawing number',
        Please_enter_numerals: 'Please enter numerals',
        Please_complete_Verification_and_Senior_Verification_first: 'Please complete Verification and Senior Verification first',
        You_did_copy_just_now: 'You did copy just now',
        Failed_to_copy: 'Failed to copy',
        Entered_passwords_differ: 'Entered passwords differ',
        Please_enter_11_mobile_number: 'Please enter 11 mobile number',
        Please_enter_11_valid_mobile_number: 'Please enter 11 valid mobile number',
        Please_enter_new_password: 'Please enter new password',
        Please_enter_8_20_characters_numbers_or_punctuation: 'Please enter 8-20 characters, numbers or punctuation',
        Please_enter_characters_numbers_or_punctuation: 'Please enter characters, numbers or punctuation',
        Please_confirm_the_password: 'Please confirm the password',
        Wrong_SMS_verification_code: 'SMS or email verification code error',
        Please_read_the_Terms_Conditions: 'Please read the Terms & Conditions',
        Authorization_completed: 'Authorization completed',
        Conclude_a_transaction_partially: 'Conclude a transaction partially',
        Cancel_the_order_after_partial_transaction: 'Cancel the order after partial transaction',
        Please_enter_the_selling_amount: 'Please enter the selling amount',
        Please_complete_the_verification_first_before_transaction: 'Please complete the verification first before transaction',
        Please_enter_the_selling_price: 'Please enter the selling price',
        Please_enter_the_buying_amount: 'Please enter the buying amount',
        Please_enter_the_buying_price: 'Please enter the buying price',
        Payment_successful_Paid: 'Payment successful/Paid',
        Enter_the_order_management_page_to_check_the_order_progress: 'Enter the order management page to check the order progress',
        Failed_to_cancel: 'Failed to cancel',
        The_order_was_completed: 'The order was completed',
        Please_confirm_your_complaint: 'Please confirm your complaint',
        Tips: 'Tips',
        Complaint_successful: 'Complaint successful',
        Complaint_cancellation_was_completed: 'Complaint cancellation was completed',
        Seller: 'Seller',
        The_buyer_has_received_the_order_waiting_for_buyer_payment: 'The buyer has received the order, waiting for buyer’s payment',
        Wait_for_counterpart_payment: 'Wait for counterpart’s payment',
        Buyer_has_completed_payment_please_make_sure_whether_the_transaction_was_finished: 'Buyer has completed payment, please make sure whether the transaction was finished.',
        Counterpart_has_completed_the_payment: 'Counterpart has completed the payment',
        You_have_confirmed_the_payment_and_the_order_was_completed: 'You have confirmed the payment, and the order was completed',
        Your_complaint_has_been_passed: 'Your complaint has been passed',
        Application_passed: 'Application passed',
        Your_complaint_has_been_rejected: 'Your complaint has been rejected',
        Application_rejected: 'Application rejected',
        Wait_for_buyer_receiving_order: 'Wait for buyer receiving order',
        Wait_for_counterpart_receiving_order: 'Wait for counterpart receiving order',
        Counterpart_has_refused_the_order: 'Counterpart has refused the order',
        Buyer_has_refused_the_order: 'Buyer has refused the order',
        Option_1_The_counterpart: 'Option 1: The counterpart didn’t put coin',
        Option_2_The_counterpart: 'Option 2: The counterpart has put the coin but I didn’t get.',
        Option_3_I_completed: 'Option 3: I completed payment but didn’t add notes',
        Confirm_to_close: 'Confirm to close',
        Enter_your_bank_of_deposit: 'Enter your bank of deposit',
        Save_UnionPay_payment_settings: 'Save UnionPay payment settings',
        Update_UnionPay_payment_settings: 'Update UnionPay payment settings',
        Please_confirm_your_cancel_of_the_order: 'Please confirm your cancel of the order',
        You_have_canceled_the_order: 'You have canceled the order',
        The_enter_number_canot_lager_than_the_available_funds: 'The enter number can’t lager than the available funds',
        The_enter_number_canot_lager_than_the_current_amount: 'The enter number can’t lager than the current amount',
        The_enter_number_canot_less_than_minimum_limit: 'The enter number can’t less than minimum limit',
        Please_enter_the_data: 'Please enter the data',
        Please_enter_the_valid_start_date: 'Please enter the valid start date',
        Please_enter_the_valid_end_date: 'Please enter the valid end date',
        Please_upload_the_photo_of_ID_card: 'Please upload the photo of ID card',
        The_photo_format_of_head_portrait_should_be_JPG_or_PNG: 'The photo format of head portrait should be JPG or PNG',
        The_picture_memory_size_of_head_portrait_should_be_less_than_3M: 'The picture memory size of head portrait should be less than 3M',
        Forgot_the_login_password: 'Forgot the login password',
        Please_enter_current_login_password: 'Please enter current login password',
        Please_enter_new_login_password: 'Please enter new login password',
        Check_up: 'Check up',
        Go_to_verified: 'Go to verify',
        Invitation_successful: 'Invitation successful',
        Invitation_failed: 'Invitation failed',

        // 补充
        The_highest: 'Highest',
        The_minimum: 'Lowest',
        Your_identity_information_has_been_authenticated: 'Your identity information has been verified.',
        Network_instability: 'Internet is unstable',
        The_session_is_invalid_Please_login_again: 'Session invalid, please login again!',
        // 0912补充
        Logo_image: 'Logo picture',
        Good_wallet_download: 'Good Wallet Download',
        GDUEX_download: 'GDUEX Download',
        An_important_statement_of_Good_chain_uniqueness: 'Important Statement of uniqueness of Goldman Sachs',
        Clinch_a_deal_the_price: 'Price',
        Clinch_a_deal_the_quantity: 'Volume',
        Clinch_a_deal_amount: 'Total',
        time_sharing: 'temporal',
        Set_up_the: 'Setting',
        indicators: 'Index',
        Place_the_order_failed: 'Fail to place an order',
        Assets_will_be_frozen_after_the_complaint_and_the_ombudsman_will_step_in: 'Controversial asset will be frozen after appeal and Ombudsman will intervene in this transaction.',
        Maliciously_appealing_terms_disrupt_the_normal_operation_order_of_the_platform: 'Malicious prosecution disrupting the normal operation order of this platform, the accounts will be frozen if the circumstances are serious.',
        Photo_attachments: 'Image attachment',
        Remark_information_copy_successfully: 'Succeed in copying note information',
        Failed_to_obtain_payment_method: 'Fail to get payment method',
        Please_select_appeal_type: 'Please choose type of complaint',
        Please_enter_cause_of_complaint: 'Please enter reason for complaint',
        The_appeal_failed: 'Your complaint is failed',
        Payment_failure: 'Your payment is failed',
        If_payment_is_not_confirmed_within_15_minutes: 'The order will be canceled if it isn’t paid within 15 minutes',
        Order_unpaid: 'Order is unpaid',
        Order_paid: 'Order is paid',
        Identify_successful: 'Confirmation succeed',
        Confirm_the_failure: 'Confirmation failed',
        The_appeal_type: 'Type of complaint',
        Please_enter_your_alipay_account: 'Please enter your Alipay account number',
        Qr_code_upload: 'Upload QR code',
        Picture_of_qr_code_uploaded: 'Upload QR code picture',
        WeChat_payment_account: 'WeChat account number',
        Please_enter_your_WeChat_payment_account: 'Enter your WeChat account number',
        Save_alipay_payment_Settings: 'Save Alipay Payment Setting',
        Update_alipay_payment_Settings: 'Upgrade Alipay Payment Setting',
        Save_the_WeChat_payment_Settings: 'Save WeChat Payment Setting',
        Update_WeChat_payment_Settings: 'Upgrade WeChat Payment Setting',
        Please_upload_the_picture_of_qr_code: 'Please upload QR code picture',
        Upload_pictures_can_only_be_JPG_or_PNG_format: 'Only JPG or PNG!',
        Operation_canceled: 'Cancel',
        Alipay_icon: 'Alipay Logo',
        Micro_letter_icon: 'WeChat Logo',
        Authentication_passed: 'Identity Verification Completed',
        Portrait_photo_of_my_id_card: 'Portrait photo of your ID card',
        My_id_card_picture_of_national_emblem: 'National Emblem photo of your ID card',
        Holding_personal_id_card_photo: 'The photo of you holding your ID card which shows portrait side',
        His_AD: 'His advertisement',
        Ad_number: 'Advertisement number',
        Create_Time: 'Creation time',
        Revocation_failure: 'Failure to cancel',
        Order_is_invalid: 'Order is invalid',
        The_order_has_lapsed_and_payment_information_cannot_be_viewed: 'Order is invalid, checking payment information is unavailable.',
        The_complaint_order_has_been_processed: 'Order complaint is processed.',
        The_order_has_been_appealed: 'Order complaint is completed.',
        Please_ensure_that_your_contact_information_is_unblocked_to_facilitate_customer_service: 'The order is under appeal, please ensure that your contact information is unblocked, which is convenient to contract you.',
        Order_Pending: 'Your order is under appeal.',
        Order_appeals: 'Complain the order',

        // 0918补充
        Activity_reward: 'Activity rewarding',
        Participation_in_GOOD_community_activities_Award: 'Rewarding for participating in GOOD community activity',
        Thank_you_for_your_support_and_concern: 'Thanks a lot for supporting and focusing on GOOD. In order to reward our users, rewarding plan of holding GOOD is started from now on. All GOOD user can voluntarily participate in locked-position activity with 30% GOOD feedback.',
        Participation_time: 'Activity duration',
        From_now_on_to_GOOD_main_network_formally_launched: 'until GOOD main net officially launched',
        Conditions_of_participation: 'Conditions of participation',
        Good_advanced_certified_users: '1）users who complete senior verification; 2) no less than 10000 GOOD; 3) locked for 10 months.',
        Incentive_conditions: 'Condition of getting feedback',
        Users_can_apply_for_the_lock_up_bonus_activities: 'Users can appeal locked-position feedback on GOOD client and will get 30% GOOD of locked-position amount as feedback',
        Activity_reward_scheme: 'Scheme of activity rewarding',
        Reward_start_time: 'Reward starts time',
        Since_the_date_of_GOOD_formally_launched: 'The day when GOOG main net officially launched',
        Automatic_release: 'Automatic release',
        Accelerated_release: 'Accelerating release',
        User_account_for_the_month_GOOD_settlement: 'Part of GOOD will be released in advance. When using GOOD for settlement method, there will be service charge and you will get accelerating released GOOD which market value equals to 30% of total service charge. Service charge will be settled monthly.',
        For_more_information_please_login_to_the_official_website_or_APP: 'Please visit official website or GOOD APP for more information.',
        Official_website: 'Official Website',
        The_ultimate_right_of_interpretation_of_this_activity_belongs_to_Good_Foundation: 'Good Foundation reserves the right for final interpretation of this activity.',
        I_agree_that_the_content_has_been_clearly_understood: 'I agree. I have clearly understood the contents and I am willing to participate in this activity.',
        Application_for_participation: 'Appeal for participation',
        Participation_volume: 'Participation Volume',
        Submission_Apply: 'Submit',
        Insufficient_number_of_participants: 'Less than minimum participation volume',
        Please_enter_your_account_number_when_registering: 'Please enter registered account number.',
        Please_enter_a_mailbox_account_or_cell_phone_number: 'Please enter email or telephone number.',
        Mobile_registration: 'Register by telephone number',
        Mailbox_registration: 'Register by email number',
        Please_enter_a_mailbox_account: 'Please enter email number',
        Mailbox_verification_code: 'Email verification code',
        Please_enter_SMS_verification_code: 'Please enter SMS verification code',
        Please_enter_the_mailbox_verification_code: 'Please enter email verification code',
        Please_enter_a_mailbox_account: 'Please enter email account number',
        Please_input_the_correct_mailbox_account: 'Please enter correct format email account number',
        Revoke_a_complaint: 'Cancel appealing',
        Upload_please_wait_a_moment: 'Uploading, wait a second please',
        Have_in_hand: 'In progress',
        Expired: 'Invalid',
        Completion_of_the_complaint: 'Complaint completed',
        Order_time: 'Time of placing an order',
        Transaction_user_status: 'Transaction customer status',
        Acceptances_status: 'Underwriter status',
        Transaction_user_complaint_status: 'Transaction customer appealing status',
        Acceptances_state_of_complaint: "Underwriter appealing status",
        Time_of_payment: 'Payment time',
        Completion_time: 'Completed time',
        Cancellation_of_time: 'Cancellation time',
        Expired_time: 'Failure time',
        Appeals_time: 'Appealing time',
        Receivables: 'Receipted',
        Unreceivable: 'Unreceipted',
        Put_money: 'Complete to put coin',
        Money_received: 'Complete to withdraw coin',
        Mandatory_currency: 'Compel to put coin',
        forced_cancellation: 'Compel to cancel',
        Not_appealing: 'Not appeal yet',
        Automatic_cancellation_after: 'It will be canceled automatically after',
        Can_not_exceed_the_maximum_order_quantity: 'No more than maximum order amount.',
        The_advertisement_is_out_of_date_Please_re_select_the_order: 'This advertisement is invalid, please place an order again.',
        You_have_placed_the_order_waiting_for_the_buyer_to_pay: 'You have placed an order, please wait for buyer paying.',
        If_the_other_party_fails_to_pay_within_15_minutes_the_order_is_invalid: 'This order will be invalid if the counterpart fails to pay within 15 minutes.',

        // 0921补充
        For_the_sake_of_your_capital_security_please_use_your_own_bank_account: 'For the security of your funds, please use your bank account or other payment methods to make offline transfer. Click ‘confirm payment’ after completing the transfer of bank card or other payment method. Your order is invalid if you haven’t completed offline transfer and click ‘confirm payment’, which will be cancelled automatically if the payment isn’t confirmed within 15 minutes.',

        // 0925补充
        Warm_reminder_please_ensure_that_the_line_is_transferred_successfully_then_click_confirm_payment: 'Warm reminder: please ensure that the line is transferred successfully, then click confirm payment.',
        Are_you_sure_you_have_completed_the_payment_on_line: 'Are you sure you have completed the payment on line?',

        // 0927补充
        Please_complete_Verification_and_Senior_Verification_first: 'Please complete Verification and Senior Verification first.',
        Good_virtual_currency_system: 'Good virtual currency system',

        // 0928补充
        Need: 'Needful',
        Unwanted: 'Needless',
        Tips_Please_make_sure_that_you_have_paid_the_order_and_then_click_confirm_payment_or_the_order_will_be_invalid: 'Tips: Please make sure that you have paid the order and then click “confirm payment”, or the order will be invalid!',
        Are_you_sure_that_you_have_paid_the_order_successfully: 'Are you sure that you have paid the order successfully?',

        // 1012补充
        Senior_Verification_is_necessary: 'Senior Verification is necessary',
        Senior_Verification_is_unnecessary: 'Senior Verification is unnecessary',
        Please_click_Confirm_button_after_finishing_transaction_so_as_not_to_affect_your_next_transaction: 'Please click “Confirm” button after finishing transaction so as not to affect your next transaction.',
        This_advertisement_requires_senior_verification_please_complete_it_first: 'This advertisement requires senior verification, please complete it first.',
        Please_upload_Alipay_QR_code: 'Please upload Alipay QR code',
        Please_upload_WeChat_QR_code: 'Please upload WeChat QR code',

        // 挂牌融合后新增
        // 挂牌交易页面
        Current_Interests: 'Current Interests',
        Risk_Rate: 'Risk Rate',
        change_lows: 'change',
        Opening_Price: 'Opening Price',
        Closing_Price: 'Closing Price',
        time_line: 'time',
        Min: 'Min',
        Hour: 'Hour',
        Day: 'Day',
        Most_available_buy: 'Most available',
        Maximum_permissible_price_difference: 'Maximum permissible price difference',
        Advance_charge: 'Advance charge',
        Most_available: 'Most available',
        History_buying_orders: 'buying',
        History_selling_orders: 'selling',
        More: 'More',
        Piece: '',
        K_line_data: 'K-line',
        needs: 'Need',
        Please_log_in_and_trade: 'Please login in before trading',
        Please_enter_purchase_price_positive_number_of_pure_numbers: 'Please enter buying price (Positive number only)',
        Please_enter_the_number_of_purchases_positive_numbers_of_pure_numbers: 'Please enter buying amount (Positive number only)',
        The_maximum_quantity_can_be_ordered: 'Maximum available amount',
        The_minimum_quantity_can_be_ordered: 'Minimum available amount',
        Please_do_not_enter_numbers_less_than_zero: 'Please don’t enter the number less than zero',
        Please_enter_the_selling_price_the_positive_number_of_pure_numbers: 'Please enter selling price (Positive number only)',
        Please_enter_the_number_of_sales_the_positive_number_of_pure_numbers: 'Please enter buying amount (Positive number only)',
        Pens_and_detail: 'Order details',
        No_data: 'No more information',
        Please_enter_a_selling_price_of_not_less_than_zero: 'Please enter selling price, no less than zero',
        Sell_volume_should_not_be_less_than_zero: 'Selling amount can’t be less than zero',
        Please_enter_a_bid_price_of_not_less_than_zero: 'Please enter buying price, no less than zero',
        Purchase_volume_should_not_be_less_than_zero: 'Buying amount can’t less than zero',
        Amount_of_order_cannot_be_zero: 'Amount of order can’t be zero',

        // 挂牌资产状况页面
        Condition_of_listed_assets: 'Condition of listed assets',
        Main_block: 'Main block',
        Listed: 'Listed',
        Market: 'Market',
        Total_Amount_two: 'Total Amount',
        Transfer: 'Transfer',
        Identity_Verification: 'Identity Verification',
        From: 'From',
        main_board: 'main block',
        Main: 'Main',
        balance: 'balance',
        transfer_to: 'To',
        Choose_an_account: 'Choose an account',
        Available_amount: 'Available amount',
        Please_consent_to_Risk_Disclosure_before_trading: 'Please consent to Risk Disclosure before trading.',

        // 挂牌交易资产账户页面
        The_current_balance: 'The current balance',
        Asset_account: 'Asset account',
        Prepayment: 'Occupied advance charge',
        Available_prepayment: 'Available advance charge',
        Real_time_price_difference: 'Real-time price difference',
        Frozen_advance_payment: 'Freeze advance charge',
        Freezing_fee: 'Freeze charge',

        // 挂牌资金流水页面
        Capital_flow: 'Detailed list of capitals',
        Complete: 'Completed',
        Transfer_to_a_record: 'Transfer-in records',
        Turn_out_record: 'Transfer-out records',
        All_records: 'All records',

        // 挂牌交易账户页面
        mid_long_term_transactions: 'mid-long-term transactions',
        microdisk: 'microdisk',
        Remarks_required_short: 'Remarks(Compulsory)',
        Please_choose_currencies: 'Choose',
        Balance_is: 'Balance is',
        Please_select_an_account: 'Please choose an account',
        Transferable_quantity: 'Transferable amount',
        Please_input_quantity_of_charge: 'Please enter deposit amount',
        Please_input_quantity: 'Please enter amount',
        Successful_transfer: 'Transfer completely',
        Successful_transfer2: 'The request is successful',
        // 挂牌交易账户资产财务记录页面
        Transaction_record: 'Transaction records',
        Transfer_credentials: 'Transfer credential',
        Registered_account: 'Listed account',

        // 挂牌账户页面
        Mutual_transfer_of_funds: 'Transfer funds mutually',
        Draw_money_to_your_wallet: 'Please transfer to wallet before withdrawing',
        Turn_to_success: 'Transfer-in completely',
        Transfer_failed_Please_try_again_later: 'Fail to transfer-in, please try again later',
        Turn_out_to_be_successful: 'Transfer-out completely',
        Turn_out_failure_Please_try_again_later: 'Fail to transfer-out, please try again later',
        wallet: 'Wallet',
        Listed_assets_status: 'Listed assets situation',
        Option_transaction: 'Option',

        // 挂牌快捷平仓
        Quick_and_flat: 'Close position efficiently',
        contract: 'Contract',
        Current_price: 'Current price',
        Success: 'Completed',

        // 挂牌成交查询
        Listing_name: 'Listed name',
        open_a_granary_to_provide_relief: 'Open position',
        Close_a_position: 'Close position',
        Flat_price_difference: 'Price difference of closing position',
        Transaction_type: 'Deal type',
        Market_transaction: 'Struck a deal by market price',
        Limited_transaction: 'Struck a deal by limit price',
        Stop_loss_deal: 'Struck a deal by stop-loss',
        Close_business: 'Struck a deal by stop-profit',
        Strong_business: 'Struck a deal by compulsory closing position',
        Hedging: 'Hedging order',
        Receipt: 'Receipt order',

        // 挂牌平仓明细
        Flat_single_number: 'Order number of closing position',
        Listing_code: '挂牌代码',
        Flat_number: 'Amount of closing position',
        Exit_price: 'Price of closing position',
        Flat_type: 'Type of closing position',
        Jiancang_single_number: 'Order number of opening position',

        // 挂牌客户持货
        Buying_average_holding_price: 'Bought average position price',
        Buying_position: 'Bought holding position',
        Buying_quantity: 'Bought amount',
        Buy_floating_profit_and_loss: 'Bought floating profit and loss',
        Average_selling_price: 'Sold average position price',
        Selling_positions: 'Sold holding position',
        Selling_quantity: 'Sold amount',
        Sell_floating_profit_and_loss: 'Sold floating profit and loss',
        Customer_net_position: 'Users’ net position',
        Floating_profit_and_loss_aggregate: 'Floating profit and loss',

        // 挂牌委托查询
        Price_stop_price: 'Stop-profit price',
        Stop_price: 'Stop-loss price',
        Entrustment_mode: 'Entrustment method',
        Are_you_sure_you_want_to_cancel_the_order: 'Please make sure',

        // 挂牌交收部分
        Delivery: 'Delivery of currency',
        If_the_price_difference_is_filled_out_please_fill_in_the_price_difference_greater_than_zero: 'Worry price difference, please enter positive number',
        Delivery_time: 'Time of currency delivery',
        Money_of_payment: 'Number of currency delivery',
        Amount_of_delivery: 'Amount of currency delivery',
        Settlement_fee: 'Charge of currency delivery',

        // 挂牌持仓明细
        market_price: 'Market',
        Fixed_price: 'Limit',
        Stop_loss: 'Stop-loss',
        target_profit: 'Stop-profit',
        Coercion: 'Compulsory closing position',
        Withdrawal_failed_Please_try_again_later: 'Fail to cancel the order, please try again later',
        Equal_stock_price: 'Average price of holding position',
        Holding_number: 'Amount of holding position',
        Floating_P_L: 'Floating profit and loss',
        Advance_payment_in_position: 'Advance charge',
        Building_time: 'Time of opening position',
        Building_price: 'Price of opening position',
        Quantity_of_warehouse: 'Amount of opening position',
        Holding_price: 'Price of holding position',
        Prepayment_coefficient: 'Advance charge coefficient',
        Advance_payment_rate: 'Rate of Advanced charge',
        Stop_get_and_stop_loss: 'Stop-profit and stop-loss',
        Listing: 'Listed',
        Stop_or_stop_price_is_not_set_correctly: 'Setting stop-profit or stop-loss price is wrong',
        Please_fill_in_the_price_of_stop_or_stop_loss: 'Please enter stop-profit or stop-loss price',

        // 挂牌委托查询、成交查询、持仓明细、持仓汇总、交收查询标题
        Entrustment_inquiry: 'Entrustments inquiry',
        Entrustment_detail: 'Entrustment detail',
        Transaction_inquiries: 'Transaction inquiry',
        Holding_details: 'Position details',
        Holding_summary: 'All holding position',
        Customer_holding: 'Users’ holding position',
        Intersection_query: 'Receipt checking',

        // 挂牌资料
        Listing_information: 'Listed information',
        Listing_state: 'Listed condition',
        Minimum_variable_price: 'Minimum price change',
        Prepayment_algorithm: 'Advance charge algorithm',
        Transaction_fee_algorithm: 'Service fees algorithm of trading',
        Warehouse_handling_fee_coefficient: 'Service fees coefficient of opening position',
        Closing_fee_coefficient: 'Service fees coefficient of closing position',
        Single_minimum_quantity: 'Minimum entrustment per order',
        The_maximum_quantity_of_a_single_pen_can_be_commissioned: 'Maximum entrustment per order',
        Maximum_position: 'Maximum amount of holding position',
        Market_position_authorization: 'Limit of opening position at market price',
        Market_clearing_authority: 'Limit of closing position at market price',
        Surplus_stop_loss_authority: 'Limit of stop-profit and stop-loss',
        Yes: 'Have',
        No:'no',
        Stop_loss_order_price_difference: 'Stop-loss order price difference',
        Profit_margin: 'Stop-profit order price difference',
        Price_fixing_price_difference: 'price difference of limiting opening position',
        Order_price_difference_default_value: 'Default value of order price difference',
        Overnight_fee: 'Overnight fee',
        Unlisted: 'Unlisted',
        Already_listed: 'Listed',
        Already_out_of_the_market: 'Delisted',
        Proportion: 'Rate',
        Fixed_value: 'Fixed value',
        Trading_Unit: 'Trading Unit',
        Trading_Unit_Name: 'Trading Unit Name',
        Volume_hand: 'Volume',

        // 个人中心增加
        Go_to_verify_again: 'Go to verify again',
        Examples: 'Examples',
        Please_enter_ID_number: 'Please enter ID number',
        Fail_to_pass_senior_identity_verification_please_try_again_later: 'Fail to pass senior identity verification, please try again later',
        Users_who_would_like_to_do_contract_transactions_on_this_site_need_to_complete_their_identity_verification: 'Users who would like to do contract transactions on this site need to complete their identity verification. Please provide true and valid ID photo of yourself. Users should be responsible for all the consequences of false verification.',
        Requirements_ID_photo_is_clear_and_its_size_should_be_no_more_than_3M: 'Requirements：ID photo is clear and its size should be no more than 3M.',

        // otc交易补充
        You_can_add_friends_through_WeChat: 'You can connect us by WeChat',
        Please_confirm_that_you_have_paid_seller: 'Please confirm that you have paid seller?',
        Important_Note: 'Important Note!',
        You_need_to_pay_seller_through_cyberbank_Alipay_or_other_method_and: 'You need to pay seller through cyberbank, Alipay or other method and click ‘confirm to pay’ after payment, otherwise the order will be invalid!',
        Appealing_order_number: 'Appealing order number',

        // v1.1.0补充 及 中远期
        Profit_and_loss_of_closing_position: 'Profit and loss of closing position',
        Net_profit_and_loss_of_holdings: 'Net profit and loss of holdings',
        Gross_profit_and_loss_of_hedging: 'Gross profit and loss of hedging',
        Initial_rights_and_interests: 'Initial rights and interests',
        Delegation_history: 'Delegation history',
        Transaction_history: 'Transaction history',
        Contract_name: 'Contract name',
        Contract_status: 'Contract status',
        Minimum_unit_of_changeable_amount: 'Minimum unit of changeable amount',
        Demurrage_algorithm: 'Demurrage algorithm',
        Demurrage_coefficient: 'Demurrage coefficient',
        Order_pan: 'Order',
        Buying_Order_pan: 'Buying Order',
        Selling_order_pan: 'Selling order',
        Minimum_charging_sum_SWTC_You_will_be_charged: 'Minimum charging sum: 20 SWTC You will be charged 20SWTC when activate charge address firstly Examples: When you charge 100 SWTC for the first time, actual arrival amount is 80 SWTC',
        Available_withdrawing_amount: 'Available withdrawing amount',
        History_records: 'History records',
        Asset_status: 'Asset status',
        Mid_long_term_asset_status: 'Mid-long-term asset status',
        Available_amount_buy: 'Available amount',
        Available_amount_sell: 'Available amount',
        Verification_code_error: 'Verification code error',
        Exhibition_or_not: 'Exhibition or not',
        Backhand_commission: 'Backhand commission',
        Proprietary_members_are_not_allowed_to_trade: 'Proprietary members are not allowed to trade',
        lease_confirm_that_the_payment_information_is_accurate: 'lease confirm that the payment information is accurate',
        

        contact_us_2: 'Telegram',
        contact_us_3: 'Service connect information',
        //申购
        purchase1:'purchase',
        placement1:'placement',
        //手机APP安装流程
        APP:'APP',
        
        //融合挂牌
        Opening_price_name:'Opening price',
        Please: 'Please',
        Query: 'Query',
    },
    ...enLocale
}
export default en;
