import store from '@/store/index'

const firmId = Number(store.state.common.sessionInfo.firmId)
export default {
  // 仓单管理/展示/搜索
  param_findWarehouseOrders: {
    url: '/base-frontquerywarehouse/queryFrontWarehouse/findWarehouseOrders',
    param: {
      // 'applyWarehouseId': null, // 入库单
      // 'categoryId': null,
      // 'storageId': null,
      // 'applyHoldStatus': null,
      // 'page': null,
      // 'size': null,
      // 'sort': [
      //   {
      //     'direction': '',
      //     'property': ''
      //   }
      // ]
    }
  },
  // 查询入库单
  param_searchDelivery: {
    url: '/base-frontquerywarehouse/queryFrontWarehouse/searchWarehouse',
    param: {
      // 'applyWarehouseId': null,
      // 'categoryId': null,
      // 'applyHoldStatus': null, // 申请仓单状态W：待入库Y：已入库T：质检通过F：质检不通过D：已提走U：已撤销
      // 'auditStatus': null, // 审核状态W：待审核T：审核通过F：审核不通过
      // 'inspectionStatus': null, // 质检状态W：未质检T：已送检
      // 'page': null,
      // 'size': null,
      // 'sort': [
      //   {
      //     'direction': '',
      //     'property': ''
      //   }
      // ]
    }
  },
   // 查询商品分类列表
   param_queryTemplateGroup: {
    url: '/base-frontquerywarehouse/capacity/queryTemplateGroup',
    param: {
      'applyWarehouseId': null,
      'buyFirmId': null,
      'commodityId': null,
      'commodityName': '',
      'deliveryId': null,
      'endTime': null,
      'firmId': null,
      'fstatus': [],
      'goodsGroupClassId': null,
      'grade': '',
      'id': null,
      'isFinish': [],
      'openTicketStatus': [],
      'operatorFirmId': null,
      'orderId': null,
      'orderStatus': [],
      'page': null,
      'payStatus': [],
      'pickOrderId': null,
      'pickStatus': [],
      'pickType': [],
      'sellFirmId': null,
      'size': null,
      'sort': [
        {
          'direction': '',
          'property': ''
        }
      ],
      'sourceId': [],
      'startTime': null,
      'status': [],
      'statusStr': [],
      'supplyCommodityId': null,
      'supplyId': null,
      'type': null,
      'typeInList': [],
      'typeList': [],
      'typeStr': '',
      'unitId': null,
      'variety': '',
      'warehousId': null,
      'warehouse': '',
      'weightMax': null,
      'weightMin': null,
      'wfirmId': null
    }
  },
  // 企业经营信息查询(下拉框)
  param_getInformation: {
    url: '/base-frontquerywarehouse/goodsClass/getInformation',
    param: {
      'userId': 0
    }
  },
  // 仓库列表查询
  param_getStorage: {
    url: '/base-frontquerywarehouse/storage/getStorage',
    param: {
    }
  },
  // 提货查询
  param_takeDelivery: {
    url: '/base-frontquerywarehouse/queryDelivery/searchDelivery',
    param: {
      'deliveryId': 0, // 编号
      'page': 0,
      'size': 0,
      'sort': [
        {
          'direction': '',
          'property': ''
        }
      ]
    }
  },
  // 重量单位列表查询(仓库绑定)
  param_findWeightUnit: {
    url: '/base-frontquerywarehouse/queryDelivery/findWeightUnit',
    param: {
      'page': 0,
      'roleId': 0, // 角色id
      'roleType': 0, // 角色类型
      'size': 10,
      'sort': [
        {
          'direction': '',
          'property': ''
        }
      ],
      'storageId': 0 // 仓库id
    }
  },
  // 单位列表查询
  param_findUnit: {
    url: '/base-frontquerywarehouse/queryDelivery/findUnit',
    param: {
      'page': 0,
      'size': 10,
      'sort': [
        {
          'direction': '',
          'property': ''
        }
      ],
      'unitId': null
    }
  },

  // get
  // 获取商品分类树
  param_getGoodsClassTree: {
    url: '/base-frontquerywarehouse/goodsClass/getGoodsClassTree',
    param: {
    }
  },

  // 添加入库申请单
  param_addWarehouse: {
    url: '/base-warehousereceiptfront/warehouseApplication/addWarehouse',
    param: {
      // 'applyWarehouseId': 0, // 申请入库id(修改时传)
      // 'batch': 0, // 批次[申请和补入]
      // 'brand': '', // 品牌
      // 'brandId': 0, // 品牌编号
      // 'categoryId': 0, // 商品品种id[申请]
      // 'commodityName': '', // 商品名称
      // 'deliveryId': 0, // 提货申请编号
      // 'executionStandard': '', // 执行标准
      // 'fillType': '', // 补入方式类型T：部分F：全部[补入]
      // 'holdId': 0, // 持仓id[补入]
      // 'img': '', // 图片[申请]
      // 'manufacturer': '', // 厂家[申请]
      // 'note': '', // 备注[申请和补入]
      // 'outWarehousDay': 0, // 生产日期[申请和补入]
      // 'packagId': 0,
      // 'placeOrigin': '', // 产地
      // 'poundsPoor': 0, // 磅差[申请]
      // 'putWarehousTime': 0,
      // 'quantity': 0,
      // 'specId': 0,
      // 'specTemplate': {},
      // 'specificationModel': {},
      // 'specificationsId': 0,
      // 'storageId': 0,
      // 'type': 0,
      // 'unit': '',
      // 'userName': '',
      // 'userPhone': '',
      // 'weight': 0,
      // 'weightUnitId': 0
    }
  },
  // 撤销入库申请单
  param_deleteWarehouse: {
    url: '/base-warehousereceiptfront/warehouseApplication/deleteWarehouse',
    param: {
      'applyWarehouseId': null // 入库单编号
    }
  },
  // 修改入库申请单
  param_updateWarehouse: {
    url: '/base-warehousereceiptfront/warehouseApplication/updateWarehouse',
    param: {
      // 'applyWarehouseId': 0,
      // 'batch': 0,
      // 'brand': '',
      // 'brandId': 0,
      // 'categoryId': 0,
      // 'commodityName': '',
      // 'deliveryId': 0,
      // 'executionStandard': '',
      // 'fillType': '',
      // 'holdId': 0,
      // 'img': '',
      // 'manufacturer': '',
      // 'note': '',
      // 'outWarehousDay': 0,
      // 'packagId': 0,
      // 'placeOrigin': '',
      // 'poundsPoor': 0,
      // 'putWarehousTime': 0,
      // 'quantity': 0,
      // 'specId': 0,
      // 'specTemplate': {},
      // 'specificationModel': {},
      // 'specificationsId': 0,
      // 'storageId': 0,
      // 'type': 0,
      // 'unit': '',
      // 'userName': '',
      // 'userPhone': '',
      // 'weight': 0,
      // 'weightUnitId': 0
    }
  },
  // 用户确认
  param_userConfirm: {
    url: '/base-warehousereceiptfront/warehouseApplication/userConfirm',
    param: {
      'applyWarehouseId': null // 入库单编号
    }
  },
  // 提货申请
  param_addDelivery: {
    url: '/base-warehousereceiptfront/delivery/addDelivery',
    param: {
      // 'auditStatus': '',
      // 'commodityId': 0,
      // 'consigneeAddr': '',
      // 'consigneeName': '',
      // 'consigneePhone': '',
      // 'createTime': 0,
      // 'deliveryFee': 0,
      // 'deliveryId': 0,
      // 'deliveryQuantity': 0,
      // 'deliveryStatus': '',
      // 'deliveryWeight': 0,
      // 'expressName': '',
      // 'expressNo': '',
      // 'shipTime': 0,
      // 'updateTime': 0,
      // 'userId': 0,
      // 'warehousId': 0
    }
  },
  // 修改提货申请
  param_updateDelivery: {
    url: '/base-warehousereceiptfront/delivery/updateDelivery',
    param: {
      // 'auditStatus': '',
      // 'commodityId': 0,
      // 'consigneeAddr': '',
      // 'consigneeName': '',
      // 'consigneePhone': '',
      // 'createTime': 0,
      // 'deliveryCar': '',
      // 'deliveryCard': '',
      // 'deliveryFee': 0,
      // 'deliveryId': 0,
      // 'deliveryQuantity': 0,
      // 'deliveryStatus': '',
      // 'deliveryWeight': 0,
      // 'expressName': '',
      // 'expressNo': '',
      // 'shipTime': 0,
      // 'updateTime': 0,
      // 'userId': 0,
      // 'warehousId': 0
    }
  },
  // 撤销提货
  param_deleteDelivery: {
    url: '/base-warehousereceiptfront/delivery/deleteDelivery',
    param: {
      'deliveryId': null // 编号
    }
  },
   // 新增收货地址
   param_saveUserAddress: {
    url: '/base-address/address/saveUserAddress',
    param: {
      'addressName': '', // 收货地址
      'defaultFlag': 0, // 是否设置为默认收获地址 (0:否 1:是)
      'postCode': '', // 邮政编码
      'recipients': '', // 收货人
      'recipientsCellphone': '', //	收货人电话
      'region': '' // 地区(前端维护辅助字段)
    }
  },
  // 编辑收获地址
  param_updateUserAddress: {
    url: '/base-address/address/updateUserAddress',
    param: {
      'addressName': '', // 收货地址
      'defaultFlag': 0, // 是否设置为默认收获地址 (0:否 1:是)
      'postCode': '', // 邮政编码
      'recipients': '', // 收货人
      'recipientsCellphone': '', //	收货人电话
      'region': '' // 地区(前端维护辅助字段)
    }
  },
  // 删除收货地址
  param_deleteUserAddress: {
    url: '/base-address/address/deleteUserAddress',
    param: {
      addressId: null // 收货地址表主键
    }
  },
  // 查询收获地址
  param_getUserAddress: {
    url: '/base-address/address/getUserAddress',
    param: {
      // addressId: null, // 收货地址表主键 false
      // defaultFlag: 0, // 是否设置为默认收获地址 (0:否 1:是) false
    }
  },
  // 会员竞拍商品列表
  param_getMemberCommodity: {
    'url': 'auction-trade-search/tradeSearch/getMemberCommodity',
    'param': {
      'commodityId': null,
      'page': null,
      'size': null,
      'startTime': null,
      'endTime': null,
      'sort': [{
        'direction': 'DESC',
        'property': 'auctionStartTime'
      }]
    }
  },
  // 首页会员竞拍商品列表
  param_getMemberCommodityIndex: {
    'url': 'auction-trade-search/tradeSearch/getMemberCommodityIndex',
    'param': {
      'commodityId': null,
      'page': null,
      'size': null,
      'startTime': null,
      'endTime': null,
      'sort': [{
        'direction': 'DESC',
        'property': 'auctionStartTime'
      }]
    }
  },
  // 会员竞拍商品时间获取
  param_getDateList: {
    'url': 'auction-trade-search/tradeSearch/getDateList',
    'param': {}
  },
  // 领拍商品分类列表
  param_getTakenTypeList: {
    'url': 'auction-trade-search/tradeSearch/getTakenTypeList',
    'param': {}
  },
  // 领拍商品查询
  param_getTakenList: {
    'url': 'auction-trade-search/tradeSearch/getTakenList',
    'param': {
      'commodityName': null,
      'commodityId': null,
      'commodityCategory': null,
      'page': null,
      'size': null,
      'sort': [{
        'direction': 'DESC',
        'property': 'startTime'
      }]
    }
  },
  // 领拍拍品查询
  param_getGetItemCommodity: {
    'url': 'auction-trade-search/tradeSearch/getGetItemCommodity',
    'param': {
      'itemId': null,
    }
  },
  // 商品轮播图
  param_querySlideshow: {
    'url': 'auction-trade-search/tradeSearch/querySlideshow',
    'param': {
      'commodityId': null
    }
  },
  // 新人竞拍商品列表查询
  param_getNewPeCommodity: {
    'url': 'auction-trade-search/tradeSearch/getNewPeCommodity',
    'param': {
      'commodityId': '', // 商品id
      'page': '0',
      'size': '10',
      'sort': [{ direction: 'DESC', property: 'auctionStartTime' }] // 排序相关信息
    }
  },
  // 全民竞拍商品列表查询
  param_getAllPeAuction: {
    'url': 'auction-trade-search/tradeSearch/getAllPeAuction',
    'param': {
      'commodityId': '', // 商品id
    }
  },
  // 首页搜索
  param_getAuctionItem: {
    'url': 'auction-trade-search/tradeSearch/getAuctionItem',
    'param': {
      'commodityName': null,
      'page': null,
      'size': null
      // 'sort': [{
      //   'direction': 'DESC',
      //   'property': 'commodityName'
      // }]
    }
  },
  // 出价查询记录
  param_getOfferPriceSum: {
    'url': 'auction-trade-search/tradeSearch/getOfferPriceSum',
    'param': {
      'itemId': '',
      'page': '0',
      'size': '10',
      'sort': [{
        'direction': 'DESC',
        'property': 'addTimes'
      }]
    }
  },
  // 我的订单 拍中的商品、特卖专区的商品可以因为质量问题换货，不能退；普通商城的商品未发货时可以退货（收到货是否还能退货客户未确定），积分商品、PLUS的商品不能退换货
 // 订单类型：1-积分订单，2-特卖订单，3-新人竞拍，4-领拍订单，5-全民竞拍订单，6-会员竞拍，7-PLUS订单 8-普通商城
  param_myOrders: {
    'url': 'auction-order/front/myOrders',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10,
      'status': 2
    }
  },
  // 新增订单
  param_addOrder: {
    'url': 'auction-order/front/addOrder',
    'param': {
      'firmId': firmId,
      'commodityId': null, // 商品id
      'itemId': null, // 拍卖物品id
      'orderType': null, // 订单类型
      'price': null,
      'commoditySpec': '' // 商品规格
    }
  },
  // 确认收货
  param_confirmReceipt: {
    'url': 'auction-order/front/confirmReceipt',
    'param': {
      'firmId': firmId,
      'orderCode': '' // 订单号
    }
  },
  // 取消订单
  param_orderCancel: {
    'url': 'auction-order/front/orderCancel',
    'param': {
      'firmId': firmId,
      'orderCode': '' // 订单号
    }
  },
  // 售后
  param_postSaleOrders: {
    'url': 'auction-order/front/postSaleOrders',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10
    }
  },

  // 换货
  param_exchangeGoods: {
    'url': 'auction-order/front/exchangeGoods',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 撤销换货
  param_cancelExchangeGoods: {
    'url': 'auction-order/front/cancelExchangeGoods',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 撤销退货
  param_cancelReturnGoods: {
    'url': 'auction-order/front/cancelReturnGoods',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 换货详情
  param_exchangeGoodsDetails: {
    'url': 'auction-order/front/exchangeGoodsDetails',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 退货详情
  param_returnGoodsDetails: {
    'url': 'auction-order/front/returnGoodsDetails',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 换货邮寄
  param_sendOutExchangeGoods: {
    'url': 'auction-order/front/sendOutExchangeGoods',
    'param': {
      'firmId': firmId,
      'orderCode': '',
      'senderPhone': '',
      'trackName': '',
      'trackNum': ''
    }
  },
  // 退货邮寄
  param_sendOutReturnGoods: {
    'url': 'auction-order/front/sendOutReturnGoods',
    'param': {
      'firmId': firmId,
      'orderCode': '',
      'senderPhone': '',
      'trackName': '', // 寄件公司名
      'trackNum': '' // 寄件单号
    }
  },
  // 查看当前订单
  param_findCurrentOrder: {
    'url': 'auction-order/front/findCurrentOrder',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 查看已拍到的商品
  param_findGoodsBought: {
    'url': 'auction-order/front/findGoodsBought',
    'param': {
      'firmId': firmId
    }
  },

  // 退货退款
  param_returnGoods: {
    'url': 'auction-order/front/returnGoods',
    'param': {
      'firmId': firmId,
      'orderCode': ''
    }
  },
  // 编辑当前订单
  param_updateCurrentOrder: {
    'url': 'auction-order/front/updateCurrentOrder',
    'param': {
      'firmId': firmId,
      'orderId': '',
      'status': null // 订单状态
    }
  },
  // 咨询列表
  param_content: {
    'url': 'content/content/content',
    'param': {
      'columnId': 1, // 1 资讯 2,首页轮播图 3 会员竞拍轮播图 4新人竞拍轮播图 5 领拍轮播图
      'page': 0,
      'size': 10,
      'direction': 'DESC',
      'property': 'createTime'
      // 'sort': [{
      //   'direction': 'DESC',
      //   'property': 'createTime'
      // }]
    }
  },
  // 获取资讯内容
  param_getContent: {
    'url': 'content/content/getContent',
    'param': {
      'id': null
    }
  },
  // 查询奖励金数
  param_getRewardSum: {
    'url': 'auction-award/award/getRewardSum',
    'param': {
      'firmId': firmId
    }
  },
  // 查询历史已返奖励流水
  param_findRewardHList: {
    'url': 'auction-award/award/findRewardHList',
    'param': {
      'firmId': firmId,
      'endTime': null,
      'startTime': null,
      'page': 0,
      'size': 10,
      'sort': [{
        'direction': 'DESC',
        'property': 'occurTime'
      }]
    }
  },
  // 查询当日已返奖励流水
  param_findRewardList: {
    'url': 'auction-award/award/findRewardList',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10,
      'sort': [{
        'direction': 'DESC',
        'property': 'occurTime'
      }]
    }
  },
  // 查询历史待返奖励流水
  param_findRewardHoldHList: {
    'url': 'auction-award/award/findRewardHoldHList',
    'param': {
      'firmId': firmId,
      'endTime': null,
      'startTime': null,
      'page': 0,
      'size': 10,
      'sort': [{
        'direction': 'DESC',
        'property': 'rewardTime'
      }]
    }
  },
  // 查询当日待返奖励流水
  param_findRewardHoldList: {
    'url': 'auction-award/award/findRewardHoldList',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10,
      'sort': [{
        'direction': 'DESC',
        'property': 'rewardTime'
      }]
    }
  },
  // 报名
  param_addApply: {
    'url': 'auction-apply/apply/addApply',
    'param': {
      'firmId': firmId, // 用户
      'itemId': ''
    }
  },
  // 查询用户报名的竞拍商品id
  param_selectFirmApplyCommodity: {
    'url': 'auction-apply/apply/selectFirmApplyCommodity',
    'param': {
      'firmId': firmId // 用户
    }
  },
  // 积分商城商品列表
  param_getIntegralGoods: {
    'url': 'service-integral-query/goods/getIntegralGoods',
    'param': {
      // 'direction': 'DESC',
      // 'property': 'commodityId'
      // 'page': 10,
      // 'size': 0,
      // 'sort': [{
      //   'direction': 'DESC',
      //   'property': 'commodityId'
      // }]
    }
  },
  // 积分商城商品详情
  param_getIntegralGoodsDetail: {
    'url': 'service-integral-query/goods/getIntegralGoodsDetail',
    'param': {
      'integralCommodityId': null
    }
  },
  // 获取商品规格
  param_getIntegralGoodsSpecByGoodsId: {
    'url': 'service-integral-query/goods/getIntegralGoodsSpecByGoodsId',
    'param': {
      'integralCommodityId': null
    }
  },
 
  // 出价 post
  // param_offer: {
  //   'url': 'auction-trade/trade/offer',
  //   'param': {
  //     'itemId': '', // 拍卖物品id integer(int32)
  //     'offerPrice': '' // 本次出价 String
  //   }
  // },
  /** ************** 商城相关start ********************/
  // 获取商品分类树(商城)
  // param_getGoodsClassTree: {
  //   'url': 'service-mall-query/goodsClass/getGoodsClassTree',
  //   'param': {
  //   }
  // },
  // 商城,查询已上架商品 post
  param_getMallGoods: {
    'url': 'service-mall-query/goods/getMallGoods',
    'param': {
      'classId': null,
      'commodityName': '',
      'page': 0,
      'size': 10,
      'sort': '' // 排序相关的信息，以`property[,ASC|DESC]`的方式组织，例如`sort=firstname&sort=lastname,desc`表示在按firstname正序排列基础上按lastname倒序排列。
    }
  },
  // 升级plus 查询已上架商品 get
  param_getPlusGoods: {
    'url': 'service-mall-query/goods/getPlusGoods',
    'param': {
      'page': 0,
      'size': 10,
      // 'sort': '' // 排序相关的信息，以`property[,ASC|DESC]`的方式组织，例如`sort=firstname&sort=lastname,desc`表示在按firstname正序排列基础上按lastname倒序排列。
    }
  },
  // 特卖专区 查询已上架商品  get
  param_getSpecialGoods: {
    'url': 'service-mall-query/goods/getSpecialGoods',
    'param': {
      'page': 0,
      'size': 10,
      // 'sort': '' // 排序相关的信息，以`property[,ASC|DESC]`的方式组织，例如`sort=firstname&sort=lastname,desc`表示在按firstname正序排列基础上按lastname倒序排列。
    }
  },
  // 根据commodituId 获取商品详情 /service-mall-query/goods/getGoodsDetail
  param_getGoodsDetail: {
    'url': 'service-mall-query/goods/getGoodsDetail',
    'param': {
      'commodityId': ''
    }
  },
  // 根据commodituId 获取商品规格参数 适用于商城和特卖转区 /service-mall-query/goods/getGoodsSpecByGoodsId
  param_getGoodsSpecByGoodsId: {
    'url': 'service-mall-query/goods/getGoodsSpecByGoodsId',
    'param': {
      'commodityId': ''
    }
  },
  // 商城下单,生成订单 (特卖专区、PLUS、普通商城和积分商城)
  param_addMallOrder: {
    'url': 'service-mall-order/mallOrder/addMallOrder',
    'param': {
      'amount': 1,
      'commodityId': 1, // 商品id
      'consigneeId': 1, // 收货人id
      'firmId': firmId, // 用户id
      'commoditySpec': '', // 商品规格
   //   'payType': '' // 支付方式 1-Account，2-Alipay，3-Wechat，4-Integral
    }
  },
  /** ************** 商城相关end ********************/
  // 地址管理
  param_queryFirmAddress: {
    'url': 'auction-address/address/getFirmAddress',
    'param': {
      // 'id': firmId
      'addressId': null // 地址id
    }
  },
  // 添加地址
  param_addShippingAddress: {
    'url': 'auction-address/address/addFirmAddress',
    'param': {
      'firmId': firmId,
      'postcode': '', // 邮政编码
      'defaultCode': '', // 是否为默认 1默认 0非默认
      'address': '', // 地址
      'cellphone': '', // 联系方式
      'recipients': '', // 收货人
      'region': '' // 地址省市 
    }
  },
  // 修改地址
  param_updateShippingAddress: {
    'url': 'auction-address/address/updateFirmAddress',
    'param': {
      'id': '', // 地址id
      'postcode': '', // 邮政编码
      'defaultCode': '', // 是否为默认 1默认 2非默认
      'address': '', // 地址
      'cellphone': '', // 联系方式
      'recipients': '', // 收货人
      'region': '' // 地址省市
    }
  },
  // 删除地址
  param_delShippingAddress: {
    'url': 'auction-address/address/deleteFirmAddress',
    'param': {
      id: '' // 地址id
    }
  },

  /** ************** 商城相关end ********************/
  // 充值
  param_fundIn: {
    'url': 'auction-pay/pay/fundIn',
    'param': {
      'firmId': firmId,
      'amount': ''// 充值金额
    }
  },
  // 提现
  param_fundOut: {
    'url': 'auction-pay/pay/fundOut',
    'param': {
      'firmId': firmId,
      'amount': '', // 充值金额
      'pwd': ''// 密码
    }
  },
  // 添加银行卡
  param_saveBankCard: {
    'url': 'mine/fund/saveBankCard',
    'param': {
      'firmId': firmId,
      'bankCardNum': '', // 银行卡号
      'bankCellPhone': '' ,// 预留手机号
      'bankName': ''// 银行卡名称
    }
  },
  // 银行卡列表
  param_findBankCardList: {
    'url': 'mine/fund/findBankCardList',
    'param': {
      'firmId': firmId
    }
  },
  // 我的账户查询
  param_getFundSum: {
    'url': 'mine/fund/getFundSum',
    'param': {
      'firmId': firmId
    }
  },
  // 累计收益金额
  param_getInComeSum: {
    'url': 'mine/fund/getInComeSum',
    'param': {
      'firmId': firmId
    }
  },
  // 查询积分总额
  param_getIntegralSum: {
    'url': 'mine/fund/getIntegralSum',
    'param': {
      'firmId': firmId
    }
  },
  // 查询冻结保证金数
  param_getMarginSum: {
    'url': 'mine/fund/getMarginSum',
    'param': {
      'firmId': firmId
    }
  },
  // 设置支付密码
  param_setFundPwd: {
    'url': 'mine/fund/setFundPwd',
    'param': {
      'firmId': firmId,
      'pwd': ''
    }
  },
  // 修改支付密码
  param_updateFundPwd: {
    'url': 'mine/fund/updateFundPwd',
    'param': {
      'firmId': firmId,
      'oldPwd': '',
      'pwd': ''
    }
  },
  // 查询历史资金流水
  param_findFundHList: {
    'url': 'mine/fund/findFundHList',
    'param': {
      'firmId': firmId,
      'endTime': null,
      'startTime': null,
      'page': 0,
      'size': 10,
      'type': '',
      'sort': [{
        'direction': 'DESC',
        'property': 'occurTime'
      }]
    }
  },
  // 查询当日资金流水
  param_findFundList: {
    'url': 'mine/fund/findFundList',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10,
      'type': '',
      'sort': [{
        'direction': 'DESC',
        'property': 'occurTime'
      }]
    }
  },
  // 查询历史积分流水
  param_findIntegralHList: {
    'url': 'mine/fund/findIntegralHList',
    'param': {
      'firmId': firmId,
      'endTime': null,
      'startTime': null,
      'page': 0,
      'size': 10,
      'type': '',
      'sort': [{
        'direction': 'DESC',
        'property': 'occurTime'
      }]
    }
  },
  // 查询当日积分流水
  param_findIntegralList: {
    'url': 'mine/fund/findIntegralList',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10,
      'type': '',
      'sort': [{
        'direction': 'DESC',
        'property': 'occurTime'
      }]
    }
  },
  // 查询历史冻结流水
  param_findMarginHList: {
    'url': 'mine/fund/findMarginHList',
    'param': {
      'firmId': firmId,
      'endTime': null,
      'startTime': null,
      'page': 0,
      'size': 10,
      'sort': [{
        'direction': 'DESC',
        'property': 'createTime'
      }]
    }
  },
  // 查询当日冻结流水
  param_findMarginList: {
    'url': 'mine/fund/findMarginList',
    'param': {
      'firmId': firmId,
      'page': 0,
      'size': 10,
      'sort': [{
        'direction': 'DESC',
        'property': 'createTime'
      }]
    }
  },
  // 用户推荐信息
  param_getPromoteInfo: {
    'url': 'mine/userInfo/getPromoteInfo',
    'param': {

    }
  },
   // 获得证件类型下拉菜单
   param_getTypeDropList: {
    'url': 'mine/userInfo/getTypeDropList',
    'param': {

    }
  },
  // 获得实时会员推广列表 
  param_pageActive: {
    'url': 'mine/userInfo/pageActive',
    'param': {
      'page': 0,
      'size': 10,
      // 'sort': [{
      //   'direction': 'DESC',
      //   'property': 'createTime'
      // }]
    }
  },
    // 获得待会员激活推广列表  mine/userInfo/queryUserInfo
  param_pageNotActive: {
      'url': 'mine/userInfo/pageNotActive',
      'param': {
        'page': 0,
        'size': 10,
        // 'sort': [{
        //   'direction': 'DESC',
        //   'property': 'createTime'
        // }]
      }
    },
     // 获得用户个人信息  
     param_queryUserInfo: {
      'url': 'mine/userInfo/queryUserInfo',
      'param': {
      }
    },
     // 修改个人信息
     param_updateUserInfo: {
      'url': 'mine/userInfo/updateUserInfo',
      'param': {
        'certificate': '', // 证件号码
        'certificateType': 1,//证件类型
        'nickname': '',//昵称
        'portrait':'' //头像
      }
    },
    // 支付密码是否设置查询
    param_findFundPwd: {
      'url':'mine/fund/findFundPwd',
      'param': {
        'firmId': firmId,
      }
    },
    // 上传图片
    param_file: {
      'url': 'upload/upload',
      'param': {
        'file': null,
        
      }
    },
  
  // 我的->我的竞拍
  // 我的竞拍 全民竞拍列表 auction-trade-search/mineItemSearch/getAllPeopleItemFirm
  param_getAllPeopleItemFirm: {
    'url': 'auction-trade-search/mineItemSearch/getAllPeopleItemFirm',
    'param': {
      // 'firmId': '',// 用户id(header)
      'orderStatusList': '', // 订单状态：1-待付款，2-待发货(已支付)，3-待收货(已发货)，4-已完成(已收货)，11-退款中，12-退款成功，13-退款失败，21-换货中，22-换货成功，23-换货失败，98-用户手动取消，99-已违约
      'page': '', // 第几页，从0开始，默认为第0页
      'size': '', // 每一页的大小，默认为10
      // 'sort': '' // 排序相关的信息，以`property[,ASC|DESC]`的方式组织，例如`sort=firstname&sort=lastname,desc`表示在按firstname正序排列基础上按lastname倒序排列。
    }
  },
  // 我的竞拍 我的竞拍列表 auction-trade-search/mineItemSearch/  getAuctionItemFirm
  param_getAuctionItemFirm: {
    'url': 'auction-trade-search/mineItemSearch/getAuctionItemFirm',
    'param': {
      'statusList': '', // 拍卖状态：1-未开始，2-拍卖中，3-完成拍卖，4-结拍中，9-流拍
      'isOrder': '', // 1-参拍中 未拍中,0-已拍中
      'page': '', // 第几页，从0开始，默认为第0页
      'size': '', // 每一页的大小，默认为10
      // 'sort': '' // 排序相关的信息，以`property[,ASC|DESC]`的方式组织，例如`sort=firstname&sort=lastname,desc`表示在按firstname正序排列基础上按lastname倒序排列。
    }
  },
  // 我的竞拍 我的领拍列表 auction-trade-search/mineItemSearch/getGetItemFirm
  param_getGetItemFirm: {
    'url': 'auction-trade-search/mineItemSearch/getGetItemFirm',
    'param': {
      'statusList': '', // 拍卖状态：1-未开始，2-拍卖中，3-完成拍卖，4-结拍中，9-流拍
      'isOrder': '', // 0-参拍中 未拍中,1-已拍中
      'page': '', // 第几页，从0开始，默认为第0页
      'size': '', // 每一页的大小，默认为10
      // 'sort': '' // 排序相关的信息，以`property[,ASC|DESC]`的方式组织，例如`sort=firstname&sort=lastname,desc`表示在按firstname正序排列基础上按lastname倒序排列。
    }
  },
  // 领拍
  param_getItem: {
    'url': 'auction-trade/trade/getItem',
    'param': {
      'endTime': '', // 拍卖结束时间
      'getItemId': '' // 领拍物品id
    }
  },
  // 订单服务 订单支付
  param_orderPayment: {
    'url': 'auction-order/front/orderPayment',
    'param': {
      'firmId': Number(firmId),
      'orderCode': null, // 订单号
      'consigneeId': null, // 收货人ID
      'payStyle': '' // 支付方式 fund，alipay，wechat
    }
  },
  // 获取支付方式
  param_getIshopDictPayTypeList: {
    'url': 'service-mall-query/payType/getIshopDictPayTypeList',
    'param': {}
  },
  // 查询微信下单状态
  param_getWechatPayStatus: {
    'url': 'service-mall-order/mallOrder/getWechatPayStatus',
    'param': {
      'outTradeNo': '' // ???
    }
  },
  // 生成支付宝订单
  param_createAlipay: {
    'url': 'alipay/alipay/create',
    'param': {
      'commodityName': '', // 订单商品分类 string
      'outTradeNo': '', // 订单编号（DD）string
      'totalPrice': '' // 订单金额 string
    }
  },
  // 用余额、积分支付 POST
  param_payOrder: {
    'url': 'auction-pay/pay/payOrder',
    'param': {
      'orderCode': '' // 订单编号 string
    }
  },
  // 微信支付
  param_wxPayOrder: {
    'url': 'pay-wechat/pay/wxPayOrder',
    'param': {
      'commodityName': '',
      'outTradeNo': '', // 订单编号 string
      'totalPrice': ''
    }
  },
  // 查询订单支付状态
  param_orderquery: {
    'url': 'pay-wechat/pay/orderquery',
    'param': {
      'outTradeNo': ''
    }
  },
  // *************** 产品预售 ***************//
  // 买家中心 -> 竞价求购 -> 新增竞价单
  param_marginBuy: {
    url: "/tclound-auction/publish/marginBuy",
    param: {
      addPrice: 0,// 加价幅度
      commodityId: 0,// 商品品种id
      businessName: "",// 联系人
      businessPhone: "",// 联系电话
      contactName: "",
      contactPhone: "",
      endTime: 0,// 竞拍结束时间
      image: "",// 商品图片
      memo: "",// 备注
      openTicketTime: 0,// 开票期限
      manufacturer: "",// 生产厂家
      poundsPoor: 0,// 磅差
      price: 0,// 单价
      quantity: 0,// 数量
      showTime: 0,// 公示开始时间
      startPrice: 0,// 起拍总价
      startTime: 0,// 竞拍开始时间
      weight: 0,// 重量
      minPickingWeight:null// 最小摘单重量
    }
  },
  // 卖家中心 -> 竞价资源 -> 新增资源单
  // 保证金竞卖
  param_marginSell: {
    url: "/tclound-auction/publish/marginSell",
    param: {
      addPrice: null, // 加价幅度
	    holdId: null,// 商品品种id
	    businessName: "",// 联系人
      businessPhone: "",// 联系电话
      contactName: "",
      contactPhone: "",
	    endTime: null,// 竞拍结束时间
	    image: null,// 商品图片
	    memo: null,// 备注  origin
	    openTicketTime: null,// 开票期限
	    manufacturer: null,// 生产厂家
	    price: null,// 单价
	    putWarehousTime: null,// 预计入库时间
	    quantity: null,// 数量
	    showTime: null,// 公示开始时间
	    startPrice: null,// 起拍总价
	    startTime: null,// 竞拍开始时间
      weight: null,// 重量
      decreasePrice:null,// 递减价格
      protectPrice:null,// 保护价格
      saleSecond:null,//递减金额
      saleList: [
        {
          salePrice:null,
          saleTime: null
        }
      ],

    }
  },
  // 注册仓单竞卖
  param_holdSell: {
    url: "/tclound-auction/publish/holdSell",
    param: {
      addPrice: 0, // 加价幅度
      auctionStyle:null,// 竞拍方式 1-普通，2-跌价式，3-大宗竞拍
	    contactName: "",// 联系人
      contactPhone: "",// 联系电话
      businessName: "",
      businessPhone: "",//
      endTime: 0,// 竞拍结束时间
      holdId: null,// 持仓id
	    image: "",// 商品图片
	    memo: "",// 备注
	    openTicketTime: 0,// 开票期限
	    price: 0,// 单价
	    showTime: 0,// 公示开始时间
	    startPrice: 0,// 起拍总价
      startTime: 0,// 竞拍开始时间
      decreasePrice:null,// 递减价格
      protectPrice:null,// 保护价格
      saleSecond:null,//递减金额
      saleList: [
        {
          salePrice:null,
          saleTime: null
        }
      ],
      //warehousId: 0,// 仓储id
      // putWarehousDay: "",//预计入库时间
    }
  },
  // 竞价报名
  param_signUp: {
    url: '/tclound-auction/trade/signUp',
    param: {
      itemId: null, // 拍卖物品id
      captchaId: "",
      code: "",
      marginPassWord: ""
    }
  },
  // 竞价出价
  param_offer: {
    url: '/tclound-auction/trade/offer',
    param: {
      itemId: null, // 拍卖物品id
      offerPrice: null, // 本次出价
      weight: null, // 重量 (分销出价,价格为单价，重量为此次购买的重量)
    }
  },
  // 竞价摘单确认 
  param_confirmBidOrder: {
    url: '/cbtrade/manage/three/confirmBidOrder',
    param: {
      "bidOrderId": 0,
	    "warehousId": 0
    }
  }
}
