import * as util from '../../assets/common/util.js';
import * as hqUtil from '../../assets/js/hq/hqUtil.js';
import { stat } from 'fs';

const state = {
    marketid: 0,
    openTime: 0,
    closeTime: 0,
    currentContractId: 0,
    timeLineData: [],
    kLineData: [],
    marketDetails: [],
    item: {},
    pricedetail: {},
    buyLst: [],
    sellLst: [],
    buyOne: [],
    sellOne: [],
    //挂牌
    lstMarketDetails: [],
    currencyData: {},
    pricedetailNon: {},
    PushPriceDetailResponseBuy: {},
    PushPriceDetailResponseSell: {},
    currentCommodityInfo: {},
    //中远期
    marketidMid: 0,
    openTimeMid: 0,
    closeTimeMid: 0,
    currentContractIdMid: 0,
    timeLineDataMid: [],
    kLineDataMid: [],
    marketDetailsMid: [],
    itemMid: {},
    hqPricedetailMid: {},
    buyLstMid: [],
    sellLstMid: [],
    buyOneMid: [],
    sellOneMid: [],
    currencyDataMid: {},
}

// getters
const getters = {
    item: (state) => {
        let itemData = state.item;
        return (itemData)
    },
    buyLst(state, data) {
        let itemData = state.buyLst.slice();
        return (itemData)
    },
    sellLst(state, data) {
        let itemData = state.sellLst.slice();
        return (itemData)
    },

    pricedetail: (state) => {
        let pricedetailData = state.pricedetail.currentDataArray;
        return (pricedetailData)
    },
    marketDetails: (state) => {
        for (let i = 0; i < state.marketDetails.length; i++) {
            let str = state.marketDetails[i].quotechange
            let zf = str.substring(0, 1)
            if (zf == "-") {
                state.marketDetails[i].quotechangeColor = 'green';
            } else {
                state.marketDetails[i].quotechangeColor = 'red';
            }
        }
        return state.marketDetails.slice();
    },
    timeLineGetter: (state) => {
        //console.log(state.timeLineData)

        //定义分时线数据格式
        let timeLineCategoryData = [];
        let timeLineValues = [];
        let timeLineAverageValues = [];
        for (let i = state.openTime; i < state.closeTime; i++) {
            let times = util.getLocalTime(i / 1000);
            i = i + 60000;
            timeLineCategoryData.push(times);
        }
        for (let value of state.timeLineData) {
            timeLineValues.push(value.price.toFixed(2));
            timeLineAverageValues.push(value.averagePrice.toFixed(2));
        }
        let dataMA5 = hqUtil.calculateMA(5, timeLineValues);
        let dataMA10 = hqUtil.calculateMA(10, timeLineValues);
        let dataMA20 = hqUtil.calculateMA(20, timeLineValues);
        //console.log(hqUtil.timeLineOption.xAxis);
        hqUtil.timeLineOption.xAxis.data = timeLineCategoryData;
        for (let value of hqUtil.timeLineOption.series) {
            if (value.name === 'timeline') {
                value.data = timeLineValues;
            } else if (value.name === 'timelineaverage') {
                value.data = timeLineAverageValues;
            } else if (value.name === 'MA5') {
                value.data = dataMA5;
            } else if (value.name === 'MA10') {
                value.data = dataMA10;
            } else if (value.name === 'MA20') {
                value.data = dataMA20;
            }
        }
        let timeLineOption = hqUtil.timeLineOption;
        return {
            timeLineOption
        };
    },



    kLineGetter: (state) => {
        let categoryData = [];
        let values = [];
        let volumes = [];
        for (let value of state.kLineData) {
            let timess = util.getLocalTime(value.belongTime / 1000);
            categoryData.push(timess);
            values.push([value.openPrice.toFixed(2), value.closePrice.toFixed(2), value.lowestPrice.toFixed(2), value.highestPrice.toFixed(2)]);
            volumes.push(value.volume);
        }
        let dataMA5 = hqUtil.calculateMA(5, values);
        let dataMA10 = hqUtil.calculateMA(10, values);
        let dataMA20 = hqUtil.calculateMA(20, values);

        // kline的配置项
        hqUtil.kLineOption.xAxis[0].data = categoryData;
        hqUtil.kLineOption.xAxis[1].data = categoryData;
        for (let value of hqUtil.kLineOption.series) {
            if (value.type === 'bar') {
                value.data = volumes;
            } else if (value.type === 'candlestick') {
                value.data = values;
            } else if (value.type === 'line' && value.name === 'MA5') {
                value.data = dataMA5;
            } else if (value.type === 'line' && value.name === 'MA10') {
                value.data = dataMA10;
            } else if (value.type === 'line' && value.name === 'MA20') {
                value.data = dataMA20;
            }
        }

        let kLineOption = hqUtil.kLineOption;
        return {
            kLineOption
        };
    },
    sellOne(state, data) {
        let itemData = state.sellOne;
        return (itemData)
    },
    buyOne(state, data) {
        let itemData = state.buyOne;
        return (itemData)
    },
    //挂牌
    lstMarketDetails: (state) => {
        let aLst = state.lstMarketDetails
        let aLstData = [];
        let arr = [];
        for (let i = 0; i < aLst.length; i++) {
            aLst[i].mdMap.b.sId = aLst[i].contractId;
            aLst[i].mdMap.s.sId = aLst[i].contractId;
            aLstData.push(aLst[i].mdMap)
        }
        //把单个买卖数据插入数组
        for (let j = 0; j < aLstData.length; j++) {
            let oB = aLstData[j].b
            let oS = aLstData[j].s
            arr.push(oB, oS)
        }
        //判断买卖涨跌并把样式赋到对象中
        for (let i = 0; i < arr.length; i++) {
            let sSign = arr[i].quotechange
            sSign.indexOf('-') == '-1' ? arr[i].quotechangeColor = 'red' : arr[i].quotechangeColor = 'green';
            // arr[i].starIcon = "el-icon-star-off";
            if (arr[i].bsFlag == "B") {
                arr[i].flag = "买入";
            } else if (arr[i].bsFlag == "S") {
                arr[i].flag = "卖出";
            }
        }
        //输出数据
        return arr;
    },
    pricedetailNon: (state) => {
        let pricedetailData = [].concat(state.pricedetailNon.currentDataArray);
        pricedetailData = pricedetailData.slice(0, 500)
        return (pricedetailData)
    },
    currencyData: (state) => {
        let itemData = state.currencyData;
        return (itemData)
    },
    // timeLine、KLine
    timeLineDataGetter: (state) => {
        return state.timeLineData;
    },
    kLineDataGetter: (state) => {
        return state.kLineData;
    },
    currentCommodityInfoGetter: (state) => {
        return state.currentCommodityInfo;
    },
    //中远期
    currencyDataMid: (state) => {
        let itemData = state.currencyDataMid;
        return (itemData)
    },
    currentCommodityInfoMidGetter: (state) => {
        return state.currentCommodityInfoMid;
    },
     itemMid: (state) => {
        let itemData = state.itemMid;
        return (itemData)
    },
    buyLstMid(state, data) {
        let itemData = state.buyLstMid.slice();
        return (itemData)
    },
    sellLstMid(state, data) {
        let itemData = state.sellLstMid.slice();
        return (itemData)
    },

    hqPricedetailMid: (state) => {
        let pricedetailData = state.hqPricedetailMid.currentDataArray;
        return (pricedetailData)
    },
    marketDetailsMid: (state) => {
        for (let i = 0; i < state.marketDetailsMid.length; i++) {
            let str = state.marketDetailsMid[i].quotechange
            let zf = str.substring(0, 1)
            if (zf == "-") {
                state.marketDetailsMid[i].quotechangeColor = 'green';
            } else {
                state.marketDetailsMid[i].quotechangeColor = 'red';
            }
        }
        return state.marketDetailsMid.slice();
    },
    timeLineGetterMid: (state) => {
        //console.log(state.timeLineData)

        //定义分时线数据格式
        let timeLineCategoryData = [];
        let timeLineValues = [];
        let timeLineAverageValues = [];
        for (let i = state.openTimeMid; i < state.closeTimeMid; i++) {
            let times = util.getLocalTime(i / 1000);
            i = i + 60000;
            timeLineCategoryData.push(times);
        }
        for (let value of state.timeLineDataMid) {
            timeLineValues.push(value.price.toFixed(2));
            timeLineAverageValues.push(value.averagePrice.toFixed(2));
        }
        let dataMA5 = hqUtil.calculateMA(5, timeLineValues);
        let dataMA10 = hqUtil.calculateMA(10, timeLineValues);
        let dataMA20 = hqUtil.calculateMA(20, timeLineValues);
        //console.log(hqUtil.timeLineOption.xAxis);
        hqUtil.timeLineOption.xAxis.data = timeLineCategoryData;
        for (let value of hqUtil.timeLineOption.series) {
            if (value.name === 'timeline') {
                value.data = timeLineValues;
            } else if (value.name === 'timelineaverage') {
                value.data = timeLineAverageValues;
            } else if (value.name === 'MA5') {
                value.data = dataMA5;
            } else if (value.name === 'MA10') {
                value.data = dataMA10;
            } else if (value.name === 'MA20') {
                value.data = dataMA20;
            }
        }
        let timeLineOption = hqUtil.timeLineOption;
        return {
            timeLineOption
        };
    },



    kLineGetterMid: (state) => {
        let categoryData = [];
        let values = [];
        let volumes = [];
        for (let value of state.kLineDataMid) {
            let timess = util.getLocalTime(value.belongTime / 1000);
            categoryData.push(timess);
            values.push([value.openPrice.toFixed(2), value.closePrice.toFixed(2), value.lowestPrice.toFixed(2), value.highestPrice.toFixed(2)]);
            volumes.push(value.volume);
        }
        let dataMA5 = hqUtil.calculateMA(5, values);
        let dataMA10 = hqUtil.calculateMA(10, values);
        let dataMA20 = hqUtil.calculateMA(20, values);

        // kline的配置项
        hqUtil.kLineOption.xAxis[0].data = categoryData;
        hqUtil.kLineOption.xAxis[1].data = categoryData;
        for (let value of hqUtil.kLineOption.series) {
            if (value.type === 'bar') {
                value.data = volumes;
            } else if (value.type === 'candlestick') {
                value.data = values;
            } else if (value.type === 'line' && value.name === 'MA5') {
                value.data = dataMA5;
            } else if (value.type === 'line' && value.name === 'MA10') {
                value.data = dataMA10;
            } else if (value.type === 'line' && value.name === 'MA20') {
                value.data = dataMA20;
            }
        }

        let kLineOption = hqUtil.kLineOption;
        return {
            kLineOption
        };
    },
    sellOneMid(state, data) {
        let itemData = state.sellOneMid;
        return (itemData)
    },
    buyOneMid(state, data) {
        let itemData = state.buyOneMid;
        return (itemData)
    },
}

// actions
const actions = {
    initDataContract(state, data) {
        state.contract = ['...data'];
    },
    initMarketid(state, data) {
        state.marketid = data;
    },
    pricedetailNon(state, data) {
        state.pricedetailNon = data;
    },
}

// mutations
const mutations = {
    item(state, data) {
        state.item = data;
        // state.items = data;
        // state.itemsEnd = data;
    },
    updateMarketDetails(state, data) {
        state.marketDetails = [...data];
    },
    pricedetail(state, data) {
        state.pricedetail = data;
    },
    updateCurrentContractId(state, data) {
        state.currentContractId = 111000017;
    },
    updateTimeLineData(state, data) {
        state.timeLineData = [...data];
    },
    updateKLineData(state, data) {
        state.kLineData = [...data];
    },
    updatemarketid(state) {
        state.marketid = 1;
    },
    updateOpenCloseTime(state, openTime, closeTime) {
        state.openTime = openTime;
        state.closeTime = closeTime;
    },
    updatemarketid(state) {
        state.marketid = 1;
    },
    buyLst(state, data) {
        state.buyLst = data
    },
    sellLst(state, data) {
        state.sellLst = data
    },
    pricedetail(state, data) {
        state.pricedetail = data;
    },
    PushPriceDetailResponse(state, data) {
        for (const j in data) {
            if (data.hasOwnProperty(j)) {
                const pushData = data[j];
                let newpricedetail = {}
                newpricedetail.price = pushData.price //价格
                newpricedetail.volume = pushData.volume //成交量
                newpricedetail.ioFlag = pushData.ioFlag //买卖方向 0 买 1 卖
                newpricedetail.occurTime = pushData.occurTime
                newpricedetail.tradeDate = '0'
                newpricedetail.contractId = pushData.contractId;
                newpricedetail.marketID = '0'
                newpricedetail.averagePrice = '0'
                newpricedetail.buyPrice = '0'
                newpricedetail.sellPrice = '0'
                newpricedetail.tickCount = '0'
                newpricedetail.itemNum = '0'
                newpricedetail.counter = '0'
                state.pricedetail.currentDataArray.unshift(newpricedetail)
                for (const key in state.marketDetails) {
                    if (state.marketDetails.hasOwnProperty(key)) {
                        const marketDetail = state.marketDetails[key];
                        if (pushData.contractId == marketDetail.symbolId) {
                            state.marketDetails[key].amount = pushData.allVolume
                            state.marketDetails[key].close = pushData.price
                            state.marketDetails[key].high = pushData.highPrice
                            state.marketDetails[key].low = pushData.lowPrice
                            state.marketDetails[key].quotechange = pushData.qc
                        }
                    }
                }

            }
        }
    },
    buyOne(state, data) {
        state.buyOne = data
    },
    sellOne(state, data) {
        state.sellOne = data
    },
    //挂牌
    updateMarketDetailsNon(state, payload) {
        state.lstMarketDetails = [...payload];
        let a = Object.keys(state.currentCommodityInfo);
        if (Object.keys(state.currentCommodityInfo).length === 0) {
            let obj = state.lstMarketDetails[0].mdMap;
            for (let objItem in obj) {
                let zf = obj[objItem].quotechange.substring(0, 1);
                if (zf == "+") {
                    obj[objItem].bgColor = 'red';
                } else {
                    obj[objItem].bgColor = 'green';
                }
                obj[objItem].baseCurrency = obj[objItem].symbol.split('/')[0];
                obj[objItem].priceCurrency = obj[objItem].symbol.split('/')[1];
                if (obj[objItem].bsFlag === 'B') {
                    state.currentCommodityInfo = obj[objItem];
                }
            }
        }
    },
    currencyData(state, data) {
        state.currencyData = data;
        state.currentCommodityInfo = data;
        // state.items = data;
        // state.itemsEnd = data;
    },
    setTimeLineData(state, data) {
        state.timeLineData = [...data];
    },
    setKLineData(state, data) {
        state.kLineData = [...data];
    },
    pricedetailNon(state, data) {
        state.pricedetailNon = data;
    },
    updateTimeLineDataNon(state, data) {
        if (data.type === 'push') {
            state.timeLineData.push(data);
        } else {
            state.timeLineData[state.timeLineData.length - 1].contractId = data.contractId;
            state.timeLineData[state.timeLineData.length - 1].price = data.price;
        }
    },
    updateKLineDataNon(state, data) {
        if (data.type === 'push') {
            data.highestPrice = data.closePrice;
            data.lowestPrice = data.closePrice;
            data.closePrice = data.closePrice;
            data.openPrice = data.closePrice;
            state.kLineData.push(data);
        } else {
            if (Number(state.kLineData[state.kLineData.length - 1].lowestPrice) > Number(data.closePrice)) {
                state.kLineData[state.kLineData.length - 1].lowestPrice = data.closePrice;
            }
            if (Number(state.kLineData[state.kLineData.length - 1].highestPrice) < Number(data.closePrice)) {
                state.kLineData[state.kLineData.length - 1].highestPrice = data.closePrice;
            }
            state.kLineData[state.kLineData.length - 1].closePrice = data.closePrice;
        }
    },
    PushPriceDetailResponseBuy(state, data) {
        if (data) {
            let sId = data.symbolId;
            if (state.lstMarketDetails.length > 0) {

                let sIdMarket = state.lstMarketDetails[0].contractId;
                let buyPricedetail = data;
                buyPricedetail.price = data.buyPrice;
                buyPricedetail.ioFlag = 0;
                buyPricedetail.occurTime = data.updateTime;
                state.PushPriceDetailResponseBuy = data;
                if (state.currencyData.symbolId) {
                    if (state.currencyData.symbolId == sId) {
                        state.pricedetailNon.currentDataArray.unshift(buyPricedetail);
                    }
                } else {
                    if (sId == sIdMarket) {
                        state.pricedetailNon.currentDataArray ? state.pricedetailNon.currentDataArray.unshift(buyPricedetail) : state.pricedetailNon.currentDataArray;
                        // state.pricedetailNon.currentDataArray.unshift(buyPricedetail);
                    }
                }
                for (const key in state.lstMarketDetails) {
                    if (state.lstMarketDetails.hasOwnProperty(key)) {
                        const marketDetail = state.lstMarketDetails[key];
                        if (buyPricedetail.symbolId == marketDetail.contractId) {
                            state.lstMarketDetails[key].mdMap.b.amount = util.exactPointTwo(buyPricedetail.amount);
                            state.lstMarketDetails[key].mdMap.b.buyPrice = util.exactPointTwo(buyPricedetail.buyPrice);
                            state.lstMarketDetails[key].mdMap.b.sellPrice = util.exactPointTwo(buyPricedetail.sellPrice);
                            state.lstMarketDetails[key].mdMap.b.close = util.exactPointTwo(buyPricedetail.close);
                            state.lstMarketDetails[key].mdMap.b.high = util.exactPointTwo(buyPricedetail.high);
                            state.lstMarketDetails[key].mdMap.b.low = util.exactPointTwo(buyPricedetail.low);
                            state.lstMarketDetails[key].mdMap.b.quotechange = buyPricedetail.quotechange;
                            state.lstMarketDetails[key].mdMap.b.changePrice = buyPricedetail.changePrice;
                            state.lstMarketDetails[key].mdMap.b.open = util.exactPointTwo(buyPricedetail.open);
                            state.lstMarketDetails[key].mdMap.b.yesterClosePrice = util.exactPointTwo(buyPricedetail.yesterClosePrice);
                            state.lstMarketDetails[key].mdMap.b.updateTime = buyPricedetail.updateTime;
                        }
                    }
                }
            }
        }
    },
    PushPriceDetailResponseSell(state, data) {
        if (data) {
            let sId = data.symbolId;
            if (state.lstMarketDetails.length > 0) {
                let sIdMarket = state.lstMarketDetails[0].contractId;
                let sellPricedetail = data;
                sellPricedetail.price = data.sellPrice;
                sellPricedetail.ioFlag = 1;
                sellPricedetail.occurTime = data.updateTime;
                state.PushPriceDetailResponseSell = data;
                if (state.currencyData.symbolId) {
                    if (state.currencyData.symbolId == sId) {
                        state.pricedetailNon.currentDataArray.unshift(sellPricedetail);
                    }
                } else {
                    if (sId == sIdMarket) {
                        state.pricedetailNon.currentDataArray ? state.pricedetailNon.currentDataArray.unshift(sellPricedetail) : state.pricedetailNon.currentDataArray;
                    }
                    // state.pricedetailNon.currentDataArray.unshift(sellPricedetail);
                }
                // state.pricedetail.currentDataArray.unshift(sellPricedetail);
                for (const key in state.lstMarketDetails) {
                    if (state.lstMarketDetails.hasOwnProperty(key)) {
                        const lstMarketDetails = state.lstMarketDetails[key];
                        if (sellPricedetail.symbolId == lstMarketDetails.contractId) {
                            state.lstMarketDetails[key].mdMap.s.amount = util.exactPointTwo(sellPricedetail.amount);
                            state.lstMarketDetails[key].mdMap.s.buyPrice = util.exactPointTwo(sellPricedetail.buyPrice);
                            state.lstMarketDetails[key].mdMap.s.sellPrice = util.exactPointTwo(sellPricedetail.sellPrice);
                            state.lstMarketDetails[key].mdMap.s.close = util.exactPointTwo(sellPricedetail.close);
                            state.lstMarketDetails[key].mdMap.s.high = util.exactPointTwo(sellPricedetail.high);
                            state.lstMarketDetails[key].mdMap.s.low = util.exactPointTwo(sellPricedetail.low);
                            state.lstMarketDetails[key].mdMap.s.quotechange = sellPricedetail.quotechange;
                            state.lstMarketDetails[key].mdMap.s.changePrice = sellPricedetail.changePrice;
                            state.lstMarketDetails[key].mdMap.s.open = util.exactPointTwo(sellPricedetail.open);
                            state.lstMarketDetails[key].mdMap.s.yesterClosePrice = util.exactPointTwo(sellPricedetail.yesterClosePrice);
                            state.lstMarketDetails[key].mdMap.s.updateTime = sellPricedetail.updateTime;
                        }
                    }
                }
            }
        }
    },
    //中远期
    currencyDataMid(state, data) {
        state.currencyDataMid = data;
        state.currentCommodityInfoMid = data;
        // state.items = data;
        // state.itemsEnd = data;
    },
    itemMid(state, data) {
        state.itemMid = data;
        // state.items = data;
        // state.itemsEnd = data;
    },
    updateMarketDetailsMid(state, data) {
        state.marketDetailsMid = [...data];
    },
    hqPricedetailMid(state, data) {
        state.hqPricedetailMid = data;
    },
    updateCurrentContractIdMid(state, data) {
        state.currentContractIdMid = 111000017;
    },
    updateTimeLineDataMid(state, data) {
        state.timeLineDataMid = [...data];
    },
    updateKLineDataMid(state, data) {
        state.kLineDataMid = [...data];
    },
    updatemarketidMid(state) {
        state.marketidMid = 1;
    },
    updateOpenCloseTimeMid(state, openTime, closeTime) {
        state.openTimeMid = openTime;
        state.closeTimeMid = closeTime;
    },
    buyLstMid(state, data) {
        state.buyLstMid = data
    },
    sellLstMid(state, data) {
        state.sellLstMid = data
    },
    PushPriceDetailResponseMid(state, data) {
        for (const j in data) {
            if (data.hasOwnProperty(j)) {
                const pushData = data[j];
                let newpricedetail = {}
                newpricedetail.price = pushData.price //价格
                newpricedetail.volume = pushData.volume //成交量
                newpricedetail.ioFlag = pushData.ioFlag //买卖方向 0 买 1 卖
                newpricedetail.occurTime = pushData.occurTime
                newpricedetail.tradeDate = '0'
                newpricedetail.contractId = pushData.contractId; 
                newpricedetail.marketID = '0'
                newpricedetail.averagePrice = '0'
                newpricedetail.buyPrice = '0'
                newpricedetail.sellPrice = '0'
                newpricedetail.tickCount = '0'
                newpricedetail.itemNum = '0'
                newpricedetail.counter = '0'
                state.hqPricedetailMid.currentDataArray.unshift(newpricedetail)
                for (const key in state.marketDetailsMid) {
                    if (state.marketDetailsMid.hasOwnProperty(key)) {
                        const marketDetail = state.marketDetailsMid[key];
                        if (pushData.contractId == marketDetail.symbolId) {
                            state.marketDetailsMid[key].amount = pushData.allVolume
                            state.marketDetailsMid[key].close = pushData.price
                            state.marketDetailsMid[key].high = pushData.highPrice
                            state.marketDetailsMid[key].low = pushData.lowPrice
                            state.marketDetailsMid[key].quotechange = pushData.qc
                        }
                    }
                }

            }
        }
    },
    buyOneMid(state, data) {
        state.buyOneMid = data
    },
    sellOneMid(state, data) {
        state.sellOneMid = data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
