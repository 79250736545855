import store from "@/store/index";

let firmId = store.state.common.sessionInfo.firmId;
let userId = store.state.common.sessionInfo.userId;
let firmType = window.sessionStorage["userType"];
export default {
  // 获取用户权限
  param_findAllRightNew: {
    url: "/ums/role/findAllRightNew",
    param: {}
  },
  // 查询全部的角色
  param_queryAllRoleInfo: {
    url: "/ums/role/queryAllRoleInfo",
    param: {
      page: 0,
      size: 1000
    }
  },
  // 关联角色
  param_addUserToRoleForUser: {
    url: "/ums/role/addUserToRoleForUser",
    param: {
      firmId: null,
      roleIds: []
    }
  },
  // 根据firmId查询电话号码
  param_selectUserInfo: {
    url: "/tclound-cacheservice/cache/selectUserInfo",
    param: {
      firmId: firmId
    }
  },
  // 数据统计分析查询
  param_findStatisticalAnalysis: {
    url: "/tclound-cacheservice/statisticalAnalysis/findStatisticalAnalysis",
    param: {}
  },
  // 物流特惠路线查询
  param_findTransport: {
    url: "/tclound-cacheservice/statisticalAnalysis/findTransport",
    param: {}
  },

  // 设置密码
  param_setUpPwd: {
    url: "/authn/authn/setUpPwd",
    param: {
      loginAccount: null,
      password: null
    }
  },
  // 忘记密码
  param_updateUserPassword: {
    // url: "/authn/authn/updateUserPassword",
    url: "/authn/authn/v1/simpleFrontForgetPwd",
    param: {
      captchaCode: null,
      captchaId: null,
      code: null,
      checkWay: 1, // 账号类型: 1 手机号 2 邮箱
      loginAccount: null,
      password: null
    }
  },
  // 查询密码是否设置
  param_queryPwdIsSetup: {
    url: "/authn/authn/queryPwdIsSetup",
    param: {
      loginAccount: null
    }
  },
  // 修改密码
  param_updatePwdByOld: {
    // url: "tq-marketing-system/frontUserMaintainController/updatePwdByOld",
    url: "/authn/authn/v1/frontUpdatePwd",
    param: {
      loginAccount: null,
      oldPassword: null,
      password: null,
      templateId: 47
    }
  },
  //改变报价单状态
  param_changeOfferOrder: {
    url: "/pretrade/manage/changeOfferOrder",
    param: {
      offerId: 0,
      status: null
    }
  },
  //确认订单
  param_confirmOrder: {
    url: "/pretrade/manage/confirmOrder",
    param: {
      offerId: "",
      commodityId: ""
    }
  },
  // 首页商品种类查询
  param_queryVarieties: {
    url: "/ishop-goods-query/goodsClass/getGoodsClassTree",
    param: {
      iconType: 2
    }
  },
  // 基差商品种类查询为了不改原来的代码，新建一个url
  param_queryVarietiesThree: {
    url: "/ishop-goods-query/goodsClass/getGoodsClassTree",
    param: {
      iconType: 3
    }
  },
  // 商品种类单位查询
  param_findWeightUnit: {
    url: "/tclound-adminbiz/dictThree/findWeightUnit",
    param: {}
  },
  // 查询质检机构
  param_findInspectionInstitutions: {
    url: "/tclound-adminbiz/inspectionInstitutions/findInspectionInstitutions",
    param: {}
  },
  // 首页商品种类查询第一级
  param_getFirstLevelNavClass: {
    url: "/ishop-goods-query/goodsClass/getChildClassList",
    param: {}
  },
  // 首页商品种类查询子级
  param_getChildClassById: {
    url: "/ishop-goods-query/goodsClass/getChildClassById",
    param: {
      iconType: 2,
      classId: ""
    }
  },
  // 首页商品种类查询子级  基差
  param_getBasisChildClassById: {
    url: "/ishop-goods-query/goodsClass/getChildClassById",
    param: {
      iconType: 3,
      classId: ""
    }
  },
  // 首页商品品级查询
  param_queryTemplateGroup: {
    url: "/tclound-cacheservice/capacity/queryTemplateGroup",
    param: {
      goodsGroupClassId: null
    }
  },
  // 最新入驻会员查询
  param_queryNewestMember: {
    url: "/tclound-cacheservice/capacity/queryNewestMember",
    param: {
      size: 12,
      page: 0
    }
  },
  param_queryPerCertification: {
    url: "/tclound-cacheservice/capacity/queryPerCertification",
    param: {}
  },
  //交易报价
  param_offerOrder: {
    url: "/pretrade/manage/offerOrder",
    param: {
      bsFlag: "B",
      commodityId: 1,
      orderId: null,
      price: 100,
      quantity: 2
    }
  },
  //交易挂单
  param_releaseOrder: {
    url: "/pretrade/manage/releaseOrder",
    param: {
      bsFlag: "S",
      commodityId: 1,
      holdId: null,
      offerId: null,
      orderId: null,
      price: 100,
      quantity: 100,
      status: 0,
      type: 1
    }
  },
  //撤单
  param_undoOrder: {
    url: "/pretrade/manage/undoOrder",
    param: {
      orderId: ""
    }
  },
  // 供求信息撤单
  param_undoSupplyNote: {
    url: "tclound-trade/manage/undoSupplyNote",
    param: {
      supplyId: 0
    }
  },
  // 供求信息发布
  param_releaseSupplyNote: {
    url: "tclound-trade/manage/releaseSupplyNote",
    param: {
      address: "", //货物存放地
      brand: "", // 规格/牌号
      commodityIntroduce: "", //商品介绍
      commodityName: "", //商品分类
      companyName: "", // 公司名称
      contactsName: "", //联系人姓名
      contactsPhone: "", // 联系人电话
      enterpriseIntroduce: "", // 企业介绍
      img: "", //图片
      packages: "", // 包装方式
      position: "", // 职位
      price: 0, // 价格
      specifications: "", //包装规格
      supplyCommodityId: 0,
      type: "",
      unit: "", //计量单位
      weight: 0, //重量
      weightUnit: "" //重量单位
    }
  },

  // 供求信息置顶
  param_topSupplyNote: {
    url: "tclound-trade/manage/topSupplyNote",
    param: {
      supplyIdList: []
    }
  },
  param_sellerOrderDetail: {
    url: "/tclound-cacheservice/cache/sellerOrderDetail",
    param: {
      commodityId: null,
      firmId: firmId,
      firmType: null,
      id: null,
      marketId: 28,
      page: 1,
      size: 10,
      sort: [
        {
          direction: "ASC",
          property: "id"
        }
      ]
    }
  },
  //资源单详情
  param_QuerysellerOrderDetails: {
    url: "/tclound-cacheservice/cache/QuerysellerOrderDetails",
    param: {
      commodityId: "",
      firmId: firmId,
      firmType: 0,
      id: 0,
      marketId: 28,
      page: 0,
      size: 1,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  //求购单查询
  param_buyerOrderDetail: {
    url: "/tclound-cacheservice/cache/buyerOrderDetail",
    param: {
      commodityId: null,
      firmId: firmId,
      firmType: null,
      id: null,
      marketId: 28,
      page: 0,
      size: 10,
      sort: [
        -{
          direction: "ASC",
          property: "id"
        }
      ]
    }
  },
  //卖家-我的订单-详情
  param_queryMyOrderDetails: {
    url: "/tclound-cacheservice/cache/queryMyOrderDetails",
    param: {
      commodityId: "",
      firmId: firmId,
      firmType: firmType,
      id: null,
      orderId: 11,
      marketId: 28,
      page: 0,
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  //我的订单-今日/历史订单
  param_queryMyOrderOverview: {
    url: "/tclound-cacheservice/cache/queryMyOrderOverview",
    param: {
      commodityId: "",
      firmId: firmId,
      firmType: firmType,
      id: 0,
      marketId: 28,
      page: 0,
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  // 用户中心 ->公司认证  检验企业地址
  param_checkCertification: {
    url: "/tclound-operate/manage/checkCertification",
    param: {
      enterpriseName: null,
      registerNo: null
    }
  },
  // 我的资源单-今日/历史订单
  param_querySellerOrderOverview: {
    url: "/tclound-cacheservice/cache/querySellerOrderOverview",
    param: {
      commodityId: "",
      firmId: firmId,
      firmType: firmType,
      id: 0,
      marketId: 28,
      page: 0,
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  //商品产地
  param_selectCommodity: {
    url: "/tclound-cacheservice/cache/selectCommodity",
    param: {}
  },
  //判断人员类型
  param_selectFirmType: {
    url: "/tclound-cacheservice/cache/selectFirmType",
    param: {
      firmId: null
    }
  },
  //我的订单-详情-报价单list
  param_queryQuotationSheetDetails: {
    url: "/tclound-cacheservice/cache/queryQuotationSheetDetails",
    param: {
      commodityId: "1",
      firmId: firmId,
      firmType: firmType,
      id: 0,
      marketId: 28,
      orderId: 0,
      page: 0,
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  //已报价
  param_buyerOrderOfferDetail: {
    url: "/tclound-cacheservice/cache/buyerOrderOfferDetail",
    param: {
      firmId: firmId
    }
  },
  //未报价
  param_buyerOrder: {
    url: "/tclound-cacheservice/cache/buyerOrder",
    param: {
      firmId: firmId
    }
  },
  //资源单报价查询
  param_sellerOrderOfferDetails: {
    url: "/tclound-cacheservice/cache/sellerOrderOfferDetails",
    param: {
      orderId: null
    }
  },
  param_queryMyBuyOrderOverview: {
    url: "/tclound-cacheservice/cache/queryMyBuyOrderOverview",
    param: {
      firmId: firmId
    }
  },
  // 查询审核信息
  param_findBaseUserAuditByUserId: {
    // url: "/base-desk-query/baseUserAudit/findBaseUserAuditByUserId",
    url: "base-desk-query/baseUserAudit/v1/getBaseUserAudit",
    param: {}
  },
  // 企业认证 -> 查询企业认证状态
  param_enterpriseCertification: {
    url: "/tclound-cacheservice/cache/enterpriseCertification",
    param: {
      firmId: firmId
    }
  },
  // 新增个人开户审核
  param_saveBaseUserAudit_user: {
    url:
      "/base-desk-manage/baseUserAudit/v1/addOrUpdateBaseUserAuditForNaturalPerson",
    param: {
      // // "auditStatus": 0,//审核状态（0:未审核 1:已认证 2:已驳回）
      // auditType: "", // 认证类型（O:组织认证 P:个人认证）
      // // "cellphone": "",//手机号
      // // "createTime": 0,//创建时间
      // // "delFlag": true,//删除标记（0:未删除 1:已删除）
      // // "note": "",//备注信息
      // // "orgCertCode": "",//组织证件代码
      // // "orgCertImg": "",//组织证件照片
      // // "orgCertType": 0,//组织证件类型（1:社会统一代码）
      // // "orgName": "",//组织名称
      // // "passTime": 0,//通过时间
      // // "payTime": 0,//申请时间
      // personCartFrontImg: "", // 证件正面照片
      // personCartNegativeImg: "", // 证件反面照片
      // personCertNo: "", //	个人证件号码
      // personCertType: 0, // 个人证件类型（1:身份证）
      // personName: "", // 个人姓名（个人、法人）
      // // "updateTime": 0,//更新时间
      // urgencyContact: "", // 紧急联系人
      // urgencyContactAddress: "", // 紧急联系人地址
      // urgencyContactPhone: "" // 紧急联系人手机号
      // // "userId": 0//客户id（唯一标识）
    }
  },
  // 新增企业开户审核
  param_saveBaseUserAudit_company: {
    // url: "/base-desk-manage/baseUserAudit/saveBaseUserAudit",
    url:
      "/base-desk-manage/baseUserAudit/v1/addOrUpdateBaseUserAuditForLegalPerson",
    param: {
      // // "auditStatus": 0,//审核状态（0:未审核 1:已认证 2:已驳回）
      // auditType: "", // 认证类型（O:组织认证 P:个人认证）
      // // "cellphone": "",//手机号
      // // "createTime": 0,//创建时间
      // // "delFlag": true,//删除标记（0:未删除 1:已删除）
      // // "note": "",//备注信息
      // orgCertCode: "", // 组织证件代码
      // orgCertImg: "", // 组织证件照片
      // orgCertType: 0, // 组织证件类型（1:社会统一代码）
      // orgName: "", // 组织名称
      // // "passTime": 0,//通过时间
      // // "payTime": 0,//申请时间
      // personCartFrontImg: "", // 证件正面照片
      // personCartNegativeImg: "", // 证件反面照片
      // personCertNo: "", //	个人证件号码
      // personCertType: 0, // 个人证件类型（1:身份证）
      // personName: "", // 个人姓名（个人、法人）
      // // "updateTime": 0,//更新时间
      // urgencyContact: "", // 紧急联系人
      // urgencyContactAddress: "", // 紧急联系人地址
      // urgencyContactPhone: "" // 紧急联系人手机号
      // // "userId": 0//客户id（唯一标识）
    }
  },

  // 企业认证 -> 提交企业认证
  param_addCertification: {
    // url: "/tclound-operate/manage/addCertification",
    url: "/tclound-contract/contract/enterpriseCertification",
    param: {
      bankFirmname: null,
      bankName: null,
      bankNumber: null,
      businessPhoto: null,
      businessPhotoFile: null,
      enterpriseName: null,
      enterpriseAddress: null,
      enterprisePhone: null,
      firmType: null
    }
  },
  // 新增企业经营信息
  param_addInformation: {
    url: "/tclound-operate/operateThree/addInformation",
    param: {
      brand: "", //品牌
      firmId: null, //企业Id(修改必传)
      id: null, //经营产品信息主键(修改必传)
      manufacturer: "",
      placeOrigin: "", // 产地
      status: 0 //审核状态: 0-已申请, 1-通过,2-驳回
    }
  },
  // 企业经营信息查询状态
  param_queryInformation: {
    url: "/tclound-cacheservice/cache/queryInformation",
    param: {
      firmId: null
    }
  },
  // 企业经营信息查询下拉框查询已通过的
  param_getInformation: {
    url: "/tclound-cacheservice/cache/getInformation",
    param: {
      firmId: null
    }
  },
  // 新增商品
  param_addCommodity: {
    url: "/tclound-operate/manage/addCommodity",
    param: {
      code: null,
      image: null,
      marketPrice: null,
      meterageUnit: null,
      name: null,
      note: null,
      origin: null,
      price: null,
      quantity: null,
      resourceChannel: null,
      warehouse: null
    }
  },
  // 成交查询
  param_queryTradeNote: {
    url: "/tclound-cacheservice/cache/queryTradeNote",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: [
        {
          direction: "DESC",
          property: "orderId"
        }
      ]
    }
  },
  // 持仓查询
  param_queryHoldNote: {
    url: "/tclound-cacheservice/cache/queryHoldNote",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: [
        {
          direction: "DESC",
          property: "id"
        }
      ]
    }
  },
  // 持仓明细查询
  param_findHoldDetail: {
    url: "/tclound-cacheservice/hold/findHoldDetail",
    param: {
      holdId: null,
      className: null,
      enterpriseName: null,
      storageName: null, //仓库名称
      holdCode: null, // 入库单编码
      page: 0,
      size: 10,
      sort: [
        {
          direction: "DESC",
          property: "holdId"
        }
      ]
    }
  },
  // 资讯 -> 获得子一级栏目
  param_getContentLowerColumn: {
    url: "/content/column/lowerColumn",
    param: {
      parentColumnId: null,
      page: 0,
      size: 30,
      sort: []
    }
  },

  // 首页合作伙伴
  param_getCooperateList: {
    url: "/content/content/content",
    param: {
      columnId: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 首页友情链接
  param_getFriendlyLink: {
    url: "/content/content/content",
    param: {
      columnId: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 首页平台特色
  param_getPlatformFeatures: {
    url: "/content/content/content",
    param: {
      columnId: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 资讯 -> 获得某个栏目下的内容标题列表
  param_getContentTitleList: {
    url: "/content/content/content",
    param: {
      columnId: null,
      page: 0,
      size: 20,
      sort: []
    }
  },
  // 融合项目首页轮播图
  param_getContentTitleList1: {
    // url: "/content/lbt/carouselMap",
    url: "/content/content/carouselList",
    param: {
      // columnId: null,
      page: 0,
      size: 20,
      sort: []
    }
  },
  // 资讯 -> 获得某栏目下的子栏目及子栏目的部分内容
  param_getLowerColumnAndTheirContent: {
    url: "/content/content/lowerColumnAndTheirContent",
    param: {
      columnId: null,
      perColumnHaveContentSize: null,
      page: 0,
      size: 20,
      sort: []
    }
  },
  // 资讯 -> 获得某个栏目下的内容标题列表 -> 轮播
  param_getContentTitleListCarousel: {
    url: "/content/content/content",
    param: {
      columnId: null,
      page: 0,
      size: 20,
      sort: []
    }
  },
  // 资讯 -> 获得某个栏目下的内容标题列表 -> 底部文章
  param_getContentTitleListFooterArticle: {
    url: "/content/content/content",
    param: {
      columnId: null,
      page: 0,
      size: 20,
      sort: []
    }
  },
  // 资讯 -> 获得详细内容
  param_getContentDetails: {
    url: "/content/content/getContent",
    param: {
      id: null
    }
  },
  // 首页 -> 昨日/今日 成交量
  param_queryVolumeIndex: {
    url: "/tclound-cacheservice/sale/queryVolume",
    param: {}
  },
  // 首页 -> 人气店铺
  param_queryPopularShop: {
    url: "/tclound-cacheservice/sale/queryPopularShop",
    param: {}
  },
  // 首页 -> 成交动态
  param_queryTradeDynamic: {
    url: "/tclound-cacheservice/sale/queryTradeDynamic",
    param: {
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 产品预售查询
  param_queryAdvanceSale: {
    url: "/tclound-cacheservice/sale/queryOrderDetails",
    param: {
      commodityName: null,
      origin: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 新增商品(二期)
  param_addCommodityTwo: {
    url: "/tclound-operate/manage/two/addCommodity",
    param: {
      buyFeeRate: "",
      code: "",
      deliveryMultiples: "",
      feeAlgr: "",
      image: "",
      integerMultiples: "",
      lastPayDay: "",
      marginAlgr: "",
      marginRate: "",
      marketDate: "",
      marketPrice: "",
      maxHold: "",
      minDeliveryUnit: "",
      name: "",
      note: "",
      origin: "",
      price: "",
      quantity: "",
      sellFeeRate: "",
      singleMax: "",
      singleMin: "",
      statusCode: "",
      storeId: "",
      tradeStatus: "",
      tradeUnit: "",
      tradeUnitName: "",
      type: "",
      warehouse: ""
    }
  },
  // 卖家中心 -> 查询商品 带分页（二期）
  param_selectLtCommodity: {
    url: "/tclound-cacheservice/sale/selectLtCommodity",
    param: {
      firmId: firmId,
      statusCode: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 查询商品 没有分页（二期）
  param_selectLtCommodityList: {
    url: "/tclound-cacheservice/sale/selectLtCommodityList",
    param: {
      firmId: firmId,
      status: null
    }
  },
  // 卖家中心 -> 获取商品类别
  param_getCommodityCategory: {
    url: "/tclound-adminbiz/itcommodity/category",
    param: {}
  },
  // 卖家中心 -> 商品上市
  param_marketCommodity: {
    url: "/lttrade/manage/two/marketCommodity",
    param: {
      commodityId: null
    }
  },
  // 卖家中心 -> 查询产能资源单
  param_querySellerOrderTwo: {
    url: "/tclound-cacheservice/sale/querySellerOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 查询产能资源单 -> 详情
  param_queryOrderInfo: {
    url: "/tclound-cacheservice/sale/queryOrderInfo",
    param: {
      marketId: 28,
      orderId: null
    }
  },
  // 卖家中心 -> 产能资源单 -> 撤单
  param_undoSellOrderTwo: {
    url: "/lttrade/manage/two/undoSellOrder",
    param: {
      sellOrderId: null
    }
  },
  // 卖家中心 -> 新增产能资源单
  param_sellOrderTwo: {
    url: "/lttrade/manage/two/sellOrder",
    param: {
      commodityId: null,
      price: null,
      quantity: null,
      type: 1
    }
  },
  // 卖家中心 -> 产品预售订单
  param_queryLtOrderTwo: {
    url: "/tclound-cacheservice/sale/queryLtOrder",
    param: {
      firmId: firmId,
      startTime: null,
      endTime: null,
      status: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 产品预售订单 -> 查询产品预售订单状态
  param_selectSellOrderStatus: {
    url: "/tclound-cacheservice/sale/selectSellOrderStatus",
    param: {}
  },
  // 卖家中心 -> 店铺管理 -> 查询店铺类型字典表
  param_storeTypeDict: {
    url: "/tclound-adminbiz/dict/storeType",
    param: {}
  },
  // 卖家中心 -> 店铺管理 -> 查询店铺信息
  param_queryStore: {
    url: "/tclound-cacheservice/sale/queryStore",
    param: {
      firmId: null
    }
  },
  // 卖家中心 -> 店铺管理 -> 修改店铺信息
  param_updateStore: {
    url: "/tclound-operate/manage/updateStore",
    param: {
      logoUrl: "",
      note: "",
      storeName: "",
      type: ""
    }
  },
  // 买家中心 -> 产品预售购物车
  param_queryShoppingList: {
    url: "/tclound-cacheservice/sale/queryShoppingList",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家摘资源单
  param_buyOrderTwo: {
    url: "/lttrade/manage/two/buyOrder",
    param: {
      sellOrderId: null,
      quantity: null
    }
  },
  // 根据id查询商品的支付方式
  param_selectCommodityId: {
    url: "/tclound-cacheservice/sale/selectCommodityPayType",
    param: {
      commodityId: null
    }
  },
  // 买家撤单
  param_undoBuyOrderTwo: {
    url: "/lttrade/manage/two/undoBuyOrder",
    param: {
      buyOrderId: null
    }
  },
  // 基差回购撤单
  param_undoRepoOrder: {
    url: "/basis-trade/manage/undoRepoOrder",
    param: {
      repoId: null, // 回购单id
      repoStatus: null, //回购状态：W：已提交T：已同意U：已撤销F：不同意
      type: null //撤销类型：F：用户撤销M：会员撤销[撤销用]
    }
  },
  // 用户回购操作
  param_firmRepoOrder: {
    url: "/basis-trade/manage/firmRepoOrder",
    param: {
      repoId: null, // 回购单id
      repoStatus: null //回购状态：W：已提交T：已同意U：已撤销F：不同意
    }
  },
  // 会员回购操作
  param_memberRepoOrder: {
    url: "/basis-trade/manage/memberRepoOrder",
    param: {
      repoId: null, // 回购单id
      repoStatus: null //回购状态：W：已提交T：已同意U：已撤销F：不同意
    }
  },
  // 客户发起回购
  param_repoOrderForFirm: {
    url: "/basis-trade/manage/repoOrderForFirm",
    param: {
      repoWeight: null, //回购重量
      orderId: null //订单id
    }
  },
  // 会员发起回购
  param_repoOrderForMember: {
    url: "/basis-trade/manage/repoOrderForMember",
    param: {
      repoWeight: null, // 回购重量
      orderId: null //订单id
    }
  },
  // 买家确认协议并支付首款
  param_payFirstOrder: {
    url: "/lttrade/manage/two/payFirstOrder",
    param: {
      buyOrderId: null,
      payType: null
    }
  },
  // 买家支付尾款
  param_payLastOrder: {
    url: "/lttrade/manage/two/payLastOrder",
    param: {
      buyOrderId: null
    }
  },
  // 买家中心 -> 查询预售协议
  param_queryBuyerAgreement: {
    url: "/tclound-cacheservice/sale/queryBuyerAgreement",
    param: {
      firmId: firmId,
      commodityName: null, // 供应商名称
      startTime: null, // 开始时间
      endTime: null, // 结束时间
      status: null, // 状态
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 查询预售协议 -> 查询预售协议状态
  param_selectBuyOrderStatus: {
    url: "/tclound-cacheservice/sale/selectBuyOrderStatus",
    param: {}
  },
  // 买家中心 -> 查询预售协议详情
  param_queryBuyerAgreementDetail: {
    url: "/tclound-cacheservice/sale/queryBuyerAgreementDetail",
    param: {
      orderId: null
    }
  },
  // 买家中心 -> 我要提货
  param_queryTakeGoodsOrderDetail: {
    url: "/tclound-cacheservice/sale/queryTakeGoodsOrderDetail",
    param: {
      firmId: firmId,
      CommodityName: null,
      startTime: null,
      endTime: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 提货查询
  param_queryTakeGoodsOrder: {
    url: "/tclound-cacheservice/sale/queryTakeGoodsOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 申请提货
  param_applyDeliveryTwo: {
    url: "/lttrade/manage/two/applyDelivery",
    param: {
      commodityId: null,
      holdId: null,
      quantity: null
    }
  },
  // 查询用户个人信息
  param_selectUserInfoDetails: {
    url: "/tclound-cacheservice/sale/selectUserInfo",
    param: {
      marketId: 28
    }
  },
  // 查询用户个人信息
  param_getUserInfo: {
    url: "/tclound-cacheservice/sale/getUserInfo",
    param: {
      firmId: firmId,
      marketId: 28
    }
  },
  // 修改用户个人信息
  param_updateFirm: {
    url: "/tclound-operate/manage/updateFirm",
    param: {
      trueName: null, // 姓名
      qq: null, // qq
      email: null, // email
      img: null, // 头像
      solidPhone: null // 固话
    }
  },

  // 三期 -------------------------------------------------------------------------------------------------------------------------------
  // 查询商品品级id字典表
  param_getCommodityGrade: {
    url: "/tclound-adminbiz/dictThree/getCommodityGrade",
    param: {}
  },
  // 查询包装方式id字典表
  param_getCommodityPackage: {
    url: "/tclound-adminbiz/dictThree/getCommodityPackage",
    param: {}
  },
  // 查询包装规格字典表
  param_getComSpecifications: {
    url: "/tclound-adminbiz/dictThree/getComSpecifications",
    param: {}
  },
  // 查询仓库字典表
  param_getStorage: {
    url: "/tclound-adminbiz/dictThree/getStorage",
    param: {}
  },
  // 现货资源/现货求购/产品预售 -> 查询商品品名字典表
  param_queryVarietiesCate: {
    url: "/tclound-cacheservice/capacity/queryVarieties",
    param: {
      type: null // 0:品种  1:品名
    }
  },
  // 供求信息-品种查询 -> 查询商品品名字典表
  param_querySupplyVarieties: {
    url: "/tclound-cacheservice/capacity/querySupplyVarieties",
    param: {
      type: null // 0:品种  1:品名
    }
  },
  param_queryVarietiesName: {
    url: "/tclound-cacheservice/capacity/queryVarieties",
    param: {
      type: null // 0:品种  1:品名
    }
  },
  // 现货资源/现货求购/产品预售 -> 查询商品品级字典表
  param_queryGrade: {
    url: "/tclound-cacheservice/capacity/queryGrade",
    param: {}
  },
  // 现货资源查询(导航页 免session)
  param_querySpotResourcesNoSession: {
    url: "/tclound-cacheservice/capacity/querySpotResourcesNoSession",
    param: {
      commodityName: null, // 品名
      warehouse: null, // 仓库
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 产品预售查询(导航页 免session)
  param_queryPresaleResourcesNoSession: {
    url: "/tclound-cacheservice/capacity/queryPresaleResourcesNoSession",
    param: {
      commodityName: null, // 品名
      warehouse: null, // 仓库
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 现货求购查询（导航页 免session）
  param_querySpotPurchaseNoSession: {
    url: "/tclound-cacheservice/capacity/querySpotPurchaseNoSession",
    param: {
      commodityName: null, // 品名
      warehouse: null, // 仓库
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 查询推荐企业
  param_findEnterpriseName: {
    url: "/prebankservice/bankAdminbiz/findEnterpriseName",
    param: {}
  },
  // 首页供求信息
  param_queryCbSupplyNoteNoSession: {
    url: "/tclound-cacheservice/capacity/queryCbSupplyNoteNoSession",
    param: {
      commodityName: null, // 品名
      warehouse: null, // 仓库
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 供求信息
  param_queryCbSupplyNote: {
    url: "/tclound-cacheservice/capacity/queryCbSupplyNote",
    param: {
      applyWarehousId: 0,
      buyFirmId: 0,
      commodityName: "",
      commodityTypeId: 0,
      deliveryId: 0,
      endTime: 0,
      firmId: 0,
      fstatus: [],
      grade: "",
      id: 0,
      isFinish: [],
      openTicketStatus: [],
      operatorFirmId: 0,
      orderId: 0,
      orderStatus: [],
      page: 0,
      size: 10,
      sort: [],
      typeStr: "" // s 卖家  b 买家
    }
  },
  // 销售信息/采购信息-详情
  param_queryCbSupplyNoteDetails: {
    url: "/tclound-cacheservice/capacity/queryCbSupplyNoteDetails",
    param: {
      orderId: null,
      supplyCommodityId: null
    }
  },
  // 摘求购单（用现货摘）现货摘单
  param_pickStockOrder: {
    url: "/tclound-trade/manage/pickStockOrder",
    param: {
      hangResourcesId: 0, // 挂单id
      holdId: 0, //持仓id
      pickResourcesWeight: 0 // 摘单重量
    }
  },
  // 摘求购单（用预售摘）保证金
  param_pickSellPresaleResource: {
    url: "/tclound-trade/manage/pickSellPresaleResource",
    param: {
      hangResourcesId: 0, // 挂单id
      holdId: 0, //持仓id
      pickResourcesWeight: 0 // 摘单重量
    }
  },
  // 摘资源单（保证金、全款摘），摘求购单（用保证金摘）
  param_pickMarginOrderAllBuy: {
    url: "/tclound-trade/manage/pickMarginOrderAllBuy",
    param: {
      orderId: null, // 挂单id
      type: null // BO: 买保证金摘  BA: 买全款摘  SO: 卖保证金摘  SA: 卖仓单摘
    }
  },
  param_pickMarginOrderSell: {
    url: "/tclound-trade/manage/pickMarginOrderSell",
    param: {
      orderId: null, // 挂单id
      type: null // BO: 买保证金摘  BA: 买全款摘  SO: 卖保证金摘  SA: 卖仓单摘
    }
  },
  // 补入库
  // param_fillPresaleHold: {
  //   url: "/tclound-trade/manage/fillPresaleHold",
  //   param: {
  //     applyHoldId: null,// 申请仓单id
  //     fillType: null//补入库类型
  //   }
  // },
  param_pickMarginOrderBuy: {
    url: "/tclound-trade/manage/pickMarginOrderBuy",
    param: {
      orderId: null, // 挂单id
      type: null // BO: 买保证金摘  BA: 买全款摘  SO: 卖保证金摘  SA: 卖仓单摘
    }
  },
  // 确认补货入库完成
  param_fillHold: {
    url: "/tclound-trade/manage/fillHold",
    param: {
      holdId: null // 挂单id
    }
  },
  // 撤单（撤自己挂的单）
  param_undoOrderThree: {
    url: "/tclound-trade/manage/undoOrder",
    param: {
      orderId: null // 订单id
    }
  },
  // 撤单（撤摘别人的单）
  param_undoPickOrderThree: {
    url: "/tclound-trade/manage/undoPickOrder",
    param: {
      pickOrderId: null // 订单id
    }
  },
  // 撤单（竞拍撤单 撤自己挂的单）
  // param_undoPublishAuction: {
  //   url: "/auction/publish/undoPublish",
  //   param: {
  //     itemId: null // 订单id
  //   }
  // },
  // 当日行情展示
  param_searchCommodityMarket: {
    url: "tclound-adminbiz/marketQuotation/searchCommodityMarket",
    param: {}
  },

  // 买家中心 -> 竞价求购 ->列表查询  资源管理-竞价资源查询-竞买
  param_queryBiddingBuyerResource: {
    url: "/tclound-cacheservice/capacity/queryBiddingBuyerResource",
    param: {
      applyWarehousId: 0,
      commodityName: null,
      endTime: 0,
      firmId: 0,
      grade: "",
      orderId: 0,
      orderStatus: [],
      page: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: true,
        sort: {
          empty: true,
          sorted: true,
          unsorted: true
        },
        unpaged: true
      },
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      startTime: 0,
      status: [],
      statusStr: [],
      type: 0,
      typeStr: "",
      variety: "",
      warehouse: "",
      weightMax: 0,
      weightMin: 0
    }
  },
  // 卖家中心 -> 竞价求购 ->列表查询  资源管理-竞价资源查询-竞卖/保证金竞卖
  param_queryBiddingSellerResource: {
    url: "/tclound-cacheservice/capacity/queryBiddingSellerResource",
    param: {
      applyWarehousId: 0,
      commodityName: null,
      endTime: 0,
      firmId: 0,
      grade: "",
      openTicketStatus: [],
      orderId: 0,
      orderStatus: [],
      page: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: true,
        sort: {
          empty: true,
          sorted: true,
          unsorted: true
        },
        unpaged: true
      },
      payStatus: [],
      pickOrderId: 0,
      pickStatus: [],
      pickType: [],
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      startTime: 0,
      status: [],
      statusStr: [],
      type: 0,
      typeStr: "",
      variety: "",
      warehouse: "",
      weightMax: 0,
      weightMin: 0
    }
  },
  // 首页-竞价资源查询 (免session)
  param_queryBiddingSellerResourceNoSession: {
    url: "/tclound-cacheservice/capacity/queryBiddingSellerResourceNoSession",
    param: {
      applyWarehousId: 0,
      buyFirmId: 0,
      commodityName: null,
      endTime: 0,
      firmId: 0,
      grade: "",
      id: 0,
      isFinish: [],
      openTicketStatus: [],
      orderId: 0,
      orderStatus: [],
      page: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: true,
        sort: {
          empty: true,
          sorted: true,
          unsorted: true
        },
        unpaged: true
      },
      payStatus: [],
      pickOrderId: 0,
      pickStatus: [],
      pickType: [],
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      startTime: 0,
      status: [],
      statusStr: [],
      type: 0,
      typeList: [],
      typeStr: "",
      variety: "",
      warehouse: "",
      weightMax: 0,
      weightMin: 0
    }
  },
  // 销售管理/采购管理-已报名竞价
  param_queryAuctionEnrolment: {
    url: "/tclound-cacheservice/hold/queryAuctionEnrolment",
    param: {
      applyWarehousId: 0,
      buyFirmId: 0,
      commodityName: null,
      endTime: 0,
      firmId: 0,
      id: 0,
      isFinish: [],
      openTicketStatus: [],
      orderId: 0,
      orderStatus: [],
      page: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: true,
        sort: {
          empty: true,
          sorted: true,
          unsorted: true
        },
        unpaged: true
      },
      payStatus: [],
      pickOrderId: 0,
      pickStatus: [],
      pickType: [],
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      startTime: 0,
      status: [],
      statusStr: [],
      type: 0,
      typeList: [],
      typeStr: "",
      // "variety": "",
      warehouse: "",
      weightMax: 0,
      weightMin: 0
    }
  },
  // 确认摘单（买家付首款，卖家出保证金或仓单）
  param_confirmPickOrderThree: {
    url: "/tclound-trade/manage/confirmPickOrder",
    param: {
      pickOrderId: null // 订单id
    }
  },
  // 买方付尾款
  param_payEndMoney: {
    url: "/tclound-trade/manage/payEndMoney",
    param: {
      code: "",
      marginPassWord: "",
      orderId: null
    }
  },
  // 卖方开票
  param_openTicket: {
    url: "/tclound-trade/manage/openTicket",
    param: {
      orderId: null // 订单id
    }
  },
  // 买方确认开票
  param_confirmTicket: {
    url: "/tclound-trade/manage/confirmTicket",
    param: {
      orderId: null // 订单id
    }
  },
  // 议价
  param_bargainingHangOrder: {
    url: "/tclound-trade/manage/bargainingHangOrder",
    param: {
      orderId: null, // 订单id
      pickOrderId: null,
      price: null
    }
  },
  // 提货 -> 提货方式查询
  param_selectAllCompany: {
    url: "/tclound-adminbiz/applyWarehouse/selectAllCompany",
    param: {}
  },
  // 提货
  param_deliveryWarehous: {
    url: "/tclound-trade/manage/deliveryWarehous",
    param: {
      source: null, // 0:仓单 1:存单
      type: null, // 0:自提  1:物流
      warehousId: null // 仓储
    }
  },
  // 确认收货
  param_confirmReceipt: {
    url: "/tclound-operate/operateThree/confirmReceipt",
    param: {
      deliveryId: null
    }
  },
  // 卖家中心 -> 资源管理 -> 入库申请
  param_addApplyWarehous: {
    url: "/tclound-operate/operateThree/addApplyWarehous",
    param: {
      type: null, // 0:申请入库 1:补入库
      commodityId: null, // 商品品种//[申请]
      batch: null, // 批次
      firmName: null, // 联系人
      firmPhone: null, // 联系电话
      manufacturer: null, // 厂家 //[申请]
      outWarehousDay: null, // 生产日期
      quantity: null, // 数量
      specifications: null, // 规格
      specificationsId: null, // 包装规格
      specificationsName: null, // 属性
      storageId: null, // 仓库[申请]
      putWarehousTime: null, // 入库日期[申请]
      weight: null, // 重量//[申请]
      applyHoldId: null, //申请入库id(修改时传)
      deliveryId: null,
      holdId: null, //补入
      note: null, //申请，补入
      poundsPoor: null, //[申请]
      putWarehousTime: null,
      unit: null, //[申请]
      placeOrigin: null, // 产地
      brand: null // 品牌
    }
  },
  // 查询基差单
  param_queryBasisQueryOrderLd: {
    url: "/basis-cacheservice/query/queryBasisQueryOrderLd",
    param: {
      buyFirmId: null,
      className: "",
      commodityId: null,
      endTime: null,
      hangFirmId: null,
      hangResourceType: "",
      hangResourcesId: null,
      hangResourcesStatus: [],
      memberFirmId: null,
      page: 0,
      pickFirmId: null,
      pickResourcesId: null,
      pickResourcesStatus: "",
      sellFirmId: null,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: null,
      specificationsName: "",
      startTime: null,
      tradeDay: null
    }
  },
  // 查询会员可回购订单
  param_queryBasisMemberQueryOrderLd: {
    url: "/basis-cacheservice/query/queryBasisMemberQueryOrderLd",
    param: {
      buyFirmId: null,
      className: "",
      commodityId: null,
      endTime: null,
      hangFirmId: null,
      hangResourceType: "",
      hangResourcesId: null,
      hangResourcesStatus: [],
      memberFirmId: null,
      page: 0,
      pickFirmId: null,
      pickResourcesId: null,
      pickResourcesStatus: "",
      sellFirmId: null,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: null,
      specificationsName: "",
      startTime: null,
      tradeDay: null
    }
  },
  // 查询基差回购单
  param_queryBasisQueryRepoOrderLd: {
    url: "/basis-cacheservice/query/queryBasisQueryRepoOrderLd",
    param: {
      repoId: null, //回购id
      className: "",
      commodityId: null,
      endTime: null,
      repoStatus: null, //回购状态：W：已提交MU：会员撤销FU:用户撤销T：已完成FR:用户拒绝MR：会员拒绝
      hangResourceType: "",
      hangResourcesId: null,
      hangResourcesStatus: [],
      memberFirmId: null,
      bsFlag: null, //买方是用户B，卖方是会员M
      page: 0,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: null,
      specificationsName: "",
      startTime: null,
      tradeDay: null
    }
  },
  // 补入库
  param_fillApplyWarehous: {
    url: "/tclound-operate/operateThree/fillApplyWarehous",
    param: {
      type: null, // 0:申请入库 1:补入库
      commodityId: null, // 商品品种//[申请]
      batch: null, // 批次
      firmName: null, // 联系人
      firmPhone: null, // 联系电话
      manufacturer: null, // 厂家 //[申请]
      outWarehousDay: null, // 生产日期
      quantity: null, // 数量
      specifications: null, // 规格
      specificationsId: null, // 包装规格
      specificationsName: null, // 属性
      storageId: null, // 仓库[申请]
      putWarehousTime: null, // 入库日期[申请]
      weight: null, // 重量//[申请]
      applyHoldId: null, //申请入库id(修改时传)
      deliveryId: null,
      holdId: null, //补入
      note: null, //申请，补入
      poundsPoor: null, //[申请]
      putWarehousTime: null,
      unit: null //[申请]
    }
  },
  // 增发
  param_addHoldWeight: {
    url: "/tclound-trade/manage/addHoldWeight",
    param: {
      batch: null, // 批次
      firmName: null, // 联系人
      firmPhone: null, // 联系电话
      holdId: null, //仓单id
      manufacturer: null, //厂家
      note: null,
      outWarehousDay: null, // 生产日期
      poundsPoor: null, //磅差
      putWarehousTime: null, // 预计入库时间
      weight: null // 增发重量
    }
  },
  // 卖家中心 -> 资源管理 -> 撤销入库申请
  param_backoutApply: {
    url: "/tclound-operate/operateThree/backoutApply",
    param: {
      applyHoldId: null // 仓储id
    }
  },
  // 卖家中心 -> 资源管理 -> 入库申请查询
  param_queryWarehousing: {
    url: "/tclound-cacheservice/capacity/queryWarehousing",
    param: {
      applyWarehousId: null, // 申请仓储id
      commodityName: null,
      status: null,
      type: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 资源管理 -> 入库申请详情查询
  param_queryNewWarehousingDetails: {
    url: "/tclound-cacheservice/capacity/queryNewWarehousingDetails",
    param: {
      applyWarehousId: null // 申请仓储id
    }
  },
  // 卖家中心 -> 资源管理 -> 发布现货资源
  param_sellHangStockOrder: {
    url: "/tclound-trade/manage/sellHangStockOrder",
    param: {
      // warehousId: null, // 申请仓储id
      // price: null, // 价格
      // openTicketDay: null, // 开票期限
      // sourceId: null // W：现货  J：竞价  C:预售
      // {
      bargainingStatus: "", //是否可以议价T：可以F：不可以
      businessName: "", //业务负责人
      businessPhone: "", // 电话
      holdId: null, // 持仓id 毕传
      manufacturer: "", //厂家
      openTicketTime: 0, // 开票期限
      phaseEndTime: 0, //集采分销结束时间
      phaseType: "", //是否开启集采分销T：开F：不开
      phaseWeight: 0, //集采分销达到重量
      price: 0, //单价
      quantity: 0, //挂单数量
      sourceId: "", //来源W：现货J：竞价C:预售
      tradeWay: "", // A：整批P：分销
      weight: 0, // 挂单重量
      effectiveTime: null,
      marginStatus: null, // 是否支持保证金付款（现货挂单用）T：是F：否
      minPickWeight: null // 最小起订量
      // }
    }
  },
  // 卖家中心 -> 资源管理 -> 现货资源仓库查询
  param_querySpotWarehouse: {
    url: "/tclound-cacheservice/capacity/queryWarehouse",
    param: {
      firmId: firmId,
      fStatus: null // 0:持有  1:冻结  2:已售  3:已提货
    }
  },
  // 卖家中心 -> 资源管理 -> 现货资源查询
  param_querySpotResources: {
    url: "/tclound-cacheservice/capacity/querySpotResources",
    param: {
      firmId: firmId, // 用户id
      commodityName: null, // 品名
      warehouse: null, // 仓库
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 资源管理 -> 现货资源详情查询
  param_querySpotResourcesDetails: {
    url: "/tclound-cacheservice/capacity/querySpotResourcesDetails",
    param: {
      orderId: null
    }
  },
  // 卖家中心 -> 资源管理 -> 预售资源查询
  param_queryPresaleResources: {
    url: "/tclound-cacheservice/capacity/queryPresaleResources",
    param: {
      firmId: firmId, // 用户id
      commodityName: null, // 品名
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 资源管理 -> 产品预售资源/现货求购查询 -> 详情
  param_queryPresaleResourcesDetails: {
    url: "/tclound-cacheservice/capacity/queryPresaleResourcesDetails",
    param: {
      orderId: null
    }
  },
  // 卖家中心 -> 资源管理 -> 发布预售资源
  param_hangOrderForMarginToBuy: {
    url: "/tclound-trade/manage/hangOrderForMarginToBuy",
    param: {
      commodityTypeId: null, // 商品分类id
      gradeId: null, // 品级id
      openTicketDay: null, // 开票期限:例20190101
      origin: null, // 产地
      packagId: null, // 包装id
      poundsPoor: null, // 磅差
      price: null, // 价格
      quantity: null, // 数量
      weight: null, // 重量
      putWarehousDay: null, // 预计入库时间
      specifications: null, // 规格
      specificationsId: null, // 包装规格
      sourceId: null, // W：现货  J：竞价  C:预售
      type: null // X：现货挂单 B：保证金挂单 Q：求购单
    }
  },
  param_hangOrderForMarginToSell: {
    url: "/tclound-trade/manage/hangOrderForMarginToSell",
    param: {
      commodityTypeId: null, // 商品分类id
      gradeId: null, // 品级id
      openTicketDay: null, // 开票期限:例20190101
      origin: null, // 产地
      packagId: null, // 包装id
      poundsPoor: null, // 磅差
      price: null, // 价格
      quantity: null, // 数量
      weight: null, // 重量
      putWarehousDay: null, // 预计入库时间
      specifications: null, // 规格
      specificationsId: null, // 包装规格
      sourceId: null, // W：现货  J：竞价  C:预售
      type: null // X：现货挂单 B：保证金挂单 Q：求购单
    }
  },
  // 挂单付款确认
  param_payHangOrder: {
    url: "/tclound-trade/manage/payHangOrder",
    param: {
      code: "",
      marginPassWord: "",
      hangResourcesId: null
    }
  },
  // 卖家中心 -> 资源管理 -> 现货资源订单
  param_querySpotResourcesOrder: {
    url: "/tclound-cacheservice/capacity/querySpotResourcesOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 资源管理 -> 预售资源订单
  param_queryPresaleResourcesOrder: {
    url: "/tclound-cacheservice/capacity/queryPresaleResourcesOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 销售管理 -> 我的质检报告查询
  param_queryQualityInspectionReport: {
    url: "/tclound-cacheservice/hold/queryQualityInspectionReport",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心 -> 销售管理 -> 存单质检报告查询
  param_queryQualityInspectionApplyReport: {
    url: "/tclound-cacheservice/hold/queryQualityInspectionApplyReport",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 查询每个质检报告
  param_queryQualityInspectionReportNoSession: {
    url:
      "/tclound-cacheservice/hold/nosession/queryQualityInspectionReportNoSession",
    param: {
      holdId: ""
    }
  },
  // 卖家中心 -> 销售管理 -> 现货订单查询
  param_queryXsSpotResourcesOrder: {
    url: "/tclound-cacheservice/capacity/queryXsSpotResourcesOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心/买家中心 -> 物流管理 -> 自提单查询
  param_querySelfPickUp: {
    url: "/tclound-cacheservice/hold/querySelfPickUp",
    param: {
      typeInList: null, // 卖家传0，2； 买家传1  类型0:初入库1：转手2：补入库
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心/买家中心 -> 物流管理 -> 自提单查询 -> 打印
  param_querySelfPickUpOrder: {
    url: "/tclound-cacheservice/capacity/querySelfPickUpOrder",
    param: {
      deliveryId: null
    }
  },
  // 卖家中心/买家中心 -> 物流管理 -> 自提单查询 -> 打印入库单编码
  param_findDeliveryDetail: {
    url: "/tclound-adminbiz/deliveryQuery/findDeliveryDetail",
    param: {
      deliveryId: null
    }
  },
  // 卖家中心/买家中心 -> 物流管理 -> 物流提单查询
  param_queryLogisticsPickUp: {
    url: "/tclound-cacheservice/hold/queryLogisticsPickUp",
    param: {
      buyFirmId: null,
      sellFirmId: null,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 求购管理 -> 现货求购订单
  param_querySpotPurchaseOrder: {
    url: "/tclound-cacheservice/capacity/querySpotPurchaseOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 求购管理 -> 现货求购查询
  param_querySpotPurchase: {
    url: "/tclound-cacheservice/capacity/querySpotPurchase",
    param: {
      firmId: firmId, // 用户id
      commodityName: null, // 品名
      grade: null, // 品级
      statusStr: null, // Y:已提交  T:已通过  F:未通过
      typeStr: null, // X:现货挂单  B:保证金挂单  Q:求购单
      orderStatus: null, // O:已挂单  Z:已摘单  T:已完成  U:已撤单
      variety: null, // 品种
      weightMax: null, // 件重最大值
      weightMin: null, // 件重最小值
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 采购管理 -> 现货订单
  param_queryCgSpotPurchaseOrder: {
    url: "/tclound-cacheservice/capacity/queryCgSpotPurchaseOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 买家中心 -> 采购管理 -> 产品预售订单
  param_queryCgPresaleOrder: {
    url: "/tclound-cacheservice/capacity/queryCgPresaleOrder",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 订单详情查询（产能和竞拍）
  param_queryResourcesOrderDetails: {
    url: "/tclound-cacheservice/capacity/queryResourcesOrderDetails",
    param: {
      pickOrderId: null
    }
  },
  // 账号中心 -> 企业信息 -> 电子签章管理
  param_findOneSignature: {
    url: "/tclound-contract/contract/findOneSignature",
    param: {
      firmId: firmId
    }
  },
  // 账号中心 -> 企业信息 -> 企业认证链接
  param_companyAuth: {
    url: "/tclound-contract/contract/companyAuth",
    param: {
      firmId: null
    }
  },
  // 账号中心 -> 企业信息 -> 查询契约所企业认证状态 认证状态：-1-无认证记录；1-未提交认证申请；2-认证通过；3-认证不通过；4-认证中。
  param_companyAuthStatus: {
    url: "/tclound-contract/contract/companyAuthStatus",
    param: {
      enterpriseName: null
    }
  },
  // 卖家中心/买家中心 -> 我的合同 -> 合同列表
  param_findAllContract: {
    url: "/tclound-contract/contract/findAllContract",
    param: {}
  },
  // 卖家中心/买家中心 -> 我的合同 -> 合同列表 -> 详情
  param_findOneContract: {
    url: "/tclound-contract/contract/findOneContract",
    param: {
      firmId: firmId,
      contractId: null
    }
  },
  // 卖家中心/买家中心 -> 我的合同 -> 合同列表 -> 下载
  param_downloadContract: {
    url: "/tclound-contract/contract/downloadContract",
    param: {
      firmId: firmId,
      documentId: null,
      value: null // 分类名称
    }
  },
  // 卖家中心/买家中心 -> 仓单查询
  param_queryWarehousSellerBuyer: {
    url: "/tclound-cacheservice/capacity/queryWarehous",
    param: {
      status: null, // 质检审核状态  0:待设置质检费用  1:待上传质检报告  2:未审核  3:审核通过  4:审核不通过
      type: null, // 0: 买家 1: 卖家
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心/买家中心 -> 存单查询
  param_queryCdWarehous: {
    url: "/tclound-cacheservice/capacity/queryCdWarehous",
    param: {
      fStatus: null,
      status: null, // 质检审核状态  0:待设置质检费用  1:待上传质检报告  2:未审核  3:审核通过  4:审核不通过
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 卖家中心/买家中心 -> 存单查询
  param_queryDepositReceiptHold: {
    url: "/tclound-cacheservice/hold/queryDepositReceiptHold",
    param: {
      // fStatus: null,
      // status: null, // 质检审核状态  0:待设置质检费用  1:待上传质检报告  2:未审核  3:审核通过  4:审核不通过
      // firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 首页 -> 找稀土指数
  param_searchMarketPrice: {
    url: "/tclound-adminbiz/marketQuotation/searchMarketPrice",
    param: {
      endDate: null,
      startDate: null,
      type: "pre"
    }
  },
  // 首页 -> 成交动态查询
  param_queryTransactionDynamics: {
    url: "/tclound-cacheservice/hold/queryTransactionDynamics",
    param: {}
  },
  // 资产中心 -> 我的账户查询
  param_getFundSum: {
    url: "/tclound-cacheservice/query/queryAccount",
    param: {
      firmId: firmId,
      marketId: 28,
      symbolId: 1
    }
  },
  // 资金流水
  param_queryMyIntegralDetail: {
    url: "/tq-margin-query/bankCacheQuery/findAccountFlow",
    param: {
      queryType: 1, // 1 分市场 2 不分市场
      userId: userId,
      typeCode: null, //101出金 102入金 null 其它
      page: 0,
      size: 15,
      sort: [
        {
          direction: "DESC",
          property: "occurTime"
        }
      ]
    }
  },
  param_queryMyIntegralDetailHis: {
    url: "/tq-margin-query/marginQuery/queryMyIntegralDetailHisEnd",
    param: {
      userId: userId,
      starTime: "", // 开始时间
      endTime: "", // 结束时间
      typeCode: null, //101出金 102入金 null 其它
      page: 0,
      size: 15,
      sort: [
        {
          direction: "DESC",
          property: "occurTime"
        }
      ]
    }
  },
  // 资产中心 -> 查询当日资金流水
  queryMyIntegralDetailEnd: {
    url: "/tq-margin-query/marginQuery/queryMyIntegralDetailEnd",
    param: {
      firmId: firmId,
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 资产中心 -> 查询历史资金流水
  param_queryMyIntegralDetailHisEnd: {
    url: "/tq-margin-query/marginQuery/queryMyIntegralDetailHisEnd",
    param: {
      firmId: firmId,
      starTime: null, // 开始时间
      endTime: null, // 结束时间
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 资产中心 -> 查询当日冻结流水
  param_findMarginList: {
    url: "/tclound-cacheservice/query/queryFrozenAccountFlow",
    param: {
      firmId: firmId,
      item: null, // 冻结码
      beginTime: null, // 开始时间
      endTime: null, // 结束时间
      marketId: 28,
      symbolId: 1, //
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 资产中心 -> 查询历史冻结流水
  param_findMarginHList: {
    url: "/tclound-cacheservice/query/queryFrozenAccountFlowH",
    param: {
      firmId: firmId,
      item: null, // 冻结码
      beginTime: null, // 开始时间
      endTime: null, // 结束时间
      marketId: 28, // 市场id
      symbolId: 1, //
      page: 0,
      size: 10,
      sort: []
    }
  },
  // 资产中心 -> 银行行名行号查询
  param_queryBankInfoNameCode: {
    url: "/prebankservice/bank/queryBankInfo",
    param: {
      bankCode: null,
      bankName: null
    }
  },
  // 资产中心 -> 银行签约
  param_userSign: {
    url: "/bankchannel/bankchannel/userSign",
    param: {
      userId: Number(userId), //
      bankId: 3, //
      memType: "1", // 1-对公（公司）  0-对私（个人）
      identiType: "", // 签约证件类型(齐鲁银行个人证件类型:01 身份证;企业证件类型:01 营业执照 06 组织机构代码 07 社会统一信用证)
      identiNum: "", // 签约证件号码
      pwd: null, // 资金密码
      custName: "河北技术有限公司", // 个人用户真实姓名/企业名称
      mobileNo: "", // 手机号码公司
      signFlag: 0, // 0：未签约；1：签约；2：解约；3：签约未绑卡"
      status: 0, // "0：签约 1：改约 2：解约"
      legalRealName: "", // 法人代表
      legalCertType: "01", // 法人证件类型01 身份证"
      legalCertNo: "", //法人证件号码

      agentName: "", // 经办人
      agentCertType: "01", // 经办人证件类型
      agentCertNo: "" //经办人证件号码
    }
  },
  // 资产中心 -> 银行签约 ->用户绑卡
  param_userBindCard: {
    url: "/tq-bankchannel/qiluBankNH/tyingCard",
    param: {
      bankAcc: "",
      bindBankName: "",
      bindBankNo: "",
      traderID: userId
    }
  },
  // 资产中心 -> 银行签约 ->出金手续费查询
  param_fundOutFee: {
    url: "/bankchannel/bankchannel/fundOutFee",
    param: {
      bankId: 3, //银行id
      rcvBankNo: "", //收款行号
      amount: 0 //想要查询的金额
    }
  },
  // 资产中心 -> 银行签约 ->用户解绑银行卡
  param_userCancelCard: {
    url: "/tq-bankchannel/qiluBankNH/unTyingCard",
    param: {
      traderID: userId
    }
  },
  // 资产中心 -> 修改银行签约信息
  param_updateuserSign: {
    url: "/prebankservice/bank/updateuserSign",
    param: {
      infoId: null,
      bankAccountName: null,
      openBankName: null,
      openBankNum: null
    }
  },
  // 资产中心 -> 对公打款验证
  param_comCustAuth: {
    url: "/tq-bankchannel/qiluBankNH/comCustAuth",
    param: {
      traderID: null,
      amount: null,
      bankSerNum: null
    }
  },
  // 资产中心 -> 手动入金
  param_getInFundOfBnakReq: {
    url: "/tq-bankchannel/qiluBankNH/getInFundOfBnakReq",
    param: {}
  },

  // 资产中心 -> 查询银行签约信息
  param_getUserSign: {
    url: "/prebankservice/prebank/getUserSign",
    param: {}
  },
  // 我的资产，资产
  param_getMyAsset: {
    // 'url': '/tq-marketing-system/frontTradeController/mineAsset',
    url: "/tq-trade-query/frontTradeController/mineAsset",
    param: {}
  },
  // 资产中心 -> 银行解约
  param_userUnSign: {
    url: "/tq-bankchannel/qiluBankNH/unTyingCard",
    param: {
      traderID: ""
      // fundNo: '',// 银行资金账户
      // userId: Number(userId)
    }
  },
  // 资产中心 -> 银行入金
  param_fundIn: {
    url: "/tclound-monibank/monibank/fundIn",
    param: {
      firmId: firmId,
      amount: null // 入金金额
    }
  },
  // 资产中心 -> 银行出金
  param_fundOutByMarket: {
    url: "/tq-bankchannel/qiluBankNH/fundOutByMarket",
    param: {
      amount: 0,
      bankId: 22,
      firmId: "",
      pwd: ""
    }
  },
  // 资金密码修改
  param_updateFundPwdEnd: {
    url: "/tq-marketing-system/operate/updateFundPwdEnd",
    param: {
      bankId: "22",
      newPassWord: "",
      oldPwd: ""
    }
  },
  // 资产中心 -> 签约信息查询
  param_queryUserSign: {
    // url: "/tq-bankchannel/qiluBankQuery/findUserSign",
    url: "/tq-margin-query/bankCacheQuery/findBankInfo",
    param: {
      userId: Number(userId)
    }
  },
  // 资金管理 -> 信息申报
  param_infoReporing: {
    url: "/tq-marketing-system/operate/transferFundsOwn",
    param: {
      userId: userId,
      status: 1, // 交易商状态
      bankId: 22 // 银行ID
    }
  },
  // 资金管理 -> 交易商信息上报
  param_addTrader: {
    url: "/tq-bankchannel/qiluBankNH/addTrader",
    param: {
      arcredentialsCode: "",
      arcredentialsType: "",
      artificialContact: "",
      artificialPerson: "",
      bankAccountID: "",
      bankId: "",
      bankShortNum: "",
      credentialsCode: "",
      credentialsType: "",
      mobile: "",
      registCapital: "",
      status: "1",
      traderID: Number(userId),
      traderName: "",
      type: ""
    }
  },
  // 销户
  param_traderCancellation: {
    url: "/tq-bankchannel/qiluBankNH/traderCancellation",
    param: {
      traderId: "",
      bankId: "22"
    }
  },
  // 资金管理 -> 信息申报状态查询
  param_findTrader: {
    // url: '/tq-bankchannel/qilubank/findTrader',
    url: "/tq-margin-query/bankCacheQuery/findTrader",
    param: {}
  },
  // 资金管理 -> 查询银行id
  param_queryBankId: {
    url: "/tq-bankchannel/qiluBankQuery/queryBankId",
    param: {}
  },
  // 银行签到签退查询
  param_queryBankSignInfo: {
    url: "tq-margin-query/bankQuery/queryBankSignInfo",
    param: {}
  },
  // 签约 -> 修改公司/用户名称
  param_updateTraderName: {
    url: "/base-desk-manage/traderUpdateApply/addTraderUpdateApply",
    param: {}
  },
  // 签约 -> 重新提交公司/用户名称
  param_reBackTraderName: {
    url: "/base-desk-manage/traderUpdateApply/updateTraderUpdateApply",
    param: {}
  },
  // 签约 -> 查询公司/用户名称状态
  param_queryTraderNameStatus: {
    url: "/base-desk-manage/traderUpdateApply/myTraderUpdateApply",
    param: {}
  },
  // 资产中心 -> 查询资金密码是否设置
  param_findFundPwd: {
    url: "/tclound-operate/manage/findFundPwd",
    param: {}
  },
  // 资产中心 -> 设置资金密码
  param_setMarginPwd: {
    url: "/tclound-operate/manage/setMarginPwd",
    param: {
      newPassWord: null
    }
  },
  // 资产中心 -> 修改资金密码
  param_updateMarginPwd: {
    url: "/tclound-operate/manage/updateMarginPwd",
    param: {
      newPassWord: null,
      oldPassWord: null
    }
  },
  // 资产中心 -> 忘记资金密码
  param_resetMarginPwd: {
    url: "/tclound-operate/manage/resetMarginPwd",
    param: {
      phone: null, // 手机号
      code: null, // 验证码
      newPassWord: null // 新密码
    }
  },
  // 我的找稀土 -> 公司认证 -> 绑定企业
  param_bindCertification: {
    url: "/base-desk-manage/baseUserBindController/personBindOrg",
    param: {
      code: null // 推荐码
    }
  },
  // 我的找稀土 -> 员工账号管理 -> 企业生成推荐码
  param_generateCode: {
    url: "/base-desk-manage/baseUserBindController/generateCode",
    param: {
      cellphone: null, // 被推荐人手机号
      companyId: null // 短信模板id
    }
  },
  // 我的找稀土 -> 员工账号管理 -> 查询员工账号
  param_queryFirmForEnterprise: {
    url: "/base-desk-query/baseUserBindController/queryFirmForEnterprise",
    param: {
      page: 1,
      size: 10,
      sort: []
    }
  },
  // 我的找稀土 -> 员工账号管理 -> 启用/禁用员工
  param_updateCertificationStatus: {
    // url: '/base-desk-manage/baseUserBindController/updateCertificationStatus',
    url: "/ums/ums/updateUserStatusInfo",
    param: {
      childFirmId: 0,
      status: ""
    }
  },
  // 已报名订单->竞价出价 销售管理/采购管理-已报名竞价详情
  paranm_queryAuctionEnrolmentDetails: {
    url: "/tclound-cacheservice/hold/queryAuctionEnrolmentDetails",
    param: {
      hangResourcesId: null // 订单id
    }
  },
  // 已报名订单->竞价出价 销售管理/采购管理-已报名竞价详情查询最近五次报价单
  paranm_queryAuctionEnrolmentFiveDetails: {
    url: "/tclound-cacheservice/hold/queryAuctionEnrolmentFiveDetails",
    param: {
      hangResourcesId: null // 订单id
    }
  },
  // 查询家加价幅度/tclound-cacheservice/capacity
  param_queryAuctionRule: {
    url: "/tclound-cacheservice/capacity/queryAuctionRule",
    param: {
      commodityId: null // 商品id
    }
  },
  // 中标查询
  param_queryAuctionWinningBid: {
    url: "/tclound-cacheservice/hold/queryAuctionWinningBid",
    param: {
      hangResourceType: null // 订单类型X：现货挂单 B：保证金挂单Q：求购单
    }
  },
  // 资源管理-竞价资源订单查询 (主动订单)
  param_queryAuctionSpotResourcesOrder: {
    url: "/tclound-cacheservice/capacity/queryAuctionSpotResourcesOrder",
    param: {
      type: null //订单类型X：现货挂单 B：保证金挂单Q：求购单  	list
    }
  },
  // 采购/销售管理-竞价订单查询 (被动摘单)
  param_queryAuctionResourcesOrder: {
    url: "/tclound-cacheservice/capacity/queryAuctionResourcesOrder",
    param: {
      type: null //订单类型X：现货挂单 B：保证金挂单Q：求购单  	list
    }
  },
  // 资源管理-竞价资源查询-详情
  param_queryBiddingResourceDetails: {
    url: "/tclound-cacheservice/capacity/queryBiddingResourceDetails",
    param: {
      orderId: null
    }
  },
  // 卖方挂预售单
  param_hangSellPresaleResources: {
    url: "/tclound-trade/manage/hangSellPresaleResources",
    param: {
      bargainingStatus: "", //是否可以议价T：可以F：不可以
      businessName: "", //业务负责人
      businessPhone: "", // 电话
      holdId: null, // 持仓id 必传
      manufacturer: "", //厂家
      openTicketTime: 0, // 开票期限
      phaseEndTime: 0, //集采分销结束时间
      phaseType: "", //是否开启集采分销T：开F：不开
      phaseWeight: 0, //集采分销达到重量
      price: 0, //单价
      putWarehousTime: "", // 预计入库时间
      quantity: 0, //挂单数量
      sourceId: "", //来源W：现货J：竞价C:预售
      tradeWay: "", // A：整批P：分销
      weight: 0, // 挂单重量
      effectiveTime: null, // 挂单有效时间，用于到期后自动撤单
      minPickWeight: null
    }
  },
  // 生成预售仓单
  param_generatePresaleHold: {
    url: "/tclound-trade/manage/generatePresaleHold",
    param: {
      commodityId: 0, // 商品id
      img: "", // 商品图片
      poundsPoor: "", // 磅差
      putHoldWeight: 0, //重量
      quantity: 0, //数量
      specificationsId: "", //规格id 1-1
      specificationsName: "", //规格名称{颜色：红色}json形式
      weightUnitId: "", //重量单位
      brand: "", // 品牌
      placeOrigin: "", // 产地
      manufacturer: "" // 厂家
      // unit: "" // 重量单位（废弃）
    }
  },
  // 撤销挂单
  param_basisUndoHangResources: {
    url: "/basis-trade/manage/undoHangResources",
    param: {
      hangResourcesId: null // 挂单id
    }
  },
  // 基差撤销挂单
  param_undoHangResources: {
    url: "/tclound-trade/manage/undoHangResources",
    param: {
      hangResourcesId: null // 挂单id
    }
  },
  // 撤销摘单
  param_undoPickResource: {
    url: "/tclound-trade/manage/undoPickResource",
    param: {
      pickResourcesId: null // 摘单id
    }
  },
  // 基差撤销摘单
  param_undoBasisPickResource: {
    url: "/basis-trade/manage/undoPickResource",
    param: {
      pickResourcesId: null // 摘单id
    }
  },
  // 买方挂求购单
  param_hangBuyPresaleResources: {
    url: "/tclound-trade/manage/hangBuyPresaleResources",
    param: {
      bargainingStatus: "", //是否可以议价T：可以F：不可以
      businessName: "", //业务负责人
      businessPhone: "", // 电话
      commodityId: null, // 商品id 必传
      manufacturer: "", //厂家
      openTicketTime: null, // 开票期限
      phaseEndTime: null, //集采分销结束时间
      phaseType: "", //是否开启集采分销T：开F：不开
      phaseWeight: null, //集采分销达到重量
      price: null, //单价
      // putWarehousTime:'',// 预计入库时间
      quantity: null, //挂单数量
      sourceId: "", //来源W：现货J：竞价C:预售
      tradeWay: "", // A：整批P：分销
      weight: null, // 挂单重量
      specificationsName: null,
      effectiveTime: null, //挂单有效时间
      minPickWeight: null
    }
  },
  // 设置集采价格区间
  param_setPhaseHangResourcePrice: {
    url: "/tclound-trade/manage/setPhaseHangResourcePrice",
    param: {
      hangResourcesId: null,
      obj: []
    }
  },
  // 持仓审核（撤销功能）
  param_auditHold: {
    url: "/tclound-trade/manage/auditHold",
    param: {
      auditHoldStatus: null, //审核状态W：待审核T：审核通过F：审核不通过U：已撤销
      holdId: null
    }
  },
  // 持仓查询 /tclound-cacheservice/hold
  param_queryHold: {
    url: "/tclound-cacheservice/hold/queryHold",
    param: {
      className: null,
      auditHoldStatus: null, //审核状态W：待审核T：审核通过F：审核不通过
      commodityId: null,
      holdStatus: null, //持仓状态T：已入库F：未入库
      holdType: null, //持仓类型X：现货持仓Y：预售持仓B：预售补入库持仓
      sellStatus: null,
      holdId: null,
      page: 0,
      size: 10,
      specificationsId: null, //规格id 例1-1
      specificationsName: null //规格名称 例{颜色：红}
    }
  },
  // 保证金比例查询
  param_getMarketParameter: {
    url: "/tclound-cacheservice/hold/nosession/getMarketParameter",
    param: {}
  },
  // 申请持仓查询 /tclound-cacheservice/hold
  param_queryFirmAppplyHold: {
    url: "/tclound-cacheservice/hold/queryFirmAppplyHold",
    param: {
      className: null,
      applyHoldStatus: null, //申请仓单状态W：待入库Y：已入库T：质检通过F：质检不通过D：已提走U：已撤销
      auditStatus: null, //审核状态W：待审核T：审核通过F：审核不通过
      endTime: null,
      holdCode: null, //货物编码
      inspectionStatus: null, //质检状态W：未质检T：已送检
      startTime: null,
      type: null, // 0：申请入库1：补入库
      commodityId: null, //产品名称id
      page: 0,
      size: 10,
      specificationsName: null //规格名称 例{颜色：红}
    }
  },
  // 确认订单之前
  param_queryFirmOrderBeforeConfirm: {
    url: "/tclound-cacheservice/hold/queryFirmOrderBeforeConfirm",
    param: {
      className: "",
      commodityId: null,
      hangEndTime: null,
      hangFirmId: null,
      hangResourceType: [],
      hangStartTime: null,
      page: 0,
      pickEndTime: null,
      pickFirmId: null,
      pickResourcesType: [],
      pickStartTime: null,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: ""
    }
  },
  // 查询委托详情
  param_queryResourceDetails: {
    url: "/tclound-cacheservice/hold/queryResourceDetails",
    param: {
      hangResourcesId: null
    }
  },
  // 竞价资源
  param_queryActionResource: {
    url: "/tclound-cacheservice/hold/queryActionResource",
    param: {
      className: "",
      commodityId: null,
      hangEndTime: null,
      hangFirmId: null,
      hangResourceType: [],
      hangStartTime: null,
      page: 0,
      pickEndTime: null,
      pickFirmId: null,
      pickResourcesType: [],
      pickStartTime: null,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  // 确认订单之前不校验
  param_queryFirmOrderBeforeConfirmA: {
    url: "/tclound-cacheservice/hold/queryFirmOrderBeforeConfirmScreening",
    param: {
      className: "",
      commodityId: 0,
      hangEndTime: 0,
      hangFirmId: 0,
      hangResourceType: [],
      hangStartTime: 0,
      page: 0,
      pickEndTime: 0,
      pickFirmId: 0,
      pickResourcesType: [],
      pickStartTime: 0,
      size: 0,
      specs: "", // 属性
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: ""
    }
  },
  //基差交易
  param_queryFirmOrderBeforeConfirmB: {
    url: "/basis-cacheservice/query/queryBasisQueryHangResourceLdNoSession",
    param: {
      auditStatus: [],
      className: "",
      commodityId: 0,
      endTime: 0,
      hangResourceType: [],
      hangResourcesId: 0,
      hangResourcesStatus: [],
      memberFirmId: 0,
      page: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: true,
        sort: {
          empty: true,
          sorted: true,
          unsorted: true
        },
        unpaged: true
      },
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: [],
      specificationsName: "",
      startTime: 0,
      storageId: 0,
      storageName: "",
      tradeDay: 0
    }
  },
  //基差交易 -> 挂单详情
  param_basisOrderInfo: {
    url: "/basis-cacheservice/query/queryBasisQueryHangResourceLdNoSession",
    param: {
      hangResourcesId: 0
    }
  },
  //基差交易 -> 买保证金摘单
  param_pickBuyBasisMarginResource: {
    url: "/basis-trade/manage/pickBuyMarginResource",
    param: {
      hangResourcesId: "", //挂单id
      pickResourcesWeight: null // 摘单重量
    }
  },
  //基差交易 => 点价
  param_dianjia: {
    url: "/tclound-financeservice/apigateway/pledgeApply",
    param: {}
  },

  // 竞价交易不校验
  param_queryActionResourceScreening: {
    url: "/tclound-cacheservice/hold/queryActionResourceScreening",
    param: {
      className: "",
      commodityId: 0,
      hangEndTime: 0,
      hangFirmId: 0,
      hangResourceType: [],
      hangStartTime: 0,
      page: 0,
      pickEndTime: 0,
      pickFirmId: 0,
      pickResourcesType: [],
      pickStartTime: 0,
      size: 0,
      specs: "", // 属性
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: ""
    }
  },
  // 确认订单之后
  param_queryFirmOrder: {
    url: "/tclound-cacheservice/hold/queryFirmOrder",
    param: {
      arrivalStatus: "", //仓储状态F：待入库T：已入库B：部分回购A：全部回购
      buyFirmId: null, //买方用户id
      commodityId: null, //
      defaultBuyStatus: null, //买方违约状态：未违约W：已延期F：已违约
      defaultSellStatus: null, //卖方违约状态N：未违约W：已延期F：已违约
      endTime: null, //
      hangResourceType: null, //挂单类型X：现货挂单 B：保证金挂单Q：求购单
      openTicketStatus: null, //开票状态W：未开O：待确认T：已完成
      page: 0,
      payStatus: null, //付款状态W：待付款F：已付首款L：已付尾款
      sellFirmId: null, //	卖方用户id
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: null, //来源W：现货J：竞价C:预售
      startTime: null,
      tradeDay: null, //交易日
      tradeStatus: null, //订单状态Z：已摘单T：已完成
      type: [] //摘单类型BO：买保证金摘BA：买全款摘SO：卖保证金摘SA：卖仓单摘
    }
  },
  param_undoPublish: {
    url: "/tclound-auction/publish/undoPublish",
    param: {
      hangResourcesId: null //验证码id
      // holdId:null,
      // type:null //摘单类型BO：买保证金摘BA：买全款摘SO：卖保证金摘SA：卖仓单摘
    }
  },
  // 查询阶梯价
  param_queryPhaseHangPrice: {
    url: "/tclound-cacheservice/hold/nosession/queryPhaseHangPrice",
    param: {
      hangResourceId: "" //挂单id
    }
  },
  // 买保证金摘单
  param_pickBuyMarginResource: {
    url: "/tclound-trade/manage/pickBuyMarginResource",
    param: {
      hangResourcesId: "", //挂单id
      pickResourcesWeight: null // 摘单重量
    }
  },
  // 买全款摘单
  param_pickBuyfullResource: {
    url: "/tclound-trade/manage/pickBuyfullResource",
    param: {
      hangResourcesId: "", //挂�����id
      pickResourcesWeight: null // 摘单重量
    }
  },
  // 买方确认摘单
  param_confirmBuyPickResource: {
    url: "/tclound-trade/manage/confirmBuyPickResource",
    param: {
      code: "",
      marginPassWord: "",
      pickResourcesId: 0
    }
  },
  // 基差买方确认摘单
  param_confirmBuyBasisPickResource: {
    url: "/basis-trade/manage/confirmBuyPickResource",
    param: {
      code: "",
      marginPassWord: "",
      pickResourcesId: 0
    }
  },
  // 卖方确认摘单
  param_confirmSellPickResource: {
    url: "/tclound-trade/manage/confirmSellPickResource",
    param: {
      code: "",
      marginPassWord: "",
      pickResourcesId: 0
    }
  },
  // 挂单方议价确认
  param_updatePickResourceBargainStatus: {
    url: "/tclound-trade/manage/updatePickResourceBargainStatus",
    param: {
      pickBargainingStatus: "", //摘单议价状态N：未议价W：已提交T：已同意F：已拒绝（操作只能传T,F）
      pickResourcesId: null //摘单id
    }
  },
  // 摘单方议价确认
  param_pickResourceBargain: {
    url: "/tclound-trade/manage/pickResourceBargain",
    param: {
      bargainPrice: "", // 议价价格
      pickResourcesId: null //摘单id
    }
  },
  // 基差挂单方议价确认
  param_updateBasisPickResourceBargainStatus: {
    url: "/basis-trade/manage/updatePickResourceBargainStatus",
    param: {
      pickBargainingStatus: "", //摘单议价状态N：未议价W：已提交T：已同意F：已拒绝（操作只能传T,F）
      pickResourcesId: null //摘单id
    }
  },
  // 基差摘单方议价确认
  param_pickBasisResourceBargain: {
    url: "/basis-trade/manage/pickResourceBargain",
    param: {
      bargainPrice: "", // 议价价格
      pickResourcesId: null //摘单id
    }
  },
  //基差挂单查询
  param_queryBasisQueryHangResourceLd: {
    url: "/basis-cacheservice/query/queryBasisQueryHangResourceLd",
    param: {
      auditStatus: [], //	 审核状态Y：待审核T：已通过F：未通过
      className: null, //
      commodityId: null, //
      endTime: null, //
      firmCorporateName: null, // 用户法人名称
      firmEnterpriseName: null, // 用户企业名称
      hangResourceType: [], //挂单类型X：现货挂单 B：保证金挂单
      hangResourcesId: null, //
      hangResourcesStatus: [], //挂单状态O：已挂单B：部分摘单A：全部摘单U：已撤销
      memberCorporateName: "", //会员法人名称
      memberEnterpriseName: "", //会员企业名称
      memberFirmId: null, //会员id
      page: 0,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: [], //来源M：初始挂单F：转让挂单
      specificationsName: "", //商品属性
      startTime: null, //
      tradeDay: null //交易日
    }
  },
  // 卖方挂基差单
  param_hangSellBaseResources: {
    url: "/basis-trade/manage/hangSellPresaleResources",
    param: {
      bargainingStatus: null, //是否可以议价T：可以F：不可以
      basisPrice: null, //基差价
      businessName: null, //业务负责人
      businessPhone: null, //
      commodityId: null, //
      deliveryTime: null, //初始交收日
      holdId: null, //持仓id
      manufacturer: null, //厂家
      quantity: null, //挂单数量
      somePriceTime: null, //点价日
      sourceId: null, //来源M：基差挂单F：转让挂单
      specificationsName: null, //
      storageId: null, //仓库id
      tradeWay: null, //A：整批P：分销
      treatyId: 1, //合约id，写死
      weight: null, //挂单重量
      weightUnitId: null //挂单重量
    }
  },
  // 卖方挂转让单
  param_transferOrder: {
    url: "/basis-trade/manage/transferOrder",
    param: {
      bargainingStatus: null, //是否可以议价T：可以F：不可以
      basisPrice: null, //基差价
      businessName: null, //业务负责人
      businessPhone: null, //
      orderId: null, //持仓id
      quantity: null, //挂单数量
      tradeWay: null, //A：整批P：分销
      treatyId: 1, //合约id，写死
      hangWeight: null //挂单重量
    }
  },
  // 查询基差单
  param_queryBasisQueryOrderLd: {
    url: "/basis-cacheservice/query/queryBasisQueryOrderLd",
    param: {
      buyFirmId: null,
      className: "",
      commodityId: null,
      endTime: null,
      hangFirmId: null,
      hangResourceType: "",
      hangResourcesId: null,
      hangResourcesStatus: [],
      memberFirmId: null,
      page: 0,
      pickFirmId: null,
      pickResourcesId: null,
      pickResourcesStatus: "",
      sellFirmId: null,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: null,
      specificationsName: "",
      startTime: null,
      tradeDay: null
    }
  },
  // 查询基差回购单
  param_queryBasisQueryRepoOrderLd: {
    url: "/basis-cacheservice/query/queryBasisQueryRepoOrderLd",
    param: {
      repoId: null, //回购id
      className: "",
      commodityId: null,
      endTime: null,
      repoStatus: null, //回购状态：W：已提交MU：会员撤销FU:用户撤销T：已完成FR:用户拒绝MR：会员拒绝
      hangResourceType: "",
      hangResourcesId: null,
      hangResourcesStatus: [],
      memberFirmId: null,
      bsFlag: null, //卖方是用户F，卖方是会员M
      page: 0,
      size: 10,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: null,
      specificationsName: "",
      startTime: null,
      tradeDay: null
    }
  },
  //基差回购重修写协议 参数发生变化
  param_queryBasisQueryRepoOrderLds: {
    url: "/basis-cacheservice/query/queryBasisQueryRepoOrderLd",
    param: {
      bsFlag: "",
      className: "",
      commodityId: 0,
      endTime: 0,
      firmRepoStatus: [],
      memberRepoStatus: [],
      page: 0,
      repoId: 0,
      repoStatus: [],
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ],
      sourceId: [],
      specificationsName: "",
      startTime: 0,
      tradeDay: 0
    }
  },
  // 质押申请
  param_pledgeApply: {
    url: "/tclound-financeservice/front/pledgeApply",
    param: {
      factorId: null, // 担保人ID
      paybackTimeId: null, // 还款期限ID
      warehousId: null //货物ID
    }
  },
  // 融资方手动还款
  param_manualPayback: {
    url: "/tclound-financeservice/front/manualPayback",
    param: {
      financeId: null // 融资单ID
    }
  },
  // 融资信息比例查询
  param_findFinanceRateInfo: {
    url: "/tclound-financeservice/front/findFinanceRateInfo",
    param: {
      factorId: null // 融资方ID
    }
  },
  // 融资方信息查询
  param_findFinanceInfo: {
    url: "/tclound-financeservice/front/findFactor",
    param: {
      warehousId: null // 仓单ID
    }
  },
  // 待质押仓单查询
  param_findFinanceWarehouseOrders: {
    url: "/tclound-financeservice/front/findFinanceWarehouseOrders",
    param: {
      fstatus: null,
      status: null, //状态0：持有1：冻结2：已售3：已提货
      type: null //0:买家，1：卖家
    }
  },
  // 质押单查询
  param_findPledgeInfoFront: {
    url: "/tclound-financeservice/front/findPledgeInfoFront",
    param: {}
  },
  // 融资单查询
  param_findFinanceInfoFront: {
    url: "/tclound-financeservice/front/findFinanceInfoFront",
    param: {}
  },
  // 融资方信息查询
  param_findFinanceInfos: {
    url: "/tclound-financeservice/front/findFinanceInfo",
    param: {
      factorId: null
    }
  },
  // 融资单详情查询
  param_findFinanceInfoDetailFront: {
    url: "/tclound-financeservice/front/findFinanceInfoDetailFront",
    param: {
      financeId: null
    }
  },
  //融资单查询（订单融资）
  param_findFinanceInfoFrontOrder: {
    url: "/tclound-financeservice/front/findFinanceInfoFrontOrder",
    param: {
      page: 0,
      size: 0,
      sort: [
        {
          direction: "",
          property: ""
        }
      ]
    }
  },
  //订单融资详情查询
  param_findFinanceInfoDetailFrontOrder: {
    url: "/tclound-financeservice/front/findFinanceInfoDetailFrontOrder",
    param: {
      financeId: null
    }
  },
  //查询资方
  param_findFactorAll: {
    url: "/tclound-financeservice/front/findFactorAll",
    param: {}
  },
  //订单申请
  param_orderApply: {
    url: "/tclound-financeservice/front/orderApply",
    param: {
      firmId: firmId,
      factorId: null,
      orderId: null,
      paybackTimeId: null
    }
  },
  param_plateNumber: {
    url: "/tclound-trade/manage/updateCarCode",
    param: {
      deliveryId: null
    }
  },
  // 根据仓库查单位
  param_unitOrWarehouse: {
    url: "/tclound-adminbiz/dictThree/findWeightUnit",
    param: {}
  },
  // 物流单信息修改
  logisticsMessageAlert: {
    url: "/tclound-trade/manage/updateLogistics",
    param: {}
  },
  // 手动还款
  param_reimbursement: {
    url: "/tclound-trade/manage/reimbursement",
    param: {}
  },

  // firmId查询
  param_firmIdSearch: {
    url: "/authn/authn/getFirmListByUserId",
    param: {}
  },
  // 登录状态监测
  param_islogin: {
    // url: "/base-desk-query/userSession/getSessionStatus",
    url: "/base/check",
    param: {}
  },
  // 查询firmid
  param_getFirmListByUserId: {
    url: "/tq-marketing-system/userController/getFirmListByUserId",
    param: {
      userId: userId
    }
  },
  // 查询市场开通状态
  param_marketState: {
    url: "/register/market/findMarketListSample",
    param: {}
  }
};
