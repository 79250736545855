import axios from "axios";
import Qs from "qs";
import store from "@/store/index";
import { Message } from "element-ui";

// 防抖使用的变量
let checkCodeTimer = true;
const marketId = 28;
//国际化
import i18n from "../../../i18n/i18n";
import { json } from "mathjs";
let chartLanguage = i18n._vm.messages[i18n._vm.locale].message;

//test
axios.interceptors.request.use(
  config => {
    // loading
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.resolve(error.response);
  }
);

function checkStatus(response) {
  // loading
  // 如果http状态码正常，则直接返回数据
  if (response) {
    return response;
    // 如果不需要除了data之外的数据，可以直接 return response.data
  }
  // 异常状态下，把错误信息返回去
  // console.log('status: -404')
  return {
    status: -404,
    msg: chartLanguage.Network_instability // 网络不稳定
  };
}

function checkCode(res) {
  // 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
  if (res.status === -404) {
    // Message({
    //     showClose: true,
    //     message: res.msg,
    //     type: 'error',
    //     duration: 2000
    // })
  }
  if (res.data) {
    if (res.data.code == -10011 || res.data.code == -2 || res.data.code == -4) {
      if (checkCodeTimer) {
        checkCodeTimer = false;
        Message({
          showClose: true,
          message: res.data.message,
          type: "error",
          duration: 2000
        });
        setTimeout(() => {
          checkCodeTimer = true;
          store.commit("setSessionInfo", "");
          store.commit("setSelectFirmApplyCommodity", "");
          sessionStorage.clear();
          global.router.push("/login");
        }, 1000);
        // return new Promise(() => {}) // 这个也许可以终止 Promise的链式调用  可以防止具体页面报错(解决办法),但是实际可能会造成内存泄露哦
      }
    } else {
      return res;
    }
    // console.log(res.data)
  } else {
    return res;
  }
}
export default {
  gete(url, param) {
    return axios({
      method: "get",
      baseURL: window.g.text_path,
      url,
      timeout: 10000,
      dataType: "jsonp",
      cache: "false",
      crossDomain: true,
      withCredentials: false,
      headers: {
        Accept: param,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4,
        clientIp: '127.0.0.1'
        // "Accept-Language":
        //   localStorage.lang || navigator.language.slice(-2).toLowerCase()
      }
      // headers: {
      //   'Access-Control-Allow-Origin': '*',
      //   'Access-Control-Allow-Methods': 'POST, PUT, DELETE, GET, OPTIONS',
      //   'Access-Control-Allow-Headers':
      //     'Origin, X-Requested-With, Content-Type, Accept, Authorization'
      // }
    })
      .then(response => {
        return response;
      })
      .then(res => {
        return res;
      });
  },
  //   operationFirmId:firmId
  postFront(data) {
    const list =  JSON.parse(localStorage.getItem('FirmIdList_w')) || []
    let marketId = list.length>0 ? list[0].marketId : 28
    if(data.param.marketId) {
      marketId = data.param.marketId
    }
    // let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        Language: "ZH",
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4,
        operationFirmId: 1
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4,
        clientIp: '127.0.0.1',
        operationFirmId: 1
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFront1(data) {
    let marketId = JSON.parse(sessionStorage.getItem("marketId_w"))
      ? sessionStorage.getItem("marketId_w")
      : 28;
    // let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        Language: "ZH",
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH1,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFrontM18(data) {
    // let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: 18,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFrontGX(data) {
    let firmId = localStorage.getItem("atPresentFirmId") || 0;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: 28,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFrontMarket(data) {
    // let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let marketId = JSON.parse(sessionStorage.getItem("marketId_w")) || 28;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFrontLST(data) {
    let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: 18,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFrontZYQ(data) {
    let firmId = store.state.common.sessionInfo.firmId;
    let operationFirmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: 18,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4

        // operationFirmId: operationFirmId
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postBasisFront(data) {
    // let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    // userId='100010';
    return axios({
      method: "post",
      baseURL: window.g.APP_BASISUSER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postReport(data) {
    // let firmId = store.state.common.sessionInfo.firmId;
    let sessionStr = store.state.common.sessionInfo.sessionStr;
    let userId = store.state.common.sessionInfo.userId;
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    return axios({
      responseType: "blob",
      method: "post",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  getRes(data) {
    const options = {
      method: "get",
      baseURL: window.g.APP_USER_PATH,
      url: data.url,
      // `params` 是即将与请求一起发送的 URL 参数
      // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
      params: data.param,
      // `paramsSerializer` 是一个负责 `params` 序列化的函数
      // (e.g. https://www.npmjs.com/package/qs, http://api.jquery.com/jquery.param/)
      // qs.stringify({ids: [1, 2, 3]}, {arrayFormat: 'indices'})
      // 形式： ids[0]=1&aids1]=2&ids[2]=3
      // qs.stringify({ids: [1, 2, 3]}, {arrayFormat: 'brackets'})
      // 形式：ids[]=1&ids[]=2&ids[]=3
      // qs.stringify({ids: [1, 2, 3]}, {arrayFormat: 'repeat'})
      // 形式： ids=1&ids=2&id=3
      paramsSerializer: function(params) {
        return Qs.stringify(params, { arrayFormat: "repeat" });
      },
      dataType: "json",
      timeout: window.g.AXIOS_TIMEOUT
    };
    if (store.state.common.sessionInfo.isLogin === "true") {
      let sessionStr = store.state.common.sessionInfo.sessionStr;
      let userId = store.state.common.sessionInfo.userId;
      let marketId = JSON.parse(sessionStorage.getItem("marketId_w"))
        ? sessionStorage.getItem("marketId_w")
        : 28;
      const Authorization =
        userId + "," + sessionStr + "," + data.url + "," + marketId;
      options.headers = {
        Authorization,
        // firmId: store.state.common.sessionInfo.firmId,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      // delete options.headers;
      options.headers = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    }
    return axios(options)
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postNewJP(data) {
    const userId = store.state.common.sessionInfo.userId;
    const sessionStr = store.state.common.sessionInfo.sessionStr;
    // const firmId = store.state.common.sessionInfo.firmId
    let headersObj = {};
    const Authorization =
      userId + "," + sessionStr + "," + data.url + "," + marketId;
    if (userId && sessionStr && userId != null && sessionStr != null) {
      headersObj = {
        // firmId: firmId,
        Authorization,
        sessionStr: sessionStr,
        userId: userId,
        marketId: marketId,
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      };
    } else {
      headersObj = {};
    }
    return axios({
      method: "post",
      baseURL: window.g.APP_JP_PATH,
      url: data.url,
      data: data.param,
      dataType: "json",
      headers: headersObj,
      timeout: window.g.AXIOS_TIMEOUT
    })
      .then(response => {
        return checkStatus(response);
      })
      .then(res => {
        return checkCode(res);
      });
  },
  postFile(data) {
    // const firmId = store.state.common.sessionInfo.firmId
    const userId = store.state.common.sessionInfo.userId
    const sessionStr = store.state.common.sessionInfo.sessionStr
    const options = {
      method: 'post',
      baseURL: window.g.APP_URL_PATH_UPLOAD,
      url: data.url,
      data: data,
      dataType: 'json',
      timeout: window.g.AXIOS_TIMEOUT
    }
    if (userId && sessionStr && userId != null && sessionStr != null) {
      options.headers = {
        'User-Id': userId,
        // firmId: firmId,
        'Session-Str': sessionStr,
        marketId: marketId,
        Language: "ZH",
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      }
    } else {
      options.headers = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      }
    }
    return axios(options)
      .then(response => {
        return checkStatus(response)
      })
      .then(res => {
        return checkCode(res)
      })
  },
  postFileJPG(data) {
    // const firmId = store.state.common.sessionInfo.firmId
    const userId = store.state.common.sessionInfo.userId
    const sessionStr = store.state.common.sessionInfo.sessionStr
    const options = {
      method: 'post',
      baseURL: window.g.APP_USER_PATH_UPLOAD,
      url: data.url,
      data: data,
      dataType: 'json',
      timeout: window.g.AXIOS_TIMEOUT
    }
    if (userId && sessionStr && userId != null && sessionStr != null) {
      options.headers = {
        'User-Id': userId,
        // firmId: firmId,
        'Session-Str': sessionStr,
        marketId: marketId,
        Language: "ZH",
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      }
    } else {
      options.headers = {
        terminalType: localStorage.getItem("equipmentFlag_PC") || 4
      }
    }
    return axios(options)
      .then(response => {
        return checkStatus(response)
      })
      .then(res => {
        return checkCode(res)
      })
  },
};
