import Vue from "vue";
import App from "./App";
import store from "./store";
import router from "./router";

// 外部插件引入
import VueBus from "vue-bus";
import echarts from "echarts";
import vueEsign from 'vue-esign';
import WujieVue from "wujie-vue2";
import ElementUI from "element-ui";
import VueClipboard from "vue-clipboard2";
import vueSeamless from "vue-seamless-scroll";
import SlideVerify from "vue-monoplasty-slide-verify";
import Ripple from 'vue-ripple-directive'
import Sticky from 'vue-sticky-directive'

// 本地配置引入
import i18n from "./i18n/i18n";
import hostMap from "./hostMap";
import lifecycles from "./lifecycles";
import credentialsFetch from "./fetch";

// 本地API引入
import http from "./assets/js/trans/http.js";
import { hqWsZyq } from "./assets/js/zyqHq/hqWs.js";
import protocol from "./assets/js/trans/protocol.js";
import hqProtocol from "./assets/js/hq/hqProtocol.js";
import protocolFwd from "./assets/js/trans/protocolFwd.js";
import protocolNJP from "./assets/js/trans/protocolNJP.js";
import protocolUOA from "./assets/js/trans/protocolUOA.js";
import hqProtocolNon from "./assets/js/hq/hqProtocolNon.js";
import hqProtocolZyq from "./assets/js/zyqHq/hqProtocol.js";

// 本地工具引入
import * as util from "./assets/common/util.js";
import lineUtil from "./assets/js/hq/lineUtil.js";
import vueFilter from "./filters/filter";

// css引入
import "element-ui/lib/theme-chalk/index.css";
import "font-awesome/css/font-awesome.css";

// global全局注册
global.hqProtocolNon = hqProtocolNon;
global.lineUtil = lineUtil;
global.protocol = protocol;
global.http = http;
global.hqProtocol = hqProtocol;
global.util = util;
global.router = router;
global.protocolFwd = protocolFwd;
global.protocolNJP = protocolNJP;
global.protocolUOA = protocolUOA;
global.i18nLanguage = i18n;
global.hqProtocolZyq = hqProtocolZyq;
global.hqWsZyq = hqWsZyq;

// vue原型上注册
Vue.prototype.$echarts = echarts;

// vue插件插件注册
Vue.use(ElementUI)
Vue.use(vueSeamless);
Vue.use(SlideVerify);
Vue.use(vueEsign);
Vue.use(VueClipboard);
Vue.use(VueBus);
Vue.use(WujieVue);
Vue.use(Sticky)

// vue钩子
Ripple.color = 'rgba(202,202,202,0.35)';
Ripple.zIndex = 55;
Vue.directive('ripple', Ripple);

// 默认函数引入
import "./directives";
import "./prototypeFunctions"
let chartLanguage = i18n._vm.messages[i18n._vm.locale].message;

/**** 钩子 ************************************************************************************/
router.beforeEach((to, from, next) => {
  if (to.meta.isController) {
    store.commit("setIsIfremeController", true);
  } else {
    store.commit("setIsIfremeController", false);
  }
  if (to.meta.isIndex) {
    store.commit("changeIsIndex", true);
  } else {
    store.commit("changeIsIndex", false);
  }
  if (
    sessionStorage.getItem("sessionStr") &&
    sessionStorage.getItem("firmId")
  ) {
    next();
  } else {
    if (to.meta.requireAuth) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    } else {
      next();
    }
  }
});
/**** 过滤器 ************************************************************************************/
Object.keys(vueFilter).forEach(key => {
  Vue.filter(key, vueFilter[key]);
});
/**** ************************************************************************************/

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  store,
  i18n,
  components: {
    App
  },
  template: "<App/>"
});

/**** 注册子应用 ***********************************************************************************/
const { setupApp, preloadApp, bus } = WujieVue;
setupApp({
  name: "module_xh",
  url: hostMap("//localhost:3000/"),
  exec: true,
  sync: true,
  fetch: credentialsFetch,
  ...lifecycles,
})
setupApp({
  name: "module_bidding",
  url: hostMap("//localhost:3001/"),
  exec: true,
  sync: true,
  fetch: credentialsFetch,
  ...lifecycles,
})
// setupApp({
//   name: "module_ysjy",
//   url: hostMap("//localhost:3002/"),
//   exec: true,
//   sync: true,
//   fetch: credentialsFetch,
//   ...lifecycles,
// })

preloadApp({
  name: "module_xh",
  url: hostMap("//localhost:3000/"),
  exec: true,
  sync: true,
  fetch: credentialsFetch,
  ...lifecycles,
})
preloadApp({
  name: "module_bidding",
  url: hostMap("//localhost:3001/"),
  exec: true,
  sync: true,
  fetch: credentialsFetch,
  ...lifecycles,
})
// preloadApp({
//   name: "module_ysjy",
//   url: hostMap("//localhost:3002/"),
//   exec: true,
//   sync: true,
//   fetch: credentialsFetch,
//   ...lifecycles,
// })

/****************************************************************************************/
