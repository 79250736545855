import store from "@/store/index";

let userId = store.state.common.sessionInfo.userId;
let sessionStr = store.state.common.sessionInfo.sessionStr;
let firmId = store.state.common.sessionInfo.firmId;
let firmCode = store.state.common.sessionInfo.firmCode;
let tel = store.state.common.sessionInfo.tel;
let i = {
  margin: {
    fid: "magin",
    bid: {
      addCurrency: "currency",
      moneyio: "myio",
      createUSDT: "create",
      orderstatus: "orderstatus",
      createinfo: "createinfo",
      outdone: "outdone",
      updateinfo: "updateinfo",
      tmy: "tmy",
      infiat: "infiat"
    }
  },
  msg: {
    fid: "message",
    bid: {
      getMessages: "getmessages",
      validCaptcha: "valid",
      sendMessage: "sendmessage",
      valid: "valid"
    }
  },
  session: {
    fid: "session",
    bid: {
      login: "login",
      logout: "logout",
      authorizeSession: "authorize",
      validCapt: "validCapt",
      captcha: "captcha",
      authorize: "authorize",
      updatePassword: "password"
    }
  },
  base: {
    fid: "base",
    bid: {
      addCustomer: "customer",
      authentication: "authentication",
      updatecode: "updatecode",
      queryInvitationCode: "invitationcode",
      queryCustomer: "queryCustomer",
      contactInfo: "contactInfo",
      updateName: "updateName",
      forgetPwd: "pass",
      phoneIsUsed: "query_code",
      useauthenticate: "useauthenticate"
    }
  },
  upload: {
    fid: "upload",
    bid: {
      upload: "base64",
      appeal: "appeal",
      listcert: "listcert"
    }
  },
  trade: {
    fid: "trade",
    bid: {
      addLimitOrder: "addLimitOrder",
      cancelOrder: "cancelOrder"
    }
  },
  queryService: {
    fid: "query",
    bid: {
      getMyAsset: "getAccount",
      getMyMarginRecord: "account",
      getadverinfo: "getadverinfo",
      getMyOtcAccount: "accountinfo",
      getGvflows: "getGvflows",
      getnicename: "getnicename",
      type: "type",
      getuser: "getuser",
      getPayMethod: "getname",
      getNickNameStatus: "getusername",
      getOtcOrderByOrderId: "getIoId",
      getLegaitem: "getLegaitem",
      hignCerStatus: "getUserImg",
      getArticle: "getArticle",
      getCurrencyinfo: "getCurrencyinfo",
      invitedList: "getInvitationCode",
      btrade: "btrade",
      order: "order",
      hisorder: "hisorder",
      rate: "rate",
      getMyOtherMarginRecord: "other",
      getCommodity: "getCommdity",
      getcounty: "getcounty",
      othermak: "othermak",
      listrecord: "listrecord",
      otcrecord: "otcrecord",
      mainrecord: "mainrecord",
      qqrecord: "qqrecord",
      otherHistory: "otherHistory",
      btradeh: "btradeh",
      listHistory: "listHistory",
      record: "record"
    }
  },
  bcncacheService: {
    fid: "cache",
    bid: {}
  },
  proxy: {
    fid: "proxy",
    bid: {
      insertAuAppeal: "insertAuAppeal"
    }
  },
  current_query: {
    fid: "current_query",
    bid: {
      query_cell: "query_cell"
    }
  },
  //挂牌
  //后期会删
  funds: {
    fid: "funds",
    bid: {
      outfrom: "outfrom",
      infrom: "infrom"
    }
  }
  //挂牌End
};

export default {
  // 获取图片验证码
  param_getCaptcha: {
    url: "/authn/authn/generateCaptcha",
    param: {}
  },
  // 校验圖片验证码
  param_validImgCaptcha: {
    url: "/bcnsessionservice/session/validCapt",
    param: {
      capid: "FE1973ECAC0D95A81E3197D8E177299E",
      capcode: "SC6Y"
    }
  },
  // 退出
  param_logout: {
    // url: "/authn/authn/logout",
    url: "/authn/authn/v1/logout",
    param: {
      terminalType: 4
    }
  },
  // 登录
  param_authn_authn_login: {
    // url: "/authn/authn/newLogin",
    // url: "/authn/authn/newLoginWithoutMarketId",
    url: "/authn/authn/v1/frontSimpleLogin", // 新 登陆不传市场id
    param: {
      captchaCode: "",
      captchaId: "",
      loginAccount: "",
      // marketId: 28, // 固定
      terminalType: 4,
      type: "", // 登录方式 1 ：账号密码登录 2 短信验证码登录
      // code: "",
      password: "",
      loginWay: 1
    }
  },
  // 注册
  param_adminRegister: {
    url: "/register/register/customer",
    param: {
      captchaCode: "",
      captchaId: "",
      messageCode: "",
      createWay: 2, // 开户通道 1 管理后台 2 网上开户
      marketId: 28,
      mobilePhone: "",
      password: "",
      userName: "", // 用户名称
      userType: 1 // 用户类型 A:交易所管理用户 M:会员管理用户 T:交易员
    }
  },
  //基础服务校验短信新 注册接口
  param_adminRegister2: {
    // url: "/register/register/registerCustomer",
    url: "/register/register/v1/addFrontSimpleUser", // (新 不传市场id)
    param: {
      templateId: 53,
      code: "", // 短信验证码
      createWay: 2, // 开户通道（1:管理后台 2:网上开户）
      firmTypeId: 1, // 用户类型 （1:交易员 9:交易所）
      lnvitationCode: "", // 邀请码
      loginAccount: "", // 登录账号
      // marketId: 28, // 市场id
      password: "", // 密码
      registerWay: 1, // 注册方式 （1:手机号注册 2:邮箱注册）
      // firstMember: true,// 管理后台用
      lnvitationCodeMustFillIn: true, // 是否校验邀请码
      messageAuthentication: true, // 是否校验短信验证码
      // userCode: '',//客户编码
      userName: "" //客户名称
    }
  },
  //校验短信新 注册接口
  param_adminRegister1: {
    url: "/tclound-adminbiz/userRegister/registerUser",
    param: {
      captchaCode: "",
      captchaId: "",
      messageCode: "",
      createWay: 2, // 开户通道 1 管理后台 2 网上开户
      marketId: 28,
      mobilePhone: "",
      password: "",
      userName: "", // 用户名称
      userType: 1 // 用户类型 A:交易所管理用户 M:会员管理用户 T:交易员
    }
  },
  // 发送验证码
  param_authn_authn_sendCaptcha: {
    url: "/authn/authn/sendCaptcha",
    param: {
      loginAccount: "",
      mark: "SMS_179220765" // 根据短信模版固定
    }
  },
  // 资金密码短信验证码
  param_capitalMesSend: {
    url: "tq-marketing-system/operate/sendSMS",
    param: {}
  },
  // 生成邀请码
  param_generateCode: {
    url: "/base-desk-manage/baseUserBindController/generateCode",
    param: {
      cellphone: null, // 被推荐人手机号
      companyId: null // 短信模板id
    }
  },
  // 新注册
  param_newRegister: {
    url: "/auction-reg/auctionRegister/register",
    param: {
      captchaId: "",
      captchaCode: "",
      mobilePhone: "",
      messageCode: "",
      createWay: "2",
      userType: "1",
      password: "",
      userName: "",
      marketId: 28,
      inviteCode: ""
    }
  },
  // 发送验证码(资金用)
  param_authn_margin_sendCaptcha: {
    url: "/tclound-msg/manage/sendCaptcha",
    param: {
      id: 30
    }
  },
  // 短信驗證碼校驗
  param_validMobileCaptcha: {
    url: "/bcnmessageservice/tradeMessage/valid",
    param: {
      capid: "18222826217",
      capcode: ""
    }
  },
  // 资金划转市场列表
  param_findAccountByUserId: {
    url: "/tq-marketing-system/operate/findAccountByUserId",
    param: {}
  },
  // 心跳
  param_queryTradeClosepl: {
    url: "/tq-trade-query/query/queryTradeClosepl",
    param: {}
  },
  //资金划转(多市场间划转)
  param_transferFundsOwn: {
    url: "/tq-marketing-system/operate/transferFundsOwn",
    param: {
      amount: 0,
      fromUserAccountId: 0,
      toUserAccountId: 0
    }
  },
  // 获取verifyToken
  param_getVerifyToken: {
    url: "/tq-marketing-system/authnController/getVerifyToken",
    param: {}
  },
  // 获取认证信息(认证前)
  param_getIdentificationInfo: {
    url: "/tq-marketing-system/authnController/getIdentificationInfo",
    param: {}
  },
  // iframe页面通信接口
  param_iframeMessage: {
    url: "/tq-marketing-system/userController/getAuditNumber",
    param: {}
  },
  // 企业认证信息查询
  param_enterpriseCertification: {
    url: "base-desk-query/baseUserAudit/findBaseUserAuditByUserIdWithSecond",
    param: {}
  },
  // 公告(分市场)
  param_noticeListMarket: {
    // url: '/content/lbt/noticeMap',
    url: "/content/content/noticeList",
    param: {}
  },
  // 获得某栏目下的子栏目及子栏目的部分内容 get
  param_lowerColumnAndTheirContent: {
    // "fid": i.queryService.fid,
    // "bid": i.queryService.bid.getArticle,
    url: "/content/content/lowerColumnAndTheirContent",
    param: {
      page: "1",
      size: "20",
      columnId: "3",
      sort: "sequence,desc"
    }
  },
  // 查询个人绑定组织记录
  param_getBaseUserBindByUserId: {
    url: "/base-desk-query/baseUserBindController/getBaseUserBindByUserId",
    param: {}
  },
  // 获取首页logo
  param_getIndexLogo: {
    url: "content/content/content",
    param: {}
  },
  // 开通市场(不传邀请码)
  param_dredgeBazaar: {
    // url: "/register/market/registerSingleMarketNew",
    url: "/register/market/v1/addSimpleRareFrontFirm",
    param: {
      templateId: 44
    }
  },
  // 开通市场(传邀请码)
  param_dredgeBazaars: {
    // url: "/register/market/registerSingleMarketNew",
    // url: "/register/market/v1/addSimpleFrontFirm",
    url: "/register/market/v1/addFrontFirm",
    param: {
      templateId: 44
    }
  },
  // 查询试卷
  param_searchQuestinbankfrontquery: {
    url: "/base-questinbankfrontquery/examFrontQuery/findExam",
    param: {}
  },
  // 获取所有考试信息
  param_allSearchQuestinbankfrontquery: {
    url: "/base-questinbankfrontquery/examFrontQuery/getAllExam",
    param: {}
  },
  // 试卷提交
  param_examinationSubmit: {
    url: "/base-questinbankfront/examFront/uploadAnswer",
    param: {}
  },
  // 查询考试是否通过
  param_questinbankfrontquery: {
    url: "/base-questinbankfrontquery/examFrontQuery/findExamIsPass",
    param: {}
  },
  // 委托查询
  queryUserEntrusts: {
    url: "/tq-trade-query/tradeQuery/queryOrderFront",
    param: {
      page: "0",
      size: "20",
      firmId: firmId,
      bsFlag: null,
      // "commodityId": "1",
      orderType: null,
      sort: [
        {
          direction: "DESC",
          property: "create_time"
        }
      ]
    }
  },
  // 商品策略查询
  param_queryCommodityStrategy: {
    url: "/tq-trade-query/strategy/queryCommodityStrategy",
    param: {}
  },
  // 查询是否确认协议
  param_agreementEnter: {
    url: "/base-desk-query/user/findByEntryUserId",
    param: {}
  },
  // 确认协议
  param_enterAgreement: {
    url: "/base-desk-manage/user/v1/addEntryAgreement",
    param: {}
  },
  // 忘记登录密码
  param_forgetLoginPwd: {
    url: "/authn/authn/v1/frontForgetPwd",
    param: {
      checkWay: 1,
      templateId: 49
    }
  },
  // 发送短信
  param_sendMes: {
    url: "/base-margin-service/margin/sendSMS",
    param: {
      bankId: 22
    }
  },
  // 忘记资金密码
  param_forgetPws: {
    url: "/base-margin-service/margin/updateFundPwdEndUserSMS",
    param: {
      bankId: 22
    }
  },
  // 认证类型选择
  param_certificationTypeSave: {
    url: "/base-desk-manage/baseUserAudit/v1/saveBaseOptAuditType",
    param: {} // auditType 认证类型（O:组织认证 P:个人认证）
  },
  // 认证类型查询
  param_queryCertificationType: {
    url: "/base-desk-query/baseUserAudit/v1/getBaseOptAuditType",
    param: {}
  },
  // 查询今日密码输入次数
  param_queryPwdCount: {
    url: "base-desk-query/baseUserAudit/v1/getPassError",
    param: {}
  }
};
