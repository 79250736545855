const state = {
  searchCommodity: {},
  searchText: null,
  getMarginRate: {},
  selectHelpName: null,
  headerText: "",
  isCollapse: false,
  firmList: [],
  isIfremeController: false, //记录状态是否为iframe
  isFlagss: 99999,
  rzFlag: 1
};

const getters = {
  firmListGetter: state => state.firmList,
  searchCommodityGetter: state => state.searchCommodity,
  searchTextGetter: state => state.searchText,
  getMarginRateGetter: state => state.getMarginRate,
  selectHelpNameGetter: state => state.selectHelpName,
  setIsFlagssGetter: state => state.isFlagss
};

const actions = {};

const mutations = {
  setIsFlagss(state, data) {
    state.isFlagss = data;
  },
  setFirmList(state, data) {
    state.firmList = data;
  },
  setSearchCommodity(state, data) {
    state.searchCommodity = data;
  },
  setSearchText(state, data) {
    state.searchText = data;
  },
  setMarginRate(state, data) {
    state.getMarginRate = data;
  },
  setSelectHelpName(state, data) {
    state.selectHelpName = data;
  },
  setHeaderText(state, data) {
    state.headerText = data;
  },
  setIsCollapse(state, data) {
    state.isCollapse = !state.isCollapse;
  },
  setIsIfremeController(state, data) {
    state.isIfremeController = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
