
const devMap = {
  "//localhost:3000/": `http://192.168.1.143:3000/module_xh/`,
  "//localhost:3001/": `http://192.168.1.143:3001/module_bidding/`,
  "//localhost:3002/": `http://192.168.1.143:3002/module_ysjy/`,
}
const prodMap = {
    "//localhost:3000/": `//${window.location.host}/module_xh/`,
    "//localhost:3001/": `//${window.location.host}/module_bidding/`,
    "//localhost:3002/": `//${window.location.host}/module_ysjy/`,
}

export default function hostMap(host) {
  if (process.env.NODE_ENV === "production") return prodMap[host];
  return devMap[host]
}
