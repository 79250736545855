import { networkInterfaces } from "os";

//修改返回数值样式
//输入参数是秒
// 负数千分位处理
function formatNum(strNum) {
  var outputmoney = function(number) {
    number = number.replace(/\,/g, '') // 把所有的逗号，清除
    if (isNaN(number) || number === '') return ''
    number = Math.round(number * 100) / 100
    if (number < 0) { return '-' + outputdollars(Math.floor(Math.abs(number) - 0) + '') + outputcents(Math.abs(number) - 0) } else { return outputdollars(Math.floor(number - 0) + '') + outputcents(number - 0) }
  }
  // 格式化金额
  var outputdollars = function(number) {
    if (number.length <= 3) { return (number === '' ? '0' : number) } else {
      var mod = number.length % 3
      var output = (mod === 0 ? '' : (number.substring(0, mod)))
      for (let i = 0; i < Math.floor(number.length / 3); i++) {
        if ((mod === 0) && (i === 0)) { output += number.substring(mod + 3 * i, mod + 3 * i + 3) } else { output += ',' + number.substring(mod + 3 * i, mod + 3 * i + 3) }
      }
      return (output)
    }
  }
  var outputcents = function(amount) {
    amount = Math.round(((amount) - Math.floor(amount)) * 100)
    return (amount < 10 ? '.0' + amount : '.' + amount)
  }
  return outputmoney(strNum + '')
}
function getLocalTime(nS1) {
    Date.prototype.Format = function(fmt) { //author: meizz
        let o = {
            "M+": this.getMonth() + 1, //月份
            "d+": this.getDate(), //日
            "h+": this.getHours(), //小时
            "m+": this.getMinutes(), //分
            "s+": this.getSeconds(), //秒
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度
            "S": this.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    };
    let nn = new Date(parseInt(nS1) * 1000).Format("yyyy-MM-dd hh:mm:ss");
    return nn;
}
function formatnewDay(timestamp) {
  if (timestamp) {
    var date = timestamp
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate();
    [year, month, day] = [year, month, day].map(item => item < 10 ? '0' + item : item)
    return `${year}${month}${day}`
  }
}

function dateFormate(timer) {    
    let date = new Date(Number(timer));
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let min = date.getMinutes()
    let sec = date.getSeconds();
    [year, month, day, hour, min, sec] = [
      year,
      month,
      day,
      hour,
      min,
      sec
    ].map(item => (item < 10 ? '0' + item : item))
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`
  }  

  function getTimes(str) {    
    let len = 0;
    try {
        len = str.toString().split(".")[1].length; //计算出小数点后有几位
    } catch (e) {
        len = 0; //小数点后没有东西，就默认为零位
    }
    let m = Math.pow(10, len);
    return m;
  }  

  function standardDateFormat(timer) {
    let datetime;
    datetime = timer.getFullYear() + '-' + (timer.getMonth() + 1) + '-' + timer.getDate() + ' ' + timer.getHours() + ':' + timer.getMinutes() + ':' + timer.getSeconds();
    return datetime;
  }

  function floatAdd(arg1, arg2) {
    let r1, r2, m;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    let n=Math.max(r1, r2);
    m = Math.pow(10, n)
    return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

function floatSub(arg1, arg2) {    
    let r1, r2, m, n;
    try { r1 = arg1.toString().split(".")[1].length } catch (e) { r1 = 0 }
    try { r2 = arg2.toString().split(".")[1].length } catch (e) { r2 = 0 }
    m = Math.pow(10, Math.max(r1, r2));
    //last modify by deeka
    //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

function floatMul(arg1, arg2) {
    if (arg1 && arg2) {
        let m = 0,
            s1 = Number(arg1).toString(),
            s2 = Number(arg2).toString();
        try { m += s1.split(".")[1].length } catch (e) {}
        try { m += s2.split(".")[1].length } catch (e) {}        
        return (Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m)).toFixed(2)
    }else{
        return 0;
    }

}

//浮点数除法运算
function floatDiv(arg1,arg2){
    arg1=Number(arg1);
    arg2=Number(arg2);   
   var t1=0,t2=0,r1,r2;
   try{t1=arg1.toString().split(".")[1].length}catch(e){}
   try{t2=arg2.toString().split(".")[1].length}catch(e){}
   r1=Number(arg1.toString().replace(".",""));
   r2=Number(arg2.toString().replace(".",""));
   let n=0;
   if(t2>t1){
        n=t2-t1;
   }else{
       n=t1-t2;
   }
   return ((r1/r2)*Math.pow(10,t2-t1)).toFixed(n);

}
//截取图片
function getSmallBase64(url, callback) {
    var Img = new Image(),
        dataURL = '',
        drawEncoderOptions = 40000.0 / url.length > 1 ? 0.92 : 40000.0 / url.length;
    Img.src = 'data:image/jpeg;base64,' + url;
    Img.onload = function() { //异步
        var canvas = document.createElement("canvas"),
            width = Img.width,
            height = Img.height;
        canvas.width = 300;
        canvas.height = 300.0 * (height / width);
        canvas.getContext("2d").drawImage(Img, 0, 0, 300, 300 * (height / width));
        dataURL = canvas.toDataURL('image/jpeg');
        let i = dataURL.indexOf(","); // 截取data:image/png;base64,后的内容
        let imageName = dataURL.substring(i + 1, dataURL.length); //把图片的二进制流付给表单ruleFinancingForm里面的idImage
        callback ? callback(imageName, drawEncoderOptions) : null;
    }
}
function getmonday() {
    let now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    let nowTime = now.getTime();
    let day = now.getDay();
    let oneDayLong = 24 * 60 * 60 * 1000;
    let mondayTime = nowTime - (day - 1) * oneDayLong ;
    return mondayTime;
}
function exactPointTwo(num, arrIndex, arrProperty){
    let newVal;
    num = Number(num);
    if (num < 0) {
      num = num.toString().substring(1);
      newVal = Number(num.toString().match(/^\d+(?:\.\d{0,2})?/)) * -1;
    } else {
      newVal = Number(num.toString().match(/^\d+(?:\.\d{0,2})?/));
    }
    //整数自动补0
    if (newVal.toString().indexOf(".") == -1) {
      newVal += '.00';
    }
    let len = newVal.toString().indexOf('.') + 1;
    //1位数自动补0
    if (newVal.toString().substring(len).split('').length == 1) {
      newVal += '0';
    }
    if (arrProperty) {
      arrIndex[arrProperty] = newVal;
    }
    return newVal;
  }
  //判断并转换时间戳
  function DateFormate(timer){
      var date = new Date(timer);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var min = date.getMinutes();
      var sec = date.getSeconds();
      [year, month, day, hour, min, sec] = [year, month, day, hour, min, sec].map(item => item < 10 ? "0" + item : item);
      return `${year}-${month}-${day} ${hour}:${min}:${sec}`;
    }  
  export { formatNum,getLocalTime, formatnewDay, dateFormate, standardDateFormat, getTimes, floatAdd, floatSub, floatMul, floatDiv,getSmallBase64, getmonday ,exactPointTwo,DateFormate}
