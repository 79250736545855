<template>
  <div id="app">
    <router-view />
    <el-backtop :bottom="100" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      userMes: "",
      showUpdater: false,
      downloadProcess: null,
      timer: null,
      heartbeat: null,
      runloopTimer: null
    };
  },
  computed: {
    ...mapGetters([
      "currentCommodityInfoGetter",
      "timeLineDataGetter",
      "kLineDataGetter",
      "currencyData",
      "sessionInfoGetter",
      "marketDetails"
    ])
  },
  created() {
    this.runloopTimer = null;
  },
  mounted() {
    localStorage.setItem("equipmentFlag_PC", 4);
    this.initZyqHq();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }

    if (this.runloopTimer) {
      window.clearInterval(this.runloopTimer);
      this.runloopTimer = null;
    }
  },
  methods: {
    // 查询当前交易id
    queryTradeClosepl() {
      if (sessionStorage.getItem("sessionStr") && sessionStorage.getItem("userId")) {
        http.postFront(protocol.param_queryTradeClosepl).then(response => {
          const { code, value } = response.data;
          if (Number(code) === 0) {
            if (value) {
              this.runloopQueryTradeClosepl();
            } else {
              this.runloopQueryTradeClosepl();
            }
          } else {
            this.runloopQueryTradeClosepl();
          }
        });
      } else {
        this.runloopQueryTradeClosepl();
      }
    },
    // 心跳
    runloopQueryTradeClosepl() {
      const that = this;
      this.runloopTimer = setInterval(
        () => {
          // if (this.isOpenN) {
          if (sessionStorage.getItem("sessionStr") && sessionStorage.getItem("userId")) {
            http.postFront(protocol.param_queryTradeClosepl).then(response => {
              if (response.data) {
                const { code, value } = response.data;
                if (Number(code) === 0) {
                  if (value) {
                    if (value.contentSystem) {
                      // 公告提醒
                      if (value.contentSystem.content) {
                        if (!this.heartbeat) {
                          this.heartbeat = value.contentSystem.content;
                        } else {
                          if (this.heartbeat != value.contentSystem.content) {
                            this.$store.commit("setHeartbeatFlag", true);
                            this.heartbeat = value.contentSystem.content;
                          } else {
                            this.$store.commit("setHeartbeatFlag", false);
                          }
                        }
                      }
                    }
                  } else {
                  }
                }
              }
            });
          }
        },
        window.g.APP_RUNLOOP_TIME ? window.g.APP_RUNLOOP_TIME : 60000
      );
    },
    // 接入中远期行情
    initZyqHq() {
      hqWsZyq.register(this.DispathSocketEvent);
      hqWsZyq.send(hqProtocolZyq.param_USDT);
      this.timer = setInterval(() => {
        const sendData = {
          fid: "ping-req"
        };
        hqWsZyq.send(sendData);
      }, 10000);
    },
    // 中远期行情socket接收消息分发函数
    DispathSocketEvent(eventData) {
      switch (eventData.fid) {
        case "marketdetail-resp":
          {
            const data = eventData.marketDetails;
            this.$store.commit("updateMarketDetails", data);
            const contractIds = [];
            for (const i in data) {
              if (Object.prototype.hasOwnProperty.call(data, i)) {
                const element = data[i];
                contractIds.push(element.symbolId);
              }
            }
            hqProtocolZyq.param_subscribe.contractIds = contractIds;
            hqWsZyq.send(hqProtocolZyq.param_subscribe);
          }
          break;
        case "top-resp":
          {
            const buyLst = eventData.buyLst;
            this.$store.commit("buyLst", buyLst);
            const sellLst = eventData.sellLst;
            this.$store.commit("sellLst", sellLst);
            this.$store.commit("buyMaxAmount", eventData.maxBuyAmount);
            this.$store.commit("sellMaxAmount", eventData.maxSellAmount);
          }
          break;
        case "pricedetail-resp":
          {
            const pricedetailData = eventData;
            this.$store.commit("pricedetail", pricedetailData);
          }
          break;
        case "PushPriceDetailResponse":
          {
            const PushPriceDetailResponseData = eventData.respList;
            this.$store.commit(
              "PushPriceDetailResponse",
              PushPriceDetailResponseData
            );
          }
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  background-color: $bodyBgColor;
}
* {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: "Microsoft YaHei";
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
#app {
  min-width: 1300px;
}




/*清除浮动*/
.cleartext:after {
  content: "";
  height: 0;
  line-height: 0;
  visibility: hidden;
  clear: both;
  display: block;
}
.clearfix {
  zoom: 1;
}
#slider img {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  max-height: 440px;
}
// .el-carousel__container{
//   height:100%;
// }
// #slider img{
//   min-width: 1300px;
//   // min-height: 440px;
//   width:100vw;
//   height:25vw;
// }
.el-pagination {
    text-align: center;
    margin-top: 20px;
}
.el-pagination .el-select .el-input .el-input__inner {
    background: #F1F5F8;
    border: none;
}
.el-pagination__editor.el-input .el-input__inner{
    background: #F1F5F8;
    border: none;
}
.el-pagination__editor{
  margin: 0 10px !important;
}

.el-step__description.is-process {
  color: $commonThemeColor !important;
}
.el-step__title.is-process {
  color: $commonThemeColor !important;
}
.el-step .is-process .el-step__icon.is-text {
  border-color: $commonThemeColor;
  color: $commonThemeColor;
}
.el-tabs__item.is-active {
  color: #1989fa;
}
.el-tabs__active-bar {
  background-color: #1989fa;
}
.el-tabs__item:hover {
  color: $commonThemeColor;
}
// 头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  background-color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff !important;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
// 通用
.width-100 {
  width: 100% !important;
  // width: calc(100% - 80px) !important;
}
.width-50 {
  width: 55% !important;
}
.width-40 {
  width: 40% !important;
}
.noData {
  margin: 50px 0;
  text-align: center;
  color: #9999a2;
}
.el-table {
  color: #333333;
}
.el-table thead {
  color: #333333;
}
.el-table th > .cell {
  text-align: center !important;
}
.el-table td,
.el-table th {
  text-align: center !important;
}
.noData {
  margin: 50px auto;
}
.noData p {
  color: #9999a2;
}
// 图片放大后的关闭按钮
.el-image-viewer__close {
  color: #ffffff;
}
.informatePage .el-image-viewer__close .el-icon-circle-close {
  font-size: 40px;
}
.informatePage .el-tabs__header .el-tabs__nav,
.informatePage .el-tabs__header .el-tabs__item,
.informatePage .el-tabs__header {
  border-color: transparent !important;
  border: 0 !important;
}
// .informatePage .el-tabs{
//   display: inline-block;
//   width: 73%;
// }
.informatePage .el-tabs__header .el-tabs__item {
  padding: 0;
  padding-right: 20px;
}
.informatePage .el-tabs__nav-next,
.informatePage .el-tabs__nav-prev {
  display: none;
}
.informatePage .el-tabs__item {
  font-size: 16px;
}
.informatePage .el-tabs--top.el-tabs__header .el-tabs__item:nth-child(2) {
  padding: 0;
  padding-right: 20px;
}
.informatePage .el-tabs__active-bar {
  background-color: transparent;
}
.informatePage .el-tabs__nav-wrap.is-scrollable {
  padding: 0;
}
.el-card__header {
  padding: 7px 20px;
}
.el-card__header span {
  font-size: 16px;
  color: #409eff;
  font-weight: bold;
}
.companyInfo .el-input {
  margin-bottom: 10px;
  width: 90%;
  margin-right: 5px;
}
.el-icon-delete {
  font-size: 16px;
  cursor: pointer;
}
.el-icon-plus {
  cursor: pointer;
}
.purchaseContainer .el-col-4,
.priceCompetitionContainer .el-col-4 {
  width: 20%;
}
.newSoptStyle .topBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newSoptStyle .topBox .isK,
.newSoptStyle .isNegotiable {
  color: #888;
  text-align: center;
}
.newSoptStyle .topBox .price,
.newSoptStyle .priceQ {
  color: #0193de;
  font-size: 14px;
}
.newSoptStyle .topBox .price .fontStyle,
.newSoptStyle .priceQ .fontStyle {
  font-size: 30px;
  font-weight: bold;
}
.newSoptStyle h1 {
  font-size: 25px;
  line-height: 35px;
}
.newSoptStyle .garyColor {
  color: #888;
  font-style: normal;
}
.newSoptStyle .info {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.newSoptStyle .bottomBox {
  margin-top: 40px;
}
.newSoptStyle .title,
.newSoptStyle .infoQ,
.newSoptStyle .priceQ {
  text-align: center;
}
.newSoptStyle .isNegotiable {
  width: 100px;
  margin: 10px auto;
  border: 1px solid #888;
  border-radius: 13px;
  height: 24px;
  line-height: 24px;
}
.newSoptStyle .priceQ {
  margin-top: 20px;
  position: relative;
}
.newSoptStyle .priceJJ .startPrice {
  position: absolute;
  color: #888;
  font-size: 12px;
  left: 0;
  top: 4px;
  text-align: left;
  width: 80px;
}

.newSoptStyle .priceQ i {
  position: absolute;
  height: 18px;
  width: 46%;
  left: 27%;
  top: 15px;
  background-color: rgba(134, 218, 243, 0.219);
}
.newSoptStyle .priceJJ i {
  width: 70%;
  left: 22%;
}
.newSoptStyle .openTicket {
  color: #333;
  line-height: 35px;
  font-weight: 500;
  font-size: 17px;
  border-top: 1px solid #ddd;
}
.newSoptStyle .bottomBox .date {
  color: #333;
  line-height: 40px;
  border-top: 1px solid #ddd;
}

.newSoptStyle .bottomBox .firstNames {
  line-height: 30px;
  font-size: 14px;
  line-height: 40px;
  color: #888;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #ddd;
}
.newSoptStyle .bottomBox .localName .firstName {
  float: left;
  width: 90%;
  // font-weight: bold;
  font-size: 16px;
  line-height: 50px;
  color: #222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.newSoptStyle .bottomBox .localName .more {
  float: right;
  margin-top: 10px;
}
.ruku .el-message-box__title {
  text-align: center;
}
.delivertip .el-form-item__label {
  color: #cf4640;
  line-height: 25px;
}
.delivertip .el-form-item__content {
  color: #cf4640;
  line-height: 25px;
}
// 输入框 多选下拉 字体颜色
.el-input.is-disabled .el-input__inner {
  color: #606266 !important;
}
.el-radio__input.is-disabled + span.el-radio__label {
  color: #606266 !important;
}


.el-dialog {
  border-radius: 14px;
}
.el-button--primary {
  background: #2a5dfb;
}
.el-message-box{
  border-radius: 8px;
}


</style>
